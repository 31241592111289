import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { oneAudit } from "../../../../../api/auditApi";
import { allListRoles } from "../../../../../api/rolesApi";
import { getAllUsers } from "../../../../../api/userApi";
import { listTeamMembers } from "../../../../../api/teamMembersApi";

import SettingsSplitNavigation from "../../../../../Admin/pages/components/SettingsSplitNavigation/SettingsSplitNavigation";
import { Loading } from "../../../../../components/ui-components";
import Details from "../../../../../Admin/SettingsAudits/AuditSettingsCards/Details";
import Team from "../../../../../Admin/SettingsAudits/AuditSettingsCards/Team";
import DangerZone from "../../../../../Admin/SettingsAudits/AuditSettingsCards/DangerZone";
import Permissions from "../../../../../Admin/SettingsAudits/AuditSettingsCards/Permissions";
import Auditor from "../../../../../Admin/SettingsAudits/AuditSettingsCards/Auditor";

import styles from "./SettingsListAudit.module.scss";

const SettingsListAudits = () => {
  const { id: auditId } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const { user_role: userRole } = useSelector((state) => state.user);
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [companyMembersToInvite, setCompanyMembersToInvite] = useState([]);
  const { isSuperadmin, isAuditor, isAdmin, isMember, hasAdminPermissions } = userRole || {};

  const fetchData = async () => {
    try {
      const rolesResp = await allListRoles(auditId);
      const dataResp = await oneAudit(auditId, { auditFirmLogo: true });
      const users = await getAllUsers();
      const members = await listTeamMembers(auditId);

      const membersEmails = members.teamMembers.map((member) => member.user.email);
      setCompanyMembersToInvite(
        dataResp.audit.company.members.filter(
          (member) => !membersEmails.includes(member.user.email)
        )
      );
      setMembers(members.teamMembers);
      setUsers(users.filter((user) => !membersEmails.includes(user.email)));
      setRoles(
        rolesResp.roles.map((role) => ({
          ...role,
          label: role.name,
          value: role.id,
        }))
      );
      setData(dataResp.audit);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auditId]);

  const syncDataOnSave = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const navigation = useMemo(() => {
    const init = [
      {
        key: "details",
        href: "#details",
        title: t("audit_details"),
        body: <Details companyData={data} auditId={auditId} syncDataOnSave={syncDataOnSave} />,
        bodyStyle: { padding: 0 },
      },
      {
        key: "auditor",
        href: "#auditor",
        title: t("auditor_section"),
        body: (
          <Auditor
            companyData={data}
            auditId={auditId}
            syncDataOnSave={syncDataOnSave}
            isSuperadmin={isSuperadmin}
            isAuditor={isAuditor}
            isAdmin={isAdmin}
            isMember={isMember}
          />
        ),
        bodyStyle: { padding: 0, marginTop: 0 },
      },
      {
        key: "team",
        href: "#team",
        title: t("team_management"),
        body: (
          <Team
            companyData={data}
            roles={roles}
            auditId={auditId}
            syncDataOnSave={syncDataOnSave}
            users={users}
            setUsers={setUsers}
            members={members}
            setMembers={setMembers}
            companyMembersToInvite={companyMembersToInvite}
            setCompanyMembersToInvite={setCompanyMembersToInvite}
            hasAdminPermissions={hasAdminPermissions}
          />
        ),
        bodyStyle: { padding: 0, marginTop: 0 },
      },
      {
        key: "permission control",
        href: "#permission-control",
        title: t("permission_control"),
        body: <Permissions hasAdminPermissions={hasAdminPermissions} roles={roles} />,
        bodyStyle: { padding: 0 },
      },
    ];

    if (isAuditor || isSuperadmin) {
      init.push({
        key: "danger zone",
        href: "#danger-zone",
        title: t("danger_zone"),
        body: <DangerZone companyData={data} syncDataOnSave={syncDataOnSave} />,
        bodyStyle: { padding: 0 },
      });
    }

    return init;
  }, [data, roles, users, members, companyMembersToInvite, userRole]);

  return (
    <div className={styles.wrapper}>
      {loading || !userRole ? (
        <Loading position='absoluteCenter' />
      ) : (
        <SettingsSplitNavigation navigation={navigation} />
      )}
    </div>
  );
};
export default SettingsListAudits;
