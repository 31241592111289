import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getPresets } from "../../../../../../api/statusApi";
import { removeMeetingData, removeRequestData } from "../../../../../../store/global/actions";

import AuditRow from "../../../../../../pages/dashboard/Audits/components/AuditRow";
import EmptyState from "../../../../../../components/EmptyState";
import CreateAudit from "../../../../../../pages/dashboard/Audits/components/CreateAudit";

const ActiveAudits = ({ id, audits = [], isAuditor, isCompanyAdmin, refetch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });
  const { data: presets = [] } = useQuery({
    queryKey: ["presets"],
    queryFn: getPresets,
  });
  const [isCreateAuditOpen, setIsCreateAuditOpen] = useState(false);

  const onSubmit = (id, role) => {
    dispatch(removeRequestData());
    dispatch(removeMeetingData());
    // history.push(`/dashboard/${id}/requests/0`);
    if (isAuditor || isCompanyAdmin || role.overviewAccess) {
      history.push(`/dashboard/${id}/overview`);
    } else if (role.requestsAccess) {
      history.push(`/dashboard/${id}/requests/new`);
    } else if (role.statusAccess) {
      history.push(`/dashboard/${id}/status`);
    } else if (role.documentAccess) {
      history.push(`/dashboard/${id}/documents`);
    } else if (role.meetingAccess) {
      history.push(`/dashboard/${id}/meetings/new`);
    } else {
      history.push(`/dashboard/${id}/settings`);
    }
  };
  return (
    <>
      {audits.length ? (
        audits.map((audit) => <AuditRow {...audit} onSubmit={onSubmit} />)
      ) : (
        <EmptyState
          title={t("empty_state_title")}
          description={t("empty_state_description")}
          buttonText={t("create_audit")}
          onButtonClick={() => setIsCreateAuditOpen(true)}
        />
      )}
      <CreateAudit
        id={id}
        presets={presets}
        isOpen={isCreateAuditOpen}
        onClose={() => setIsCreateAuditOpen(false)}
        onSuccess={refetch}
      />
    </>
  );
};

export default ActiveAudits;
