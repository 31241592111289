import { Modal } from "antd";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "../../../../../../components/ui-components";

import styles from "../../Users.module.scss";

const MakeSuperadminPopup = ({ isOpen, onClose, onSubmit, data }) => {
  const { t } = useTranslation("admin", { keyPrefix: "users" });

  return (
    <Modal
      centered
      open={isOpen}
      className={styles.modal}
      title={t("make_superadmin_popup_title")}
      onCancel={onClose}
      footer={[
        <Button key='back' secondary color='blue' handleClick={onClose}>
          {t("cancel")}
        </Button>,
        <Button key='submit' primary color='red' handleClick={() => onSubmit()}>
          {t("make_super_admin")}
        </Button>,
      ]}
    >
      <p>
        <Trans
          t={t}
          i18nKey='make_superadmin_popup_content'
          values={{ name: data?.name }}
          components={{ b: <b /> }}
        />
      </p>
    </Modal>
  );
};

export default MakeSuperadminPopup;
