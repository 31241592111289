import FileExtension from "../FileExtension";

import styles from "./DocumentOption.module.scss";

const DocumentOption = ({ document, name, bordered = false }) => {
  return (
    <div className={`${styles.root} ${bordered ? styles.bordered : ""}`}>
      <FileExtension document={document || name} />
      <span className={styles.name}>{name || document}</span>
    </div>
  );
};

export default DocumentOption;
