import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { forgotEmail } from "../../../api/recoveryPasswordApi";

import Button from "../../../components/ui-components/Button/Button";
import TextInput from "../../../customComponents/TextInput";

import logo from "../../../assets/images/logo-primary.svg";

import "../../styles/loginPassword.css";

const ForgotPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const initEmail = {
    email: {
      value: location.state ? location.state.email : "",
      error: false,
    },
  };

  const [fields, setFields] = useState(initEmail);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(true);

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
      },
    }));
    setError(false);
  };

  function emailValidation() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+-?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(fields.email.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (emailValidation()) {
      forgotEmail({
        email: fields.email.value,
      })
        .then((res) => {
          if (res.message === "Email sent") {
            history.push("/login/email/send");
          } else {
            setError(res.response.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setError(tGlobal("email_error_invalid"));
    }
  };

  useEffect(() => {
    setActive(emailValidation());
    // eslint-disable-next-line
  }, [fields.email.value]);

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>

        <form onSubmit={onSubmit} className={"login_form"}>
          <p className={"login_form__title"}>{t("action_forgot_password")}</p>
          <div className={"login_form__inputs"}>
            <TextInput
              title={tGlobal("email")}
              placeholder='john@aivo.ch'
              value={fields.email.value}
              htmlFor={"email"}
              onChange={onChange}
              error={error}
              errorMessage={error}
            />
            <Button type='submit' primary large color={"blue"} disabled={!active}>
              {t("password_reset")}
            </Button>
            <Link to={`/login`}>{tGlobal("cancel")}</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
