import { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SettingTeamDetails from "./SettingTeamDetails";
import CheckData from "../../../components/CheckData";

import btnBack from "../../../assets/admin/btnBack.svg";

const AddNewTeamMember = () => {
  const { id: idAudit } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [save, setSave] = useState(false);
  const [active, setActive] = useState(false);
  const [activePage, setActivePage] = useState("settingDetails");

  const onSubmit = () => {
    history.push(`/dashboard/${idAudit}/settings`);
  };

  const pages = {
    settingDetails: (
      <SettingTeamDetails
        save={save}
        close={onSubmit}
        setActive={setActive}
        setSave={() => setSave(false)}
      />
    ),
  };

  return (
    <div>
      <div className='header_settings'>
        <div className='header_settings-left'>
          <img onClick={onSubmit} src={btnBack} alt={btnBack} />
          <span>{t("add_team_member")}</span>
        </div>
        <div className='header_settings-right'>
          <CheckData
            activeBtn={active}
            text={tGlobal("add")}
            discard={onSubmit}
            onClick={setSave}
          />
        </div>
      </div>
      <div className=' settingsAudit_role '>
        <div className='settings_left-sidebar'>
          <ul className='setting_menu setting_menu_details setting_menu_detailsAddTeamMem '>
            <li
              className={`setting_menu-list ${
                activePage === "settingDetails" ? "setting_menu-list-active" : ""
              }`}
              onClick={() => {
                setActivePage("settingDetails");
              }}
            >
              {t("details")}
            </li>
          </ul>
        </div>
        {pages[activePage]}
      </div>
    </div>
  );
};

export default AddNewTeamMember;
