import i18next from "i18next";

export const SORTING_KEYS = {
  NAME: "name",
  TYPE: "type",
  ACCOUNTS: "accounts",
  CREATED: "createdAt",
  SHARED_WITH: "sharedWith",
};

export const SORT_TITLES = [
  {
    title: i18next.t("admin:companies:table_name"),
    key: SORTING_KEYS.NAME,
  },
  {
    title: i18next.t("admin:companies:table_type"),
    key: SORTING_KEYS.TYPE,
  },
  {
    title: i18next.t("admin:companies:table_accounts"),
  },
  {
    title: i18next.t("admin:companies:table_created_on"),
    key: SORTING_KEYS.CREATED,
  },
];
