import { EditorState, RichUtils } from "draft-js";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/underline.svg";
import { useTranslation } from "react-i18next";

type BoldButtonProps = {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
};

const UnderlineButton = ({ editorState, onEditorStateChange }: BoldButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const toggleStyle = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={Icon}
      isActive={editorState.getCurrentInlineStyle().has("UNDERLINE")}
      tooltipTitle={t("underline")}
      onClick={toggleStyle}
    />
  );
};

export default UnderlineButton;
