import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SettingTeamDanger from "./settingTeam/SettingTeamDanger";
import SettingTeamDetails from "./settingTeam/SettingTeamDetails";
import btnBack from "../../assets/admin/btnBack.svg";
import { useHistory } from "react-router-dom";
import { oneTeamMembers, updateTeamMembers } from "../../api/teamMembersApi";
import { Element, Link } from "react-scroll";
import CheckData from "../../components/CheckData";
import { useTranslation } from "react-i18next";

const SettingsTeamEdit = () => {
  const { id: idAudit, idTeam } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [teamData, setTeamData] = useState(false);
  const [active, setActive] = useState(false);

  const onSubmit = () => {
    history.push(`/dashboard/${idAudit}/settings`);
  };

  useEffect(() => {
    oneTeamMembers(idAudit, idTeam)
      .then((res) => {
        setTeamData({
          fullName: res.teamMember.user.fullName,
          email: res.teamMember.user.email,
          phone: res.teamMember.user.phone,
          role: res.teamMember.role.id,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit, idTeam]);

  const onEditSubmit = () => {
    if (active === true) {
      updateTeamMembers(teamData, idAudit, idTeam)
        .then(() => {
          onSubmit();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <div>
      <div className='header_settings'>
        <div className='header_settings-left'>
          <img onClick={onSubmit} src={btnBack} alt={btnBack} />
          <span>{t("edit_team_member")}</span>
        </div>
        <div className='header_settings-right'>
          <CheckData
            activeBtn={active}
            text={tGlobal("save")}
            discard={onSubmit}
            onClick={onEditSubmit}
          />
        </div>
      </div>

      <div className=' settingsAudit_role '>
        <div className='settings_left-sidebar'>
          <ul className='setting_menu setting_menu_details'>
            <Link
              to={"settingsDetails"}
              spy={true}
              activeClass='setting_menu-list-active'
              duration={400}
              offset={-200}
              isDynamic={true}
              className={"setting_menu-list"}
            >
              {t("details")}
            </Link>
            <Link
              to={"settingsDanger"}
              spy={true}
              activeClass='setting_menu-list-active'
              duration={400}
              isDynamic={true}
              offset={-185}
              className={"setting_menu-list"}
            >
              {t("danger_zone")}
            </Link>
          </ul>
        </div>
        {teamData?.fullName && (
          <div className='settingsAudit_role_informationTeam'>
            <Element id={"settingsDetails"}>
              <SettingTeamDetails
                teamData={teamData}
                newTeamData={(e) => setTeamData(e)}
                close={onSubmit}
                setActive={(e) => setActive(e)}
              />
            </Element>
            <Element id={"settingsDanger"}>
              <SettingTeamDanger changeTeamData={teamData} close={onSubmit} />
            </Element>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsTeamEdit;
