import { Link } from "react-router-dom";

import { NotificationSearchType, useNotifications } from "./useNotifications";
import HeaderAdmin from "../../Admin/components/HeaderAdmin/HeaderAdmin";
import { NotificationItem } from "./components/NotificationItem";
import { Loading } from "../../components/ui-components";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const {
    notifications,
    activeTab,
    handleTabChange,
    getNotificationUrl,
    loading,
    cx,
    styles,
    history,
    handleClickImportant,
  } = useNotifications();

  const { t } = useTranslation("dashboard", { keyPrefix: "notifications" });

  return (
    <div className={styles.root}>
      <HeaderAdmin />
      <div className={styles.tabContainer}>
        <h1>{t("notifications")}</h1>
        <div className={styles.tabBody}>
          <button
            className={cx(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === NotificationSearchType.important,
            })}
            onClick={() => {
              handleTabChange("important");
            }}
          >
            {t("important")}
          </button>
          <button
            className={cx(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === NotificationSearchType.general,
            })}
            onClick={() => {
              handleTabChange("general");
            }}
          >
            {t("general")}
          </button>
          <button
            className={cx(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === NotificationSearchType.archive,
            })}
            onClick={() => {
              handleTabChange("archive");
            }}
          >
            {t("archive")}
          </button>
        </div>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}

      {!loading && (
        <div className={styles.list}>
          {notifications.map((item, idx) => (
            <Link
              onClick={() => handleClickImportant(item.id)}
              to={() => getNotificationUrl(item)}
              key={idx}
              className={cx(styles.item, { [styles.unread]: !item.read })}
            >
              <NotificationItem notification={item} history={history} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
