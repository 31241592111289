import { Button } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getDocumentSharedAccess } from "../../Documents.helpers";
import DocumentRowUsersTooltip from "../DocumentRowUsersTooltip";

import styles from "./DocumentSharedWith.module.scss";

const DocumentSharedWith = ({ access, accessibleBy = {}, type }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });

  const [text, icon] = useMemo(
    () => getDocumentSharedAccess(accessibleBy, type),
    [accessibleBy, type]
  );

  return (
    <DocumentRowUsersTooltip
      title={t("table_header_shared_with")}
      users={access?.map((i) => i.user)}
    >
      <Button className={styles.root}>
        {icon}
        {text}
      </Button>
    </DocumentRowUsersTooltip>
  );
};

export default DocumentSharedWith;
