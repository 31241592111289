import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ChangeEvent, MouseEvent, SyntheticEvent, useState } from "react";
import {
  AutocompleteCloseReason,
  Button,
  ClickAwayListener,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";

import StyledAutocomplete from "../../../../../../components/ui-components/StyledAutocomplete";
import { Label } from "../../../../../../components/ui-components";

import { CheckmarkIcon, SearchIcon, ThinChevronBottom } from "../../../../../../assets/icons";

import { AccountingSystemOptions } from "./AccountingSystemAutocomplete.constants";
import styles from "./AccountingSystemAutocomplete.module.scss";

type AccountingSystemAutocompleteProps = {
  error?: string;
  value?: string;
  onChange?: (val: string) => void;
};

export default function AccountingSystemAutocomplete({
  error,
  value,
  onChange,
}: AccountingSystemAutocompleteProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleChange = (_: SyntheticEvent, value: any | null) => {
    if (value) {
      onChange?.(value.code);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  return (
    <div className={styles.wrapper}>
      <Label>{t("accounting")}</Label>
      <div className={styles.container}>
        <Button
          fullWidth
          disableRipple
          variant='outlined'
          aria-describedby={id}
          onClick={handleClick}
          className={classNames(
            styles.selectButton,
            error && styles.selectButtonError,
            open && styles.selectButtonActive
          )}
          endIcon={<ThinChevronBottom />}
        >
          {value ? <span className={styles.label}>{value}</span> : "Select"}
        </Button>
        <Popper
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={styles.popper}
          placement='bottom-start'
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <StyledAutocomplete
                open
                disablePortal
                onChange={handleChange}
                className={styles.autocomplete}
                classes={{
                  popper: styles.autocompletePopper,
                  inputRoot: styles.searchInput,
                  inputFocused: styles.searchInputFocused,
                  paper: styles.autocompletePaper,
                  option: styles.option,
                }}
                popupIcon={false}
                onClose={(event: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                  if (reason === "escape") {
                    handleClose();
                  }
                }}
                options={AccountingSystemOptions}
                getOptionLabel={(option: { code: string; label: any }) => option.code}
                renderOption={(props, option) => (
                  <li
                    key={option.code}
                    {...props}
                    className={classNames(styles.item, props.className)}
                  >
                    <div className={styles.itemLabel}>
                      {option.code}
                      <div className={styles.itemLabelInner}>
                        {option.label}
                        {option.code === value && <CheckmarkIcon className={styles.checkmark} />}
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    autoFocus
                    placeholder='Search countries'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
    </div>
  );
}
