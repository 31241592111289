import { Input } from "antd";
import { Controller, ControllerProps, RegisterOptions } from "react-hook-form";
import { Form } from "antd";

interface FormFieldProps {
  name: string;
  control: any;
  errors: any;
  placeholder?: string;
  rules?: RegisterOptions;
  render?: ControllerProps["render"];
  className?: string;
  disabled?: boolean;
}

const FormField = ({
  name,
  control,
  errors,
  placeholder,
  rules,
  render,
  className,
  disabled,
}: FormFieldProps) => (
  <Form.Item
    className={className}
    validateStatus={errors[name] ? "error" : undefined}
    help={errors[name]?.message}
  >
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      render={render || (({ field }) => <Input placeholder={placeholder} {...field} />)}
    />
  </Form.Item>
);

export default FormField;
