import { Col, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "../../../../../components/ui-components";
import CardActions from "../../../components/CardActions";
import Popup from "../../../../../components/ui-components/Popup";

type DeleteCompanyPopupProps = {
  isOpen: boolean;
  companyName?: string;
  onClose: () => void;
  onSubmit: () => void;
};

const DeleteCompanyPopup = ({
  isOpen,
  companyName,
  onClose,
  onSubmit,
}: DeleteCompanyPopupProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [deleteValue, setDeleteValue] = useState("");

  const closeDeleteCompanyPopup = () => {
    setDeleteValue("");
    onClose();
  };

  return (
    <Popup title={t("delete_company")} onClose={closeDeleteCompanyPopup} isOpen={isOpen}>
      <Space.Compact direction={"vertical"} block className={"popup-body"}>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("confirm_deletion")}</span>
            <span className='caption'>{t("deletion_description")}</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder={t("company_name")}
              value={deleteValue}
              onChange={({ target }) => {
                setDeleteValue(target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
      <CardActions
        onCancel={closeDeleteCompanyPopup}
        onSubmit={onSubmit}
        submitText={t("delete")}
        submitDisabled={deleteValue !== companyName}
        submitButtonColor={"red"}
        cancelButtonColor={"blue"}
      />
    </Popup>
  );
};

export default DeleteCompanyPopup;
