import React, { useEffect, useRef } from "react";
import "./styles/faq.css";
import myAnswer from "../assets/images/my_answer.svg";
import Button from "../customComponents/Button/Button";
import questions from "../assets/images/questions_re.svg";
import { useState } from "react";
import { otherConst } from "../consts/otherConst";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t, i18n } = useTranslation("faq");
  const [byQuestion, setByQuestion] = useState(t("faq_tab_1"));
  const [showQuestion, setShowQuestion] = useState(null);

  useEffect(() => {
    setByQuestion(t("faq_tab_1"));
  }, [i18n.language, t]);

  const icon = (
    <svg
      className={"plus"}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44771 0 8C0 8.55228 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1C9 0.447715 8.55229 0 8 0Z'
        fill='#49BACA'
      />
    </svg>
  );

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft = scrollOffset;
  };

  return (
    <div className={"faq-wrapper"}>
      <Helmet>
        <title>FAQ | aivo</title>
        <meta name='theme-color' content='#f2f5f6' />
        <style>{"body { background-color: #f2f5f6; }"}</style>
      </Helmet>
      <div className={"frequently-questions"}>
        <div className={"faq-info"}>
          <span>{t("title")}</span>
          <p>{t("description")}</p>
          <a href='#section_faq'>
            <Button text={t("button").toUpperCase()} className={"btn_orange"} />
          </a>
        </div>
        <img src={myAnswer} className={"my_answer"} alt={"my-answer"} />
      </div>
      <div id={"section_faq"} />
      <div className={"offer-blocks"} ref={ref}>
        <div
          className={`${
            byQuestion === t("faq_tab_1") ? "faq-offer-block-active" : ""
          } faq-offer-block`}
          onClick={() => {
            setByQuestion(t("faq_tab_1"));
            setShowQuestion(null);
            scroll(-100);
          }}
        >
          <svg
            width='43'
            height='29'
            viewBox='0 0 43 29'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.81805 12.3142H0.876274C0.375546 12.3142 0 12.7452 0 13.1762V27.5839C0 28.0764 0.375546 28.4459 0.876274 28.4459H3.81805C4.56914 28.4459 5.19505 27.8302 5.19505 27.0913V13.6688C5.19505 12.93 4.50655 12.3142 3.81805 12.3142ZM3.50509 16.0085C3.50509 16.4395 3.06696 16.8705 2.62882 16.8705C2.19068 16.8705 1.75255 16.4395 1.75255 16.0085V14.2845C1.75255 13.8535 2.19068 13.4225 2.62882 13.4225C3.06696 13.4225 3.50509 13.8535 3.50509 14.2845V16.0085Z'
              fill='#99B2B5'
            />
            <path
              d='M42.6869 14.6539C42.4991 14.1614 42.1236 13.7919 41.4977 13.7304C41.0595 13.6688 40.5588 13.6688 40.1207 13.9151L29.5428 19.3949C29.5428 19.3949 29.4802 19.3949 29.4802 19.4565L29.2298 19.5181C29.2924 20.0106 29.2924 20.6263 29.2298 21.1805C28.7917 22.6582 27.2895 23.828 25.5996 23.828C25.3492 23.828 25.0362 23.828 24.5981 23.7665L13.8325 20.6879C13.3943 20.5032 13.144 20.1338 13.2691 19.7028C13.4569 19.2718 13.8325 19.0255 14.2706 19.1486L25.0362 22.1656C25.224 22.2272 25.3492 22.2272 25.5996 22.2272C26.5384 22.2272 27.3521 21.673 27.5399 20.8111C27.7902 19.8875 27.2895 18.9023 26.3506 18.4714L19.5908 15.5775C17.5253 14.7155 15.4598 14.3461 13.3317 14.4692L6.82227 14.9002V26.4756L16.8994 28.7537C18.339 29.0616 19.9664 29 21.6563 28.5074L31.3579 25.2442C32.2968 24.9363 33.1105 24.5053 33.9242 23.8896L42.1862 17.4246C43.0624 16.8089 43.3128 15.5775 42.6869 14.6539Z'
              fill='#99B2B5'
            />
            <path
              d='M19.4658 8.92781C19.6536 9.6051 20.8428 9.72824 21.2184 10.2824C21.6565 10.8365 21.4061 11.9448 22.032 12.3758C22.5954 12.7452 23.6594 12.2527 24.2853 12.4374C24.9112 12.6221 25.4745 13.6688 26.163 13.6688C26.8515 13.6688 27.4149 12.6837 28.0408 12.4374C28.6667 12.2527 29.7307 12.7452 30.2941 12.3758C30.8574 11.9448 30.6696 10.8365 31.1077 10.2824C31.4833 9.72824 32.6725 9.6051 32.8603 8.92781C33.0481 8.3121 32.297 7.45011 32.297 6.8344C32.297 6.15711 33.1106 5.35669 32.8603 4.74098C32.6725 4.06369 31.4833 3.94055 31.1077 3.38641C30.6696 2.83227 30.92 1.72399 30.2941 1.29299C29.7307 0.923567 28.6667 1.41614 28.0408 1.23142C27.4149 1.04671 26.8515 0 26.163 0C25.4745 0 24.9112 0.985138 24.2853 1.23142C23.5968 1.41614 22.5954 0.923567 22.032 1.29299C21.4687 1.72399 21.6565 2.83227 21.2184 3.38641C20.8428 3.94055 19.6536 4.06369 19.4658 4.74098C19.278 5.35669 20.0291 6.21868 20.0291 6.8344C20.0291 7.51168 19.2155 8.3121 19.4658 8.92781Z'
              fill='#99B2B5'
            />
          </svg>
          <span>{t("faq_tab_1")}</span>
        </div>
        <div
          className={`${
            byQuestion === t("faq_tab_2") ? "faq-offer-block-active" : ""
          } faq-offer-block`}
          onClick={() => {
            setByQuestion(t("faq_tab_2"));
            setShowQuestion(null);
            scroll(100);
          }}
        >
          <svg
            width='28'
            height='30'
            viewBox='0 0 28 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18.5 0H3.5C1.85 0 0.5 1.35 0.5 3V27C0.5 28.65 1.85 30 3.5 30H19.4L13.85 24.45C12.8 25.05 11.6 25.5 10.25 25.5C6.5 25.5 3.5 22.5 3.5 18.75C3.5 15 6.5 12 10.25 12C14 12 17 15 17 18.75C17 20.1 16.55 21.3 15.95 22.35L23.6 30H24.5C26.15 30 27.5 28.65 27.5 27V9L18.5 0ZM17 10.5V3L24.5 10.5H17Z'
              fill='#99B2B5'
            />
          </svg>

          <span>{t("faq_tab_2")}</span>
        </div>
        <div
          className={`${
            byQuestion === t("faq_tab_3") ? "faq-offer-block-active" : ""
          } faq-offer-block`}
          onClick={() => {
            setByQuestion(t("faq_tab_3"));
            setShowQuestion(null);
            scroll(300);
          }}
        >
          <svg
            width='25'
            height='31'
            viewBox='0 0 25 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M24.9943 2.46483C25.0919 1.13761 23.9203 0 22.4558 0H2.53849C1.07397 0 0 1.13761 0 2.46483V28.5352C0 29.9572 1.17161 31 2.53849 31H22.4558C23.9203 31 24.9943 29.8624 24.9943 28.5352V2.46483ZM18.8434 23.4159H6.15094C5.36987 23.4159 4.68643 22.7523 4.68643 21.9939C4.68643 21.2355 5.36987 20.5719 6.15094 20.5719H18.8434C19.6244 20.5719 20.3079 21.2355 20.3079 21.9939C20.3079 22.7523 19.6244 23.4159 18.8434 23.4159ZM18.8434 16.7798H6.15094C5.36987 16.7798 4.68643 16.1162 4.68643 15.3578C4.68643 14.5994 5.36987 13.9358 6.15094 13.9358H18.8434C19.6244 13.9358 20.3079 14.5994 20.3079 15.3578C20.3079 16.1162 19.6244 16.7798 18.8434 16.7798ZM18.8434 10.1437H6.15094C5.36987 10.1437 4.68643 9.48012 4.68643 8.72171C4.68643 7.9633 5.36987 7.29969 6.15094 7.29969H18.8434C19.6244 7.29969 20.3079 7.9633 20.3079 8.72171C20.3079 9.48012 19.6244 10.1437 18.8434 10.1437Z'
              fill='#99B2B5'
            />
          </svg>
          <span>{t("faq_tab_3")}</span>
        </div>
        <div
          className={`${
            byQuestion === t("faq_tab_4") ? "faq-offer-block-active" : ""
          } faq-offer-block`}
          onClick={() => {
            setByQuestion(t("faq_tab_4"));
            setShowQuestion(null);
            scroll(600);
          }}
        >
          <svg
            width='34'
            height='34'
            viewBox='0 0 34 34'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M29.7582 0H4.24184C1.89251 0 0 1.89251 0 4.24184V21.2745C0 23.6238 1.89251 25.5163 4.24184 25.5163H29.7582C32.1075 25.5163 34 23.6238 34 21.2745V4.24184C34 1.89251 32.1075 0 29.7582 0ZM12.7908 24.4722C12.1382 24.4722 11.7466 24.0154 11.7466 23.428C11.7466 22.7754 12.2035 22.3839 12.7908 22.3839C13.4434 22.3839 13.8349 22.8407 13.8349 23.428C13.8349 24.0154 13.3781 24.4722 12.7908 24.4722ZM17.0326 24.4722C16.38 24.4722 15.9885 24.0154 15.9885 23.428C15.9885 22.7754 16.4453 22.3839 17.0326 22.3839C17.6852 22.3839 18.0768 22.8407 18.0768 23.428C18.0768 24.0154 17.6852 24.4722 17.0326 24.4722ZM21.2745 24.4722C20.6219 24.4722 20.2303 24.0154 20.2303 23.428C20.2303 22.7754 20.6871 22.3839 21.2745 22.3839C21.9271 22.3839 22.3186 22.8407 22.3186 23.428C22.3186 24.0154 21.9271 24.4722 21.2745 24.4722ZM31.9117 19.1209C31.9117 20.2956 30.9328 21.2745 29.7582 21.2745H4.24184C3.06718 21.2745 2.08829 20.2956 2.08829 19.1209V4.24184C2.08829 3.06718 3.06718 2.08829 4.24184 2.08829H29.7582C30.9328 2.08829 31.9117 3.06718 31.9117 4.24184V19.1209Z'
              fill='#99B2B5'
            />
            <path
              d='M23.9498 30.8676H22.3836L21.3394 27.6699H12.8558L12.2032 29.3666C11.8769 30.215 11.159 30.8676 10.1801 30.8676H10.0496C9.20125 30.8676 8.4834 31.5854 8.4834 32.4338C8.4834 33.2822 9.20125 34 10.0496 34H23.8846C24.7329 34 25.4508 33.2822 25.4508 32.4338C25.516 31.5854 24.7982 30.8676 23.9498 30.8676Z'
              fill='#99B2B5'
            />
            <path
              d='M10.5724 4.17661H5.15594C4.50335 4.17661 3.91602 4.76394 3.91602 5.41653V11.4856C3.91602 12.1382 4.43809 12.7255 5.15594 12.7255H10.5072C11.1598 12.7255 11.7471 12.2035 11.7471 11.4856V5.41653C11.7471 4.76394 11.225 4.17661 10.5724 4.17661Z'
              fill='#99B2B5'
            />
            <path
              d='M19.7084 4.17661H14.3571C13.7045 4.17661 13.1172 4.69868 13.1172 5.41653V13.7697C13.1172 14.4223 13.6393 15.0096 14.3571 15.0096H19.7084C20.361 15.0096 20.9483 14.4875 20.9483 13.7697V5.41653C20.9483 4.76394 20.361 4.17661 19.7084 4.17661Z'
              fill='#99B2B5'
            />
            <path
              d='M28.8441 4.17661H23.4929C22.8403 4.17661 22.2529 4.69868 22.2529 5.41653V16.3148C22.2529 16.9674 22.775 17.5547 23.4929 17.5547H28.8441C29.4967 17.5547 30.084 17.0327 30.084 16.3148V5.41653C30.084 4.76394 29.562 4.17661 28.8441 4.17661Z'
              fill='#99B2B5'
            />
          </svg>
          <span>{t("faq_tab_4")}</span>
        </div>
        <div
          className={`${
            byQuestion === t("faq_tab_5") ? "faq-offer-block-active" : ""
          } faq-offer-block`}
          onClick={() => {
            setByQuestion(t("faq_tab_5"));
            scroll(1000);
          }}
        >
          <svg
            width='31'
            height='30'
            viewBox='0 0 31 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4.35234 21.6449C2.71435 21.6449 1.23798 22.6297 0.612339 24.144C-0.0150132 25.6565 0.332182 27.3975 1.48891 28.5541C2.64734 29.7125 4.38842 30.058 5.90079 29.4324C7.41329 28.805 8.39986 27.3286 8.39986 25.6924C8.39986 24.6181 7.97361 23.5886 7.21392 22.8289C6.45422 22.0709 5.42471 21.6447 4.35221 21.6447L4.35234 21.6449Z'
              fill='#99B2B5'
            />
            <path
              d='M25.6476 0.30489C23.9374 0.322078 22.4215 1.41519 21.8646 3.03254H18.7846C18.1383 1.16254 16.2374 0.033322 14.2882 0.35989C12.3375 0.686453 10.9092 2.37425 10.9092 4.35254C10.9092 6.32911 12.3375 8.0169 14.2882 8.34519C16.2373 8.67175 18.1382 7.54082 18.7846 5.67254H21.8199C22.3149 7.1094 23.573 8.14582 25.0786 8.36067C26.5826 8.5738 28.0796 7.92583 28.9544 6.68489C29.831 5.44224 29.9376 3.81459 29.2294 2.46881C28.523 1.12474 27.1239 0.28773 25.6046 0.30489H25.6476Z'
              fill='#99B2B5'
            />
            <path
              d='M25.6476 10.7751C24.0543 10.7493 22.5951 11.6723 21.9368 13.1246C21.2768 14.577 21.5432 16.2803 22.6123 17.4645L17.5523 22.5676C17.1862 22.2634 16.7686 22.0245 16.32 21.8646V18.6076C18.19 17.9631 19.3192 16.0621 18.9926 14.1112C18.666 12.1622 16.9765 10.7339 15 10.7339C13.0234 10.7339 11.3339 12.1622 11.0073 14.1112C10.6807 16.062 11.81 17.963 13.68 18.6076V21.8646C12.176 22.3785 11.1139 23.7243 10.9609 25.3056C10.8096 26.8868 11.5951 28.4113 12.9735 29.2037C14.3502 29.9943 16.0638 29.9084 17.3529 28.9803C18.6437 28.0538 19.271 26.4571 18.9599 24.9L25.0323 18.7848H25.6476C26.7889 18.9034 27.9267 18.5339 28.7792 17.7656C29.6317 16.9991 30.1181 15.9059 30.1181 14.7578C30.1181 13.6114 29.6317 12.5183 28.7792 11.75C27.9267 10.9835 26.7889 10.6122 25.6476 10.7325L25.6476 10.7751Z'
              fill='#99B2B5'
            />
            <path
              d='M25.6474 21.6449C24.0111 21.6449 22.5348 22.6297 21.9074 24.144C21.2818 25.6565 21.6273 27.3975 22.7857 28.5541C23.9424 29.7125 25.6835 30.058 27.1958 29.4324C28.71 28.805 29.6949 27.3286 29.6949 25.6924C29.6949 24.6181 29.2686 23.5886 28.5107 22.8289C27.751 22.0709 26.7215 21.6447 25.6472 21.6447L25.6474 21.6449Z'
              fill='#99B2B5'
            />
            <path
              d='M8.35484 4.35245C8.35999 2.81932 7.49719 1.4151 6.12734 0.725884C4.75749 0.0366676 3.11607 0.179315 1.88719 1.09541C0.65827 2.01321 0.0532709 3.54463 0.323123 5.0537C0.592966 6.56448 1.69298 7.78997 3.1642 8.22478V11.3495V11.3478C1.26499 11.8995 0.0429306 13.742 0.271555 15.7048C0.501868 17.6693 2.11577 19.1801 4.09062 19.2781C6.06547 19.3777 7.82204 18.0354 8.24655 16.1035C8.67108 14.1734 7.63812 12.2175 5.8042 11.48V8.09238C7.33734 7.48223 8.34621 6.00238 8.35484 4.35238V4.35245Z'
              fill='#99B2B5'
            />
          </svg>
          <span>{t("faq_tab_5")}</span>
        </div>
      </div>
      <div className={"get-an-offer"}>
        <h1>{byQuestion}</h1>
        <div className={"offer-questions"}>
          {Object.entries(otherConst.faqOptions).map((item) => {
            return t(item[0]) === byQuestion
              ? item[1].map((i) => (
                  <div
                    className={`${
                      showQuestion === i.question ? "first-question-active" : ""
                    } first-question`}
                    onClick={() => {
                      if (showQuestion === i.question) {
                        setShowQuestion(null);
                      } else {
                        setShowQuestion(i.question);
                      }
                    }}
                  >
                    <div className={"question"}>
                      <span>{t(i.question)}</span>
                      {icon}
                    </div>
                    {showQuestion === i.question ? (
                      <div className='open-answer'>
                        <span>{t(i.response)}</span>
                      </div>
                    ) : null}
                  </div>
                ))
              : null;
          })}
        </div>
      </div>

      <div className={"blue-window"}>
        <div className={"left-side-blue"}>
          <h2>{t("blue_window_title")}</h2>
          <span className={"appointment"}>{t("blue_window_description")}</span>
          <Button text={t("blue_window_btn")} className={"btn_orange"} />
        </div>
        <div className={"right-side-blue"}>
          <img src={questions} className={"questions"} alt='questions' />
        </div>
      </div>
    </div>
  );
};

export default Faq;
