import React, { useMemo, useState } from "react";
import Details from "./Cards/Details";
import Team from "./Cards/Team/Team";
import DangerZone from "./Cards/DangerZone";
import DeleteCompanyPopup from "./DeleteCompanyPopup/DeleteCompanyPopup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingsSplitNavigation from "../../../../pages/components/SettingsSplitNavigation/SettingsSplitNavigation";
import { superadminDeleteCompany } from "../../../../../api/superadminApi";

const SettingsTab = ({ companyData, syncDataOnSave }) => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [deleteCompanyPopupActive, setDeleteCompanyPopupActive] = useState(false);

  const openDeleteCompanyPopup = () => {
    setDeleteCompanyPopupActive(true);
  };

  const closeDeleteCompanyPopup = () => {
    setDeleteCompanyPopupActive(false);
  };

  const onDeleteCompany = async () => {
    await superadminDeleteCompany(companyData.id, companyData.type);
    closeDeleteCompanyPopup();
    history.push("/admin/companies");
  };

  const data = useMemo(() => {
    if (!companyData || companyData.members) return companyData;
    return {
      ...companyData,
      members: companyData.roles.reduce((acc, role) => {
        role.members.forEach((member) => {
          acc.push({
            ...member,
            role: role.name,
          });
        });
        return acc;
      }, []),
    };
  }, [companyData]);

  return (
    <>
      <DeleteCompanyPopup
        isOpen={deleteCompanyPopupActive}
        onClose={closeDeleteCompanyPopup}
        onSubmit={onDeleteCompany}
        companyName={companyData?.name}
      />
      <SettingsSplitNavigation
        navigation={[
          {
            key: "details",
            href: "#details",
            title: t("details"),
            body: <Details companyData={companyData} syncDataOnSave={syncDataOnSave} />,
            bodyStyle: { padding: 0 },
          },
          {
            key: "team",
            href: "#team",
            title: t("team"),
            body: <Team companyData={data} syncDataOnSave={syncDataOnSave} />,
            bodyStyle: { padding: 0, marginTop: 0 },
          },
          {
            key: "danger-zone",
            href: "#danger-zone",
            title: t("danger_zone"),
            body: (
              <DangerZone
                openDeleteCompanyPopup={openDeleteCompanyPopup}
                closeDeleteCompanyPopup={closeDeleteCompanyPopup}
                companyData={companyData}
                syncDataOnSave={syncDataOnSave}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default SettingsTab;
