export default class Keys {
  static JWT_TOKEN = "AivoJwtToken";
  static USER_DATA = "userData";
  static BEXIO_TOKEN = "BexioJwtToken";
  static LAST_VISITED_PAGE = "AIVO_LAST_VISITED_PAGE";
  static PREVIOUS_VISITED_PAGE = "AIVO_PREVIOUS_VISITED_PAGE";
  static BEXIO_REFRESH_TOKEN = "BexioRefreshToken";
  static BEXIO_REDIRECT_URL = "BexioRedirectURL";
  static BEXIO_REQUEST_DATA = "BexioRequestData";
}
