const SET_NEW_REQUEST_DATA = "SET_NEW_REQUEST_DATA";
const REMOVE_NEW_REQUEST_DATA = "REMOVE_NEW_REQUEST_DATA";

const SET_NEW_MEETING_DATA = "SET_NEW_MEETING_DATA";
const REMOVE_NEW_MEETING_DATA = "REMOVE_NEW_MEETING_DATA";

const HIGHLIGHT_PROFILE_DETAILS = "HIGHLIGHT_PROFILE_DETAILS";
const UNHIGHLIGHT_PROFILE_DETAILS = "UNHIGHLIGHT_PROFILE_DETAILS";

export {
  SET_NEW_REQUEST_DATA,
  REMOVE_NEW_REQUEST_DATA,
  SET_NEW_MEETING_DATA,
  REMOVE_NEW_MEETING_DATA,
  HIGHLIGHT_PROFILE_DETAILS,
  UNHIGHLIGHT_PROFILE_DETAILS,
};
