import React from "react";
import classes from "./Logo.module.scss";
import { LogoPlaceholder } from "../../assets/icons";

const Logo = ({ logo, size }) => {
  return (
    <div className={classes.root} {...(size && { styles: { width: size, height: size } })}>
      {logo ? <img src={logo} alt='' /> : <LogoPlaceholder />}
    </div>
  );
};

export default Logo;
