import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import Button from "../../components/ui-components/Button/Button";

import { DoneIcon } from "../../assets/icons";
import logo from "../../assets/images/logo-primary.svg";

const LoginEmailSend = () => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });

  const onSubmit = () => {
    history.push(`/login`);
  };

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>
        <div className={"login_form"}>
          <DoneIcon />
          <p className={"login_form__title"}>{t("reset_send")}</p>
          <p className={"login_form__subtitle login_form__subtitle-sendEmail "}>
            {t("check_mailbox")}
          </p>
          <div className={"login_form__inputs"}>
            <Button handleClick={onSubmit} primary large color={"blue"}>
              {t("go_back_login")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginEmailSend;
