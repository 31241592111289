import { useEffect, useMemo, useState } from "react";
import { Avatar, Card } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { deleteMember, inviteTeamMembers, updateTeamMembers } from "../../../api/teamMembersApi";

import { Button } from "../../../components/ui-components";
import MemberInfo from "../../../components/MemberRow/MemberInfo";
import InviteForm from "../../../components/InviteForm";
import MemberActions from "../../../components/MemberRow/MemberActions";

import classes from "../../pages/Company/CompanySettings/Cards/Team/Team.module.scss";

const Team = ({
  companyData,
  members,
  setMembers,
  users,
  setUsers,
  auditId,
  roles,
  companyMembersToInvite,
  setCompanyMembersToInvite,
  hasAdminPermissions,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const initRole = roles.sort((a, b) => b.id - a.id)?.[0].id;
  const initInvite = {
    email: "",
    role: initRole,
  };
  const [error, setError] = useState("");
  const [invite, setInvite] = useState(initInvite);
  const [shownMenu, setShowMenu] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
  }, [users]);

  const inputData = (key, value) => {
    setInvite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const inviteMember = async () => {
    try {
      const member = await inviteTeamMembers(invite, auditId);
      setMembers((state) => [...state, member]);
      setUsers((state) => state.filter((user) => user.email !== invite.email));
      setCompanyMembersToInvite((state) =>
        state.filter((user) => user.user.email !== invite.email)
      );
      setInvite(initInvite);
    } catch (error) {
      setError(error?.response?.data);
      console.log(error);
    }
  };

  const addToAudit = async (email) => {
    try {
      const member = await inviteTeamMembers({ email, role: initRole }, auditId);
      setMembers((state) => [...state, member]);
      setCompanyMembersToInvite((state) => state.filter((user) => user.user.email !== email));
    } catch (error) {
      console.log(error);
    }
  };

  const changeRole = async (id, role) => {
    try {
      if (role === "remove") {
        let memberToInvite;
        await deleteMember(auditId, id);
        setMembers((state) =>
          state.filter((item) => {
            if (item.id !== id) {
              return item;
            } else {
              memberToInvite = item;
            }
          })
        );
        setCompanyMembersToInvite((state) => [memberToInvite, ...state]);
        return;
      }

      const member = await updateTeamMembers({ role }, auditId, id);
      setMembers((state) => state.map((item) => (item.id === id ? member.member : item)));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeInvite = ({ target }) => {
    const inputEmail = target.value;
    inputData("email", inputEmail);
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.firstName.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.lastName.toLowerCase().includes(inputEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    setShowMenu(true);
  };

  const activeRole = roles.find((role) => role.id === invite.role);

  const handleClickUser = (email) => {
    inputData("email", email);
    setShowMenu(false);
    setFilteredUsers(users);
  };
  const [rolesFormatted, rolesWithActions] = useMemo(() => {
    const rolesFormatted = roles.map((i) => ({ label: i.name, value: i.id }));
    return [
      rolesFormatted,
      [...rolesFormatted, { label: "Remove user", value: "remove", caution: true }],
    ];
  }, [roles]);

  const memberAmount = members.length;
  return (
    <>
      <InviteForm
        placeholder={t("invite_audit")}
        allowMenu={shownMenu}
        handleClickUser={handleClickUser}
        filteredUsers={filteredUsers}
        invite={invite}
        inviteRoles={roles}
        inputData={inputData}
        inviteMember={inviteMember}
        onChangeInvite={onChangeInvite}
        activeRole={activeRole}
        error={error}
        setError={setError}
      />
      {members
        .sort((a, b) => b.id - a.id)
        .map((member) => {
          const { id, firstName, lastName, lastActive, email } = member.user;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid, {
            [classes.highlighted]: id === error?.userId,
          });
          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <MemberInfo
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  lastActive={lastActive}
                  addedAt={member.addedAt}
                />

                <MemberActions
                  allowEdit={hasAdminPermissions && memberAmount > 1}
                  role={member.role.id}
                  tRole={member.role.name}
                  roles={rolesWithActions}
                  member={member}
                  onChangeRole={changeRole}
                  hideSignatory
                />
              </div>
            </Card.Grid>
          );
        })}
      {companyMembersToInvite
        .filter(
          (i) => companyData.auditors.some((j) => j.member.user.email === i.user.email) === false
        )
        .map((member) => {
          const { firstName, lastName, email } = member.user;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid);
          const hasName = firstName || lastName;
          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <div className={classes.memberInfo}>
                  <Avatar
                    style={{ backgroundColor: "#0F8594", verticalAlign: "middle", opacity: 0.3 }}
                    size={32}
                    gap={4}
                  >
                    {(firstName[0] || "" || email[0] || "").toUpperCase()}
                  </Avatar>
                  <div className={classes.memberName}>
                    {hasName && (
                      <div className={classes.memberTopRow}>
                        {hasName && (
                          <span className={classes.name}>{`${firstName || ""} ${
                            lastName || ""
                          }`}</span>
                        )}
                      </div>
                    )}
                    <span className={classes.email}>{email}</span>
                  </div>
                </div>
                <Button
                  style={{ width: 150 }}
                  secondary
                  color='gray'
                  handleClick={() => addToAudit(email)}
                >
                  {t("add_to_audit")}
                </Button>
              </div>
            </Card.Grid>
          );
        })}
    </>
  );
};

export default Team;
