export const REQUEST_STATUSES = {
  SENT: "sent",
  REQUESTED: "requested",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const DOCUMENT_STATUSES = {
  MISSING_SIGNATURE: "missing_signature",
  UNSIGNED: "unsigned",
  FAILED: "failed",
  SIGNED: "signed",
};

export const REQUEST_STATUS_ORDERED = [
  REQUEST_STATUSES.REQUESTED,
  REQUEST_STATUSES.SENT,
  REQUEST_STATUSES.APPROVED,
];

export const STATUSES_STATUS = {
  DONE: "done",
  IN_PROGRESS: "in_progress",
  NOT_STARTED: "not_started",
};

export const COLORS = {
  DARK: "dark",
  GREEN: "green",
  BLUE: "blue",
  ORANGE: "orange",
  RED: "red",
};

export const MEMBER_ROLES = {
  SUPER_ADMIN: "super-admin",
  USER: "user",
  AUDITOR: "auditor",
  ADMIN: "admin",
  MEMBER: "member",
};
export const REQUEST_TYPE = {
  FILE_REQUEST: "file_request",
  DATA_TRANSFER_REQUEST: "data_transfer_request",
};

export const ROLES_REQUSTED_TEXT = {
  [REQUEST_STATUSES.REQUESTED]: "status_requested",
  [REQUEST_STATUSES.SENT]: "status_sent",
  [REQUEST_STATUSES.APPROVED]: "status_approved",
  [REQUEST_STATUSES.REJECTED]: "status_rejected",
};

export const STATUS_COLORS = {
  [REQUEST_STATUSES.REQUESTED]: COLORS.DARK,
  [REQUEST_STATUSES.SENT]: COLORS.BLUE,
  [REQUEST_STATUSES.APPROVED]: COLORS.GREEN,
  [REQUEST_STATUSES.REJECTED]: COLORS.ORANGE,
  [DOCUMENT_STATUSES.MISSING_SIGNATURE]: COLORS.ORANGE,
  [DOCUMENT_STATUSES.UNSIGNED]: COLORS.ORANGE,
  [DOCUMENT_STATUSES.FAILED]: COLORS.RED,
  [DOCUMENT_STATUSES.SIGNED]: COLORS.GREEN,
  [STATUSES_STATUS.DONE]: COLORS.GREEN,
  [STATUSES_STATUS.IN_PROGRESS]: COLORS.ORANGE,
  [STATUSES_STATUS.NOT_STARTED]: COLORS.DARK,
};
