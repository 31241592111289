import i18next from "i18next";

import { TableHeaderProps } from "../../../components/TableHeader";

export const SORT_TITLES: TableHeaderProps["titles"] = [
  { title: i18next.t("admin:vouchers:table_code"), key: "code" },
  { title: i18next.t("admin:vouchers:table_user"), key: "user.firstName" },
  { title: i18next.t("admin:vouchers:table_date"), key: "attachedAt" },
  { title: i18next.t("admin:vouchers:table_used"), key: "used" },
  { title: "" },
];

export const VOUCHERS_TABS = ["all", "used", "unused"];
