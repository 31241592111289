import classNames from "classnames";
import PhoneInput, { Props as PhoneInputProps } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { ReactComponent as ArrowIcon } from "../../../assets/images/arrow-down-filled.svg";

import styles from "./StyledPhoneInput.module.scss";

type Props = PhoneInputProps<{
  isHighlighted?: boolean;
  error?: boolean;
  errorMessage?: string;
}>;

export default function StyledPhoneInput({
  isHighlighted,
  className,
  error,
  errorMessage,
  ...props
}: Props) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.withError]: error,
        [styles.highlighted]: isHighlighted,
      })}
    >
      <PhoneInput
        international
        limitMaxLength
        defaultCountry='CH'
        placeholder='+41 1234 5678'
        {...props}
        className={classNames(styles.input, className)}
        countrySelectProps={{
          className: styles.countrySelect,
          arrowComponent: ArrowIcon,
        }}
      />
      {error && errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
}
