import { useEffect, useMemo } from "react";
import classNames from "classnames/bind";

import { Input } from "../../../../components/ui-components";

import styles from "./FormPicker.module.scss";

const cx = classNames.bind(styles);

const FormPicker = ({
  options,
  selected,
  initialValue,
  onSelect,
  label,
  labelId,
  optionSize,
  name,
  error,
  hasOther,
  otherError,
  disabled,
}) => {
  const handleSelect = (value) => {
    onSelect(name, value);
  };

  useEffect(() => {
    if (initialValue && !selected) {
      onSelect(name, initialValue);
    }
  }, []);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selected),
    [selected, options]
  );
  const isOther = !options.some((option) => option.value === selected) && !!selected;
  return (
    <div className={`${styles.column} ${disabled ? styles.disabled : ""}`}>
      {label && (
        <label className={styles.label} id={labelId}>
          {label}
        </label>
      )}
      <div className={styles.row}>
        {options.map(({ name, value, disabled }, index) => (
          <div
            onClick={() => handleSelect(value)}
            className={cx(
              styles.option,
              selected === value && styles.selected,
              optionSize && styles[optionSize],
              disabled && styles.disabled,
              error && styles.error
            )}
            key={index}
          >
            {name}
          </div>
        ))}
      </div>
      {hasOther && (
        <div className={cx(styles.column, styles.other)}>
          <div
            onClick={() => handleSelect("other")}
            className={cx(styles.option, styles.otherOption, isOther && styles.selected)}
          >
            Other {label}
          </div>
          {isOther && (
            <Input
              name={name}
              error={otherError}
              placeholder={`Specify the ${label}`}
              onHandleChange={onSelect}
            />
          )}
        </div>
      )}
      {selectedOption?.description && (
        <span className={styles.description}>{selectedOption.description}</span>
      )}
    </div>
  );
};

export default FormPicker;
