import { CollapseProps } from "antd";
import i18next from "i18next";
import { useState } from "react";
import TableHeader from "../../../../../../../../Admin/components/TableHeader";
import StyledCollapse from "../../StyledCollapse";
import RiskItem from "../RiskItem";
import styles from "./RisksTable.module.scss";

export const SORT_TITLES = [
  { title: i18next.t("dashboard:status:risk_table_header_level"), key: "level" },
  { title: i18next.t("dashboard:status:risk_table_header_description"), key: "description" },
  { title: i18next.t("dashboard:status:risk_table_header_type"), key: "type" },
];

type Props = {
  editMode: boolean;
};

export default function RisksTable({ editMode }: Props) {
  const [filters, setFilters] = useState({});
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <RiskItem
          status='high'
          type='Fraud'
          title='Valuation and existence of related party  transactions...'
          description='FSA, accounts 4200 - 4204'
        />
      ),
      children: (
        <div className={styles.list}>
          <RiskItem type='Fraud' title='Sed posuere consectetur est a' status='inquiry' />
          <RiskItem
            type='Fraud'
            title='Sed posuere consectetur est at lobortis. Praesent commodo cursus magna, vel.'
            status='general'
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <RiskItem
          type='Fraud'
          title='None and no indication for the necessity to build some.'
          status='medium'
        />
      ),
      children: (
        <div className={styles.list}>
          <RiskItem type='Fraud' title='Sed posuere consectetur est a' status='inquiry' />
          <RiskItem
            type='Fraud'
            title='Sed posuere consectetur est at lobortis. Praesent commodo cursus magna, vel.'
            status='general'
          />
        </div>
      ),
    },
    {
      key: "3",
      label: <RiskItem type='Fraud' title='Completeness of other operating expenses' />,
      children: (
        <div className={styles.list}>
          <RiskItem type='Fraud' title='Sed posuere consectetur est a' status='inquiry' />
          <RiskItem
            type='Fraud'
            title='Sed posuere consectetur est at lobortis. Praesent commodo cursus magna, vel.'
            status='general'
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {!editMode && (
        <TableHeader
          titles={SORT_TITLES}
          filters={filters}
          wrapperStyles={{ gridTemplateColumns: "80px 1fr 100px 30px" }}
          onChange={setFilters}
        />
      )}
      <div className={styles.list}>
        <StyledCollapse editMode={editMode} items={items} />
      </div>
    </>
  );
}
