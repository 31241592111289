import { Button, Dropdown } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { getMyTeam } from "../../../api/teamApi";
import { forceLogout } from "../../../api/API";
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";

import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import { ReactComponent as GearIcon } from "../../../assets/icons/gear.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as WorkIcon } from "../../../assets/icons/work.svg";

import styles from "./DropDownUser.module.scss";

const DropDownUser = () => {
  const { data } = useQuery({
    queryKey: ["team"],
    queryFn: getMyTeam,
    refetchInterval: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { user_data } = useSelector((state) => state.user);

  const fullName = `${user_data?.firstName ?? ""} ${user_data?.lastName ?? ""}`.trim();

  const items = [
    {
      label: (
        <Link to='/account/settings' className={styles.link}>
          <UserIcon className={styles.linkIcon} />
          {t("account_settings")}
        </Link>
      ),
      key: "0",
    },
    data?.team && {
      label: (
        <Link to='/company/settings' className={styles.link}>
          <WorkIcon className={styles.linkIcon} />
          {t("company_settings")}
        </Link>
      ),
      key: "1",
    },
    user_data?.role === "super-admin" && {
      label: (
        <Link to='/admin/companies' className={styles.link}>
          <GearIcon className={styles.linkIcon} />
          {t("platform_admin")}
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <div className={clsx(styles.link, styles.linkDanger)}>
          <LogoutIcon className={styles.linkIcon} />
          {t("logout")}
        </div>
      ),
      key: "3",
      onClick: forceLogout,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
    >
      <Button type='text' className={styles.button}>
        <span>{fullName || tGlobal("no_name")}</span>
        <CustomAvatar height={24} width={24} user={user_data} />
      </Button>
    </Dropdown>
  );
};

export default DropDownUser;
