import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import auditsComplete from "../../../../../../assets/admin/AuditsComplete.svg";

const FinalisedAudits = ({ audits = [] }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  return audits.length > 0 ? (
    <>
      {audits.map((i) => (
        <Card.Grid hoverable={false} style={{ padding: "16px 24px" }}>
          <Row align='middle' gutter={30}>
            <Col style={{ height: 48 }}>
              <img src={auditsComplete} alt='auditsComplite' />
            </Col>
            <Col>
              <span className='audits_info_block-info_subtitle'>{i.name}</span>
            </Col>
          </Row>
        </Card.Grid>
      ))}
    </>
  ) : (
    <div className='empty-section'>
      <span className='title'>{t("archive_is_empty_title")}</span>
      <span>{t("archive_is_empty_description")}</span>
    </div>
  );
};

export default FinalisedAudits;
