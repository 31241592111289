import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

function useDeepEqualEffect(callback: () => void, dependencies: any[]) {
  const dependenciesRef = useRef<any[]>([]);

  if (!isEqual(dependenciesRef.current, dependencies)) {
    dependenciesRef.current = dependencies;
  }

  useEffect(callback, [dependenciesRef.current]);
}

export default useDeepEqualEffect;
