import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { sendSmS } from "../../api/phoneApi";
import Keys from "../../consts/helper";

import { Button } from "../../components/ui-components";

import logo from "../../assets/images/logo-primary.svg";

import "../../Admin/styles/loginPhone.css";

const LoginPhone = () => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });

  const userData = JSON.parse(localStorage.getItem(Keys.USER_DATA));
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (active === true) {
      try {
        await sendSmS({ phone: value }, userData.token);

        localStorage.setItem(
          Keys.USER_DATA,
          JSON.stringify({
            ...userData,
            phone: value,
          })
        );
        history.push("/login/phone/sms");
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setError(true);
    }
  };

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>

        <form onSubmit={onSubmit} className={"login_form"}>
          <p className={"login_form__title"}>{t("phone_number")}</p>
          <p className={"login_form__subtitle login_form__subtitle-inner "}>
            {t("enter_phone_number_description")}
          </p>
          <div className={"login_form__inputs"}>
            <div className='login_form__inputs_flag'>
              <div
                className={`${
                  error ? "login_form__inputs_flag-inner-error" : ""
                } login_form__inputs_flag-inner`}
              >
                <span className='input_text'>{t("phone_number")}</span>

                <div className='login_form__inputs_flag-left'></div>
                <div className='login_form__inputs_flag-right'>
                  <PhoneInput
                    international
                    placeholder={"079 916 46 27"}
                    value={value}
                    limitMaxLength
                    onChange={(value) => {
                      setActive(value?.length > 9);
                      setValue(value);
                      setError(false);
                    }}
                  />
                </div>
              </div>
            </div>

            <Button primary large color={"blue"} disabled={!active}>
              {t("next")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPhone;
