export function getHumanFileSize(size: number, addSuffix: boolean = true): string {
  if (size === 0) return "0 B";
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const suffix = addSuffix ? ["B", "kB", "MB", "GB", "TB"][i] : "";
  return (size / Math.pow(1024, i)).toFixed(1) + " " + suffix;
}

export const hexToRgba = (hex: string, opacity: number) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
