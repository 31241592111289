import React from "react";
import classNames from "classnames/bind";
import styles from "./Button.module.scss";

const cx = classNames.bind(styles);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  color:
    | "lightRed"
    | "red"
    | "gray"
    | "green"
    | "lightGreen"
    | "blue"
    | "lightBlue"
    | "bright"
    | "dark";
  modal?: boolean;
  fullWidth?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  handleClick,
  children,
  color,
  fullWidth,
  small = false,
  large = false,
  medium = false,
  primary = false,
  secondary = false,
  tertiary = false,
  modal = false,
  icon,
  ...restAttributes
}) => {
  const className = cx(styles.button_root, {
    large,
    medium,
    small,
    secondary,
    primary,
    fullWidth,
    tertiary,
    modal,
    icon,
    [color]: true,
  });
  return (
    <button
      onClick={handleClick}
      {...restAttributes}
      className={`${className} ${restAttributes.className || ""}`}
    >
      {icon}
      {children}
    </button>
  );
};

export default Button;
