import i18next from "i18next";
import React, { useState } from "react";
import TableHeader from "../../../../../../../Admin/components/TableHeader";
import EditDocument from "../../../../../../../components/EditDocument";
import NewDocument from "../../../../../../../components/NewDocument";

import { Document } from "../../../../../../../types/document";
import { ReactComponent as SearchIcon } from "../../../../../../../assets/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../../../../../../assets/icons/upload.svg";
import { Button } from "../../../../../../../components/ui-components";
import DocumentRow from "../../../../../Documents/components/DocumentRow";
import StatusTableActions from "../StatusTableActions";

import styles from "./StatusDocuments.module.scss";

const SORT_TITLES = [
  { title: i18next.t("dashboard:status:risk_table_header_name"), key: "name" },
  { title: i18next.t("dashboard:status:risk_table_header_signature"), key: "signature" },
];

type Props = {
  documents: Document[];
};

export default function StatusDocuments({ documents }: Props) {
  const [addNewDocument, setAddNewDocument] = useState(false);
  const [editDoc, setEditDoc] = useState<Document | null>(null);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.title}>Documents</h3>
          <div className={styles.headerActions}>
            <Button color='gray' icon={<SearchIcon />} />
            <Button small primary color='blue' onClick={() => setAddNewDocument(true)}>
              <PencilIcon width={24} height={24} />
              Upload
            </Button>
          </div>
        </div>
        <div className={styles.subheader}>
          <StatusTableActions />
        </div>
        <TableHeader
          titles={SORT_TITLES}
          wrapperStyles={{ gridTemplateColumns: "1fr 150px 35px" }}
        />
        <div className={styles.list}>
          {documents.map((d: Document) => (
            // @ts-ignore
            <DocumentRow
              key={d.id}
              document={d}
              hideDownload
              style={{ gridTemplateColumns: "1fr 150px 35px" }}
              renderFields={["name", "signatures"]}
              onEdit={() => setEditDoc(d)}
            />
          ))}
        </div>
        {addNewDocument && <NewDocument activeTab='all' close={setAddNewDocument} />}
        {editDoc && (
          <EditDocument
            id={editDoc.id}
            docName={editDoc.name}
            docFile={editDoc.document}
            requiresSignature={editDoc.requiresSignature}
            closeDrop={setEditDoc}
            assigners={editDoc.signers}
          />
        )}
      </div>
    </>
  );
}
