import { useState, useEffect } from "react";

const usePasswordValidator = (password = "", repeatPassword = "") => {
  const [validationState, setValidationState] = useState({
    hasSpecialCharacter: false,
    hasUpperCase: false,
    hasNumber: false,
    isMinLength: false,
    arePasswordsEqual: false,
  });

  useEffect(() => {
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isMinLength = password.length >= 8;
    const arePasswordsEqual = password === repeatPassword;

    setValidationState({
      hasSpecialCharacter,
      hasUpperCase,
      hasNumber,
      isMinLength,
      arePasswordsEqual,
    });
  }, [password, repeatPassword]);

  return validationState;
};

export default usePasswordValidator;
