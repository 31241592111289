import API from "./API.js";

export const addAudit = async (body, token) => {
  try {
    const response = await API(token).post("/audit/create", body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const allAudits = async () => {
  try {
    const response = await API().get("/audit/");
    return response.data;
  } catch (error) {
    return error;
  }
};
export const oneAudit = async (id, params) => {
  try {
    const response = await API().get(`/audit/${id}`, { params });
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteAudit = async (id) => {
  const response = await API().delete(`/audit/${id}`);
  return response.data;
};
export const auditorFirmMembers = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/auditor-firm-members`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addAuditor = async (id, body) => {
  const response = await API().post(`/audit/${id}/add-auditor`, body);
  return response.data;
};

export const removeAuditor = async (id, auditorId) => {
  try {
    const response = await API().delete(`/audit/${id}/auditor/${auditorId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const changeAuditorRole = async (id, auditorId, body) => {
  try {
    const response = await API().post(`/audit/${id}/auditor/${auditorId}/role`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateAudit = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/update`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const generateAuditReport = async (id, name) => {
  try {
    const response = await API().post(`/audit/${id}/generate-report`, {}, { responseType: "blob" });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${name} Report.pdf`;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    return error;
  }
};

// export const updateLogo = async (body) => {
//   const FD = new FormData();
//   FD.append('file', body.files[0]);
//   try {
//     const response = await API().post('/upload', FD);
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };
