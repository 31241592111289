import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import { getCompany } from "../../../api/requestsPage";
import { useFetch } from "../../../hooks";

import CompanyWrapper from "./CompanyWrapper";
import CompanyAudits from "./CompanyAudits";
import CompanySettings from "./CompanySettings";
import { Loading } from "../../../components/ui-components";

import HeaderAdmin from "../../components/HeaderAdmin";

const auditsTab = {
  key: "audits",
  label: "audits",
};

const settingsTab = {
  key: "settings",
  label: "settings",
};

const params = {
  params: {
    auditors: true,
  },
};

const CompanyPage = () => {
  const { id, path } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const { data, refetch } = useFetch(getCompany, id, params);

  const [companyData, setCompanyData] = useState(null);
  const [tabs, setTabs] = useState([auditsTab]);
  const BASE_URL = `/company/${id}`;

  useEffect(() => {
    if (!data) return;
    setCompanyData(data.company);

    if (
      (!data.company?.members?.length > 0 &&
        !data.company.auditor &&
        !tabs.some((i) => i.key === "setting")) ||
      (!data.company.isCompanyAdmin && !data.company.isAuditor)
    )
      return;
    setTabs((state) =>
      Array.from(new Map([settingsTab, ...state].map((item) => [item.key, item])).values())
    );
  }, [data]);

  const changeTab = (key) => {
    history.push(`${BASE_URL}/${key}`);
  };

  const syncDataOnSave = (data) => {
    setCompanyData((state) => ({ ...state, ...data }));
  };

  return (
    <>
      <HeaderAdmin backTo='/audits' />
      {!companyData ? (
        <div className='loading_wrapper'>
          <Loading />
        </div>
      ) : (
        <CompanyWrapper companyData={companyData}>
          <Tabs
            items={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            activeKey={path}
            defaultActiveKey={path || tabs[0].key}
            onChange={changeTab}
          />
          <Switch>
            <Route
              path={`${BASE_URL}/audits`}
              render={(props) => (
                <CompanyAudits {...props} id={id} companyData={companyData} refetch={refetch} />
              )}
              companyData={companyData}
            />
            <Route
              path={`${BASE_URL}/settings`}
              component={(props) => (
                <CompanySettings
                  {...props}
                  companyData={companyData}
                  syncDataOnSave={syncDataOnSave}
                />
              )}
            />
          </Switch>
        </CompanyWrapper>
      )}
    </>
  );
};

export default CompanyPage;
