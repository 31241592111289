import { Trans, useTranslation } from "react-i18next";

import styles from "./AttachmentsAuditorInfo.module.scss";

export default function AttachmentsAuditorInfo() {
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  return (
    <div className={styles.root}>
      <Trans t={t} i18nKey='attachments_auditor_info' components={{ ul: <ul />, li: <li /> }} />
    </div>
  );
}
