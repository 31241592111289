import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./dropDown.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { allListRoles } from "../../../../api/rolesApi";
import { changeRoleTeamMembers } from "../../../../api/teamMembersApi";
import { setUserPermissionsRole } from "../../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";

const DropDown = ({ valueId, setValue, changeRole, idTeam, disable, check, customRoles }) => {
  const { id: idAudit } = useParams();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.user_data?.id);
  const [roles, setRoles] = useState(customRoles || []);

  useEffect(() => {
    if (customRoles) return;

    allListRoles(idAudit)
      .then((res) => {
        setRoles(res.roles);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit]);
  const onSubmit = (id) => {
    changeRoleTeamMembers(
      {
        role: id,
      },
      idAudit,
      idTeam
    )
      .then((res) => {
        if (userId === res.teamMember.user.id) {
          dispatch(setUserPermissionsRole(res.teamMember));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  let role = valueId ? roles.find((el) => el.id === valueId) : "";
  const options = roles.map((i) => i.name);

  return (
    <div>
      <Dropdown
        options={options}
        onChange={(target) => {
          const id = roles.find((el) => el.name === target.value).id;
          changeRole && onSubmit(id);
          setValue?.(id);
        }}
        value={role ? role.name : ""}
        disabled={disable}
        className={check ? "error-dropdown" : ""}
        style
      />
    </div>
  );
};

export default DropDown;
