import dayjs from "dayjs";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { Voucher } from "../../../../../../types/vouchers";
import { useVouchers } from "../../../../../../hooks/services/useVouchers";

import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";
import ActionDropdown from "../../../../../../components/ui-components/ActionDropdown";

import styles from "./VouchersRow.module.scss";

interface VouchersRowProps extends Voucher {}

const VouchersRow = ({ id, code, attachedAt, user, used }: VouchersRowProps) => {
  const { deleteVoucher, detachVoucher, toggleVoucherUsage } = useVouchers();
  const { t } = useTranslation("admin", { keyPrefix: "vouchers" });
  const [messageApi, contextHolder] = message.useMessage();

  const name = `${user?.firstName} ${user?.lastName}`;

  const voucherActions = [
    {
      label: t("copy"),
      value: "copy",
    },
    ...(user
      ? [
          {
            label: t("detach_user"),
            value: "detach",
            caution: true,
          },
        ]
      : []),
    {
      label: used ? t("mark_as_unused") : t("mark_as_used"),
      value: "toggle_used",
      caution: true,
    },
    {
      label: t("delete"),
      value: "delete",
      caution: true,
    },
  ];

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(code);
    messageApi.success(t("voucher_copied_to_clipboard"));
  };

  const onActionCallback = (name: string, value: string) => {
    if (value === "copy") {
      handleCopyToClipboard();
    } else if (value === "toggle_used") {
      toggleVoucherUsage({ id, used: !used });
    } else if (value === "delete") {
      deleteVoucher(`${id}`);
    } else if (value === "detach") {
      detachVoucher(`${id}`);
    }
  };

  return (
    <div className={styles.root}>
      {contextHolder}
      <div className={styles.code}>{code}</div>
      <div className={styles.row}>
        {user && (
          <>
            <CustomAvatar user={user} height={24} width={24} />
            <span className={styles.name}>{!!name.trim() ? name : user?.email}</span>
          </>
        )}
      </div>
      <div className={styles.date}>
        {attachedAt && dayjs(attachedAt).format("D MMMM YYYY, HH:mm")}
      </div>
      <div className={styles.date}>{used ? "Yes" : "No"}</div>
      {/*<div>*/}
      {/*  <Button type='text' icon={<CopyIcon />} onClick={handleCopyToClipboard} />*/}
      {/*</div>*/}
      <ActionDropdown onChange={onActionCallback} options={voucherActions} />
      <hr className={styles.divider} />
    </div>
  );
};

export default VouchersRow;
