import React from "react";
import classes from "./InputIcon.module.scss";
import { Input } from "../index";

const InputIcon = ({ icon, onIconClick, ...props }) => {
  return (
    <div className={classes.root}>
      <Input {...props} />
      <div onClick={onIconClick} className={classes.icon}>
        {icon}
      </div>
    </div>
  );
};

export default InputIcon;
