import React from "react";
import "./styles/team.css";
import creativeTeam from "../assets/images/undraw_creative_team.svg";
import publicDiscussion from "../assets/images/public_discussion.svg";
import businessDecisions from "../assets/images/business_decisions.svg";
import expert1 from "../assets/images/expert1.png";
import expert2 from "../assets/images/expert2.png";
import expert3 from "../assets/images/expert3.png";
import expert4 from "../assets/images/expert4.png";
import expert5 from "../assets/images/expert5.png";
import expert6 from "../assets/images/expert6.png";
import expert7 from "../assets/images/expert7.png";
import expert8 from "../assets/images/expert8.png";
import expert9 from "../assets/images/expert9.png";
import undrawSegment from "../assets/images/undraw_segment.svg";
import { Link } from "react-router-dom";
import BlueWindow from "../customComponents/BlueWindow/BlueWindow";
import Button from "../customComponents/Button/Button";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const everyUniqueAIVO = [
  {
    name: "Martin Höpperger",
    img: expert1,
    title: "experts_title_1",
    text: "experts_text_1",
  },
  {
    name: "Patrick Schönegger",
    img: expert2,
    title: "experts_title_2",
    style: {
      marginTop: "30px",
    },
    text: "experts_text_2",
  },
  {
    name: "Jennifer Lim",
    img: expert3,
    title: "experts_title_3",
    text: "experts_text_3",
  },
  {
    name: "Piotr Węgrzynek",
    img: expert4,
    title: "experts_title_4",
    text: "experts_text_4",
    style: { marginTop: "30px" },
  },
  {
    name: "Stefan Hintner",
    img: expert5,
    title: "experts_title_5",
    text: "experts_text_5",
    style: { marginTop: "55px" },
  },
  {
    name: "Elena Prenn",
    img: expert6,
    title: "experts_title_6",
    text: "experts_text_6",
    style: { marginTop: "20px" },
  },
  {
    name: "Gregor Lanzinger",
    img: expert7,
    title: "experts_title_7",
    text: "experts_text_7",
  },
  {
    name: "Carolina Ouro",
    img: expert8,
    title: "experts_title_8",
    text: "experts_text_8",
    style: { marginTop: "55px" },
  },
  {
    name: "Janis Laubergs",
    img: expert9,
    title: "experts_title_9",
    text: "experts_text_9",
  },
  // {
  //     name: 'Janis Laubergs',
  //     img: expert9,
  //     title: 'App Designer',
  //     style: {marginTop: "55px"},
  //     text: 'Als Kletterer fühlt sich Patrick in der Höhe wohl. In extreme Höhen kommt er auch auf der Tonleiter seiner Klarinette.'
  //
  // }
];

const Team = () => {
  const { t } = useTranslation(["aboutUs", "homepage"]);
  return (
    <div className={"wrapper_team"}>
      <Helmet>
        <title>Über uns | aivo</title>
        <meta name='theme-color' content='#f2f5f6' />
        <style>{"body { background-color: #f2f5f6; }"}</style>
      </Helmet>
      <section className={"block-experts"}>
        <div className={"content-experts"}>
          <span>{t("about_title")}</span>
          <p>{t("about_description")}</p>
          <Link to={`/kontakt/besprechung`} className={"btn_orange btn_default"}>
            {t("about_btn")}
          </Link>
        </div>
        <div className={"creative-team-experts"}>
          <img src={creativeTeam} className={"creative-team"} alt={"creative-team"} />
        </div>
      </section>
      <section className={"team-what-believe"}>
        <div className={"team-white-block"}>
          <img src={publicDiscussion} className={"public-discussion"} alt={"public-discussion"} />
        </div>
        <div className={"team-info"}>
          <span className={"title"}>{t("believe_aivo_title")}</span>
          <p className={"description"}>{t("believe_aivo_description_1")}</p>
          <p className={"description"}>{t("believe_aivo_description_2")}</p>
          <p className={"description"}>{t("believe_aivo_description_3")}</p>
        </div>
      </section>
      <section className={"experts-support"}>
        <div className={"experts-support-info"}>
          <span className={"title"}>{t("experts_guide")}</span>
          <p className={"description"}>{t("experts_guide_description_1")}</p>
          <p className={"description"}>{t("experts_guide_description_2")}</p>
        </div>
        <div className={"experts-white-block"}>
          <img
            src={businessDecisions}
            className={"business-decisions"}
            alt={"business-decisions"}
          />
        </div>
      </section>
      <section className={"our-experts"}>
        <div className={"our-experts-title"}>
          <span className={"title"}>{t("our_experts_title")}</span>
        </div>
        <div className={"our-experts-wrapper"}>
          {everyUniqueAIVO.map((i, index) => (
            <div style={i?.style} className='every-expert' key={index}>
              <div className={"img-experts"}>
                <img src={i.img} alt={"experts"} />
                <div className={"bg"}>
                  <p className={"text"}>{t(i.text)}</p>
                </div>
              </div>
              <p className={"name"}>{i.name}</p>
              <span className={"title"}>{t(i.title)}</span>
            </div>
          ))}
          <div className={"orange-block"}>
            <p>Hiring</p>
            <span className={"new-talent"}>{t("experts_hiring_text")}</span>
            <Button
              text={"talent@aivo.ch"}
              onClick={() => window.open("mailto:talent@aivo.ch")}
              className='white'
            />
          </div>
        </div>
      </section>

      <BlueWindow
        title={t("blue_window_title_team", { ns: "homepage" })}
        linkTo={"/kontakt/besprechung"}
        desc={t("blue_window_desc_team", { ns: "homepage" })}
        src={undrawSegment}
        btnText={t("blue_window_btn_team", { ns: "homepage" })}
      />
    </div>
  );
};

export default Team;
