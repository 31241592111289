import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAudit } from "../../services/auditService";

type UseAuditsProps = {
  onAfterCreate?: () => void;
};

export const useAudits = ({ onAfterCreate }: UseAuditsProps) => {
  const queryClient = useQueryClient();

  const createAuditMutation = useMutation({
    mutationFn: createAudit,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      onAfterCreate?.();
    },
  });

  return {
    createAudit: createAuditMutation.mutate,
  };
};
