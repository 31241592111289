import { Input, InputRef, Popover, PopoverProps } from "antd";
import React, { MouseEvent, ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getDocumentBacklinks } from "../../../../../api/documentApi";
import { Document } from "../../../../../types/document";
import { useDocuments } from "../../../../../hooks/services/useDocuments";
import { Button } from "../../../../../components/ui-components";
import DocumentOption from "../../../../../Admin/components/DocumentOption";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/arrow-left.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/icons/upload.svg";
import { SearchIcon } from "../../../../../assets/icons";

import styles from "./ReplaceDocumentPopover.module.scss";

type ReplaceDocumentPopoverProps = {
  children: React.ReactNode;
  documentId: Document["id"];
  onBack: (e: MouseEvent<HTMLDivElement>) => void;
} & PopoverProps;

export default function ReplaceDocumentPopover({
  documentId,
  children,
  onBack,
  ...props
}: ReplaceDocumentPopoverProps) {
  const { data: documents = [] } = useQuery({
    queryKey: ["documents"],
    queryFn: () => getDocumentBacklinks(auditId),
  });
  const { id: auditId } = useParams<{ id: string }>();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const { replaceDocument } = useDocuments();
  const searchRef = useRef<InputRef>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    searchRef?.current?.focus?.();
  }, [searchRef.current]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      replaceDocument({ idAudit: auditId, documentId, data: { file } });
    }
    props.onOpenChange?.(false);
  };

  const handleClickDocument = (sourceId: number) => {
    replaceDocument({ idAudit: auditId, documentId, data: { sourceId } });
    setSearchValue("");
    props.onOpenChange?.(false);
  };

  const filteredDocuments = useMemo(
    () =>
      documents.filter(
        (document: Document) =>
          [document.document, document.name].some((i) =>
            i?.toLowerCase().includes(searchValue.toLowerCase())
          ) && document.id !== documentId
      ),
    [documents.length, searchValue]
  );

  return (
    <Popover
      arrow={false}
      trigger={["click"]}
      placement='bottomRight'
      overlayClassName={styles.popover}
      {...props}
      content={
        <>
          <div className={styles.header} onClick={onBack}>
            <ArrowLeftIcon />
            {t("replace_document")}
          </div>
          <div className={styles.inputWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <Input
              ref={searchRef}
              value={searchValue}
              className={styles.input}
              onChange={handleSearchChange}
              placeholder={t("search_files_placeholder")}
            />
          </div>
          <div className={styles.options}>
            {filteredDocuments.length ? (
              <>
                <div className={styles.footnote}>{t("replace_document_footnote")}</div>
                <div className={styles.list}>
                  {filteredDocuments.map((document: Document) => (
                    <div
                      key={document.id}
                      className={styles.documentWrapper}
                      onClick={() => handleClickDocument?.(document.id)}
                    >
                      <DocumentOption name={document.name} document={document.document} />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className={styles.footnote}>
                {searchValue ? tGlobal("no_results") : t("no_documents")}
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <input
              ref={uploadRef}
              type='file'
              className={styles.uploadInput}
              onChange={handleUploadFile}
            />
            <Button
              color='lightBlue'
              primary
              onClick={() => uploadRef.current?.click()}
              className={styles.uploadButton}
            >
              <UploadIcon />
              {tGlobal("upload_new")}
            </Button>
          </div>
        </>
      }
    >
      {children}
    </Popover>
  );
}
