import React from "react";
import styles from "../../../../Admin/Superadmin/PlatformAdmin/Users/Users.module.scss";
import { Button } from "../../index";
import { Modal } from "antd";

const DeletePresetPopup = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      className={`${styles.modal} ${styles.danger}`}
      title='Are you sure?'
      onCancel={onClose}
      open={isOpen}
      centered
      footer={[
        <Button key='back' secondary color='blue' handleClick={onClose}>
          Cancel
        </Button>,
        <Button key='submit' primary color='red' handleClick={onSubmit}>
          Delete
        </Button>,
      ]}
    >
      <p>
        This will permanently delete the template. Audits using this template will keep it’s tasks,
        but will be disconnected from the template. This action can not be undone.
      </p>
    </Modal>
  );
};

export default DeletePresetPopup;
