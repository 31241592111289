import { useEffect, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Request } from "../../../../../types/request";
import { Meeting } from "../../../../../types/meeting";
import { StatusStep } from "../../../../../types/status";

import BacklinksItem from "./BacklinksItem";

import { ReactComponent as ArrowSouthWestIcon } from "../../../../../assets/icons/arrow-south-west.svg";
import { ReactComponent as ArrowsIcon } from "../../../../../assets/icons/arrows.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/icons/calendar.svg";
import { ReactComponent as MetricsIcon } from "../../../../../assets/icons/metrics.svg";

import styles from "./DocumentRowBacklinks.module.scss";

type DocumentRowBacklinksProps = {
  total: number;
  quoteRequest?: any;
  requests: Array<Request>;
  meetings?: Array<Meeting>;
  statusMentions?: Array<StatusStep>;
  dependentSteps?: Array<StatusStep>;
};

interface RouteParams {
  id: string;
}

const calculateHeight = (screenHeight: number, itemHeight = 53, titleHeight = 37) => {
  const maxItems = Math.floor((screenHeight * 0.625 - titleHeight) / itemHeight);
  const itemsToShow = maxItems >= 4.5 ? 4.5 : maxItems >= 3.5 ? 3.5 : 2.5;
  return itemsToShow * itemHeight + titleHeight;
};

export default function DocumentRowBacklinks({
  total,
  quoteRequest,
  requests = [],
  meetings = [],
  statusMentions = [],
  dependentSteps = [],
}: DocumentRowBacklinksProps) {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });

  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const height = calculateHeight(window.innerHeight); // Set percentage of screen height here

      setDropdownHeight(height);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "1",
      type: "group",
      label: t("backlinks"),
      children: [
        ...meetings.map((m, i) => ({
          key: `1-${i}`,
          label: (
            <BacklinksItem
              href={`/dashboard/${id}/meetings/${m.id}`}
              title={m.title}
              icon={<CalendarIcon />}
              date={m.date}
              startTime={m.startTime}
            />
          ),
        })),
        ...statusMentions.map((s, i) => ({
          key: `2-${i}`,
          label: (
            <BacklinksItem
              href={`/dashboard/${id}/status?activeGroup=${s.groupId}&openedStep=${
                s.parentTaskId || s.id
              }${s.parentTaskId ? `&openedSubtask=${s.id}` : ""}`}
              title={s.name}
              icon={<MetricsIcon />}
              status={s.status}
            />
          ),
        })),
        ...requests.map((r, i) => ({
          key: `3-${i}`,
          label: (
            <BacklinksItem
              href={`/dashboard/${id}/requests/${r.id}`}
              title={r.name}
              icon={<ArrowsIcon />}
              status={r.status}
            />
          ),
        })),
        quoteRequest
          ? {
              key: "4-1",
              label: (
                <BacklinksItem
                  href={`/dashboard/${quoteRequest.id}/requests/quote`}
                  title='Quote request'
                  status={quoteRequest?.status}
                  icon={<ArrowsIcon />}
                />
              ),
            }
          : null,
        ...dependentSteps.map((s, i) => ({
          key: `5-${i}`,
          label: (
            <BacklinksItem
              href={`/dashboard/${id}/status?activeGroup=${s.groupId}&openedStep=${
                s.parentTaskId || s.id
              }${s.parentTaskId ? `&openedSubtask=${s.id}` : ""}`}
              title={s.name}
              icon={<MetricsIcon />}
              status={s.status}
            />
          ),
        })),
      ],
    },
  ];

  return (
    <Dropdown
      arrow
      menu={{ items }}
      placement='bottomLeft'
      overlayStyle={{ maxHeight: `${dropdownHeight}px` }}
      overlayClassName={styles.overlay}
    >
      <div className={styles.root}>
        <ArrowSouthWestIcon width='1.2em' height='1.2em' />
        <span>
          {total} {t("backlinks")}
        </span>
      </div>
    </Dropdown>
  );
}
