import React from "react";
import FormPicker from "../../../../components/FormPicker/FormPicker";
import QuoteCheckboxes from "../QuoteCheckboxes/QuoteCheckboxes";
import { useTranslation } from "react-i18next";
import Attachments from "../../../../components/Attachments/Attachments";

const employeeOptions = [
  {
    name: "<10",
    value: "<10",
  },
  {
    name: "11-50",
    value: "11-50",
  },
  {
    name: "51-250",
    value: "51-250",
  },
  {
    name: "250+",
    value: "250+",
  },
];

const revenueOptions = [
  {
    name: "<1M",
    value: "<1M",
  },
  {
    name: "1-10M",
    value: "1-10M",
  },
  {
    name: "10-40M",
    value: "10-40M",
  },
  {
    name: "40-100M",
    value: "40-100M",
  },
  {
    name: "100M+",
    value: "100M+",
  },
];

const totalAssetsOptions = [
  {
    name: "<1M",
    value: "<1M",
  },
  {
    name: "1-5M",
    value: "1-5M",
  },
  {
    name: "5-20M",
    value: "5-20M",
  },
  {
    name: "20-50M",
    value: "20-50M",
  },
  {
    name: "50M+",
    value: "50M+",
  },
];

const QuoteFields = ({
  requestData,
  errors,
  handleChangePicker,
  handleCheckboxChange,
  progress,
  lastFetchDate,
  handleDeleteDocument,
  addAttachmentFile,
  disabled,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  if (requestData?.provideInformation === "upload" || requestData?.provideInformation === "auto") {
    return (
      <>
        <Attachments
          disabled={disabled}
          progress={progress}
          isBoxioRequest={requestData.provideInformation === "auto"}
          relatedDocs={requestData.relatedDocs}
          attachments={requestData.attachments}
          last_fetched={requestData.last_fetched}
          bexio_refresh={requestData.bexio_refresh}
          lastFetchDate={lastFetchDate}
          onRemoveFile={handleDeleteDocument}
          onAddFile={addAttachmentFile}
          label={
            requestData?.provideInformation === "auto" ? "Connect accounting system" : "Attachments"
          }
        />
      </>
    );
  }

  return (
    <>
      <FormPicker
        options={employeeOptions}
        error={errors.employees}
        selected={requestData.employees}
        name='employees'
        onSelect={handleChangePicker}
        label={t("employees")}
        labelId='employees'
        disabled={disabled}
      />
      <FormPicker
        options={revenueOptions}
        error={errors.companyRevenue}
        selected={requestData.companyRevenue}
        name='companyRevenue'
        onSelect={handleChangePicker}
        label={t("revenue")}
        labelId='companyRevenue'
        disabled={disabled}
      />
      <FormPicker
        options={totalAssetsOptions}
        error={errors.companyTotalAssets}
        selected={requestData.companyTotalAssets}
        name='companyTotalAssets'
        onSelect={handleChangePicker}
        label={t("total_assets")}
        labelId='companyTotalAssets'
        disabled={disabled}
      />
      <QuoteCheckboxes data={requestData} handleChange={handleCheckboxChange} disabled={disabled} />
    </>
  );
};

export default QuoteFields;
