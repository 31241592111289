import "./settingTeam.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { deleteMember } from "../../../api/teamMembersApi";
import { useParams } from "react-router-dom";
import { Button } from "../../../components/ui-components";
import { useTranslation } from "react-i18next";

const SettingTeamDanger = ({ changeTeamData, close }) => {
  const { id: idAudit, idTeam: teamId } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const onDelete = () => {
    deleteMember(idAudit, teamId)
      .then(() => {
        close();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className='settingsAudit_role settingsAudit_role_danger'>
      <div className='settings_right-info setting_right_info_danger'>
        <div className='settings_right-info_inner'>
          <div className='settings_right-info-title'>{t("danger_zone")}</div>
          <hr />
          <div className='settings_audit'>
            <div className='settings_team'>
              <div className='settings_team_details'>
                <div className='settings_team_name'>
                  <span>{t("active_status")}</span>
                </div>
              </div>
              <div className='settings_audit-team_information'>
                <FormGroup>
                  <FormControlLabel control={<Switch />} label={t("disable_account")} />
                </FormGroup>
              </div>
            </div>
            <hr />
            <div className='settings_audit-team_information-danger'>
              <Button
                color={"red"}
                style={{ minWidth: 330, width: "max-content" }}
                secondary
                onClick={onDelete}
              >
                {t("delete_user")} {changeTeamData.fullName}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingTeamDanger;
