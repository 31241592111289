import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../../../../../consts/enums";

import SharedWith from "../SharedWith/SharedWith";
import { Tag } from "../../../../../../components/ui-components";
import Logo from "../../../../../../components/Logo/Logo";

import { ThreeDotsIcon } from "../../../../../../assets/icons";
import styles from "./CompanyRow.module.scss";

const TYPE_COLORS = {
  audit_firm: COLORS.DARK,
  fiduciary: COLORS.GREEN,
  client: COLORS.BLUE,
};

const CompanyRow = ({ id, logo, name, accountAmount, type, createdAt }) => {
  const { t } = useTranslation("admin", { keyPrefix: "companies" });

  const [openDrop, setOpenDrop] = useState(null);

  const date = dayjs(createdAt).format("D MMMM YYYY");

  const handleOpenDropdown = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenDrop(openDrop === id ? null : id);
  };

  return (
    <Link to={`/admin/companies/${type}/${id}/settings`} className={styles.root}>
      <div className={styles.row}>
        <Logo logo={logo} />
        <span className={styles.fileName}>{name}</span>
      </div>
      <Tag color={TYPE_COLORS[type]}>{t(`role_${type}`)}</Tag>
      <SharedWith amount={accountAmount} />
      <span className={styles.date}>{date}</span>
      <div className={styles.actions}>
        <ThreeDotsIcon onClick={handleOpenDropdown} />
      </div>
      <hr />
    </Link>
  );
};

export default CompanyRow;
