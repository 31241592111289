import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Label } from "../../../../components/ui-components";
import TimeRangePicker from "../../../../components/ui-components/TimeRangePicker/TimeRangePicker";
import CustomDatePicker from "../../../../components/ui-components/CustomDatePicker/CustomDatePicker";

import "../../../../styles/timepicker.scss";
import styles from "./DueDateTimeRange.module.scss";

const DueDateTime = ({ date, errors, startTime, endTime, setData, onChange }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "meetings" });

  const editDueDate = async (e) => {
    const newDate = e && new Date(e).toISOString();
    setData({ date: newDate });
    onChange?.({ date: newDate });
  };

  const editTimeRange = ([startTime, endTime]) => {
    setData({ startTime, endTime });

    onChange?.({ startTime, endTime });
  };

  return (
    <div className={styles.column}>
      <Label>{t("date")}</Label>
      <div className={styles.row} style={{ gap: "8px" }}>
        <CustomDatePicker
          onChange={editDueDate}
          value={date ? dayjs(date) : null}
          allowClear={false}
          error={errors?.date}
        />
        <TimeRangePicker
          value={[dayjs(startTime || undefined), dayjs(endTime || undefined)]}
          onChange={editTimeRange}
        />
      </div>
    </div>
  );
};

export default DueDateTime;
