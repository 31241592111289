import { useState } from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";

import { deleteAudit, generateAuditReport } from "../../../api/auditApi";
import { disableCompany } from "../../../api/companyApi";

import DeleteAuditPopup from "../DeleteAuditPopup";
import CustomSwitch from "../../../components/ui-components/Switch/Switch";
import { Button } from "../../../components/ui-components";

const DangerZone = ({ companyData, syncDataOnSave }) => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const onChange = async (e) => {
    const checked = e.target.checked;
    syncDataOnSave({ disabled: checked });
    await disableCompany(companyData.id, { disabled: checked });
  };

  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false);
  };

  const handleSubmitDeletePopup = async () => {
    try {
      await deleteAudit(companyData.id);
      setDeletePopupOpen(false);
      history.push("/audits");
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenDeletePopup = () => {
    setDeletePopupOpen(true);
  };

  const handleGenerateReport = async () => {
    await generateAuditReport(companyData.id, companyData.name);
  };

  return (
    <>
      <DeleteAuditPopup
        companyName={companyData.name}
        isOpen={deletePopupOpen}
        onClose={handleCloseDeletePopup}
        onSubmit={handleSubmitDeletePopup}
      />
      <Space.Compact direction={"vertical"} block style={{ padding: "32px 24px" }}>
        <Row align='middle' gutter={24} span={24}>
          <Col span={8}>
            <span>{t("audit_status")}</span>
          </Col>
          <Col span={16}>
            <FormGroup>
              <CustomSwitch
                checked={companyData.disabled}
                onChange={onChange}
                label={t("set_inactive")}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row align={"middle"}>
          <Col span={8}>
            <span>{t("delete_audit")}</span>
          </Col>
          <Col span={16}>
            <Button
              handleClick={handleOpenDeletePopup}
              secondary
              color={"red"}
              style={{ width: "max-content" }}
            >
              {t("delete_audit")}
            </Button>
          </Col>
        </Row>
        <br />
        <Row align={"middle"}>
          <Col span={8}>
            <span>{t("generate_audit_report")}</span>
          </Col>
          <Col span={4}>
            <Button color={"green"} secondary handleClick={handleGenerateReport}>
              {t("generate")}
            </Button>
          </Col>
        </Row>
      </Space.Compact>
    </>
  );
};

export default DangerZone;
