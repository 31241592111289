import orderBy from "lodash/orderBy";
import { PrivateIcon, PublicIcon } from "../../../assets/icons";

export const getDocumentSharedAccess = (accessibleBy, type) => {
  switch (type) {
    case "legal":
      return ["All", <PublicIcon />];
    case "shared":
      if (accessibleBy.all) return ["All", <PublicIcon />];
      const users = accessibleBy.users?.length || 0;
      const roles = accessibleBy.roles?.length || 0;
      if (users + roles === 0) return ["Only Auditor", <PrivateIcon />];
      const roleMembers = accessibleBy.roles.reduce((acc, role) => {
        return acc + role.teamMembers.length;
      }, 0);
      const totalUsers = users + roleMembers;
      return [`${totalUsers} user${totalUsers > 1 ? "s" : ""}`, <PublicIcon />];
    case "evidence":
      return ["Only Auditor", <PrivateIcon />];
    default:
      return ["All", <PublicIcon />];
  }
};

export const getFilteredDocuments = (arr = [], type, filters) => {
  let filtered = arr.filter((el) => {
    switch (type) {
      case "evidence":
        return el.type === "evidence" || el.statusMentions?.length > 0;
      case "all":
        return true;
      default:
        return el.type === type;
    }
  });

  if (filters) {
    if (filters.order === "type") {
      filtered = filtered.sort((a, b) => {
        const [aType] = getDocumentSharedAccess(a.accessibleBy, a.type);
        const [bType] = getDocumentSharedAccess(b.accessibleBy, b.type);
        return filters.orderBy === "asc" ? aType.localeCompare(bType) : bType.localeCompare(aType);
      });
    } else if (filters.order === "status") {
      filtered = filtered.sort((a, b) => {
        const aSign = a.type === "legal" && a.signers?.length > 0;
        const bSign = b.type === "legal" && b.signers?.length > 0;
        return filters.orderBy === "asc" ? aSign - bSign : bSign - aSign;
      });
    } else {
      filtered = orderBy(filtered, filters.order, filters.orderBy);
    }
  }

  return filtered;
};
