import { useEffect, useMemo, useState } from "react";
import { Anchor, Card, Col, Row } from "antd";

import classes from "./SettingsSplitNavigation.module.scss";

const renderCard = (item, idx) => {
  return (
    <Card
      key={idx}
      title={item.title}
      bordered={false}
      hoverable={false}
      id={item.key}
      bodyStyle={item.bodyStyle}
      extra={item.extra}
      {...(idx > 0 && { style: { marginTop: 24 } })}
    >
      {item.body}
    </Card>
  );
};

const SettingSplitNavigation = ({
  navigation,
  targetOffset = 400,
  offsetTop = 100,
  style,
  sections,
}) => {
  const [activeSection, setActiveSection] = useState(0);
  const filteredNavigation = navigation.filter((i) => !i.hide);

  const navigationToIndex = useMemo(() => {
    if (!sections) return {};
    return filteredNavigation.reduce((acc, item, key) => {
      return {
        ...acc,
        [key]: item.children.map((child) => child.href),
      };
    }, {});
  }, [filteredNavigation, sections]);

  const handleClick = (_, anchor) => {
    const index = Object.entries(navigationToIndex).reduce((acc, [key, items]) => {
      if (items.includes(anchor.href)) {
        acc = key;
      }
      return acc;
    }, 0);
    setActiveSection(index);
  };

  useEffect(() => {
    if (!sections) return;
    const hash = window.location.hash;
    if (!hash) return;
    const element = document.querySelector(hash);
    if (!element) return;
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth" });
    }, 400);
  }, [activeSection, sections]);

  return (
    <Row gutter={24} style={style} className={classes.root}>
      <Col span={8}>
        <Anchor
          offsetTop={offsetTop}
          targetOffset={targetOffset}
          items={filteredNavigation}
          {...(sections && { rootClassName: "sectioned", onClick: handleClick })}
        />
      </Col>
      <Col span={16} style={{ paddingBottom: 200 }}>
        {sections
          ? filteredNavigation[activeSection].children.map(renderCard)
          : filteredNavigation.map(renderCard)}
      </Col>
    </Row>
  );
};

export default SettingSplitNavigation;
