const useStatusGroupActions = (setData: any) => {
  const createNewGroup = async (order: number) => {
    updateStatusGroups((groups) => {
      const newGroup = {
        id: new Date().getTime(),
        name: "",
        steps: [],
        order,
        editModeAction: "create",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      groups.push(newGroup);
    });
  };

  const changeGroupName = (index: number, value: string) => {
    updateStatusGroups((groups) => {
      const group = groups[index];
      if (!group.editModeAction && group.name !== value) {
        groups[index].editModeAction = "update";
      }
      group.name = value;
    });
  };

  const duplicateGroup = (index: number) => {
    updateStatusGroups((groups) => {
      const group = groups[index];
      let order = group.order + 1;

      groups.forEach((g) => {
        if (g.order >= order) {
          g.order++;
          g.editModeAction = g.editModeAction || "update";
        }
      });

      const newGroup = {
        ...group,
        name: `${group.name} (copy)`,
        order,
        editModeAction: "duplicate",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      let insertIndex = groups.findIndex((g) => g.order === order + 1) || groups.length;
      groups.splice(insertIndex, 0, newGroup);
    });
  };

  const deleteGroup = (index: number) => {
    updateStatusGroups((groups) => {
      if (
        groups[index].editModeAction === "create" ||
        groups[index].editModeAction === "duplicate"
      ) {
        groups.splice(index, 1);
      } else {
        groups[index].editModeAction = "delete";
      }
    });
  };

  const updateStatusGroups = (modifyGroups: (groups: any[]) => void) => {
    setData((state: any) => {
      const newGroups = [...state.audit.statusGroups];
      modifyGroups(newGroups);
      return {
        ...state,
        audit: {
          ...state.audit,
          statusGroups: newGroups,
        },
      };
    });
  };

  return {
    createNewGroup,
    changeGroupName,
    duplicateGroup,
    deleteGroup,
  };
};

export default useStatusGroupActions;
