import React from "react";
import classNames from "classnames";

import styles from "./Label.module.scss";

type LabelProps = {
  nowrap?: boolean;
  gutterBottom?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Label = ({ children, nowrap, gutterBottom, className, ...restAttr }: LabelProps) => {
  const classes = classNames(styles.label, className, {
    [styles.nowrap]: nowrap,
    [styles.gutterBottom]: gutterBottom,
  });

  return (
    <label className={classes} {...restAttr}>
      {children}
    </label>
  );
};

export default Label;
