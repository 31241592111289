import API from "./API.js";

export const forgotEmail = async (body) => {
  try {
    const response = await API().post("/user/forgot-password-email", body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (body, token) => {
  try {
    const response = await API(token).post("/user/forgot-password", body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setPassword = async (body, token) => {
  try {
    const response = await API(token).post("/user/set-password", body);
    return response.data;
  } catch (error) {
    return error;
  }
};
