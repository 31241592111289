import React from "react";
import { Link, useHistory } from "react-router-dom";
import str_right from "../../../../assets/admin/str_right.svg";
import { ROLES_REQUSTED_TEXT } from "../../../../consts/enums";
import { useTranslation } from "react-i18next";

const requestsNumber = [
  {
    count: 0,
    color: "#003E47CC",
    case: "requested",
    upload: "1",
  },
  {
    count: 0,
    color: "#49BACA",
    case: "sent",
  },
  {
    count: 0,
    color: "#6db333",
    case: "approved",
  },
];

const RequestSection = ({ idAudit, statusRequest }) => {
  const history = useHistory();
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className={"request_wrapper content_box"}>
      <div
        className={"header"}
        onClick={() => {
          history.push(`/dashboard/${idAudit}/requests/new`);
        }}
      >
        <span className={"header__title"}>{tGlobal("requests")}</span>
        <Link to={`/dashboard/${idAudit}/requests/new`}>
          <img src={str_right} alt='Right' />
        </Link>
      </div>
      <div className={"body"}>
        {requestsNumber.map((item, index) => (
          <Link
            key={index}
            className='request_wrapper__every receive'
            to={{
              pathname: `/dashboard/${idAudit}/requests/0`,
              state: { status: item.case },
            }}
          >
            <div style={{ backgroundColor: `${item.color}1F` }} className='request_preview'>
              <div key={index}>
                <div style={{ color: item.color }} className='req-number'>
                  {statusRequest ? statusRequest[item.case] || item.count : item.count}
                </div>
                {/* {item.upload ? <div className={'message'}>{item.upload}</div> : ''} */}
              </div>
            </div>
            <p className='description'>{tGlobal(ROLES_REQUSTED_TEXT[item.case])}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RequestSection;
