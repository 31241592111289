import React, { useEffect, useRef } from "react";
import styles from "./HeaderSection.module.scss";
import { Button, Input } from "../../../../components/ui-components";
import { useTranslation } from "react-i18next";

interface HeaderSectionProps {
  handleDiscard: () => void;
  submitButtonClick: () => Promise<void>;
  submitButtonText: string;
  onHandleChange: (name: string, value: string) => void;
  disabledSubmitButton: boolean;
  headerValue: string;
  headerValueName: string;
  headerPlaceholder: string;
  headerTitle: string;
  titleError?: boolean;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  handleDiscard,
  onHandleChange,
  submitButtonClick,
  submitButtonText,
  headerValue,
  headerValueName,
  headerPlaceholder,
  headerTitle,
  titleError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={styles.headerSection}>
      <div className={styles.headingWrapper}>
        <h4 className={styles.title}>{headerTitle}</h4>
        <div className={styles.buttonsWrapper}>
          <Button handleClick={handleDiscard} secondary color='red'>
            {tGlobal("discard")}
          </Button>
          <Button handleClick={submitButtonClick} disabled={!headerValue} primary color='blue'>
            {submitButtonText}
          </Button>
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <Input
          ref={inputRef}
          error={titleError}
          name={headerValueName}
          onHandleChange={onHandleChange}
          value={headerValue}
          placeholder={headerPlaceholder}
        />
      </div>
    </div>
  );
};

export default HeaderSection;
