import React, { useState } from "react";
import { Col, Row, Space, Card } from "antd";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../../components/ui-components";
import FileUpload from "../../../../../../components/FileUpload";
import { superadminUpdateCompany } from "../../../../../../api/superadminApi";
import CardActions from "../../../../../pages/components/CardActions/CardActions";
import CompanyIDInput from "../../../../../../components/CompanyIDInput";

const Details = ({ companyData, syncDataOnSave }) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [profileData, setProfileData] = useState(companyData);
  const [errors, setErrors] = useState({
    name: "",
  });

  const inputData = (key, value) => {
    setProfileData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [key]: "",
    }));
  };

  const onSubmit = () => {
    if (profileData.name) {
      const body = {
        name: profileData.name,
        logo: profileData.logo,
        UID: profileData.UID,
      };
      superadminUpdateCompany(companyData.id, companyData.type, body)
        .then((res) => {
          const syncData = body;
          if (res.logo) {
            syncData.logo = res.logo;
          }
          syncDataOnSave(syncData);
        })
        .catch((error) => {
          setErrors((prevState) => ({
            ...prevState,
            name: error?.response?.data?.message,
          }));
        });
    }
  };

  const discard = () => {
    setProfileData(companyData);
  };
  const logo = profileData.logoURL || profileData.logo;
  const hasChanged = Object.entries(profileData).some(([key, value]) => companyData[key] !== value);

  return (
    <>
      <Card.Grid hoverable={false}>
        <Space.Compact direction={"vertical"} block>
          <Row gutter={24} span={24}>
            <Col span={8}>
              <span>{tGlobal("company_logo")}</span>
            </Col>
            <Col span={16}>
              <FileUpload
                title={tGlobal("select_picture")}
                file={logo}
                subtitle={"JPG, PNG"}
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                }}
                onChange={(file) => {
                  inputData("logo", file || "");
                  inputData("logoURL", file ? URL.createObjectURL(file) : "");
                }}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col span={8}>
              <span>{tGlobal("company_name")}</span>
            </Col>
            <Col span={16}>
              <Input
                placeholder={"Muster AG"}
                value={profileData.name}
                error={!!errors.name}
                errorMessage={errors.name}
                onChange={({ target }) => {
                  inputData("name", target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col span={8}>
              <span>{tGlobal("company_id")}</span>
            </Col>
            <Col span={16}>
              <CompanyIDInput name='UID' value={profileData.UID} onChange={inputData} />
            </Col>
          </Row>
        </Space.Compact>
      </Card.Grid>
      {hasChanged && <CardActions onSubmit={onSubmit} onCancel={discard} />}
    </>
  );
};

export default Details;
