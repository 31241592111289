import API from "./API.js";

export const createRoles = async (body, id) => {
  try {
    const response = await API().post(`/audit/${id}/roles/create`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const allListRoles = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/roles`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const oneRoles = async (id, id_roles) => {
  try {
    const response = await API().get(`/audit/${id}/roles/${id_roles}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateRoles = async (body, id, id_roles) => {
  try {
    const response = await API().post(`/audit/${id}/roles/${id_roles}/update`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteRole = async (auditId, roleId) => {
  try {
    const response = await API().delete(`/audit/${auditId}/roles/${roleId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
