export const ownersOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2-5",
    value: "2-5",
  },
  {
    name: "6-10",
    value: "6-10",
  },
  {
    name: "11-20",
    value: "11-20",
  },
  {
    name: "21+",
    value: "21+",
  },
];

export const requiredFields = [
  "accountingSystem",
  "industry",
  "industrySegment",
  "businessDescription",
  "numberOfOwners",
  "provideInformation",
  "employees",
  "companyRevenue",
  "companyTotalAssets",
  "reportDate",
];

export const industryOptions = [
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Construction",
    value: "Construction",
  },
  {
    label: "Design",
    value: "Design",
  },
  {
    label: "Corporate Services",
    value: "Corporate Services",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Energy & Mining",
    value: "Energy & Mining",
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Recreation & Travel",
    value: "Recreation & Travel",
  },
  {
    label: "Arts",
    value: "Arts",
  },
  {
    label: "Health Care",
    value: "Health Care",
  },
  {
    label: "Hardware & Networking",
    value: "Hardware & Networking",
  },
  {
    label: "Real Estate",
    value: "Real Estate",
  },
  {
    label: "Legal",
    value: "Legal",
  },
  {
    label: "Consumer Goods",
    value: "Consumer Goods",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Media & Communications",
    value: "Media & Communications",
  },
  {
    label: "Nonprofit",
    value: "Nonprofit",
  },
  {
    label: "Software & IT Services",
    value: "Software & IT Services",
  },
  {
    label: "Transportation & Logistics",
    value: "Transportation & Logistics",
  },
  {
    label: "Entertainment",
    value: "Entertainment",
  },
  {
    label: "Wellness & Fitness",
    value: "Wellness & Fitness",
  },
  {
    label: "Public Safety",
    value: "Public Safety",
  },
  {
    label: "Public Administration",
    value: "Public Administration",
  },
];

export const industrySegments = {
  Education: [
    "Education Management",
    "E-Learning",
    "Higher Education",
    "Primary/Secondary Education",
    "Research",
  ],
  Construction: ["Building Materials", "Civil Engineering", "Construction"],
  Design: ["Architecture & Planning", "Design", "Graphic Design"],
  "Corporate Services": [
    "Accounting",
    "Business Supplies & Equipment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Human Resources",
    "Information Services",
    "Management Consulting",
    "Outsourcing/Offshoring",
    "Professional Training & Coaching",
    "Security & Investigations",
    "Staffing & Recruiting",
  ],
  Retail: ["Retail", "Supermarkets", "Wholesale"],
  "Energy & Mining": ["Mining & Metals", "Oil & Energy", "Utilities"],
  Manufacturing: [
    "Automotive",
    "Aviation & Aerospace",
    "Chemicals",
    "Defense & Space",
    "Electrical & Electronic Manufacturing",
    "Food Production",
    "Glass, Ceramics & Concrete",
    "Industrial Automation",
    "Machinery",
    "Mechanical or Industrial Engineering",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Plastics",
    "Railroad Manufacture",
    "Renewables & Environment",
    "Shipbuilding",
    "Textiles",
  ],
  Finance: [
    "Banking",
    "Capital Markets",
    "Financial Services",
    "Insurance",
    "Investment Banking",
    "Investment Management",
    "Venture Capital & Private Equity",
  ],
  "Recreation & Travel": [
    "Airlines/Aviation",
    "Gambling & Casinos",
    "Hospitality",
    "Leisure, Travel & Tourism",
    "Restaurants",
    "Recreational Facilities & Services",
    "Sports",
  ],
  Arts: ["Arts & Crafts", "Fine Art", "Performing Arts", "Photography"],
  "Health Care": [
    "Biotechnology",
    "Hospital & Health Care",
    "Medical Device",
    "Medical Practice",
    "Mental Health Care",
    "Pharmaceuticals",
    "Veterinary",
  ],
  "Hardware & Networking": [
    "Computer Hardware",
    "Computer Networking",
    "Nanotechnologie",
    "Semiconductors",
    "Telecommunications",
    "Wireless",
  ],
  "Real Estate": ["Commercial Real Estate", "Real Estate"],
  Legal: ["Alternative Dispute Resolution", "Law Practice", "Legal Services"],
  "Consumer Goods": [
    "Apparel & Fashion",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Food & Beverages",
    "Furniture",
    "Luxury Goods & Jewelry",
    "Sporting Goods",
    "Tobacco",
    "Wine and Spirits",
  ],
  Agriculture: ["Dairy", "Farming", "Fishery", "Ranching"],
  "Media & Communications": [
    "Market Research",
    "Marketing & Advertising",
    "Newspapers",
    "Online Media",
    "Printing",
    "Public Relations & Communications",
    "Publishing",
    "Translation & Localization",
    "Writing & Editing",
  ],
  Nonprofit: [
    "Civic & Social Organization",
    "Fundraising",
    "Individual & Family Services",
    "International Trade & Development",
    "Libraries",
    "Museums & Institutions",
    "Non-Profit Organization Management",
    "Philanthropy",
    "Program Development",
    "Religious Institutions",
    "Think Tanks",
  ],
  "Software & IT Services": [
    "Computer & Network Security",
    "Computer Software",
    "Information Technology & Services",
    "Internet",
  ],
  "Transportation & Logistics": [
    "Import & Export",
    "Logistics & Supply Chain",
    "Maritime",
    "Package/Freight Delivery",
    "Transportation/Trucking/Railroad",
    "Warehousing",
  ],
  Entertainment: [
    "Animation",
    "Broadcast Media",
    "Computer Games",
    "Entertainment",
    "Media Production",
    "Mobile Games",
    "Motion Pictures & Film",
    "Music",
  ],
  "Wellness & Fitness": ["Alternative Medicine", "Health, Wellness & Fitness"],
  "Public Safety": ["Law Enforcement", "Military", "Public Safety"],
  "Public Administration": [
    "Government Administration",
    "Government Relations",
    "International Affairs",
    "Judiciary",
    "Legislative Office",
    "Political Organization",
    "Public Policy",
  ],
};
