import API from "./API";

export const getStatusAudit = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/status`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPresetPreview = async (id, presetName) => {
  try {
    const body = {
      preset: presetName,
    };
    const response = await API().post(`/audit/${id}/status/preset/preview`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const reorderSteps = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/reorder`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createStep = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/create`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateStep = async (id, stepId, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/${stepId}/update`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const attachEntities = async (id, stepId, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/${stepId}/attach-entity`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteStep = async (id, stepId) => {
  try {
    const response = await API().delete(`/audit/${id}/status/steps/${stepId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createPreset = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/new-preset`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const startEdit = async (id) => {
  try {
    const response = await API().post(`/audit/${id}/status/start-edit`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePreset = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/sync-update`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const pullPreset = async (id, presetId) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/pull-preset`, { presetId });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const disconnectFromPreset = async (id, body) => {
  const response = await API().post(`/audit/${id}/status/steps/disconnect`, body);
  return response.data;
};

export const savePreset = async (id) => {
  try {
    const response = await API().post(`/audit/${id}/status/save`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setPreset = async (id, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/set-preset`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const markAsDone = async (id, stepId) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/${stepId}/mark-as-done`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setTaskStatus = async (id, stepId, body) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/${stepId}/set-status`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const toggleTaskStatus = async (id, stepId) => {
  try {
    const response = await API().post(`/audit/${id}/status/steps/${stepId}/toggle-status`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCompanyPresets = async (id) => {
  try {
    const response = await API().get(`/company/${id}/presets`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPresets = async () => {
  try {
    const response = await API().get(`/presets`);
    return response.data.presets;
  } catch (error) {
    return error;
  }
};

export const submitDeletePreset = async (id, presetId) => {
  const response = await API().delete(`/audit/${id}/status/preset/${presetId}`);
  return response.data;
};

export const submitRenamePreset = async (id, presetId, body) => {
  const response = await API().post(`/audit/${id}/status/preset/${presetId}`, body);
  return response.data;
};
