import React, { useState } from "react";
import chatAll from "../../assets/admin/chatAll.svg";
import closeChat from "../../assets/images/CloseChat.svg";
import logoChat from "../../assets/images/logoChat.svg";
import searchIcon from "../../assets/images/seacrhIcon.svg";

import "./chat.css";

const chatList = [
  {
    id: 1,
    name: "Name Surname",
    logo: "",
    position: "Company Senior Auditor",
    message: "Morbi leo risus, porta ac consectetur tortor commodo euismod etiam...",
    meseageUnreviewed: "1",
  },
  {
    id: 2,
    name: "Name Surname",
    position: "Company FCO",
    message: "Morbi leo risus, porta ac consectetur tortor commodo euismod etiam...",
    meseageUnreviewed: "2",
  },
  {
    id: 3,
    name: "Name Surname",
    position: "Company Bookeeper",
    message: "Morbi leo risus, porta ac consectetur tortor commodo euismod etiam...",
    meseageUnreviewed: "0",
  },
];

export default function Chat() {
  const [openChat, setOpenChat] = useState(false);

  return openChat ? (
    <div className='chat-block'>
      <div className='chat'>
        <div className='chat_inner'>
          <div className='chat_header'>
            <span>Chat</span>
            <img
              className='closeIcon'
              src={closeChat}
              alt='close'
              onClick={() => setOpenChat(false)}
            />
          </div>
          <div className='chat-search'>
            <img src={searchIcon} alt='search' />
            <input className='chat-search_input' placeholder='Search messages' />
          </div>
          {/* Chat message */}
          <ul className='chat_all'>
            {chatList.map((item, id) => (
              <li className='chat_all-inner' key={id}>
                <div className='chat_all-inner_logo'>
                  <img src={logoChat} alt='logoChat' />
                </div>
                <div className='chat_all-inner_data'>
                  <div className='chat_all-inner_data_info'>
                    <span>{item.name}</span>
                    <span className='chat_all-inner_data_info_time'>2m ago</span>
                  </div>
                  <span className='chat_all-inner_data_info_subtitle'>{item.position}</span>
                  <div className='chat_all-inner_data_info-message'>
                    <span>{item.message}</span>
                    <span className='chat_all-inner_data_info_message-all'>
                      <span className='chat_all-inner_data_info-message_last'>
                        {item.meseageUnreviewed}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='chat_btn'>
          <button className='chat_btn_inner'>Start a new chat</button>
        </div>
      </div>
    </div>
  ) : (
    <div className='chatSvg' onClick={() => setOpenChat(true)}>
      <img src={chatAll} alt='Chat' />
    </div>
  );
}
