import "./custom_text_input.scss";
import { useState } from "react";

const TextInput = (props) => {
  const [activePass, setActivePass] = useState(false);
  const {
    htmlFor,
    value,
    autoComplete,
    title,
    placeholder,
    disabled,
    maxLength,
    style,
    autoFocus,
    onChange,
    className,
    errorMessage,
    error,
    type,
    showPassIcon,
    classNameWrapper,
    description,
    wrapperStyle,
    icon,
    asterisksone,
    asteriskstwo,
    ...rest
  } = props;

  return (
    <div
      style={wrapperStyle}
      className={`input_container 
            ${classNameWrapper ?? ""}
            ${error ? "input_error" : ""}
            ${showPassIcon || icon ? "input_show_icon" : ""}`}
    >
      <div className={"wrapper_input"}>
        {title && (
          <label
            htmlFor={htmlFor}
            className={`input_title 
          ${error ? "input_text_error" : ""}`}
          >
            {title}
          </label>
        )}
        <span className='asteriskone'>{asterisksone}</span>
        <span className='asterisktwo'>{asteriskstwo}</span>
        <input
          value={value}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onChange={(e) => onChange(e)}
          style={style ?? null}
          disabled={disabled}
          maxLength={maxLength ?? null}
          placeholder={placeholder ?? null}
          className={`custom_input ${className ?? ""}`}
          type={activePass ? "text" : type}
          name={htmlFor}
          id={htmlFor}
          {...rest}
        />
        {showPassIcon ? (
          <svg
            width='24'
            height='24'
            onClick={() => setActivePass(!activePass)}
            className={"show_pass_icon"}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.9597 18.5381C7.81321 18.5381 3.26905 15.8268 0.318733 12.7968C-0.0797146 12.3186 -0.0797146 11.6005 0.318733 11.2021C3.26905 8.09241 7.81393 5.46082 11.9597 5.46082C16.1859 5.46082 20.73 8.17209 23.6803 11.2021C24.0788 11.6005 24.0788 12.3186 23.6803 12.7968C20.73 15.8268 16.1851 18.5381 11.9597 18.5381ZM11.9597 8.89029C13.7138 8.89029 15.1491 10.2459 15.1491 12C15.1491 13.6744 13.7137 15.1097 11.9597 15.1097C10.2853 15.1097 8.85001 13.6743 8.85001 12C8.85001 10.2459 10.2853 8.89029 11.9597 8.89029Z'
              fill='#003E47'
              fillOpacity='0.4'
            />
          </svg>
        ) : null}
      </div>
      {error ? <p className={"errorMessage"}>{errorMessage}</p> : null}
      {description ? <p className={"input_descpription"}>{description}</p> : null}
    </div>
  );
};

export default TextInput;
