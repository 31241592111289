import { Tooltip, TooltipProps } from "antd";
import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./StyledTooltip.module.scss";

type StyledTooltipProps = {
  children?: ReactNode;
  className?: string;
  theme?: "default" | "light";
} & TooltipProps;

export default function StyledTooltip({
  children,
  className,
  theme = "default",
  ...props
}: StyledTooltipProps) {
  return (
    <Tooltip {...props} rootClassName={clsx(styles.root, styles[theme], className)}>
      {children}
    </Tooltip>
  );
}
