import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { deleteCompany } from "../../../../api/companyApi";

import SettingsSplitNavigation from "../../components/SettingsSplitNavigation";
import CompanySettingsDetails from "./CompanySettingsDetails/CompanySettingsDetails";
import Team from "./Cards/Team/Team";
import DangerZone from "./Cards/DangerZone";
import DeleteCompanyPopup from "./DeleteCompanyPopup";

const CompanySettings = ({ companyData, syncDataOnSave }) => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [deleteCompanyPopupActive, setDeleteCompanyPopupActive] = useState(false);

  const openDeleteCompanyPopup = () => {
    setDeleteCompanyPopupActive(true);
  };

  const closeDeleteCompanyPopup = () => {
    setDeleteCompanyPopupActive(false);
  };

  const onDeleteCompany = async () => {
    await deleteCompany(companyData.id);
    closeDeleteCompanyPopup();
    history.push("/audits");
  };

  return (
    <div>
      <DeleteCompanyPopup
        isOpen={deleteCompanyPopupActive}
        onClose={closeDeleteCompanyPopup}
        onSubmit={onDeleteCompany}
        companyName={companyData?.name}
      />
      <SettingsSplitNavigation
        navigation={[
          {
            key: "details",
            href: "#details",
            title: t("details"),
            body: (
              <CompanySettingsDetails companyData={companyData} syncDataOnSave={syncDataOnSave} />
            ),
            bodyStyle: { padding: 0 },
          },
          {
            key: "team",
            href: "#team",
            title: t("team"),
            body: <Team companyData={companyData} />,
            bodyStyle: { padding: 0, marginTop: 0 },
          },
          {
            key: "danger-zone",
            href: "#danger-zone",
            title: t("danger_zone"),
            body: (
              <DangerZone
                openDeleteCompanyPopup={openDeleteCompanyPopup}
                closeDeleteCompanyPopup={closeDeleteCompanyPopup}
                companyData={companyData}
                syncDataOnSave={syncDataOnSave}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CompanySettings;
