import { Card } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import checkbox from "../../../assets/admin/checkbox.svg";

import "../../styles/settingsAudits.css";

const PersonalPermit = ({ syncDataOnSave, personalDetailsData }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const checkList = [
    {
      id: "editCompany",
      title: t("audit_permission_control_edit_details"),
    },
    {
      id: "editAudit",
      title: t("audit_permission_control_edit_audit_details"),
    },
    {
      id: "editPermissionRoles",
      title: t("audit_permission_control_edit_permission"),
      subtitle: t("audit_permission_control_edit_permission_description"),
    },
    {
      id: "createNewPermissions",
      title: t("audit_permission_control_create_permission"),
    },
    {
      id: "manageTeam",
      title: t("audit_permission_control_manage_team"),
      subtitle: t("audit_permission_control_manage_team_description"),
    },
    {
      id: "overviewAccess",
      title: t("audit_permission_control_access_to_overview"),
    },

    {
      id: "requestsAccess",
      title: t("audit_permission_control_access_to_requests"),
    },
    {
      id: "createRequests",
      title: t("audit_permission_control_create_new_requests"),
    },
    {
      id: "refetchData",
      title: t("audit_permission_control_can_fetch"),
    },
    {
      id: "sensitiveRequestsWithoutAssign",
      title: t("audit_permission_control_sensitive_request"),
    },
    {
      id: "statusAccess",
      title: t("audit_permission_control_access_status"),
    },
    {
      id: "documentAccess",
      title: t("audit_permission_control_access_document"),
    },
    {
      id: "meetingAccess",
      title: t("audit_permission_control_access_meetings"),
    },
  ];
  const [answers, setAnswers] = useState(personalDetailsData);

  return checkList.map((item, index) => (
    <Card.Grid
      onClick={() => {
        const date = {
          ...answers,
          [item.id]: !answers[item.id],
        };
        setAnswers(date);
        syncDataOnSave({ [item.id]: !answers[item.id] });
      }}
      hoverable={false}
      style={{ padding: 0 }}
      className={"permission_row"}
      key={index}
    >
      <div>
        {answers[item.id] ? (
          <img alt='checkbox' src={checkbox} />
        ) : (
          <div className='pesrmission_info_check'></div>
        )}
      </div>
      <div>
        <span className='permission_title'>{item.title}</span>
        <span className='permission_description'>{item.subtitle}</span>
      </div>
    </Card.Grid>
  ));
};

export default PersonalPermit;
