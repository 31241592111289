import React, { useEffect, useRef, useState } from "react";
import styles from "./TimeRangePicker.module.css";
import dayjs from "dayjs";
import { ChevronBottom, WatchIcon } from "../../../assets/icons";

const TimeRangePicker = ({
  mode = "range",
  value = mode === "range" ? [dayjs(), dayjs()] : dayjs(),
  onChange,
  disabled,
}) => {
  const wrapperRef = useRef(null);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    if (isActive) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isActive]);

  const formatDate = (hour, minute) => {
    return dayjs().hour(hour).minute(minute).second(0).millisecond(0);
  };

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const handleTimeSelection = (type, hour, minute) => {
    let newTime = formatDate(hour, minute);

    if (mode === "single") {
      onChange(newTime);
      return;
    }

    if (type === "start") {
      onChange([newTime, value[1]]);
    } else {
      onChange([value[0], newTime]);
    }
  };
  const formatOption = (option) => {
    return option < 10 ? `0${option}` : option;
  };

  const currentTimeDisplay =
    mode === "single"
      ? value.format("HH:mm")
      : `${value[0].format("HH:mm")} - ${value[1].format("HH:mm")}`;
  return (
    <div
      ref={wrapperRef}
      className={`${styles.timeRangePicker} ${disabled ? styles.disabled : ""} ${
        isActive ? styles.active : ""
      } ${mode ? styles[mode] : ""}`}
    >
      <div className={styles.pickerOverlay} onClick={toggleDropdown}>
        <WatchIcon />
        <span>{currentTimeDisplay}</span>
        <ChevronBottom className={styles.chevron} />
      </div>
      <div className={styles.dropdown}>
        <div className={styles.header}>{currentTimeDisplay}</div>
        <div className={styles.columns}>
          <div className={styles.column}>
            {Array.from({ length: 24 }).map((_, i) => (
              <div
                key={i}
                onClick={() =>
                  handleTimeSelection("start", i, (mode === "range" ? value[0] : value).minute())
                }
                className={
                  i === (mode === "range" ? value[0] : value).hour() ? styles.selected : ""
                }
              >
                {formatOption(i)}
              </div>
            ))}
          </div>
          <div
            className={styles.secondaryDivider}
            style={{ left: mode === "single" ? "50%" : "25%" }}
          />
          <div className={styles.column}>
            {Array.from({ length: 12 }).map((_, i) => (
              <div
                key={i}
                onClick={() =>
                  handleTimeSelection("start", (mode === "range" ? value[0] : value).hour(), i * 5)
                }
                className={
                  i * 5 === (mode === "range" ? value[0] : value).minute() ? styles.selected : ""
                }
              >
                {formatOption(i * 5)}
              </div>
            ))}
          </div>
          {mode === "range" && (
            <>
              <div className={styles.divider}></div>
              <div className={styles.column}>
                {Array.from({ length: 24 }).map((_, i) => (
                  <div
                    key={i}
                    onClick={() => handleTimeSelection("end", i, value[1].minute())}
                    className={i === value[1].hour() ? styles.selected : ""}
                  >
                    {formatOption(i)}
                  </div>
                ))}
              </div>
              <div className={styles.secondaryDivider} style={{ left: "75%" }} />
              <div className={styles.column}>
                {Array.from({ length: 12 }).map((_, i) => (
                  <div
                    key={i}
                    onClick={() => handleTimeSelection("end", value[1].hour(), i * 5)}
                    className={i * 5 === value[1].minute() ? styles.selected : ""}
                  >
                    {formatOption(i * 5)}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeRangePicker;
