import React from "react";
import "./styles/thank-you.css";
import { useTranslation } from "react-i18next";

const ThankYou = () => {
  const { t } = useTranslation("thankYou");

  return (
    <div className='thank-you-wrapper'>
      <svg
        width='49'
        height='40'
        viewBox='0 0 49 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.6154 15.6989L33.2769 12.043L36.9385 8.3871L39.5231 5.80645C40.4923 4.83871 42.2154 4.83871 43.1846 5.80645C44.1539 6.77419 44.1539 8.49462 43.1846 9.46237L40.2769 12.3656L28.6462 23.9785L25.5231 26.9892L18.4154 34.086C17.4462 35.0538 15.7231 35.0538 14.7538 34.086L8.18462 27.5269L5.81538 25.1613C4.84615 24.1935 4.84615 22.4731 5.81538 21.5054C6.78462 20.5376 8.50769 20.5376 9.47692 21.5054L11.0923 23.1183L14.7538 26.7742L16.6923 28.7097L21.9692 23.4409L26.0615 19.3548L29.0769 16.3441L29.6154 15.6989ZM22.0769 37.7419L28.6462 31.1828L34.2462 25.5914L38.6615 21.1828L42.6462 17.2043L46.7385 13.1183C49.7538 10.1075 49.7538 5.26882 46.7385 2.25806C43.7231 -0.752688 38.8769 -0.752688 35.8615 2.25806L33.0615 5.05376L29.4 8.70968L25.6308 12.4731L21.8615 16.2366L16.6923 21.3978L15.1846 19.8925L13.2462 17.957C12.6 17.3118 11.8462 16.7742 11.0923 16.4516C8.18462 15.0538 4.63077 15.5914 2.26154 17.957C-0.753846 20.9677 -0.753846 25.8064 2.26154 28.8172L6.67692 33.2258L11.2 37.7419C14.2154 40.7527 19.0615 40.7527 22.0769 37.7419Z'
          fill='#49BACA'
        />
      </svg>
      <span className='title'>{t("title")}</span>
      <span className='description'>{t("description")}</span>
    </div>
  );
};

export default ThankYou;
