const BexioIcon = ({ ...props }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M25.7708 7.2636C24.5368 6.02962 22.863 5.33594 21.1174 5.33594C19.3715 5.33594 17.6978 6.02962 16.4639 7.2636L15.9439 7.7836C16.3776 8.69563 17.1118 9.42992 18.0239 9.8636L18.5439 9.3436C19.9922 8.0761 22.1748 8.14922 23.5356 9.51016C24.8966 10.8711 24.9697 13.0537 23.7022 14.5019L14.7161 23.4901C13.6029 24.5758 11.9993 24.9912 10.4991 24.5819C8.99905 24.1736 7.8271 23.0016 7.41864 21.5014C7.00935 20.0013 7.42473 18.3975 8.51043 17.2845L12.3272 13.4677C12.901 12.8928 13.8324 12.8928 14.4072 13.4677C14.981 14.0415 14.981 14.9728 14.4072 15.5477L10.7672 19.1877C11.2009 20.0987 11.9352 20.833 12.8472 21.2677L16.4872 17.6277C17.5993 16.5146 18.034 14.8935 17.6268 13.3743C17.2195 11.8552 16.0322 10.6677 14.5127 10.2603C12.9933 9.85305 11.3725 10.2877 10.2604 11.3998L6.4426 15.2195C4.99635 16.5733 4.1605 18.4573 4.128 20.4387C4.09448 22.4202 4.86739 24.3296 6.26894 25.731C7.6705 27.1326 9.57978 27.9055 11.5612 27.872C13.5427 27.8395 15.4267 27.0036 16.7805 25.5574L25.7708 16.5713C27.0058 15.3373 27.6994 13.6634 27.6994 11.9178C27.6994 10.1719 27.0058 8.49827 25.7708 7.26327L25.7708 7.2636Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default BexioIcon;
