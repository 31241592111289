import React from "react";

const FolderIcon = ({ ...restAttributes }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.76875 24.6136C3.76219 24.5513 3.75781 24.4867 3.75781 24.4222V7.66281C3.75781 6.8589 4.31563 6.20703 5.00359 6.20703H14.0369C14.8069 6.20703 15.5026 6.68499 15.799 7.4189C16.2387 8.50936 16.8173 9.94108 16.8173 9.94108H26.1875C26.4871 9.94108 26.7759 10.0647 26.9881 10.2834C27.2003 10.5022 27.3195 10.7997 27.3195 11.1092V15.0127L6.85206 15.1571C6.36752 15.1604 5.94752 15.5027 5.8316 15.9872L3.77097 24.6146L3.76875 24.6136Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.4028 17.7823C29.4509 17.476 29.3612 17.1632 29.1578 16.9281C28.9543 16.6929 28.659 16.5584 28.3484 16.5606C24.2797 16.589 11.4445 16.6809 8.04952 16.7049C7.56171 16.7082 7.13952 17.044 7.02688 17.5187C6.59702 19.3234 5.55579 23.6983 5.03297 25.8946C4.9575 26.2096 5.03078 26.5421 5.23203 26.7959C5.43219 27.0496 5.73844 27.1984 6.06218 27.1984H27.0305C27.5523 27.1984 27.9963 26.8177 28.0762 26.3026C28.3726 24.3995 29.0638 19.95 29.4007 17.7833L29.4028 17.7823Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default FolderIcon;
