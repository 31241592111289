import moment from "moment/moment";
import classNames from "classnames/bind";

import { CardBlock } from "../../../../../../Admin/pages/components";
import { CalendarIcon } from "../../../../../../assets/icons";
import toFullName from "../../../../../../consts/toFullName";
import AvatarBlock from "../../../../../../Admin/pages/Requests/components/NewRequest/AvatarBlock/AvatarBlock";
import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";

import { ReactComponent as LocationIcon } from "../../../../../../assets/icons/location.svg";

import styles from "./MeetingBlock.module.scss";

const cx = classNames.bind(styles);

const MeetingBlock = ({
  openMeeting,
  name,
  itemId,
  creator,
  assignedUsers = [],
  assignedRoles = [],
  date,
  startTime,
  platform,
  readOnly = false,
  active,
  bordered,
  titleStyle,
}) => {
  const startDate = date ? moment(date).format("D MMM YYYY") : "";
  const timeFrom = startTime ? moment(startTime).format("HH:mm") : "";
  const meetingDate = startDate + (timeFrom ? ", " + timeFrom : "");

  const onOpenMeeting = () => {
    openMeeting(itemId);
  };

  return (
    <CardBlock bordered={bordered} active={active} onClick={!readOnly ? onOpenMeeting : null}>
      <span style={titleStyle} className={cx(styles.titleBlock, { readOnly })}>
        {name}
      </span>
      <div className={styles.meetingInfo}>
        {meetingDate && (
          <div className={styles.infoBlock}>
            <CalendarIcon />
            <span>{meetingDate}</span>
          </div>
        )}
        {platform && (
          <div className={styles.infoBlock}>
            <LocationIcon />
            <span>{platform}</span>
          </div>
        )}
      </div>
      <div className={styles.assignedUsers}>
        {creator && <CustomAvatar user={creator} width={24} height={24} />}
        {assignedUsers.map((assignedUser) => (
          <CustomAvatar key={assignedUser.user} user={assignedUser.user} width={24} height={24} />
        ))}
        {assignedRoles?.map((assignedRole) => {
          const users = assignedRole.teamMembers.map(({ user }) => toFullName(user));
          return <AvatarBlock showGroup name={assignedRole.name} users={users} />;
        })}
      </div>
    </CardBlock>
  );
};
export default MeetingBlock;
