import API from "./API";
import { INotification } from "../interfaces/Notifications";

export const sendData = async (userData: any) => {
  try {
    const FD = new FormData();

    Object.entries(userData).forEach((i) => {
      if (i[0] === "logo" && userData.logoURL && i[1]) {
        // @ts-ignore
        FD.append(`file`, i[1]);
      } else if (i[0] === "logo" && !userData.logoURL && !i[1]) {
        FD.append(i[0], "undefined");
      } else if (typeof i[1] === "boolean") {
        FD.append(i[0], i[1].toString());
      } else if (i[1]) {
        // @ts-ignore
        FD.append(i[0], i[1]);
      }
    });

    const response = await API().post("/user/", FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const userChange = async () => {
  try {
    const response = await API().get("/user");
    return response.data;
  } catch (error) {
    return error;
  }
};

// @ts-ignore
export const isUserActive = async (token) => {
  try {
    const response = await API(token).get("/user/is-active");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getNotifications = async ({
  type = "important",
}: {
  type: "general" | "archive" | "important" | string;
}): Promise<INotification[]> => {
  try {
    const response = await API().get(`/user/notifications?type=${type}`);
    return response.data.notifications;
  } catch (error) {
    throw error;
  }
};

export const readGeneralNotifications = async (): Promise<{ succes: boolean }> => {
  try {
    const response = await API().put(`/user/read-general-notifications`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const readImportantNotifications = async (
  notificationId: number
): Promise<{ succes: boolean }> => {
  try {
    const response = await API().put(`/user/read-important-notifications`, { notificationId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = async (): Promise<{ succes: boolean }> => {
  try {
    const response = await API().get(`/user/get-all-users`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
