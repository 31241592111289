import classNames from "classnames";
import React, { forwardRef } from "react";

import styles from "./Input.module.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onHandleChange?: (name: string, value: string) => void;
  error?: boolean;
  isHighlighted?: boolean;
  errorMessage?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ onHandleChange, error, isHighlighted, errorMessage, ...inputAttributes }, ref) => {
    const classes = classNames(styles.root, {
      [styles.error]: error,
      [styles.highlighted]: isHighlighted,
    });

    return (
      <>
        <input
          ref={ref}
          autoComplete='off'
          onChange={(event) =>
            onHandleChange && onHandleChange(event.target.name, event.target.value)
          }
          {...inputAttributes}
          className={classes}
        />
        {error && errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
      </>
    );
  }
);

export default Input;
