import { useState } from "react";
import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";

import { forgotPassword } from "../../../../../api/recoveryPasswordApi";
import usePasswordValidator from "../../../../../hooks/usePasswordValidator";

import CardActions from "../../../../../Admin/pages/components/CardActions";
import InputIcon from "../../../../../components/ui-components/InputIcon/InputIcon";
import PasswordCondition from "../../../../../components/PasswordCondition/PasswordCondition";
import { Label } from "../../../../../components/ui-components";

import showPassword from "../../../../../assets/images/showPassword.svg";
import { ReactComponent as PasswordNotValid } from "../../../../../assets/admin/passwordNotValid.svg";
import { ReactComponent as PasswordValid } from "../../../../../assets/admin/passwordValid.svg";

import styles from "./AccountSettingsChangePassword.module.scss";

const AccountSettingsChangePassword = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [shown, setShown] = useState(false);
  const [error, setError] = useState("");

  const [profileData, setProfileData] = useState({
    newPassword: {
      id: 1,
      password: "",
      title: t("new_password"),
    },
    repeatPassword: {
      id: 2,
      password: "",
      title: t("repeat_new_password"),
    },
    currentPassword: {
      id: 3,
      password: "",
      title: t("current_password"),
    },
  });

  const handlePasswordChange = (key, value) => {
    setProfileData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        password: value,
      },
    }));
  };

  const newPassword = profileData.newPassword.password;
  const repeatPassword = profileData.repeatPassword.password;
  const validationState = usePasswordValidator(newPassword, repeatPassword);

  const discardData = () => {
    setProfileData((prevState) => ({
      newPassword: {
        ...prevState.newPassword,
        password: "",
      },
      repeatPassword: {
        ...prevState.repeatPassword,
        password: "",
      },
      currentPassword: {
        ...prevState.currentPassword,
        password: "",
      },
    }));
    setError(false);
  };

  const onSubmit = (e) => {
    e?.preventDefault();
    forgotPassword({
      password: profileData.newPassword.password,
      oldPassword: profileData.currentPassword.password,
    })
      .then((res) => {
        if (res?.message === "Success") {
          discardData();
        } else if (res.response.data.message) {
          setError(res.response.data.message);
        } else if (!res?.message) {
          setError("Old password does not match");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const profileDataKeys = Object.keys(profileData);
  const showActions =
    profileData.repeatPassword.password !== "" || profileData.newPassword.password !== "";
  return (
    <>
      <Card.Grid hoverable={false}>
        {profileDataKeys.map((item, index) => (
          <form onSubmit={onSubmit} key={profileData[item].id}>
            <Row gutter={24}>
              <Col span={8}>
                <Label>{profileData[item].title}</Label>
              </Col>
              <Col span={16}>
                <InputIcon
                  value={profileData[item].password}
                  onChange={(e) => {
                    e.preventDefault();
                    handlePasswordChange(item, e.target.value);
                    setError(false);
                  }}
                  type={shown === profileData[item].id ? "text" : "password"}
                  icon={<img src={showPassword} alt='show' />}
                  onIconClick={() => {
                    if (shown === profileData[item].id) {
                      setShown(false);
                    } else {
                      setShown(profileData[item].id);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 8 }}>
              <Col span={8} />
              <Col span={16}>
                {index === 0 && (
                  <PasswordCondition profileData={profileData} validationState={validationState} />
                )}
                {index === 1 && profileData.repeatPassword.password !== "" && (
                  <div className={styles.conditionItem}>
                    {validationState.arePasswordsEqual ? <PasswordValid /> : <PasswordNotValid />}
                    <span
                      className={
                        validationState.arePasswordsEqual
                          ? styles.conditionSuccess
                          : styles.conditionError
                      }
                    >
                      {validationState.arePasswordsEqual
                        ? tGlobal("passwords_match")
                        : tGlobal("passwords_not_match")}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            {index !== profileDataKeys.length - 1 && <br />}
          </form>
        ))}
        {error && <div className={styles.rightError}>{error}</div>}
      </Card.Grid>
      {showActions && <CardActions onSubmit={onSubmit} onCancel={discardData} />}
    </>
  );
};

export default AccountSettingsChangePassword;
