import { Popover } from "antd";
import { EditorState, Modifier } from "draft-js";
import { Map } from "immutable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as AlignLeftIcon } from "../../../../../assets/icons/textEditor/align-left.svg";
import { ReactComponent as AlignCenterIcon } from "../../../../../assets/icons/textEditor/align-center.svg";
import { ReactComponent as AlignRightIcon } from "../../../../../assets/icons/textEditor/align-right.svg";

import styles from "./TextAlignButton.module.scss";

type TextAlignButtonProps = {} & TextEditorButtonProps;

export default function TextAlignButton({
  editorState,
  onEditorStateChange,
}: TextAlignButtonProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const [isOpen, setIsOpen] = useState(false);

  const handleAlignment = (textAlign: string) => () => {
    const newContentState = Modifier.setBlockData(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      Map({ "text-align": textAlign })
    );
    const newEditorState = EditorState.push(editorState, newContentState, "change-block-data");
    onEditorStateChange(newEditorState);
    setIsOpen(false);
  };

  return (
    <Popover
      trigger='click'
      placement='bottom'
      open={isOpen}
      onOpenChange={setIsOpen}
      rootClassName={styles.popover}
      content={
        <div className={styles.menu}>
          <div className={styles.menuItem} onClick={handleAlignment("left")}>
            <AlignLeftIcon />
            {t("align_left")}
          </div>
          <div className={styles.menuItem} onClick={handleAlignment("center")}>
            <AlignCenterIcon />
            {t("align_center")}
          </div>
          <div className={styles.menuItem} onClick={handleAlignment("right")}>
            <AlignRightIcon />
            {t("align_right")}
          </div>
        </div>
      }
    >
      <ToolbarButton
        Icon={AlignLeftIcon}
        tooltipTitle={isOpen ? "" : t("align_text")}
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
}
