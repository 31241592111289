import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";

import { disableCompany } from "../../../../../../api/companyApi";
import { Button } from "../../../../../../components/ui-components";
import CustomSwitch from "../../../../../../components/ui-components/Switch/Switch";

const DangerZone = ({ companyData, syncDataOnSave, openDeleteCompanyPopup }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const onChange = async (e) => {
    const checked = e.target.checked;
    syncDataOnSave({ disabled: checked });
    await disableCompany(companyData.id, { disabled: checked });
  };

  return (
    <Space.Compact direction={"vertical"} block>
      <Row align='middle' gutter={24} span={24}>
        <Col span={8}>
          <span>{t("active_status")}</span>
        </Col>
        <Col span={16}>
          <FormGroup>
            <CustomSwitch
              checked={companyData.disabled}
              onChange={onChange}
              label={t("disable_company")}
            />
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row align={"middle"}>
        <Col span={8}>
          <span>{t("delete_company")}</span>
        </Col>
        <Col span={16}>
          <Button
            handleClick={openDeleteCompanyPopup}
            secondary
            color={"red"}
            style={{ width: "max-content" }}
          >
            {t("delete_company")}
          </Button>
        </Col>
      </Row>
    </Space.Compact>
  );
};

export default DangerZone;
