import dayjs from "dayjs";

const calculateExactTimeDifference = (date: string | Date) => {
  const now = dayjs();
  const givenDate = dayjs(date);

  // Check if the given date is in the future or the past
  const isFuture = givenDate.isAfter(now);

  // Get the absolute difference in total days
  const totalDaysDifference = Math.abs(givenDate.diff(now, "day"));

  // Calculate months and remaining days
  const diffInMonths = Math.floor(totalDaysDifference / 30); // Rough approximation for months
  const diffInDays = totalDaysDifference % 30; // Remaining days after full months

  // Construct the result string based on conditions
  let result = "";
  if (diffInMonths > 0) {
    result += `${diffInMonths} months`;
  }
  if (diffInDays > 0) {
    if (diffInMonths > 0) {
      result += ` and `;
    }
    result += `${diffInDays} days`;
  }

  // If both months and days are 0 (same date), return "today"
  if (result === "") {
    result = "today";
  }

  // Format the result based on whether the date is in the future or the past
  return isFuture ? `in ${result}` : `${result} ago`;
};

export default calculateExactTimeDifference;
