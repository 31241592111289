import API from "./API.js";

export const sendSmS = async (body, token) => {
  try {
    const response = await API(token).post("/user/send-sms", body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifySmS = async (body, token) => {
  try {
    const response = await API(token).post("/user/verify-sms", body);
    return response.data;
  } catch (error) {
    return error;
  }
};
