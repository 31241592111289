import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/ui-components/Button/Button";
import TextInput from "../../customComponents/TextInput";
import "../../Admin/styles/loginPassword.css";
import { setPassword } from "../../api/recoveryPasswordApi";
import logo from "../../assets/images/logo-primary.svg";
import Keys from "../../consts/helper";
import { usePasswordValidator } from "../../hooks";
import PasswordCondition from "../../components/PasswordCondition/PasswordCondition";
import { useTranslation } from "react-i18next";
import { languageToValue } from "../../consts/languageToValue";
import { isUserActive } from "../../api/userApi";

const LoginPassword = () => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });
  const { i18n, t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [active, setActive] = useState(false);
  const [error, setError] = useState(false);
  const [profileData, setProfileData] = useState({
    newPassword: {
      id: 1,
      password: "",
      title: "New password",
    },
  });

  const handlePasswordChange = (value) => {
    setProfileData((prevState) => ({
      ...prevState,
      newPassword: {
        ...prevState.newPassword,
        password: value,
      },
    }));
  };

  const urlParams = new URLSearchParams(document.location.search);
  const isReset = urlParams.get("reset-password");
  const token = urlParams.get("token");
  const phoneRedirect = urlParams.get("phoneRedirect");

  const newPassword = profileData.newPassword.password;
  const validationState = usePasswordValidator(newPassword, newPassword);
  const allFieldsTrue = Object.values(validationState).every((value) => value === true);

  useEffect(() => {
    if (isReset) return;
    isUserActive(token).then((res) => {
      if (res.isActive) {
        history.push("/login");
      }
    });
  }, [isReset]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (active) {
      setPassword(
        {
          password: profileData.newPassword.password,
        },
        token
      )
        .then((res) => {
          console.log("res", res);
          localStorage.setItem(
            Keys.USER_DATA,
            JSON.stringify({
              token: token,
            })
          );
          if (phoneRedirect) {
            history.push("/login/phone");
          } else {
            history.push("/login");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (allFieldsTrue) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [allFieldsTrue]);

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const language = URLParams.get("language");
    if (language) {
      const lang = languageToValue[language];
      i18n.changeLanguage(lang).then(() => {
        localStorage.setItem("i18nextLng", lang);
      });
    }
  }, []);

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>

        <form onSubmit={onSubmit} className={"login_form"}>
          <p className={"login_form__title"}>{t("set_password")}</p>
          <p className={"login_form__subtitle"}>{t("set_password_description")}</p>
          <div className={"login_form__inputs"} style={{ gridGap: "9px 24px" }}>
            <TextInput
              onChange={(e) => {
                e.preventDefault();
                handlePasswordChange(e.target.value);
              }}
              title={tGlobal("new_password")}
              value={profileData.newPassword.password}
              type={"password"}
              htmlFor={"password"}
              showPassIcon={true}
              error={error}
            />
            <PasswordCondition validationState={validationState} profileData={profileData} />
            <Button type={"submit"} primary large color={"blue"} disabled={!active}>
              {t("set_password")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPassword;
