import { useState } from "react";
import { Card, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import { updateTeam } from "../../../../api/teamApi";

import { Input } from "../../../../components/ui-components";
import CardActions from "../../components/CardActions/CardActions";
import FileUpload from "../../../../components/FileUpload";

import "./accountSettings.css";

const TeamProfile = ({ userData = {}, syncDataOnSave }) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [data, setData] = useState({
    name: userData?.name,
    logo: userData?.logo,
    logoURL: "",
  });

  const hasChanged = data.name !== userData.name || !!data.logoURL || data.logo !== userData.logo;

  const handleSubmit = async () => {
    try {
      const res = await updateTeam(data);
      // dispatch(setUserData({ ...userData, ...data, logo: res.logo }));
      const newData = {
        ...data,
        logo: res.logo,
        logoURL: "",
      };
      setData(newData);
      syncDataOnSave(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const inputData = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleCancel = () => {
    setData({
      name: userData.name ?? "",
      logo: userData.logo ?? "",
    });
  };

  return (
    <>
      <Card.Grid hoverable={false}>
        <Space.Compact direction='vertical' block>
          <Row gutter={24} span={24}>
            <Col span={8}>
              <span>{tGlobal("company_logo")}</span>
            </Col>
            <Col span={16}>
              <FileUpload
                title={tGlobal("select_picture")}
                file={data.logoURL || data.logo}
                subtitle='JPG, PNG'
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                }}
                onChange={(file) => {
                  setData({
                    ...data,
                    logo: file,
                    logoURL: file ? URL.createObjectURL(file) : "",
                  });
                }}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col span={8}>
              <span>{tGlobal("company_name")}</span>
            </Col>
            <Col span={16}>
              <Input
                placeholder='aivo Revisions AG'
                value={data.name}
                onChange={({ target }) => {
                  inputData("name", target.value);
                }}
              />
            </Col>
          </Row>
        </Space.Compact>
      </Card.Grid>
      {hasChanged && <CardActions onSubmit={handleSubmit} onCancel={handleCancel} />}
    </>
  );
};
export default TeamProfile;
