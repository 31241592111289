import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { createMeeting, editMeeting } from "../../../../../api/meetingsAPI";
import { PATH } from "../../../../../consts/constants";
import { prepareDraft } from "../../../../../consts/prepareDraft";
import { removeMeetingData } from "../../../../../store/global/actions";

import { Input, Label } from "../../../../../components/ui-components";
import Attachments from "../../../../../Admin/pages/components/Attachments/Attachments";
import {
  AssignedWrapper,
  HeaderSection,
  PageTextEditor,
} from "../../../../../Admin/pages/components";
import DueDateTimeRange from "../../../../../Admin/pages/components/DueDateTimeRange/DueDateTimeRange";

import styles from "./NewMeeting.module.scss";

const initialMeeting = {
  title: "",
  date: "",
  startTime: dayjs().set("hours", 18).set("minutes", 0),
  endTime: dayjs().set("hours", 19).set("minutes", 0),
  location: "",
  description: "",
  privateNote: "",
  assignedUsers: [],
  assignedRoles: [],
  files: [],
};
const NewMeeting = ({
  editData,
  triggerRefetch,
  handleUpdateMeetingData,
  handleRedirectLastMeeting,
  lastChanges,
  requestId,
  setIsDiscarded,
}) => {
  const { id: idAudit } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "meetings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const isOrganiser = (email) => user.email === email;
  const user = useSelector((state) => state.user.user_data);
  const [errors, setErrors] = useState({});
  const [meetingsData, setMeetingsData] = useState(editData || lastChanges || initialMeeting);
  const [editorState, setEditorState] = useState(() => {
    if (editData) {
      return prepareDraft(editData.description);
    }
    if (lastChanges) {
      return prepareDraft(lastChanges?.description);
    }

    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (meetingsData !== initialMeeting) {
      handleUpdateMeetingData({ data: { ...meetingsData, files: [] } });
    }
    // eslint-disable-next-line
  }, [meetingsData]);

  const handleMeetingsData = (name, value) => {
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
    setMeetingsData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setMeetingsData((prev) => ({
      ...prev,
      description: forFormik,
    }));
    setEditorState(editorState);
  };

  const onUpdateMeeting = async () => {
    const { id, auditId, assignedRoles, assignedUsers } = meetingsData;
    try {
      const response = await editMeeting(auditId, id, {
        ...meetingsData,
        assignedRoles: assignedRoles.map((a) => a.id),
        assignedUsers: assignedUsers.map((a) => a.id),
      });
      console.log(response);
      triggerRefetch();
      history.push(`/dashboard/${auditId}/meetings/${id}`);
    } catch (err) {
      console.error(err);
      alert("ERROR");
    }
  };

  const onCreateMeeting = async () => {
    if (!meetingsData.title || !meetingsData.date) {
      setErrors({
        title: !meetingsData.title,
        date: !meetingsData.date,
      });
      return;
    }

    if (dayjs(meetingsData.date) < dayjs().startOf("day")) {
      setErrors((prev) => ({
        ...prev,
        date: true,
      }));
      return;
    }

    try {
      const { meeting } = await createMeeting(idAudit, {
        ...meetingsData,
        startTime: meetingsData.startTime,
        endTime: meetingsData.endTime,
        assignedRoles: meetingsData.assignedRoles.map((a) => a.id),
        assignedUsers: meetingsData.assignedUsers.map((a) => a.id),
      });
      triggerRefetch();
      history.push(`/dashboard/${idAudit}/meetings/${meeting.id}`);
    } catch (err) {
      console.error(err);
      alert("ERROR");
    }
  };

  const removerAssignedUsersAndRoles = ({ assignedUsers, assignedRoles }) => {
    setMeetingsData((prevValue) => ({
      ...prevValue,
      assignedUsers: [],
      assignedRoles: [],
    }));
  };

  const handleAssignedUsersAndRoles = ({ assignedUsers, assignedRoles }) => {
    console.log(assignedRoles, assignedUsers, "right here");
    setMeetingsData((prevValue) => ({
      ...prevValue,
      assignedUsers,
      assignedRoles,
    }));
  };

  const handleDiscard = () => {
    if (editData) {
      history.push(`/dashboard/${idAudit}/meetings/${editData.id}`);
    } else {
      setIsDiscarded(true);
      handleRedirectLastMeeting();
      dispatch(removeMeetingData());
    }
  };

  const changeDateTime = (newState) => {
    if (newState.date && errors.date) {
      setErrors((prev) => ({
        ...prev,
        date: false,
      }));
    }
    setMeetingsData((state) => ({
      ...state,
      ...newState,
    }));
  };

  const submitButtonText = editData ? tGlobal("update") : tGlobal("create");

  const submitButtonClick = editData ? onUpdateMeeting : onCreateMeeting;
  return (
    <div className={styles.wrapper}>
      <HeaderSection
        handleDiscard={handleDiscard}
        submitButtonClick={submitButtonClick}
        submitButtonText={submitButtonText}
        onHandleChange={handleMeetingsData}
        headerValue={meetingsData.title}
        headerValueName='title'
        headerTitle={requestId === PATH.EDIT ? t("update_meeting") : t("new_meetings")}
        headerPlaceholder={t("meeting_title")}
        titleError={errors.title}
      />
      <div className={styles.bodySection}>
        <div className={styles.column}>
          <AssignedWrapper
            assignButtonLabel={t("invite_user")}
            assignedLabel={t("attenedees")}
            assignedRoles={meetingsData.assignedRoles}
            assignedUsers={meetingsData.assignedUsers}
            isOrganiser={isOrganiser}
            onRemove={removerAssignedUsersAndRoles}
            handleAssignUsers={handleAssignedUsersAndRoles}
          />
        </div>

        <DueDateTimeRange
          date={meetingsData.date}
          startTime={meetingsData.startTime}
          endTime={meetingsData.endTime}
          setData={changeDateTime}
          errors={errors}
        />

        <div className={styles.column}>
          <Label>{t("location")}</Label>
          <Input
            onHandleChange={handleMeetingsData}
            value={meetingsData.location}
            name='location'
            placeholder='Url, address'
          />
        </div>
        <PageTextEditor
          value={meetingsData.description}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          name='description'
          label={t("description")}
          bordered
        />
        <Attachments
          attachments={meetingsData.files}
          onAddFile={(files) => {
            files[0].id = Math.random();
            setMeetingsData((prev) => ({
              ...prev,
              files: [...prev.files, files[0]],
            }));
          }}
          onRemoveFile={(deletedId) => {
            setMeetingsData((prev) => ({
              ...prev,
              files: prev.files.filter((f) => f.id !== deletedId),
            }));
          }}
        />
      </div>
    </div>
  );
};

export default NewMeeting;
