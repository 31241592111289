import { Col, ModalProps, Row } from "antd";
import React, { MouseEvent, ReactNode } from "react";

import StyledModal from "../ui-components/StyledModal";
import { Button } from "../ui-components";
import { useTranslation } from "react-i18next";

type DeleteModalProps = {
  content: ReactNode;
  onDelete?: () => void;
} & ModalProps;

export default function DeleteModal({ content, onDelete, ...props }: DeleteModalProps) {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    onDelete?.();
    props.onCancel?.(e);
  };

  return (
    <StyledModal
      footer={
        <Row gutter={16} justify='end'>
          <Col span={6}>
            <Button secondary color='blue' handleClick={props.onCancel}>
              {tGlobal("cancel")}
            </Button>
          </Col>
          <Col span={6}>
            <Button primary color='red' handleClick={handleDelete}>
              {tGlobal("delete")}
            </Button>
          </Col>
        </Row>
      }
      {...props}
    >
      {content}
    </StyledModal>
  );
}
