import React, { useState } from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import Popup from "./ui-components/Popup/Popup";
import { Input } from "./ui-components";
import CardActions from "../Admin/pages/components/CardActions/CardActions";

const ConfirmDeletePopup = ({
  title,
  description,
  placeholder,
  matchValue,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [deleteValue, setDeleteValue] = useState("");
  const { t } = useTranslation("settings");
  const handleClose = () => {
    setDeleteValue("");
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
    handleClose();
  };
  return (
    <Popup title={title} onClose={handleClose} isOpen={isOpen}>
      <Space.Compact direction={"vertical"} block className={"popup-body"}>
        <Row gutter={24} span={24}>
          <Col span={8}>
            <span>{t("confirm_deletion")}</span>
            <span className='caption'>{description}</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder={placeholder}
              type='name'
              value={deleteValue}
              onChange={({ target }) => {
                setDeleteValue(target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
      <CardActions
        onCancel={handleClose}
        onSubmit={handleSubmit}
        submitText={t("delete")}
        submitDisabled={deleteValue !== matchValue}
        submitButtonColor={"red"}
        cancelButtonColor={"blue"}
      />
    </Popup>
  );
};

export default ConfirmDeletePopup;
