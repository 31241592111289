import ApiNew from "./apiNew";
import { Voucher } from "../types/vouchers";

export const fetchVouchers = async (): Promise<Voucher[]> => {
  try {
    const response = await ApiNew().get("/superadmin/vouchers");
    return response.data as Voucher[];
  } catch (error) {
    console.error("Error fetching vouchers", error);
    throw error;
  }
};

export const createVoucher = async (code: Voucher["code"]): Promise<Voucher> => {
  try {
    const response = await ApiNew().post("/superadmin/vouchers", { code });
    return response.data as Voucher;
  } catch (error) {
    console.error("Error creating voucher", error);
    throw error;
  }
};

export const detachVoucher = async (voucherId: string) => {
  try {
    const response = await ApiNew().post(`/superadmin/vouchers/${voucherId}/detach`);
    return response.data;
  } catch (error) {
    console.error("Error updating voucher", error);
    throw error;
  }
};

export const toggleVoucherUsage = async (data: Partial<Voucher>) => {
  try {
    const response = await ApiNew().post(`/superadmin/vouchers/${data.id}/toggle`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating voucher", error);
    throw error;
  }
};

export const deleteVoucher = async (voucherId: string) => {
  try {
    const response = await ApiNew().delete(`/superadmin/vouchers/${voucherId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting voucher", error);
    throw error;
  }
};
