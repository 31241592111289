import React, { useRef, useEffect, useState, useMemo } from "react";
import {
  ChevronBottom,
  CloseDarkIcon,
  PenDarkIcon,
  SearchIcon,
  TickmarkIcon,
} from "../../../assets/icons";
import { Button, Input } from "../index";
import DeletePresetPopup from "./DeletePresetPopup/DeletePresetPopup";
import { useParams } from "react-router-dom";
import { submitDeletePreset, submitRenamePreset } from "../../../api/statusApi";
import RenamePresetPopup from "./RenamePresetPopup/RenamePresetPopup";
import Warning from "../Warning/Warning";
import { useTranslation } from "react-i18next";

const CustomPresetDropdown = ({
  options,
  setTemplateOptions,
  setCurrentTemplate,
  placeholder = "Select an option",
  value,
  onChange,
  type,
  headerStyle,
  name,
  style,
  dropdownStyle,
  disabled,
  error,
}) => {
  const { id } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const toggleContainer = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [deletePreset, setDeletePreset] = useState("");
  const [renamePreset, setRenamePreset] = useState("");

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  const onClickOutside = (event) => {
    if (toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) {
      onChange(name, option.value);
      setIsOpen(false);
    }
  };

  const selectedOption = options.find((opt) => opt.value === value);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (_, value) => {
    setSearch(value);
  };

  const handleEditClick = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    setRenamePreset(option.value);
  };

  const handleSubmitRename = async (name) => {
    try {
      await submitRenamePreset(id, renamePreset, { name });
      setTemplateOptions((prev) => {
        return prev.map((i) => {
          if (i.value === renamePreset) {
            return { ...i, label: name, value: name };
          }
          return i;
        });
      });
      if (value === renamePreset) setCurrentTemplate(name);
      setRenamePreset(null);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseRename = () => {
    setRenamePreset(null);
  };

  const handleDeleteClick = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletePreset(option.value);
  };

  const handleSubmitDelete = async () => {
    try {
      await submitDeletePreset(id, deletePreset);
      setTemplateOptions((prev) => prev.filter((i) => i.value !== deletePreset));
      setDeletePreset(null);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseDelete = () => {
    setDeletePreset(null);
  };

  const [filteredOptions, hasInactive] = useMemo(() => {
    let inactive = false;
    const filtered = options.filter((i) => {
      if (i.active === false) inactive = true;
      return !i.hidden && i.label.toLowerCase().includes(search.toLowerCase());
    });
    return [filtered, inactive];
  }, [search, options]);

  return (
    <>
      <RenamePresetPopup
        isOpen={renamePreset}
        onSubmit={handleSubmitRename}
        onClose={handleCloseRename}
      />
      <DeletePresetPopup
        isOpen={!!deletePreset}
        onSubmit={handleSubmitDelete}
        onClose={handleCloseDelete}
      />
      <div
        className={`dropdown-container ${type} ${disabled ? "disabled" : ""} ${
          isOpen ? "open" : ""
        } ${error ? "error" : ""}`}
        ref={toggleContainer}
        style={style}
      >
        <div className='dropdown-header' onClick={handleOpen} style={headerStyle}>
          {selectedOption ? selectedOption.label : placeholder}
          <ChevronBottom className='dropdown-arrow' />
        </div>
        {isOpen && (
          <div className='dropdown-list-wrapper'>
            <div className={"dropdown-search-wrapper"}>
              <SearchIcon />
              <Input
                value={search}
                onHandleChange={handleSearchChange}
                placeholder={"Search templates"}
              />
            </div>
            <ul style={dropdownStyle} className='dropdown-list-inner'>
              {hasInactive && (
                <Warning color={"gray"}>{t("warning_can_not_switch_template")}</Warning>
              )}
              {filteredOptions.map((option, index) => {
                const isActive = value === option.value;
                const hidden = option.active === false;
                return (
                  <li
                    key={index}
                    onClick={(e) => hidden || handleOptionClick(e, option)}
                    className={`dropdown-list-item ${isActive ? "dropdown-active-item" : ""} ${
                      hidden ? "dropdown-hidden-item" : ""
                    }`}
                    style={option.style}
                  >
                    {option.label}
                    {isActive ? (
                      <div className='dropdown-item-actions'>
                        {!!option.actions && (
                          <Button
                            tertiary
                            icon={<PenDarkIcon />}
                            handleClick={(e) => handleEditClick(e, option)}
                            color='gray'
                          />
                        )}
                        <TickmarkIcon />
                      </div>
                    ) : (
                      !!option.actions && (
                        <div className='dropdown-item-actions'>
                          <Button
                            tertiary
                            icon={<PenDarkIcon />}
                            handleClick={(e) => handleEditClick(e, option)}
                            color='gray'
                          />
                          <Button
                            tertiary
                            icon={<CloseDarkIcon />}
                            handleClick={(e) => handleDeleteClick(e, option)}
                            color='gray'
                          />
                        </div>
                      )
                    )}
                  </li>
                );
              })}
            </ul>
            <div className='dropdown-footer'>
              <Button
                secondary
                color='blue'
                handleClick={(e) => handleOptionClick(e, { value: "custom" })}
              >
                {t("create")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomPresetDropdown;
