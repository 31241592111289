import classNames from "classnames";
import { ElementType } from "react";

import StyledTooltip from "../../../ui-components/StyledTooltip";

import styles from "./ToolbarButton.module.scss";

type CustomToolbarButtonProps = {
  id?: string;
  disabled?: boolean;
  Icon: ElementType;
  isActive?: boolean;
  tooltipTitle?: string;
  onClick?: () => void;
};

export default function ToolbarButton({
  id,
  disabled,
  Icon,
  isActive,
  tooltipTitle,
  onClick,
}: CustomToolbarButtonProps) {
  return (
    <StyledTooltip className={styles.tooltip} placement='bottom' theme='light' title={tooltipTitle}>
      <button
        id={id}
        className={classNames(styles.button, {
          [styles.active]: isActive,
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon />
      </button>
    </StyledTooltip>
  );
}
