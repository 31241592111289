import { ReactNode } from "react";
import { EditorState } from "draft-js";

import * as buttons from "./components/buttons";
import ToolbarDivider from "./components/ToolbarDivider";

export type TextEditorButtonProps = {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  popup?: ReactNode;
  onClick?: () => void;
};

export const TEXT_EDITOR_BUTTONS = {
  undo: buttons.UndoButton,
  redo: buttons.RedoButton,
  divider: ToolbarDivider,
  textSize: buttons.TextSizeButton,
  bold: buttons.BoldButton,
  italic: buttons.ItalicButton,
  underline: buttons.UnderlineButton,
  strikethrough: buttons.StrikethroughButton,
  highlight: buttons.HighlightButton,
  divider2: ToolbarDivider,
  textAlign: buttons.TextAlignButton,
  indentIncrease: (props: TextEditorButtonProps) => (
    <buttons.IndentButton type='increase' {...props} />
  ),
  indentDecrease: (props: TextEditorButtonProps) => (
    <buttons.IndentButton type='decrease' {...props} />
  ),
  divider3: ToolbarDivider,
  listOrdered: (props: TextEditorButtonProps) => <buttons.ListButton type='ordered' {...props} />,
  listUnordered: (props: TextEditorButtonProps) => (
    <buttons.ListButton type='unordered' {...props} />
  ),
  divider4: ToolbarDivider,
  mention: buttons.MentionButton,
  image: buttons.AttachImageButton,
  document: buttons.AttachDocumentButton,
  comment: buttons.CommentButton,
};
