import frame1 from "../assets/images/Frame.svg";
import frame2 from "../assets/images/Frame1.svg";
import frame3 from "../assets/images/Frame2.svg";
import frame4 from "../assets/images/Frame3.svg";
import frame5 from "../assets/images/Frame4.svg";

export const otherConst = {
    revision_5_steps: [
        {
            title: 'revision_steps_1',
            img: frame1,
            description: 'revision_steps_1_description',
            link: true
        },
        {
            title: 'revision_steps_2',
            img: frame5,
            description: 'revision_steps_2_description'
        },
        {
            title: 'revision_steps_3',
            img: frame4,
            description: 'revision_steps_3_description'
        },
        {
            title: 'revision_steps_4',
            img: frame3,
            description: 'revision_steps_4_description'
        },
        {
            title: 'revision_steps_5',
            img: frame2,
            description: 'revision_steps_5_description'
        }
    ],
    faqOptions: {
        'faq_tab_1': [
            {
                question: 'faq_1_1_title',
                response: 'faq_1_1_description'
            },
            {
                question: 'faq_1_2_title',
                response: 'faq_1_2_description'
            },
            {
                question: 'faq_1_3_title',
                response: 'faq_1_3_description'
            },
            {
                question: 'faq_1_4_title',
                response: 'faq_1_4_description'
            }
        ],
        'faq_tab_2': [
            {
                question: 'faq_2_1_title',
                response: 'faq_2_1_description'
            },
            {
                question: 'faq_2_2_title',
                response: 'faq_2_2_description'
            }
        ],
        'faq_tab_3': [
            {
                question: 'faq_3_1_title',
                response: 'faq_3_1_description'
            },
            {
                question: 'faq_3_2_title',
                response: 'faq_3_2_description'
            },
            {
                question: 'faq_3_3_title',
                response: 'faq_3_3_description'
            },
            {
                question: 'faq_3_4_title',
                response: 'faq_3_4_description'
            }
        ],
        'faq_tab_4': [
            {
                question: 'faq_4_1_title',
                response: 'faq_4_1_description'
            },
            {
                question: 'faq_4_2_title',
                response: 'faq_4_2_description'
            }
        ],
        'faq_tab_5': [
            {
                question: 'faq_5_1_title',
                response: 'faq_5_1_description'
            },
            {
                question: 'faq_5_2_title',
                response: 'faq_5_2_description'
            },
            {
                question: 'faq_5_3_title',
                response: 'faq_5_3_description'
            }
        ],
    }
}