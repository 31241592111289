import React from "react";
import styles from "./AvatarBlock.module.scss";
import { Tooltip } from "antd";
import { XMarkIcon } from "../../../../../../assets/icons";
import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";
import toFullName from "../../../../../../consts/toFullName";

const TooltipContent = ({ users }) => (
  <div className={styles.tooltipContent}>
    <span className={styles.title}>Users in group</span>
    {users.map((i) => (
      <span className={styles.user}>{i}</span>
    ))}
  </div>
);

const AvatarBlock = ({ name, user, showGroup, users, onRemove }) => (
  <div className={styles.avatar_wrapper}>
    {!showGroup ? (
      <div className={styles.block_name_person}>
        <CustomAvatar height={24} width={24} user={user} />
        <span className={styles.title_name}>{toFullName(user)}</span>
        {onRemove && (
          <XMarkIcon color={"rgba(0, 62, 71, 0.4)"} fillOpacity={0.4} onClick={onRemove} />
        )}
      </div>
    ) : (
      <Tooltip
        placement={"bottomLeft"}
        arrow={false}
        overlayClassName={styles.tooltipOverlay}
        title={<TooltipContent users={users} />}
      >
        <div className={styles.block_name_groups}>
          <span className={styles.icon_name}>
            {name}
            {onRemove && <XMarkIcon onClick={onRemove} />}
          </span>
        </div>
      </Tooltip>
    )}
  </div>
);

export default AvatarBlock;
