import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

import styles from "./AssignButton.module.scss";

const AssignButton = ({ handleAssignOpen, labelText }) => (
  <div className={styles.root} onClick={handleAssignOpen}>
    <div role='button' className={styles.assignButton}>
      <PlusIcon width={9} height={9} />
    </div>
    <label className={styles.assignText}>{labelText}</label>
  </div>
);

export default AssignButton;
