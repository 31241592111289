import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/ui-components";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

import styles from "./PlatformAdminTitleBlock.module.scss";

type PlatformAdminTitleBlock = {
  onAdd?: () => void;
  onExport?: () => void;
};

export default function PlatformAdminTitleBlock({ onAdd, onExport }: PlatformAdminTitleBlock) {
  const { t } = useTranslation("admin", { keyPrefix: "common" });

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("title")}</h1>
      <Space>
        {onAdd && (
          <Button color='blue' secondary className={styles.button} handleClick={onAdd}>
            <PlusIcon />
          </Button>
        )}
        {onExport && (
          <Button color='blue' secondary handleClick={onExport}>
            Export
          </Button>
        )}
      </Space>
    </div>
  );
}
