import "./styles/body.css";
import macbook2 from "../assets/images/macbook2.png";
import girl from "../assets/images/girl-with-desktop.svg";
import people from "../assets/videos/people.mp4";
import peopleEng from "../assets/videos/people_eng.mp4";
import undraw from "../assets/images/undraw.svg";
import ninety from "../assets/images/ninety.svg";
import wohen from "../assets/images/wohen.svg";
import weeks from "../assets/images/weeks.svg";
import twentyFour from "../assets/images/twenty-four.svg";
import Button from "../customComponents/Button/Button";
import BlueWindow from "../customComponents/BlueWindow/BlueWindow";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import { otherConst } from "../consts/otherConst";
import DifferentlyBlock from "./DifferentlyBlock";
import useResize from "../consts/useResize";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Body() {
  const { t, i18n } = useTranslation("homepage");
  const [activeWidthBetweenSlide, setActiveWidthBetweenSlide] = useState();
  const [playVideo, setPlayVideo] = useState(false);
  const { innerWidth } = useResize();
  const history = useHistory();

  const sliderData = [
    {
      text: "slider_1_text",
      from_name: "slider_1_from",
      from_position: "slider_1_pos",
    },
    {
      text: "slider_2_text",
      from_name: "slider_2_from",
      from_position: "slider_2_pos",
    },
    {
      text: "slider_3_text",
      from_name: "slider_3_from",
      from_position: "slider_3_pos",
    },
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1.2,
    centerMode: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1.2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.4,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const vidRef = useRef(null);

  const getWidthBetween = useCallback(() => {
    let elem = document.querySelectorAll(".elipses");
    if (!elem || !elem[0] || !elem[0].clientHeight || !elem[0].clientWidth) {
      return;
    }

    if (innerWidth > 900) {
      setActiveWidthBetweenSlide(elem[0]?.clientWidth - 170);
    } else {
      setActiveWidthBetweenSlide(elem[0]?.clientHeight - 170);
    }
  }, [innerWidth]);

  useEffect(() => {
    getWidthBetween();

    window.addEventListener("resize", () => {
      getWidthBetween();
    });

    return () => {
      window.removeEventListener("resize", () => {
        getWidthBetween();
      });
    };
  }, [getWidthBetween]);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setPlayVideo(true);
  };

  const handlePauseVideo = () => {
    vidRef.current.pause();
    setPlayVideo(false);
  };

  // useEffect(() => {
  //     if (playVideo) {
  //         document.body.style.overflow = "hidden";
  //     } else {
  //         document.body.style.overflow = "unset";
  //     }
  // }, [playVideo])

  return (
    <div className={"times-wrapper"}>
      <div className='first-title'>
        <div className={"more-time"}>
          <span className='title'>{t("title")}</span>
          <p className='text-first-block'>{t("description")}</p>
          <Link to={`/kontakt/besprechung`} className={"btn_orange btn_default"}>
            {t("button_text")}
          </Link>
        </div>
        <img className={"macbook"} src={macbook2} alt={"macbook"} />
      </div>

      <div className={`${playVideo ? "video_wrapper_active" : ""} video_wrapper`}>
        {playVideo ? (
          <div className='video_wrapper_play'>
            <svg
              width='58'
              style={{ margin: "auto" }}
              onClick={() => handlePauseVideo()}
              className={"close_video"}
              height='58'
              viewBox='0 0 58 58'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='29' cy='29' r='28.5' stroke='#F17F16' />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.7216 20.8067C22.5264 20.6115 22.2098 20.6115 22.0145 20.8067L21.3074 21.5138C21.1122 21.7091 21.1122 22.0257 21.3074 22.2209L28.0858 28.9993L21.3072 35.7778C21.112 35.9731 21.112 36.2897 21.3072 36.4849L22.0143 37.1921C22.2096 37.3873 22.5262 37.3873 22.7214 37.1921L29.5 30.4135L36.278 37.1915C36.4733 37.3868 36.7898 37.3868 36.9851 37.1915L37.6922 36.4844C37.8875 36.2891 37.8875 35.9725 37.6922 35.7773L30.9142 28.9993L37.692 22.2215C37.8872 22.0262 37.8872 21.7096 37.692 21.5144L36.9849 20.8073C36.7896 20.612 36.473 20.612 36.2778 20.8073L29.5 27.5851L22.7216 20.8067Z'
                fill='#F17F16'
              />
            </svg>
          </div>
        ) : null}

        <video
          controls={playVideo}
          onEnded={() => {
            setPlayVideo(false);
          }}
          ref={vidRef}
        >
          <source
            src={i18n.language === "en" ? peopleEng : people}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
        {!playVideo ? (
          <div className={"wrapper_controls"} onClick={() => handlePlayVideo()}>
            <span>{t("video_text")}</span>
            <svg
              width='80'
              height='80'
              viewBox='0 0 80 80'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='40' cy='40' r='30' fill='#49BACA' />
              <path
                d='M35.58 48.787C35.8928 48.9434 36.2036 48.9942 36.5164 48.9942C36.88 48.9942 37.2964 48.8906 37.6093 48.6306L48.1127 41.454C48.6327 41.0904 48.9456 40.4669 48.9456 39.8412C48.9456 39.2176 48.6328 38.594 48.062 38.2284L37.5564 31.263C36.9328 30.8466 36.2036 30.8466 35.5293 31.1594C34.9057 31.523 34.4893 32.1466 34.4893 32.8758V47.0193C34.54 47.7993 34.9036 48.4757 35.5801 48.7864L35.58 48.787Z'
                fill='white'
              />
            </svg>
          </div>
        ) : null}
      </div>

      <div className='second-title'>
        <div className={"left-block"}>
          <img className={"girl"} src={girl} alt={"girl"} />
        </div>

        <div className={"right-block-content"}>
          <span className='right-partner'>{t("aivo_partner")}</span>
          <p className='text-first-block'>{t("aivo_partner_description_1")}</p>
          <span className={"second-text-block"}>{t("aivo_partner_description_2")}</span>
        </div>
      </div>
      <div className={"return-time"}>
        <span className='right-partner'>{t("win_time")}</span>
        <div className={"block-return-time"}>
          <div className={"blocks-return-time1"}>
            <img src={ninety} className={"ninety"} alt={"ninety"} />
            <span className={"third-title-return-time"}>{t("win_time_1")}</span>
          </div>
          <div className={"blocks-return-time2"}>
            <img src={twentyFour} className={"twenty_four"} alt={"twenty-four"} />
            <span className={"third-title-return-time"}>{t("win_time_2")}</span>
          </div>
          <div className={"blocks-return-time3"}>
            <img src={i18n.language === "en" ? weeks : wohen} className={"wohen"} alt={"wohen"} />
            <span className={"third-title-return-time"}>{t("win_time_3")}</span>
          </div>
        </div>
      </div>

      <DifferentlyBlock innerWidth={innerWidth} />

      <div className={"your-revision"}>
        <div className={"your-revision-info"}>
          <h1>{t("audit_steps_title")}</h1>
          <span className={"revision-text"}>{t("audit_steps_description")}</span>
          <Button
            text={t("audit_steps_button")}
            onClick={() => {
              history.push("/revision");
            }}
            className={"btn_orange"}
          />
        </div>
        <div className={"elipses"}>
          {otherConst.revision_5_steps.map((i, index) => (
            <div className='elipses__every' key={index}>
              <div className={"elipses-photos"}>
                <img src={i.img} alt={"logo"} />
              </div>
              <p className={"title-for-elipses"}>{t(i.title)}</p>
            </div>
          ))}
          <svg
            width={`${activeWidthBetweenSlide}`}
            className={"line"}
            height='43'
            viewBox={`0 0 ${activeWidthBetweenSlide} 43`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M909.5 8.69791C883.205 17.2916 856.537 25.4221 826.815 31.7845C682 62.7846 555.418 12.1168 463 2.28471C370.582 -7.54737 232.501 34.4501 143.289 39.9295C103.765 42.357 53.2219 33.2391 0.999952 21.7822'
              stroke='#F17F16'
            />
          </svg>
        </div>
      </div>

      <div className={"reviews"}>
        <div className={"navigation-reviews"}>
          <h1>{t("others_say_title")}</h1>
          <div className={"vectors"}></div>
        </div>
        <div className={"sliders"}>
          <Slider
            {...settings}
            beforeChange={(event, slick, currentSlide, nextSlide) => {
              let direction,
                slideCountZeroBased = slick.slideCount - 1;

              if (nextSlide === currentSlide) {
                direction = "same";
              } else if (Math.abs(nextSlide - currentSlide) === 1) {
                direction = nextSlide - currentSlide > 0 ? "right" : "left";
              } else {
                direction = nextSlide - currentSlide > 0 ? "left" : "right";
              }

              // Add a temp CSS class for the slide animation (.slick-current-clone-animate)
              if (direction === "right") {
                let g2Parms = `.slick-cloned[data-slick-index=${
                  nextSlide + slideCountZeroBased + 1
                }]`;
                let bt = document.querySelector(g2Parms, ".slick-slide");
                bt.classList.add("slick-current-clone-animate");
              }

              if (direction === "left") {
                let g2Parms = `.slick-cloned[data-slick-index=${
                  nextSlide - slideCountZeroBased - 1
                }]`;
                let bt = document.querySelector(g2Parms, ".slick-slide");
                bt.classList.add("slick-current-clone-animate");
              }
            }}
          >
            {sliderData.map((i) => (
              <div className={"review1"}>
                <span className={"review"}>"{t(i.text)}"</span>
                <p className={"from-who-review"}>{t(i.from_name)}</p>
                <p className={"from-who-review"}>{t(i.from_position)}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <BlueWindow
        title={t("blue_window_btn_team")}
        linkTo={"/kontakt/besprechung"}
        desc={t("blue_window_desc_revision")}
        src={undraw}
        btnText={t("blue_window_btn_revision")}
      />
    </div>
  );
}

export default Body;
