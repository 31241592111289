import styles from "./Tabs.module.scss";

const Tabs = ({ options, onClick, value, t }) => {
  return (
    <div className={styles.root}>
      {options.map((tab, index) => (
        <h3
          key={index}
          className={`${styles.tab} ${value === tab ? styles.active : ""}`}
          onClick={() => {
            onClick(tab);
          }}
        >
          {t ? t(tab) : tab}
        </h3>
      ))}
    </div>
  );
};

export default Tabs;
