import "./blueWindow.css";
import { Link } from "react-router-dom";

const BlueWindow = ({ title, linkTo, desc, src, btnText }) => (
  <div className={"blue-window"}>
    <div className={"left-side-blue"}>
      <h2>{title}</h2>
      <span className={"appointment"}>{desc}</span>
      <Link
        to={linkTo ?? null}
        onClick={(e) => {
          if (!linkTo) {
            e.preventDefault();
          }
        }}
        className={"btn_orange btn_default"}
      >
        {btnText}
      </Link>
    </div>
    <div className={"right-side-blue"}>
      {/* <img src={src} alt='src' /> */}
      <img src={src} alt='src' className='undrawSegment' />
    </div>
  </div>
);

export default BlueWindow;
