import { EditorState, Modifier } from "draft-js";
import { Document } from "../types/document";

export const appendDocumentToEditorState = (
  editorState: EditorState,
  document: Document,
  auditId: string,
  isLastDocument = true
) => {
  const documentHref = `/dashboard/${auditId}/documents/${document.id}`;
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity("LINK", "IMMUTABLE", {
    url: documentHref,
    target: "_blank",
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const fileName = document.name || document.document;
  const contentStateWithLink = Modifier.replaceText(
    contentStateWithEntity,
    editorState.getSelection(),
    fileName,
    editorState.getCurrentInlineStyle(),
    entityKey
  );

  let newEditorState = EditorState.push(editorState, contentStateWithLink, "insert-characters");
  if (!isLastDocument) {
    const contentStateWithComma = Modifier.insertText(
      contentStateWithLink,
      contentStateWithLink.getSelectionAfter(),
      ", "
    );
    newEditorState = EditorState.push(newEditorState, contentStateWithComma, "insert-characters");
  }
  return newEditorState;
};
