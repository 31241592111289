import abacusLogo from "../../../../../../assets/images/logos/abacus-logo.png";
import abaWebLogo from "../../../../../../assets/images/logos/aba-web-logo.png";
import atlantoLogo from "../../../../../../assets/images/logos/atlanto-logo.png";
import bexioLogo from "../../../../../../assets/images/logos/bexio-logo.png";
import comarchLogo from "../../../../../../assets/images/logos/comarch-logo.png";
import comaticLogo from "../../../../../../assets/images/logos/comatic-logo.png";
import dynamicsLogo from "../../../../../../assets/images/logos/dynamics-365-logo.png";
import klaraLogo from "../../../../../../assets/images/logos/klara-logo.png";
import numaricsLogo from "../../../../../../assets/images/logos/numarics-logo.png";
import runMyAccountsLogo from "../../../../../../assets/images/logos/run-my-accounts-logo.png";
import sageLogo from "../../../../../../assets/images/logos/sage-logo.png";
import sapLogo from "../../../../../../assets/images/logos/sap-logo.png";
import topalLogo from "../../../../../../assets/images/logos/topal-logo.png";
import xeroLogo from "../../../../../../assets/images/logos/xero-logo.png";

export const AccountingSystemOptions = [
  { code: "Abacus", label: <img width='auto' src={abacusLogo} alt='Abacus' /> },
  { code: "Aba Web", label: <img width='auto' src={abaWebLogo} alt='Aba Web' /> },
  { code: "Atlanto", label: <img src={atlantoLogo} alt='Atlanto' /> },
  { code: "bexio", label: <img width='auto' src={bexioLogo} alt='bexio' /> },
  { code: "comarch", label: <img width='auto' src={comarchLogo} alt='comarch' /> },
  { code: "comatic", label: <img width='auto' src={comaticLogo} alt='comatic' /> },
  { code: "Dynamics 365", label: <img width='auto' src={dynamicsLogo} alt='Dynamics 365' /> },
  { code: "Klara", label: <img width='auto' src={klaraLogo} alt='Klara' /> },
  { code: "numarics", label: <img width='auto' src={numaricsLogo} alt='numarics' /> },
  {
    code: "Run my Accounts",
    label: <img width='auto' src={runMyAccountsLogo} alt='Run my Accounts' />,
  },
  { code: "sage", label: <img width='auto' src={sageLogo} alt='sage' /> },
  { code: "SAP", label: <img width='auto' src={sapLogo} alt='SAP' /> },
  { code: "Topal", label: <img width='auto' src={topalLogo} alt='Topal' /> },
  { code: "xero", label: <img width='auto' src={xeroLogo} alt='xero' /> },
];
