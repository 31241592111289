import { RichUtils } from "draft-js";
import { useTranslation } from "react-i18next";

import { getCurrentBlock } from "../../../../../utils/textEditor";
import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as OrderedIcon } from "../../../../../assets/icons/textEditor/order.svg";
import { ReactComponent as BulletIcon } from "../../../../../assets/icons/textEditor/bullets.svg";

type ListButtonProps = {
  type: "ordered" | "unordered";
} & TextEditorButtonProps;

const ListButton = ({ type, editorState, onEditorStateChange }: ListButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const toggleStyle = () => {
    const newState = RichUtils.toggleBlockType(editorState, `${type}-list-item`);
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={type === "ordered" ? OrderedIcon : BulletIcon}
      isActive={getCurrentBlock(editorState).getType() === `${type}-list-item`}
      tooltipTitle={t(`list_${type}`)}
      onClick={toggleStyle}
    />
  );
};

export default ListButton;
