import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import str_right from "../../../../assets/admin/str_right.svg";
import { useTranslation } from "react-i18next";
import FileExtension from "../../../components/FileExtension";
import EditableDocumentName from "../../../../components/EditableDocumentName";

const DocumentSection = ({ allDocument, idAudit }) => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "dashboard" });
  const { t: tDocuments } = useTranslation("dashboard", { keyPrefix: "documents" });

  const legalDocuments = useMemo(() => {
    return allDocument.filter((doc) => doc.type === "legal");
  }, [allDocument]);

  return (
    <div className={"document_wrapper content_box"}>
      <div
        className={"header"}
        onClick={() => {
          history.push(`/dashboard/${idAudit}/documents`);
        }}
      >
        <span className={"header__title"}>{t("received_documents")}</span>

        <Link to={`/dashboard/${idAudit}/documents`}>
          <img src={str_right} alt='Right' />
        </Link>
      </div>
      <div className={"body"}>
        {legalDocuments.map(
          (item, index) =>
            index < 4 && (
              <div key={index}>
                <div
                  className={"every_document"}
                  onClick={() => {
                    history.push(`/dashboard/${idAudit}/documents/${item.id}`);
                  }}
                >
                  <div className='every_document_left'>
                    <FileExtension document={item.document} />
                    <EditableDocumentName
                      size='small'
                      id={item.id}
                      name={item.name}
                      className='title'
                    />
                  </div>

                  <div className='every_document_right'>
                    {item.status === "signed" ? (
                      <span className={"status status_missing_ready "}>
                        <span className='ready-button'>{tDocuments("signed")}</span>{" "}
                      </span>
                    ) : (
                      <span className={"status status_missing"}>
                        <span className='missing-button'>{t("missing_signatures")}</span>{" "}
                      </span>
                    )}

                    <img src={str_right} alt='Right' />
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DocumentSection;
