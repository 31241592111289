import React from 'react'
import './styles/change_confirmed_popup.css'


const ChangedConfirmedPopup = ({changingConfirmed, successText}) => {
    let tickSvg = () => {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11.5" stroke="#000"/>
                <path d="M18 8L9.75 15L6 11.8182" stroke="#000" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    return changingConfirmed ? (
        <div className={'changed_confirmed_popup'}>
            <div className={'changing_confirmed'}>
                {tickSvg()}
                <span>{successText}</span>
            </div>
        </div>
    ) : <div className={'changed_confirmed_popup_un_active'}>
        <div className={'changing_confirmed'}>
            {tickSvg()}
            <span>{successText}</span>
        </div>
    </div>
}
export default ChangedConfirmedPopup