import React, { useState } from "react";
import styles from "./AssignedWrapper.module.scss";
import AssignButton from "../AssignButton/AssignButton";
import AssignSearch from "../../../../components/AssignSearch";
import { Label } from "../../../../components/ui-components";
import AvatarBlock from "../../Requests/components/NewRequest/AvatarBlock/AvatarBlock";
import { useTranslation } from "react-i18next";
import toFullName from "../../../../consts/toFullName";
import { Typography } from "antd";

const AssignedWrapper = ({
  errors = {},
  onRemove,
  assignedRoles,
  assignedUsers,
  isOrganiser,
  handleAssignUsers,
  creator,
  hideGroups,
  creatorLabel, //Created by
  assignedLabel, //Assigne to
  disabled,
}) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [assignOpen, setAssignOpen] = useState(false);

  const handleAssignOpen = () => {
    if (!assignOpen) {
      setAssignOpen(true);
    }
  };
  const onCloseSearch = (val) => setAssignOpen(val || false);
  const assignUsersAndRoles = {
    assignedRoles,
    assignedUsers,
  };
  const showAssignButtonLabel = assignedRoles.length === 0 && assignedUsers.length === 0;

  return (
    <>
      {creator && (
        <div className={styles.container}>
          <Label>{creatorLabel}</Label>
          <div className={styles.columnWrapper}>
            <AvatarBlock user={creator} />
          </div>
        </div>
      )}
      <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
        <Label>{assignedLabel}</Label>
        <div className={styles.columnWrapper}>
          {assignedRoles?.map((assignedRole, index) => {
            const users = assignedRole.teamMembers.map(({ user }) => toFullName(user));
            return (
              <AvatarBlock
                key={index}
                showGroup
                onRemove={() => onRemove("assignedRoles", assignedRole.id)}
                name={assignedRole.name}
                users={users}
              />
            );
          })}
          <div>
            <div className={styles.columnWrapper}>
              {assignedUsers?.map((assignedUser, index) => (
                <AvatarBlock
                  key={index}
                  removable
                  onRemove={() => onRemove("assignedUsers", assignedUser.id)}
                  user={assignedUser.user}
                />
              ))}
              <AssignButton
                labelText={showAssignButtonLabel && tGlobal("assign_user")}
                handleAssignOpen={handleAssignOpen}
              />
            </div>
            {errors.assignedUsers && (
              <Typography.Text type='danger'>{errors.assignedUsers}</Typography.Text>
            )}
          </div>
        </div>
        <div className={styles.relativeSearch}>
          <div className={styles.searchWrapper}>
            <AssignSearch
              isOpen={assignOpen}
              hideGroups={hideGroups}
              onClose={onCloseSearch}
              setAssignId={() => {}}
              isOrganiser={isOrganiser}
              setAssignTitle={handleAssignUsers}
              addAssign={assignUsersAndRoles}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignedWrapper;
