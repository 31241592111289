import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Line } from "rc-progress";
import moment from "moment";

import toFullName from "../../../../consts/toFullName";

import {
  FinishedStatusArrow,
  InProgressStatusArrow,
  NotStartedStatusArrow,
} from "../../../../assets/icons";

const StatusSection = ({ audit, selectedStatusGroup, statusGroups, auditor, auditorFirm }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { t: tStatus } = useTranslation("dashboard", { keyPrefix: "status" });
  const [selectedGroupNumber, selectedGroupData] = useMemo(() => {
    if (!audit) return [null, null];
    const statusGroups = audit?.statusGroups.sort((a, b) => a.order - b.order);
    const idx = statusGroups?.findIndex((i) => i.id === selectedStatusGroup);
    return [idx + 1, audit?.statusGroups?.[idx]];
  }, [audit, selectedStatusGroup]);

  const statusGroupsAmount = statusGroups?.length;
  const allSteps = statusGroups?.reduce((acc, item) => [...acc, ...item.steps], []) || [];
  const allStepAmount = allSteps?.length;
  const doneStepAmount = allSteps.filter((i) => i.status === "done").length;
  const progress = (doneStepAmount / allStepAmount) * 100 || 0;
  const finishedStepArr = Array.from({ length: selectedGroupNumber - 1 });
  const progressGridStyles = {
    gridTemplateColumns: `${
      finishedStepArr.length ? `repeat(${finishedStepArr.length}, minmax(115px, 10%))` : ""
    } repeat(${statusGroupsAmount - finishedStepArr.length}, minmax(115px, 1fr))`,
  };

  if (!audit || !selectedGroupData) {
    return null;
  }

  return (
    <div className={"body"}>
      <div className={"body_inner"}>
        <div className={"date"}>
          <span className={"title"}>{audit.name}</span>
          <div className={"title_sub"}>
            {auditorFirm && (
              <p className={"description"}>
                {t("audit_company")}: {auditorFirm.name}
              </p>
            )}
            {auditor && (
              <p className={"description"}>
                {t("role_lead_auditor")}: {toFullName(auditor)}
              </p>
            )}
            {/*<p className={"description"}>Audit firm: aivo Revisions AG</p>*/}
            {/*<p className={"description"}>Lead Auditor: Martin Höpperger</p>*/}
          </div>
        </div>
        <div className={"progress_line"}>
          <span className={"title"}>{t("progress")}</span>
          <p className={"percent"}>{progress.toFixed(0)}%</p>
          <Line
            percent={progress}
            className={"line_progress"}
            strokeWidth='4'
            trailColor={"rgba(0, 62, 71, 0.1)"}
            trailWidth={4}
            strokeColor='#6DB333'
          />
        </div>
        <div className={"time"}>
          <span className={"title"}>{t("audit_report_date")}</span>
          {!!audit?.reportDate && (
            <div className={"title_sub"}>
              <p className={"description"}>{moment(audit.reportDate).format("DD.MM.YYYY")}</p>
              <p className={"description"}>{moment(audit.reportDate).fromNow()}</p>
            </div>
          )}
        </div>
      </div>

      <div className={"progress_bar"}>
        <div className='progress_line' style={progressGridStyles}>
          {statusGroups
            .sort((a, b) => a.order - b.order)
            .map((i, idx) => {
              const isDone = i.steps.every((j) => j.status === "done");
              const isInProgress = i.steps.some((j) => j.status === "in_progress");
              const zIdx = statusGroupsAmount - idx;
              return isDone ? (
                <div style={{ zIndex: zIdx }} className={"status-progress-arrow"}>
                  <FinishedStatusArrow />
                </div>
              ) : isInProgress ? (
                <div style={{ zIndex: zIdx }} className={"status-progress-arrow"}>
                  <InProgressStatusArrow />
                  <span className={"preview"}>
                    {i.order}. {tStatus(i.name.toLowerCase())}
                  </span>
                </div>
              ) : (
                <div style={{ zIndex: zIdx }} className={"status-progress-arrow"}>
                  <NotStartedStatusArrow />
                  <span className={"preview"}>
                    {i.order}. {tStatus(i.name.toLowerCase())}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StatusSection;
