import helper from "../consts/helper";

export function forceLogout() {
  localStorage.removeItem(helper.JWT_TOKEN);
  localStorage.removeItem(helper.USER_DATA);
  localStorage.removeItem(helper.BEXIO_REDIRECT_URL);
  localStorage.removeItem(helper.BEXIO_REQUEST_DATA);
  localStorage.removeItem(helper.BEXIO_TOKEN);

  sessionStorage.clear();
  window.location.href = "/login";
}
