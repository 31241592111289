import placeholder from "../assets/images/file-upload-placeholder.svg";
import "./styles/fileUpload.css";
import { Button } from "./ui-components";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const FileUpload = ({ onChange, file, disabled, title, subtitle, customPlaceholder, accept }) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    multiple: false,
    accept,
    disabled,
    onDropAccepted: (acceptedFiles) => {
      onChange(acceptedFiles[0]);
    },
  });

  const onClick = () => {
    if (disabled) return;

    if (file) {
      onChange(null);
    } else {
      open();
    }
  };

  const logo = file || customPlaceholder || placeholder;
  return (
    <div
      {...getRootProps()}
      className={`
      file-upload-wrapper 
      ${file ? "file-upload-active" : ""} 
      ${disabled ? "file-upload-disabled" : ""}
      ${isDragActive ? "file-upload-drag-active" : ""}
      `}
    >
      <input {...getInputProps()} />
      <img className='audits_download_img' src={logo} alt='Logo' />
      <div className='file-upload-description'>
        {file ? (
          <span className='subtitle'>{tGlobal("remove_picture")}</span>
        ) : (
          <>
            <span className='title'>{title}</span>
            <span className='subtitle'>{subtitle}</span>
          </>
        )}
      </div>
      <Button
        color={file ? "red" : "blue"}
        secondary
        style={{ width: "max-content" }}
        onClick={onClick}
      >
        {file ? tGlobal("remove") : tGlobal("browse")}
      </Button>
    </div>
  );
};

export default FileUpload;
