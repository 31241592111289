import React, { useState } from "react";
import classes from "../Admin/Superadmin/PlatformAdmin/ViewCompany/SettingsTab/Cards/Team/Team.module.scss";
import { Card, Col, Row } from "antd";
import { Button, Input } from "./ui-components";
import { useTranslation } from "react-i18next";
import CustomAvatar from "./CustomAvatar/CustomAvatar";
import CustomAntDropdown from "./ui-components/CustomAntDropdown/CustomAntDropdown";

const InviteForm = ({
  allowMenu,
  handleClickUser,
  filteredUsers,
  invite,
  inviteRoles,
  inputData,
  inviteMember,
  onChangeInvite,
  activeRole,
  placeholder,
  error,
  setError,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [showMenu, setShowMenu] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    inviteMember();
  };

  const handleChange = (e) => {
    setError("");
    onChangeInvite(e);
  };

  const handleSelectUser = (email, user) => {
    setError("");
    handleClickUser(email, user);
  };

  return (
    <Card.Grid hoverable={false} className={classes.grid}>
      <form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={14}>
            <Input
              placeholder={placeholder || t("invite_user")}
              value={invite.email}
              error={error}
              onChange={handleChange}
              onFocus={() => setShowMenu(true)}
              onBlur={() => {
                setShowMenu(false);
              }}
            />
            {allowMenu && showMenu && filteredUsers?.length > 0 && (
              <div className={classes.relativeSearch}>
                <div className={classes.searchWrapper}>
                  {filteredUsers.map((item, index) => (
                    <div
                      key={index}
                      className={`${classes.blockName} ${classes.inviteUser}`}
                      onMouseDown={() => handleSelectUser(item.email, item)}
                    >
                      <CustomAvatar user={item} />
                      <span className={classes.titleName}>
                        {item.firstName ? item.firstName + " " + item.lastName : item.email}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>

          <Col span={6}>
            <CustomAntDropdown
              options={inviteRoles}
              value={invite.role}
              onChange={inputData}
              name='role'
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={4}>
            <Button primary medium type='submit' color='blue' disabled={!invite.email}>
              {t("invite")}
            </Button>
          </Col>
        </Row>
      </form>
      {(error || activeRole?.disclaimer) && (
        <span
          className={classes.roleDisclaimer}
          {...(activeRole.disclaimerColor && { style: { color: activeRole.disclaimerColor } })}
          {...(error && { style: { color: "#FF5454" } })}
        >
          {error ? error?.message || error : t(activeRole.disclaimer)}
        </span>
      )}
    </Card.Grid>
  );
};

export default InviteForm;
