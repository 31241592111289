import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import arrowAudit from "../../../../../assets/admin/arrowAudit.svg";
import fileAudit from "../../../../../assets/admin/fileAudit.svg";
import calendar from "../../../../../assets/admin/calendar.svg";

const AuditRow = ({
  progress,
  name,
  myRole,
  requests,
  documents,
  meetings,
  isAuditor,
  id,
  status,
  onSubmit,
  hasAdminAccess,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });

  const statusTranslation = {
    offer: "offer_status",
  };

  const closestMeeting = meetings
    .filter((i) => new Date(i.date) > new Date())
    .sort((a, b) => {
      const dateComparison = new Date(a.date) - new Date(b.date);

      // If dates are the same, compare the start times
      if (dateComparison === 0) {
        return new Date(a.startTime) - new Date(b.startTime);
      }

      return dateComparison;
    })[0];

  return (
    <div
      className='audits_info_data'
      onClick={() => {
        onSubmit(id, myRole, isAuditor, hasAdminAccess);
      }}
    >
      <div className='audits_info_data_inner'>
        <div className='audits_info_block-percent'>
          <div className='progresBar_audits'>
            <CircularProgressbar
              value={progress}
              text={`${progress.toFixed(0)}%`}
              styles={buildStyles({
                textColor: "#6db333",
                pathColor: "#6db333",
                textSize: "28px",
                trailColor: "#d0dbdd",
              })}
            />
          </div>
        </div>
        <div className='audits_info_data_inner_info'>
          <span className='audits_info_block-info_subtitle'>{name}</span>
          <span className='audits_info_block-info_text'>
            {t(statusTranslation[status])}
            {/*{status}*/}
          </span>
        </div>
      </div>

      <div className='audits_info_block-info_status'>
        <span className='audits_info_block-info_status_inner'>
          <img src={arrowAudit} alt='arrowAudit' />
          {requests.length + 1}
          {/* <span className="audits_info_data-plus">+1</span> */}
        </span>
        <span className='audits_info_block-info_status_inner'>
          <img src={fileAudit} alt='fileAudit' />
          {documents.length}

          {/* <span className="audits_info_data-plus">+1</span> */}
        </span>
        <span className='audits_info_block-info_status_inner' style={{ fontWeight: "400" }}>
          <img src={calendar} alt='calendar' />
          {closestMeeting ? (
            <>
              {dayjs(closestMeeting.date).format("DD.MM.YYYY")}{" "}
              {dayjs(closestMeeting.startTime).format("HH:mm")} - {t("status_meeting")}
            </>
          ) : (
            t("no_future_meetings")
          )}
        </span>
      </div>
    </div>
  );
};

export default AuditRow;
