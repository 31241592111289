import { useTranslation } from "react-i18next";

import SettingsListAudits from "./components/SettingsListAudits";

import styles from "./Settings.module.scss";

const Settings = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("settings")}</h1>
      <SettingsListAudits />
    </div>
  );
};

export default Settings;
