type ItemType = {
  editModeAction?: string;
};

export const reorder = <T extends ItemType>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  const newStep = removed;

  if (!newStep.editModeAction) {
    newStep.editModeAction = "update";
  }

  result.splice(endIndex, 0, newStep);

  return result;
};
