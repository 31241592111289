import classNames from "classnames";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

import { ThinChevronBottom } from "../../../assets/icons";

import styles from "./StyledAutocomplete.module.scss";

interface StyledAutocompleteProps<T> extends Partial<AutocompleteProps<T, false, false, false>> {
  options: T[];
  className?: string;
}

const StyledAutocomplete = <T,>({ options, className, ...rest }: StyledAutocompleteProps<T>) => {
  return (
    <div className={styles.container}>
      <Autocomplete
        autoHighlight
        options={options}
        renderInput={(params) => <TextField variant='outlined' {...params} />}
        popupIcon={<ThinChevronBottom />}
        componentsProps={{
          paper: {
            elevation: 0,
          },
          popupIndicator: {
            disableRipple: true,
          },
        }}
        {...rest}
        classes={{
          listbox: styles.listbox,
          inputRoot: styles.input,
          inputFocused: styles.inputFocused,
          paper: classNames(styles.paper, styles.autocompletePaper),
          ...rest.classes,
        }}
        className={classNames(styles.root, className)}
      />
    </div>
  );
};

export default StyledAutocomplete;
