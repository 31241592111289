import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState, Modifier, SelectionState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export function updateLinkText(editorState, populatedLinks) {
  const contentState = editorState.getCurrentContent();
  const rawContent = convertToRaw(contentState);
  const htmlString = draftToHtml(rawContent);

  // Create a DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, "text/html");
  // Find all anchor tags in the document
  const links = doc.querySelectorAll("a");

  // Update the inner text of each link
  links.forEach((link) => {
    const doc = populatedLinks.find((doc) => link.href === doc.url);
    link.innerText = doc?.name || doc?.document || link.innerText;
  });

  // Convert the updated HTML back to a Draft.js content state
  const updatedHtml = doc.body.innerHTML;
  const { contentBlocks, entityMap } = htmlToDraft(updatedHtml);
  const updatedContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const updatedEditorState = EditorState.createWithContent(updatedContentState);

  return updatedEditorState;
}

export function extractLinks(editorState) {
  const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  // Create a DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, "text/html");
  // Find all anchor tags in the document
  const links = doc.querySelectorAll("a");
  // Define the regex pattern to match
  const regex = /\/dashboard\/(\d+)\/documents\/(\d+)/;
  // Filter and return the links that match the regex pattern
  const result = Array.from(links)
    .reduce((acc, link) => {
      const href = link.href;
      const match = href.match(regex);
      if (match && !acc.some((i) => i.url === href)) {
        const id = +match[2];
        return [...acc, { url: href, id }];
      }
      return acc;
    }, [])
    .filter((item) => item !== null);

  // what to do if referenced document was deleted?

  return result;
}

export function parseEditorState(editorState, documents, removeUnmatched) {
  let contentState = editorState.getCurrentContent();
  let hasChanged = false;

  const blockMap = contentState.getBlockMap();
  blockMap.forEach((block) => {
    const blockKey = block.getKey();

    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
      },
      (start, end) => {
        const entityKey = block.getEntityAt(start);
        const entity = contentState.getEntity(entityKey);
        const entityData = entity.getData();
        const href = entityData.url;
        const match = href.match(/\/dashboard\/(\d+)\/documents\/(\d+)/);

        if (match) {
          const id = +match[2];
          const document = documents.find((doc) => doc.id === id);

          if (removeUnmatched) {
            const documentExists = !!document;
            const text = block.getText().substring(start, end);
            const textHasChanged = document?.name !== text && document?.document !== text;

            if (!documentExists || textHasChanged) {
              hasChanged = true;
              const rangeToRemove = SelectionState.createEmpty(blockKey).merge({
                anchorOffset: start,
                focusOffset: end,
              });
              contentState = Modifier.removeRange(contentState, rangeToRemove, "backward");
            }
          }
        }
      }
    );
  });

  if (!hasChanged) {
    return editorState;
  }

  const updatedEditorState = EditorState.push(editorState, contentState, "remove-range");

  return updatedEditorState;
}
