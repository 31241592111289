import { ReactNode, createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { INotification } from "../interfaces/Notifications";
import { getNotifications } from "../api/userApi";
import { NotificationSearchType } from "../pages/Notifications/useNotifications";
import helper from "../consts/helper";
import { useDocuments } from "../hooks/services/useDocuments";
import { useParams } from "react-router-dom";
import { socket } from "../utils/socket";

export const NotificationContext = createContext<{
  notification: INotification | null;
  isUserHasNotification: boolean;
  getUserNotifications: () => void;
}>({
  notification: null,
  isUserHasNotification: false,
  getUserNotifications: async () => {},
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<INotification | null>(null);
  const [isUserHasNotification, setUserHasNotification] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.user_data);

  useEffect(() => {
    const isSupported =
      "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;
    console.log({ isSupported });
    if (!isSupported) return;

    socket.on("notification", (response) => {
      setNotification(response);
      showBrowserNotification();
      getUserNotifications();
    });

    requestNotificationPermission();

    // return () => {
    //   console.log(123333);
    //   socket.disconnect();
    // };
  }, [user?.token]);

  const getUserNotifications = async () => {
    const response = await getNotifications({ type: NotificationSearchType.all });
    setUserHasNotification(response.length > 0);
  };

  const requestNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notifications permission granted.");
        }
      });
    }
  };

  const showBrowserNotification = () => {
    if (Notification.permission === "granted") {
      const notification = new Notification("New notification", {
        body: "Please check notification page.",
      });
      notification.onclick = () => {
        window.open(
          process.env.REACT_APP_API_URL?.includes("localhost")
            ? "http://localhost:3000/notifications"
            : "https://aivo-website.netlify.app/notifications"
        );
      };
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        isUserHasNotification,
        getUserNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
