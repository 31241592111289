import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/link.svg";

import styles from "./AttachDocumentButton.module.scss";

type AttachDocumentButtonProps = {
  popup?: ReactNode;
  onClick?: () => void;
};

export default function AttachDocumentButton({ popup, onClick }: AttachDocumentButtonProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className={styles.wrapper}>
      <ToolbarButton Icon={Icon} tooltipTitle={t("attach_file")} onClick={onClick} />
      {popup}
    </div>
  );
}
