import OutsideClickHandler from "../consts/detectOutSideClick";

const DropDownOptions = ({ options, handleClose }) => {
  return (
    <>
      <OutsideClickHandler
        callback={(e) => {
          e?.preventDefault();
          handleClose(false);
        }}
      >
        <div className='document_settings'>
          {options.map((i) => (
            <span
              className={`document_settings_inner ${i.caution ? "inner-caution" : ""} ${
                i.disabled ? "disabled" : ""
              }`}
              style={i.style}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                if (i.disabled) return;
                i.onClick();
              }}
            >
              {i.label}
            </span>
          ))}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default DropDownOptions;
