import React from "react";
import { Route, useLocation } from "react-router-dom";
import { Navigation } from "./Navigation";
import { WrapperApp } from "./MainRouter";
import { useState, useEffect } from "react";
import ScrollToTop from "../consts/ScrollToTop";

const noScrollRoutes = ["/kontakt/"];

const Routers = (props) => {
  const [activeTheme, setActiveTheme] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const findTheme = Navigation.mainNavigation.find((i) => i.name === pathname.split("/")[1]);

    if (!findTheme) {
      return null;
    }
    setActiveTheme(findTheme.theme);
  }, [pathname]);

  const noScroll = noScrollRoutes.some((i) => pathname.toLowerCase().includes(i));
  return (
    <WrapperApp theme={activeTheme}>
      {noScroll || <ScrollToTop />}
      {Navigation.mainNavigation.map((route) => (
        <Route path={route.path} exact {...props} component={route.main} key={route.path} />
      ))}
    </WrapperApp>
  );
};

export default Routers;
