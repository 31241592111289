export const antdTheme = {
  // cssVar: { key: 'app' },
  token: {
    colorPrimary: "#003E47",
    colorError: "#FF5454",
    colorSuccess: "#ACDB30",
    fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
  },
  components: {
    Anchor: {
      linkPaddingBlock: 14,
      linkPaddingInlineStart: 24,
    },
    Alert: {
      fontSize: 12,
      fontWeight: "bold",
      colorErrorBg: "var(--color-red-100)",
      colorErrorBorder: "var(--color-red-700)",
    },
    Checkbox: {
      fontSize: 12,
      lineWidth: 2,
      controlInteractiveSize: 24,
    },
  },
};
