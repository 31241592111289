import { Input } from "antd";
import { KeyboardEvent, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import CommentBlock, { CommentBlockProps } from "./components/CommentBlock";

import styles from "./CommentsViewer.module.scss";

type CommentContainerProps = {
  tKeyPrefix: CommentBlockProps["tKeyPrefix"];
  value: string;
  createdAt: string;
  comments?: Array<any>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const CommentContainer = ({
  createdAt,
  comments,
  value,
  tKeyPrefix,
  onChange,
  onSubmit,
}: CommentContainerProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className={styles.container}>
      <div className={styles.commentsList}>
        <CommentBlock tKeyPrefix={tKeyPrefix} empty createdAt={createdAt} />
        {comments?.length
          ? comments.map(({ user, createdAt, text }, index) => (
              <CommentBlock
                key={index}
                tKeyPrefix={tKeyPrefix}
                user={user}
                createdAt={createdAt}
                text={text}
              />
            ))
          : null}
      </div>
      <div className={styles.commentInputWrapper}>
        <Input
          placeholder={t("add_comment")}
          value={value}
          onChange={onChange}
          onKeyDown={onSubmit}
        />
      </div>
    </div>
  );
};

export default CommentContainer;
