import { useTranslation } from "react-i18next";

import HeaderAdmin from "../../../Admin/components/HeaderAdmin";
import AccountSettingsContent from "./AccountSettingsContent";

import styles from "./AccountSettings.module.scss";

const AccountSettings = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  return (
    <>
      <HeaderAdmin />
      <div className={styles.inner}>
        <div className={styles.title}>{t("account_settings")}</div>
        <AccountSettingsContent />
      </div>
    </>
  );
};

export default AccountSettings;
