import React, { memo, useCallback } from "react";
import classes from "classnames";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, RichUtils, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import debounce from "../../../utils/debounde";

import styles from "./TextEditor.module.scss";

interface TextEditorProps extends EditorProps {
  name: string;
  label: string;
  value: string;
  required?: boolean;
  bordered?: boolean;
  hiddenInput?: boolean;
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  disabled?: boolean;
  onSave?: (e: any, name: string) => void;
}

const TextEditor = ({
  name,
  label,
  value,
  required,
  readOnly,
  editorState,
  hiddenInput,
  bordered,
  disabled,
  onSave,
  onEditorStateChange,
  ...props
}: TextEditorProps) => {
  const classNames = classes(styles.wrapper, {
    [styles.readOnly]: readOnly,
    [styles.hiddenInput]: hiddenInput,
    [styles.bordered]: bordered,
    [styles.disabled]: disabled,
  });

  const debouncedFetchData = useCallback(
    debounce(async (value) => {
      const html = draftToHtml(convertToRaw(value.getCurrentContent()));
      const e = {
        target: {
          name,
          value: html,
        },
      };
      onSave?.(e, name);
    }, 250),
    []
  );

  const handleEditorStateChange = (editorState: EditorState) => {
    onEditorStateChange(editorState);
    if (onSave) {
      debouncedFetchData(editorState);
    }
  };

  const handleKeyCommandEn = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorStateChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  return (
    <div className={classNames}>
      <Editor
        toolbar={{
          options: [],
        }}
        readOnly={readOnly}
        editorClassName={styles.editor}
        editorState={editorState}
        handleKeyCommand={handleKeyCommandEn}
        onEditorStateChange={handleEditorStateChange}
        {...props}
      />
    </div>
  );
};

export default memo(TextEditor);
