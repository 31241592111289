import React, { useEffect } from "react";
import Keys from "../consts/helper";
import { useHistory } from "react-router-dom";
import API from "../api/API";

const AuthConsume = () => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search),
      code = params.get("code"),
      requestData = localStorage.getItem(Keys.BEXIO_REQUEST_DATA);

    if (code && requestData) {
      API()
        .post("/bexio/token", JSON.parse(requestData), { params: { code } })
        .then((res) => {
          // window.open(res.data, "_self")
          const redirect_url = localStorage.getItem(Keys.BEXIO_REDIRECT_URL);

          if (redirect_url) {
            localStorage.removeItem(Keys.BEXIO_REDIRECT_URL);
          }
          localStorage.removeItem(Keys.BEXIO_REQUEST_DATA);

          localStorage.setItem(Keys.BEXIO_TOKEN, res.data.access_token);

          history.push(redirect_url || "/audits");
          console.log(res);
        });
    }
  }, [history]);

  return <div>Authorizing...</div>;
};

export default AuthConsume;
