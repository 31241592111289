import { useLocation, useHistory } from "react-router-dom";
import queryString, { ParsedQuery } from "query-string";

interface QueryParams {
  [key: string]: string | undefined;
}

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const query: ParsedQuery = queryString.parse(location.search);

  const setQueryParams = (newParams: QueryParams) => {
    const newQuery = { ...query, ...newParams };
    const searchString = queryString.stringify(newQuery);
    history.push({
      pathname: location.pathname,
      search: searchString,
    });
  };

  const removeQueryParams = (paramsToRemove: string[]) => {
    const newQuery = { ...query };
    paramsToRemove.forEach((param) => {
      delete newQuery[param];
    });
    const searchString = queryString.stringify(newQuery);
    history.push({
      pathname: location.pathname,
      search: searchString,
    });
  };

  return {
    query,
    setQueryParams,
    removeQueryParams,
  };
};

export default useQueryParams;
