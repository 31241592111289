import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { STATUS_COLORS, ROLES_REQUSTED_TEXT } from "../../../../../../consts/enums";

import { Tag } from "../../../../../../components/ui-components";
import { CardBlock } from "../../../../components";

import messageIconFiles from "../../../../../../assets/admin/filesIconSmol.svg";
import messageIconComment from "../../../../../../assets/admin/message.svg";
import lock from "../../../../../../assets/admin/lock.svg";

import styles from "./RequestBlock.module.scss";

const cx = classNames.bind(styles);

const requestTypeFormat = {
  file_request: "File",
  data_transfer_request: "Data Transfer",
};

const RequestBlock = ({
  openRequest,
  status,
  name,
  security,
  commentsCount,
  relatedDocsCount,
  itemId,
  readOnly = false,
  active = false,
  requestType,
  bordered,
  titleStyle,
}) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const requestTypeText = requestTypeFormat[requestType] || requestType;

  const onOpenRquest = () => {
    openRequest(itemId, requestType);
  };

  return (
    <CardBlock bordered={bordered} active={active} onClick={!readOnly ? onOpenRquest : null}>
      {requestTypeText && (
        <span className={styles.requestType}>{requestTypeText + " REQUEST"}</span>
      )}
      <span style={titleStyle} className={cx(styles.titleBlock, { readOnly })}>
        {name}
      </span>
      <div className={styles.requestInfo}>
        <div className={styles.statusTag}>
          <Tag color={STATUS_COLORS[status]}>
            {ROLES_REQUSTED_TEXT[status] && tGlobal(ROLES_REQUSTED_TEXT[status])}
          </Tag>
        </div>
        <div className={styles.notificationSide}>
          {commentsCount > 0 && (
            <div className={styles.block}>
              <img src={messageIconComment} alt='Message' />
              <span className={styles.countLength}>{commentsCount}</span>
            </div>
          )}
          {relatedDocsCount > 0 && (
            <div className={styles.block}>
              <img src={messageIconFiles} alt='Message' />
              <span className={styles.countLength}>{relatedDocsCount}</span>
            </div>
          )}
          {security && (
            <div className={styles.block}>
              <img src={lock} alt='Lock' />
            </div>
          )}
        </div>
      </div>
      {/*<div className={styles.assignedUsers}>*/}
      {/*  {creatorFullName && <span className={styles.userIcon}>{creatorFullName[0]}</span>}*/}
      {/*  {assignedUsers.map((assignedUser) => (*/}
      {/*    <span className={styles.userIcon}>{toFullName(assignedUser.user)[0]}</span>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </CardBlock>
  );
};
export default RequestBlock;
