import React, { useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./Assertion.module.scss";
import { assertionColors } from "../../../consts/assertionColors";
import { CloseRoundedIcon } from "../../../assets/icons";

const cx = classNames.bind(styles);

interface TagProps {
  children: React.ReactNode | string | number | React.ReactElement[];
  onClick?: () => void;
  onClose?: (id: number) => void;
  id: number;
}

const Assertion: React.FC<TagProps> = ({ children, id, onClick, onClose }) => {
  const className = cx(styles.tag_root, {
    clickable: !!onClick,
    closable: !!onClose,
  });
  const color = useMemo(() => assertionColors[(id - 1) % assertionColors.length], [id]);
  return (
    <div onClick={onClick} style={color} className={className}>
      {children} {!!onClose && <CloseRoundedIcon onClick={() => onClose(id)} color={color.color} />}
    </div>
  );
};

export default Assertion;
