import * as types from "./types";

export const setRequestData = (action) => ({
  type: types.SET_NEW_REQUEST_DATA,
  payload: action,
});

export const removeRequestData = (action) => ({
  type: types.REMOVE_NEW_REQUEST_DATA,
  payload: action,
});

export const setMeetingData = (action) => ({
  type: types.SET_NEW_MEETING_DATA,
  payload: action,
});

export const removeMeetingData = (action) => ({
  type: types.REMOVE_NEW_MEETING_DATA,
  payload: action,
});

export const setProfileDetailsHighlighted = () => ({
  type: types.HIGHLIGHT_PROFILE_DETAILS,
});

export const setProfileDetailsUnHighlighted = () => ({
  type: types.UNHIGHLIGHT_PROFILE_DETAILS,
});
