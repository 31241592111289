import { Alert, ModalProps, Spin, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

import API from "../../../../../../api/API";

import StyledModal from "../../../../../../components/ui-components/StyledModal";
import Button from "../../../../../../components/ui-components/Button/Button";

import styles from "./ChangePhoneConfirmationPopup.module.scss";

type ChangePhoneConfirmationPopupProps = {
  phone: string;
  onClose: () => void;
  onSubmit: () => void;
} & ModalProps;

export default function ChangePhoneConfirmationPopup({
  phone,
  onClose,
  onSubmit,
  ...props
}: ChangePhoneConfirmationPopupProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleClose = () => {
    onClose();
    setValue("");
    setError("");
  };

  const handleResendCode = () => {
    setValue("");
    setError("");
    API().post("/user/resend-code", { phone });
  };

  const handleSendCode = (code: string) => {
    setIsSending(true);
    API()
      .post("/user/phone", { code, phone })
      .then(() => {
        handleClose();
        onSubmit();
      })
      .catch((e) => {
        setError(e.response?.data?.message);
      })
      .finally(() => setIsSending(false));
  };

  return (
    <StyledModal {...props} onCancel={handleClose} width={394} footer={null}>
      <div className={styles.content}>
        <div className={styles.info}>
          <Typography.Title level={3} className={styles.title}>
            {t("almost_there")}
          </Typography.Title>
          <p className={"login_form__subtitle"}>
            {t("number_confirm_first")} <br /> {t("number_confirm_second")}
            <span>{phone}</span>
            {t("number_confirm_third")}
          </p>
          <Alert type='info' message={t("code_resended")} />
        </div>
        <div className={styles.inputWrapper}>
          <VerificationInput
            placeholder=''
            validChars='A-Za-z0-9'
            value={value}
            onChange={setValue}
            onComplete={handleSendCode}
          />
          {error && <Typography.Text type='danger'>{error}</Typography.Text>}
        </div>
        <div className={styles.buttons}>
          <Button
            primary
            large
            color='blue'
            disabled={value.length !== 6}
            onClick={() => handleSendCode(value)}
          >
            {isSending ? (
              <Spin />
            ) : (
              <>{value.length > 5 ? "Send" : `${6 - value.length} ${t("digits_left")}`}</>
            )}
          </Button>
          <Button primary large color='lightBlue' onClick={handleResendCode}>
            {t("resend_code")}
          </Button>
          <Button tertiary color='blue' large onClick={handleClose}>
            {tGlobal("close")}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
}
