import { useTranslation } from "react-i18next";

import Popup from "../../../../../../components/ui-components/Popup";
import CardActions from "../../../../../../Admin/pages/components/CardActions";

import styles from "./AccountDetailsConfirmUpdatePopup.module.scss";

type AccountDetailsConfirmUpdatePopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function AccountDetailsConfirmUpdatePopup({
  isOpen,
  onClose,
  onConfirm,
}: AccountDetailsConfirmUpdatePopupProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <Popup
      popupStyle={{ maxWidth: 450 }}
      title={tGlobal("are_you_sure")}
      titleClassName={styles.title}
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className='popup-body'>{t("update_profile_popup_text")}</div>
      <CardActions
        onSubmit={onConfirm}
        onCancel={onClose}
        submitText={tGlobal("update")}
        cancelText={tGlobal("cancel")}
        submitDisabled={false}
        cancelButtonColor='lightBlue'
        cancelButtonProps={{
          primary: true,
        }}
        submitButtonColor='red'
      />
    </Popup>
  );
}
