export const permissions = [
  "editCompany",
  "editAudit",
  "editPermissionRoles",
  "createNewPermissions",
  "manageTeam",
  "refetchData",
  "createRequests",
  "sensitiveRequestsWithoutAssign",
  "overviewAccess",
  "requestsAccess",
  "statusAccess",
  "documentAccess",
  "meetingAccess",
];

export const countActivePermissions = (role) => {
  return [
    permissions.reduce((acc, permission) => {
      if (role[permission]) {
        return acc + 1;
      }
      return acc;
    }, 0),
    permissions.length,
  ];
};
