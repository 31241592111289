import { useQuery } from "@tanstack/react-query";
import { Avatar, Popover } from "antd";
import { EditorState, Modifier } from "draft-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auditServices } from "../../../../../services/auditService";
import { TeamMembersResponse } from "../../../../../types/audit";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";
import { User } from "../../../../../types/user";
import { TeamMember } from "../../../../../types/team";
import { appendUser } from "../../../../../utils/textEditor";
import { getFullNameFromUserData } from "../../../../../utils/general";

import { Label } from "../../../../../components/ui-components";
import ToolbarButton from "../../ToolbarButton";
import MentionInvitePerson from "./MentionInvitePerson";

import { ReactComponent as MentionIcon } from "../../../../../assets/icons/textEditor/mention.svg";
import { ReactComponent as UserPlusIcon } from "../../../../../assets/icons/user-plus.svg";
import { ReactComponent as PhonePlusIcon } from "../../../../../assets/icons/phone-plus.svg";

import styles from "./MentionButton.module.scss";

type MentionPopupProps = {
  teamMembers: Array<TeamMember>;
} & TextEditorButtonProps;

type ParamsProps = {
  id: string;
};

export default function MentionButton({ editorState, onEditorStateChange }: MentionPopupProps) {
  const { id: auditId } = useParams<ParamsProps>();
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { data, refetch } = useQuery<TeamMembersResponse, Error>({
    queryKey: ["teamMembers", auditId], // Pass auditId here
    queryFn: ({ queryKey }) => {
      const [, auditId] = queryKey as [string, string]; // Destructure to get auditId
      return auditServices.getTeamMembers(auditId);
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState<"mention" | "add_contact" | "invite">("mention");

  useEffect(() => {
    handleMentionTrigger(editorState);

    // eslint-disable-next-line
  }, [editorState]);

  const handleMention = (user: User) => () => {
    const newEditorState = appendUser(editorState, user);
    onEditorStateChange(newEditorState);
    setIsOpen(false);
  };

  function handleMentionTrigger(editorState: EditorState) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const currentBlock = content.getBlockForKey(selection.getStartKey());
    const text = currentBlock.getText();
    const lastChar = text.charAt(selection.getStartOffset() - 1);

    if (lastChar === "@") {
      setIsOpen(true);
    } else if (isOpen && (lastChar !== "@" || !text.includes("@"))) {
      setIsOpen(false);
    }
  }

  const getPopoverContent = () => {
    switch (screen) {
      case "mention": {
        return (
          <div className={styles.menu}>
            <Label className={styles.title}>{t("person")}</Label>
            <div className={styles.menuItem} onClick={() => setScreen("invite")}>
              <div className={styles.menuItemIcon}>
                <UserPlusIcon />
              </div>
              {t("invite_person")}
            </div>
            <div className={styles.menuItem} onClick={() => setScreen("add_contact")}>
              <div className={styles.menuItemIcon}>
                <PhonePlusIcon />
              </div>
              {t("add_contact")}
            </div>
            {data?.teamMembers?.map((member) => (
              <div key={member.id} className={styles.menuItem} onClick={handleMention(member.user)}>
                <Avatar size={24} />
                {getFullNameFromUserData(member.user) || member.user.email}
              </div>
            ))}
          </div>
        );
      }
      case "invite":
      case "add_contact": {
        return (
          <MentionInvitePerson
            screen={screen}
            onBack={() => setScreen("mention")}
            onAfterSubmit={() => {
              setScreen("mention");
              refetch();
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const handleOpenChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      setScreen("mention");
    }
  };

  const handleOpenPopover = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const contentStateWithAt = Modifier.insertText(contentState, selectionState, "@");

    const newEditorState = EditorState.push(editorState, contentStateWithAt, "insert-characters");

    onEditorStateChange(newEditorState);

    setIsOpen(true);
  };

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      open={isOpen}
      onOpenChange={handleOpenChange}
      rootClassName={styles.popover}
      content={getPopoverContent()}
      getPopupContainer={() => document.getElementById("mentionButton") || document.body}
    >
      <ToolbarButton
        id='mentionButton'
        Icon={MentionIcon}
        tooltipTitle={t("mention")}
        onClick={handleOpenPopover}
      />
    </Popover>
  );
}
