interface Object {
  [key: string]: any;
}

interface Booleans {
  [key: string]: boolean;
}

export const getBooleans = (object: Object): Booleans => {
  return Object.keys(object).reduce((acc, key) => {
    if (typeof object[key] === "boolean") {
      acc[key] = object[key];
    }

    return acc;
  }, {} as Booleans);
};
