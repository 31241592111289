import API from "./API";

export const getMyTeam = async () => {
  try {
    const response = await API().get("/team/");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateTeam = async (data) => {
  try {
    const FD = new FormData();

    Object.entries(data).forEach((i) => {
      if (i[0] === "logo" && data.logoURL && i[1]) {
        // @ts-ignore
        FD.append(`file`, i[1]);
      } else if (i[0] === "logo" && !data.logoURL) {
        FD.append(i[0], undefined);
      } else if (i[1]) {
        // @ts-ignore
        FD.append(i[0], i[1]);
      }
    });

    const response = await API().post("/team/update", FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const inviteUser = async (data) => {
  const response = await API().post("/team/invite", data);
  return response.data;
};

export const changeTeamRole = async (id, data) => {
  try {
    const response = await API().post(`/team/member/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const changeTeamSignatory = async (id, data) => {
  try {
    const response = await API().post(`/team/member/${id}/signatory`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const removeTeamMember = async (id) => {
  try {
    const response = await API().delete(`/team/member/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
