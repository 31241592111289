import { RichUtils } from "draft-js";
import { useTranslation } from "react-i18next";

import { getCurrentBlock } from "../../../../../utils/textEditor";
import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/comment.svg";

type BoldButtonProps = {} & TextEditorButtonProps;

const CommentButton = ({ editorState, onEditorStateChange }: BoldButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const toggleStyle = () => {
    const newState = RichUtils.toggleBlockType(editorState, "blockquote");
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={Icon}
      isActive={getCurrentBlock(editorState).getType() === "blockquote"}
      tooltipTitle={t("comment")}
      onClick={toggleStyle}
    />
  );
};

export default CommentButton;
