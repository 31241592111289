import { lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "../main.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import RouterWrapper from "./RouterWrapper";

import AuthConsume from "../pages/AuthConsume";
import Login from "../pages/Login";
import LoginPhone from "../pages/LoginPhone";
import LoginPhoneSms from "../pages/LoginPhoneSms";
import LoginPassword from "../pages/LoginPassword";
import LoginEmailSend from "../pages/LoginEmailSend";
import LoginPhoneDoubleSms from "../pages/LoginPhoneDoubleSms";

import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import ForgotPassword from "../Admin/pages/ForgotPassword/ForgotPassword";
import AdminPlatform from "../Admin/AdminPlatform";
import { privatePaths } from "./Navigation";
import { NotificationProvider } from "../context/notificationContext";
import PrivateWrapper from "./PrivateWrapper";

const PublicDocumentAccess = lazy(() => import("../pages/PublicDocumentAccess"));
const PublicDocumentView = lazy(() => import("../pages/PublicDocumentView"));

const MainRouter = () => (
  <Router>
    <Switch>
      {/* Public Routes */}
      <Route exact path='/' component={RouterWrapper} />
      <Route exact path='/revision' component={RouterWrapper} />
      <Route exact path='/team' component={RouterWrapper} />
      <Route exact path='/imprint' component={RouterWrapper} />
      <Route exact path='/data_protection' component={RouterWrapper} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/login/phone' component={LoginPhone} />
      <Route exact path='/login/phone/sms' component={LoginPhoneSms} />
      <Route exact path='/login/phone/double_sms' component={LoginPhoneDoubleSms} />
      <Route exact path='/login/new-password' component={LoginPassword} />
      <Route exact path='/login/email' component={ForgotPassword} />
      <Route exact path='/login/email/send' component={LoginEmailSend} />
      <Route exact path='/thank-you' component={RouterWrapper} />
      <Route exact path='/kontakt/:slug?' component={RouterWrapper} />
      <Route exact path='/partner' component={RouterWrapper} />
      <Route exact path='/faq' component={RouterWrapper} />
      <Route exact path='/consume' component={AuthConsume} />

      <Route exact path='/public/document/:id/:code/access' component={PublicDocumentAccess} />
      <Route exact path='/public/document/:id/:code/view' component={PublicDocumentView} />

      {/* Private Routes */}
      <PrivateWrapper exact path={privatePaths}>
        <NotificationProvider>
          <Switch>
            <Route exact path='/audits' component={AdminPlatform} />
            <Route exact path='/company/:id/:path' component={AdminPlatform} />
            <Route exact path='/notifications' component={AdminPlatform} />
            <Route exact path='/account/settings' component={AdminPlatform} />
            <Route exact path='/company/settings' component={AdminPlatform} />
            <Route exact path='/admin/:path' component={AdminPlatform} />
            <Route exact path='/admin/companies/:type/:id/:path' component={AdminPlatform} />

            <Route exact path='/dashboard/:id/overview' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/requests/:requestId' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/status' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/documents' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/documents/:documentId' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/meetings/:meetingId' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/settings' component={DashboardLayout} />
            <Route
              exact
              path='/dashboard/:id/team-member/edit/:idTeam'
              component={DashboardLayout}
            />
            <Route exact path='/dashboard/:id/team-member/add' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/team-role/edit/:idRole' component={DashboardLayout} />
            <Route exact path='/dashboard/:id/role/add' component={DashboardLayout} />
          </Switch>
        </NotificationProvider>
      </PrivateWrapper>

      {/* Redirects */}
      <Redirect exact from='/dashboard/:id/requests/' to='/dashboard/:id/requests/0' />
      <Redirect exact from='/dashboard/:id/meetings/' to='/dashboard/:id/meetings/0' />
    </Switch>
  </Router>
);

export const WrapperApp = ({ children, theme }) => (
  <div className={`wrapper_app wrapper_app_${theme}`}>
    <div className={"wrapper_main"}>
      <Header theme={theme} />
      {children}
      <Footer theme={theme} />
      <span className={`copyright`}>
        © copyright– all rights reserved | {new Date().getFullYear()} aivo
      </span>
    </div>
  </div>
);

export default MainRouter;
