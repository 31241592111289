import React from "react";
import "./styles/imprint.css";
import { useTranslation } from "react-i18next";

const Imprint = () => {
  const { t } = useTranslation("impressum");
  return (
    <div className={"imprint-wrapper"}>
      <div className={"imprint-block"}>
        <span className={"imprint-title"}>Impressum</span>
        <p className={"description-imprint"}>{t("subtitle")}</p>
      </div>

      <div className={"imprint-blocks"}>
        <span className={"imprint-headlines"}>Disclaimer</span>
        <p className={"description-imprint"}>{t("disclaimer_text")}</p>
      </div>
      <div className={"responsible-content"}>
        <span className={"imprint-headlines"}>{t("responsible_for_content")}</span>
        <div className={"content-blocks"}>
          <span className={"content-text"}>aivo Revisions AG</span>
          <span className={"content-text"}>Schwamendingenstrasse 77</span>
          <span className={"content-text"}>8050 Zürich</span>
        </div>
        <div className={"info"}>
          <a href={"mailto:info@aivo.ch"} className={"info-text content-text"}>
            info@aivo.ch
          </a>
          <span className={"info-text content-text"}> +41 (0)79 916 46 27</span>
        </div>
        <span className={"content-text"}>CHE-298.469.692</span>
      </div>
      <div className={"imprint-blocks"}>
        <span className={"imprint-headlines"}>Copyright</span>
        <p className={"description-imprint"}>{t("copyright_text")}</p>
      </div>
    </div>
  );
};

export default Imprint;
