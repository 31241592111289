import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { userChange } from "../../../../api/userApi";
import { setUserData } from "../../../../store/user/actions";

import AccountSettingsProfile from "./AccountSettingsProfile";
import AccountSettingsAccessOverview from "./AccountSettingsAccessOverview";
import AccountSettingsIdentityVerification from "./AccountSettingsIdentityVerification";
import AccountSettingsChangePassword from "./AccountSettingsChangePassword";
import AccountSettingsNotifications from "./AccountSettingsNotifications";
import AccountSettingsSettings from "./AccountSettingsSettings";
import SettingsSplitNavigation from "../../../../Admin/pages/components/SettingsSplitNavigation";
import { Loading } from "../../../../components/ui-components";

import styles from "./AccountSettingsContent.module.scss";

const AccountSettingsContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [userInfo, setUserInfo] = useState(null);

  const syncDataOnSave = (data) => {
    setUserInfo({
      ...userInfo,
      ...data,
    });
  };

  const navigation = useMemo(() => {
    const code = userInfo?.voucher?.code;
    const signatoryStatus = userInfo?.signatoryStatus;
    return [
      {
        body: <AccountSettingsProfile userData={userInfo} syncDataOnSave={syncDataOnSave} />,
        title: t("account_profile_title"),
        key: "profile",
        href: "#profile",
        bodyStyle: { padding: "0px" },
      },
      ...(userInfo?.hasSignatoryRights
        ? [
            {
              body: (
                <AccountSettingsIdentityVerification
                  code={code}
                  status={signatoryStatus}
                  setUserInfo={setUserInfo}
                />
              ),
              title: t("identity_verification"),
              key: "identity_verification",
              href: "#identity_verification",
              bodyStyle: { padding: "0px" },
            },
          ]
        : []),
      {
        body: <AccountSettingsChangePassword />,
        title: t("change_password"),
        key: "password",
        href: "#password",
        bodyStyle: { padding: "0px" },
      },
      {
        body: <AccountSettingsAccessOverview />,
        title: t("access_overview"),
        key: "access_overview",
        href: "#access_overview",
        bodyStyle: { padding: "0px" },
      },
      {
        body: <AccountSettingsNotifications userData={userInfo} syncDataOnSave={syncDataOnSave} />,
        title: t("account_notifications_title"),
        key: "notifications",
        href: "#notifications",
        bodyStyle: { padding: "0px" },
      },
      {
        body: <AccountSettingsSettings userData={userInfo} syncDataOnSave={syncDataOnSave} />,
        title: t("settings"),
        key: "settings",
        href: "#settings",
        bodyStyle: { padding: "0px" },
      },
    ];
  }, [userInfo]);

  useEffect(() => {
    userChange()
      .then((res) => {
        const newUserInfo = {
          email: res.user.email,
          phone: res.user.phone,
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          title: res.user.title,
          country: res.user.country,
          language: res.user.language,
          logo: res.user.logo,
          signatoryStatus: res.user.signatoryStatus,
        };
        dispatch(setUserData(newUserInfo));
        setUserInfo({
          ...newUserInfo,
          hasSignatoryRights: res.hasSignatoryRights,
          showNotifications: res.user.showNotifications,
          statusUpdateNotification: res.user.statusUpdateNotification,
          documentUploadNotification: res.user.documentUploadNotification,
          commentAddedNotification: res.user.commentAddedNotification,
          commentMentionNotification: res.user.commentMentionNotification,
          requestCreatedNotification: res.user.requestCreatedNotification,
          voucher: res.user.voucher,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      {userInfo ? (
        <SettingsSplitNavigation navigation={navigation} targetOffset={200} />
      ) : (
        <Loading position='absoluteCenter' />
      )}
    </div>
  );
};

export default AccountSettingsContent;
