import { useState } from "react";
import { Card, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../../../components/ui-components";
import Popup from "../../../../../../components/ui-components/Popup/Popup";

import styles from "./CreateVoucherPopup.module.scss";
import { useVouchers } from "../../../../../../hooks/services/useVouchers";

type VoucherPopupProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CreateVoucherPopup({ isOpen, onClose }: VoucherPopupProps) {
  const [code, setCode] = useState<string>("");
  const { t } = useTranslation("admin", { keyPrefix: "vouchers" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { createVoucher } = useVouchers();

  const handleCreate = () => {
    createVoucher(code, {
      onSuccess: () => {
        setCode("");
        onClose();
      },
    });
  };

  return (
    <Popup title={t("create_voucher")} isOpen={isOpen} onClose={onClose} popupStyle={{}}>
      <Space.Compact direction='vertical' block className='popup-body'>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("voucher_label")}</span>
          </Col>
          <Col span={16}>
            <Input
              type='name'
              value={code}
              onChange={({ target }) => {
                setCode(target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
      <Card.Grid className={styles.root} hoverable={false}>
        <Button color='gray' handleClick={onClose} secondary>
          {tGlobal("cancel")}
        </Button>
        <Button primary color='blue' handleClick={handleCreate}>
          {tGlobal("create")}
        </Button>
      </Card.Grid>
    </Popup>
  );
}
