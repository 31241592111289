import * as types from "./types";

export const setUserData = (action) => ({
  type: types.USER_DATA,
  payload: action,
});
export const setUserToken = (action) => ({
  type: types.USER_TOKEN,
  payload: action,
});
export const setUserPermissionsRole = (action) => ({
  type: types.USER_ROLE,
  payload: action,
});
export const setPlatformRole = (action) => ({
  type: types.PLATFORM_ROLE,
  payload: action,
});
