import { User } from "../types/user";

export function formatCompanyId(value: string) {
  // Remove any non-digit characters except for "CHE-"
  const cleanValue = value.replace(/^CHE-|\D/g, "");
  const parts = cleanValue.match(/(\d{1,3})(\d{0,3})?(\d{0,3})?/);

  let formattedCompanyId = "CHE-";
  if (parts) {
    formattedCompanyId += parts[1];
    if (parts[2]) {
      formattedCompanyId += "." + parts[2];
    }
    if (parts[3]) {
      formattedCompanyId += "." + parts[3];
    }
  }

  return formattedCompanyId;
}

export function getFileExtensionFromUrl(url: string) {
  if (!url) return "";
  const parts = url.split(/[?#]/)[0].split(".");
  const extension = parts.pop();
  return extension ? extension.trim() : "";
}

export function getFullNameFromUserData(user?: User): string {
  return user?.firstName ? `${user.firstName} ${user.lastName}` : "";
}
