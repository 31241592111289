import classNames from "classnames";

import { Button } from "../../../../components/ui-components";
import { DiscardIcon, DoneIcon } from "../../../../AdminComponents/icons";

import styles from "./EditButtons.module.scss";

const EditButtons = ({ name = "", className = "", discardClick, acceptClick }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <Button
        primary
        className={styles.button}
        color='gray'
        type='button'
        handleClick={(event) => discardClick(event, name)}
      >
        <DiscardIcon />
      </Button>
      <Button
        primary
        className={styles.button}
        color='lightBlue'
        type='button'
        handleClick={(event) => acceptClick(event, name)}
      >
        <DoneIcon />
      </Button>
    </div>
  );
};

export default EditButtons;
