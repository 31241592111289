import { Popover } from "antd";
import classNames from "classnames";
import { EditorState, RichUtils } from "draft-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/highlight.svg";

import { hexToRgba } from "../../../../../utils/files";
import { getCurrentColor } from "../../../../../utils/textEditor";

import ToolbarButton from "../../ToolbarButton";

import styles from "./HighlightButton.module.scss";

type HighlightButtonProps = {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
};

export const COLORS = ["#FF5454", "#F17F16", "#FCC419", "#569720", "#49BACA", "#1864AB", "#5F3DC4"];

export default function HighlightButton({
  editorState,
  onEditorStateChange,
}: HighlightButtonProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const [isOpen, setIsOpen] = useState(false);

  function handleClearColor() {
    const currentStyle = editorState.getCurrentInlineStyle();
    let newEditorState = editorState;

    currentStyle.forEach((style) => {
      if (style?.startsWith("color-")) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
      }
    });

    onEditorStateChange(newEditorState);
  }

  function handleSetEditorColor(color: string) {
    let newEditorState = editorState;

    const currentStyle = editorState.getCurrentInlineStyle();
    currentStyle.forEach((style) => {
      if (style?.startsWith("color-")) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
      }
    });

    newEditorState = RichUtils.toggleInlineStyle(newEditorState, `color-${color}`);
    onEditorStateChange(newEditorState);
    setIsOpen(false);
  }

  return (
    <Popover
      trigger='click'
      placement='bottom'
      open={isOpen}
      onOpenChange={setIsOpen}
      rootClassName={styles.popover}
      content={
        <div className={styles.list}>
          <div
            className={classNames(styles.colorPickerItem, styles.colorPickerItemReset)}
            onClick={handleClearColor}
          />
          {COLORS.map((color, index) => (
            <div
              key={index}
              style={{ background: hexToRgba(color, 0.5), borderColor: color }}
              className={styles.colorPickerItem}
              onClick={() => handleSetEditorColor(color)}
            />
          ))}
        </div>
      }
    >
      <ToolbarButton
        Icon={Icon}
        isActive={!!getCurrentColor(editorState)}
        tooltipTitle={t("highlight")}
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
}
