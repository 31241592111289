import i18next from "i18next";

export const SORTING_KEYS = {
  NAME: "firstName",
  COMPANY: "companyMemberships[0].company.name",
  EMAIL: "email",
  PHONE: "phone",
  ACTIVE: "lastActive",
  SHARED_WITH: "sharedWith",
};

export const SORT_TITLES = [
  {
    title: i18next.t("admin:users:table_name"),
    key: SORTING_KEYS.NAME,
  },
  {
    title: i18next.t("admin:users:table_company"),
    key: SORTING_KEYS.COMPANY,
  },
  {
    title: i18next.t("dashboard:global:email"),
    key: SORTING_KEYS.EMAIL,
  },
  {
    title: i18next.t("admin:users:table_phone"),
    key: SORTING_KEYS.PHONE,
  },
  {
    title: i18next.t("admin:users:table_last_active"),
    key: SORTING_KEYS.ACTIVE,
  },
];
