import ApiNew from "./apiNew";
import { forceLogout } from "../utils/storageUtils";

export const logout = async () => {
  try {
    await ApiNew().get("/user/logout");
  } catch (error) {
    console.error("Error fetching vouchers", error);
    throw error;
  } finally {
    forceLogout();
  }
};
