import i18next from "i18next";

import homepageEN from "../assets/translations/en/homepage.json";
import headerEN from "../assets/translations/en/header.json";
import footerEN from "../assets/translations/en/footer.json";
import aboutUsEN from "../assets/translations/en/aboutUs.json";
import revisionEN from "../assets/translations/en/revision.json";
import partnerEN from "../assets/translations/en/partner.json";
import faqEN from "../assets/translations/en/faq.json";
import contactEN from "../assets/translations/en/contact.json";
import thankYouEN from "../assets/translations/en/thankYou.json";
import impressumEN from "../assets/translations/en/impressum.json";
import dataPrivacyEN from "../assets/translations/en/dataPrivacy.json";
import homepageDE from "../assets/translations/de/homepage.json";
import headerDE from "../assets/translations/de/header.json";
import footerDE from "../assets/translations/de/footer.json";
import revisionDE from "../assets/translations/de/revision.json";
import aboutUsDE from "../assets/translations/de/aboutUs.json";
import partnerDE from "../assets/translations/de/partner.json";
import faqDE from "../assets/translations/de/faq.json";
import contactDE from "../assets/translations/de/contact.json";
import thankYouDE from "../assets/translations/de/thankYou.json";
import impressumDE from "../assets/translations/de/impressum.json";
import dataPrivacyDE from "../assets/translations/de/dataPrivacy.json";

import dashboardEN from "../assets/translations/en/dashboard/dashboard.json";
import dashboardDE from "../assets/translations/de/dashboard/dashboard.json";
import globalEN from "../assets/translations/en/dashboard/global.json";
import globalDE from "../assets/translations/de/dashboard/global.json";
import loginDE from "../assets/translations/de/dashboard/login.json";
import loginEN from "../assets/translations/en/dashboard/login.json";
import auditsDE from "../assets/translations/de/dashboard/audits.json";
import auditsEN from "../assets/translations/en/dashboard/audits.json";
import requestsDE from "../assets/translations/de/dashboard/requests.json";
import requestsEN from "../assets/translations/en/dashboard/requests.json";
import documentsDE from "../assets/translations/de/dashboard/documents.json";
import documentsEN from "../assets/translations/en/dashboard/documents.json";
import meetingsDE from "../assets/translations/de/dashboard/meetings.json";
import meetingsEN from "../assets/translations/en/dashboard/meetings.json";
import settingsDE from "../assets/translations/de/dashboard/settings.json";
import settingsEN from "../assets/translations/en/dashboard/settings.json";
import notificationsDE from "../assets/translations/de/dashboard/notifications.json";
import notificationsEN from "../assets/translations/en/dashboard/notifications.json";
import statusEN from "../assets/translations/en/dashboard/status.json";
import statusDE from "../assets/translations/de/dashboard/status.json";

import adminCommonEN from "../assets/translations/en/admin/common.json";
import adminCommonDE from "../assets/translations/de/admin/common.json";
import adminCompaniesEN from "../assets/translations/en/admin/companies.json";
import adminCompaniesDE from "../assets/translations/de/admin/companies.json";
import adminUsersEN from "../assets/translations/en/admin/users.json";
import adminUsersDE from "../assets/translations/de/admin/users.json";
import adminVouchersEN from "../assets/translations/en/admin/vouchers.json";
import adminVouchersDE from "../assets/translations/de/admin/vouchers.json";

export const i18nResources = {
  en: {
    aboutUs: aboutUsEN,
    contact: contactEN,
    dataPrivacy: dataPrivacyEN,
    faq: faqEN,
    footer: footerEN,
    header: headerEN,
    homepage: homepageEN,
    impressum: impressumEN,
    partner: partnerEN,
    revision: revisionEN,
    thankYou: thankYouEN,

    admin: {
      common: adminCommonEN,
      users: adminUsersEN,
      vouchers: adminVouchersEN,
      companies: adminCompaniesEN,
    },
    dashboard: {
      audits: auditsEN,
      dashboard: dashboardEN,
      documents: documentsEN,
      global: globalEN,
      login: loginEN,
      meetings: meetingsEN,
      notifications: notificationsEN,
      requests: requestsEN,
      settings: settingsEN,
      status: statusEN,
    },
  },
  de: {
    aboutUs: aboutUsDE,
    contact: contactDE,
    dataPrivacy: dataPrivacyDE,
    faq: faqDE,
    footer: footerDE,
    header: headerDE,
    homepage: homepageDE,
    impressum: impressumDE,
    partner: partnerDE,
    revision: revisionDE,
    thankYou: thankYouDE,

    admin: {
      common: adminCommonDE,
      users: adminUsersDE,
      vouchers: adminVouchersDE,
      companies: adminCompaniesDE,
    },
    dashboard: {
      audits: auditsDE,
      dashboard: dashboardDE,
      documents: documentsDE,
      global: globalDE,
      login: loginDE,
      meetings: meetingsDE,
      notifications: notificationsDE,
      requests: requestsDE,
      settings: settingsDE,
      status: statusDE,
    },
  },
};

const hideTranslations = process.env.REACT_APP_HIDE_TRANSLATIONS === "true";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng") || "de", // language to use
  resources: hideTranslations ? {} : i18nResources,
});

export default i18next;
