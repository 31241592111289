import React, { useState } from "react";
import classNames from "classnames";
import styles from "./ToggleComponent.module.scss";

interface ToggleComponentProps {
  onChange: (name: string, toggleValue: boolean) => void;
  name: string;
  value: boolean;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({ onChange, name, value = false }) => {
  const [isToggled, setIsToggled] = useState(value);

  function handleToggle() {
    setIsToggled(!isToggled);
    if (onChange) {
      onChange(name, !isToggled);
    }
  }

  const toggleClasses = classNames(styles.root, {
    [styles.toggled]: isToggled,
  });

  return (
    <div role='radiogroup' className={toggleClasses} onClick={handleToggle}>
      <div role='radio' className={styles.toggleSwitch} aria-checked={isToggled} />
    </div>
  );
};

export default ToggleComponent;
