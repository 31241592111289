import { CSSProperties } from "react";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";

import styles from "./TableHeader.module.scss";

type TableHeaderFilters = {
  order?: string;
  orderBy?: "asc" | "desc";
};

export type TableHeaderProps = {
  titles: {
    title: string;
    key?: string;
  }[];
  wrapperStyles?: CSSProperties;
  filters?: TableHeaderFilters;
  onChange?: (data: TableHeaderFilters) => void;
};

const TableHeader = ({ titles, onChange, filters = {}, wrapperStyles }: TableHeaderProps) => {
  const handleSort = (title: string) => () => {
    onChange?.({
      order: title,
      orderBy: filters.order === title && filters.orderBy === "asc" ? "desc" : "asc",
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner} style={wrapperStyles}>
        {titles.map((item) => (
          <div
            key={item.title}
            className={classNames(styles.title, {
              [styles.pointer]: item.key,
              [styles.active]: item.key && item.key === filters.order,
              [styles[filters.orderBy!]]: filters.orderBy,
            })}
            {...(item.key && { onClick: handleSort(item.key) })}
          >
            <span>{item.title}</span>
            {item.key && item.key === filters?.order && <ArrowIcon className={styles.icon} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableHeader;
