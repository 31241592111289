import { InputHTMLAttributes, useEffect, useState } from "react";

import { formatCompanyId } from "../../utils/general";

import { Input } from "../ui-components";

type CompanyIDInputProps = {
  error?: boolean;
  value?: string;
  onChange?: (name: string, value: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export default function CompanyIDInput({ error, value, onChange, ...props }: CompanyIDInputProps) {
  const [formattedValue, setFormattedValue] = useState<string>("CHE-");

  useEffect(() => {
    if (value) {
      setFormattedValue(formatCompanyId(value));
    } else {
      setFormattedValue("CHE-");
    }
  }, [value]);

  const handleChange = (name: string, value: string) => {
    const formattedValue = formatCompanyId(value);
    onChange?.(name, formattedValue);
  };

  return (
    <Input
      error={error}
      name='companyId'
      {...props}
      value={formattedValue}
      onHandleChange={handleChange}
      placeholder='e.g. CHE-123.456.789'
    />
  );
}
