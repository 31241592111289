import { useState } from "react";
import draftToHtml from "draftjs-to-html";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { convertToRaw, EditorState } from "draft-js";

import { prepareDraft } from "../../../../../../../consts/prepareDraft";
import { parseEditorState } from "../../../../../../../consts/DOM/extractLinks";

import { PageTextEditor } from "../../../../../../../Admin/pages/components";
import FormPicker from "../../../../../../../Admin/pages/components/FormPicker/FormPicker";
import SubtaskList from "../../SubtaskList";

import styles from "../../StatusView.module.scss";

const findingOptions = [
  {
    name: i18next.t("dashboard:status:no_findings"),
    value: false,
  },
  {
    name: i18next.t("dashboard:status:findings"),
    value: true,
  },
];

const AuditorContent = ({
  openedStep,
  setOpenedStep,
  documents,
  updateCurrentStepData,
  saveChanges,
  setOpenedSubtask,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });

  const createEditorState = (name) => {
    if (openedStep) {
      const htmlString = openedStep[name] || "";

      const state = prepareDraft(htmlString);

      return parseEditorState(state, documents);
    }
    return EditorState.createEmpty();
  };
  const [documentationEditorState, setDocumentationEditorState] = useState(() =>
    createEditorState("documentation")
  );
  const [findingDescriptionEditorState, setFindingDescriptionEditorState] = useState(() =>
    createEditorState("findingDescription")
  );
  const [conclusionEditorState, setConclusionEditorState] = useState(() =>
    createEditorState("conclusion")
  );
  const setEditorStateByField = {
    documentation: setDocumentationEditorState,
    findingDescription: setFindingDescriptionEditorState,
    conclusion: setConclusionEditorState,
  };

  const onEditorStateChange = (editorState, name) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setOpenedStep((prev) => ({
      ...prev,
      [name]: forFormik,
    }));
    setEditorStateByField[name]?.(editorState);
  };

  const handleChangePicker = async (name, value) => {
    const body = { [name]: value };
    if (name === "hasFindings" && !value) {
      setEditorStateByField[name]?.(EditorState.createEmpty());
      body.findingDescription = "";
    }
    setOpenedStep((state) => ({
      ...state,
      ...body,
    }));
    updateCurrentStepData(body);
    await saveChanges(body);
  };

  async function submitChanges(e, name) {
    if (!openedStep) return;
    let currentStep;
    setOpenedStep((prev) => {
      currentStep = prev;
      return prev;
    });
    const body = { [name]: e.target.value };

    // TODO: !!WARNING!! The following code may cause issues with synchronization
    //  There's a way to fix this, but it'll impact performance, if this won't cause bugs - leave it as it is
    //  If it causes issues - move every state-related code to onEditorStateChange function
    //  and only leave a request call (await saveChanges) to save the changes with debounce
    updateCurrentStepData(body);
    if (
      (name === "documentation" || name === "findingDescription" || name === "conclusion") &&
      currentStep?.status === "not_started" &&
      currentStep?.parentTaskId
    ) {
      body.status = "in_progress";
    }
    await saveChanges(body, openedStep.id);
  }

  return (
    <>
      {openedStep.subtasks?.length > 0 && (
        <div className={styles.field}>
          <label>{t("subtasks")}</label>
          <SubtaskList onClick={setOpenedSubtask} subtasks={openedStep.subtasks} />
        </div>
      )}

      {(openedStep.subtasks?.length === 0 || !openedStep.subtasks) && (
        <>
          <div className={styles.field}>
            <PageTextEditor
              value={openedStep.documentation}
              editorState={documentationEditorState}
              onEditorStateChange={onEditorStateChange}
              name='documentation'
              label={t("audit_documentation")}
              bordered
              onBlur={submitChanges}
              documents={documents}
              allowAttachments
              disabled={openedStep.status === "done"}
            />
          </div>
          <div className={styles.field}>
            <FormPicker
              options={findingOptions}
              selected={openedStep.hasFindings}
              name='hasFindings'
              onSelect={handleChangePicker}
              label={t("audit_findings")}
              disabled={openedStep.status === "done"}
            />
          </div>

          {openedStep.hasFindings && (
            <div className={styles.field}>
              <PageTextEditor
                value={openedStep.findingDescription}
                editorState={findingDescriptionEditorState}
                onEditorStateChange={onEditorStateChange}
                name='findingDescription'
                label={t("description_of_finding")}
                bordered
                documents={documents}
                onBlur={submitChanges}
                allowAttachments
                disabled={openedStep.status === "done"}
              />
            </div>
          )}
        </>
      )}

      <div className={styles.field}>
        <PageTextEditor
          value={openedStep.conclusion}
          editorState={conclusionEditorState}
          onEditorStateChange={onEditorStateChange}
          name='conclusion'
          label={t("conclusion")}
          bordered
          documents={documents}
          onBlur={submitChanges}
          allowAttachments
          disabled={openedStep.status === "done"}
        />
      </div>
    </>
  );
};

export default AuditorContent;
