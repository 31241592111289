import API from "./API";
import axios from "axios";

export const disableCompany = async (companyId, body) => {
  try {
    const response = await API().post(`/company/${companyId}/toggle-disable`, body);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const createCompany = async (body) => {
  const FD = new FormData();
  FD.append("name", body.name);
  FD.append("client_email", body.client_email);
  FD.append("UID", body.UID);
  FD.append("logo", body.logo);
  try {
    const response = await API().post("/company/create", FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCompanyByAuditId = async (id, body) => {
  const FD = new FormData();
  FD.append("name", body.name);
  FD.append("logo", body.logo);
  FD.append("UID", body.UID);

  try {
    const response = await API().post(`/company/audit/${id}/update`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCompany = async (id, body) => {
  const FD = new FormData();
  FD.append("name", body.name);
  FD.append("logo", body.logo);
  FD.append("UID", body.UID);

  try {
    const response = await API().post(`/company/${id}/update`, FD);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inviteCompanyMember = async (id, body) => {
  const response = await API().post(`/company/${id}/team-members`, body);
  return response.data;
};

export const changeMemberRole = async (id, teamMemberId, body) => {
  try {
    const response = await API().post(`/company/${id}/member/${teamMemberId}/change-role`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const changeMemberSignatory = async (id, teamMemberId, body) => {
  try {
    const response = await API().post(`/company/${id}/member/${teamMemberId}/signatory`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const removeCompanyMember = async (id, teamMemberId) => {
  try {
    const response = await API().delete(`/company/${id}/member/${teamMemberId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await API().delete(`/company/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const fetchZefixCompanies = async (query, options = {}) => {
  const { signal } = options;

  try {
    const response = await API().get("/company/zefix", {
      params: { query },
      signal,
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      console.error("Failed to fetch companies:", error);
    }
    throw error;
  }
};

export const getCommercialRegister = async (companyId) => {
  try {
    const response = await API().get(`/company/${companyId}/commercial-register`);
    return response.data;
  } catch (error) {
    return error;
  }
};
