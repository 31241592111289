import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { languageToValue } from "../../consts/languageToValue";
import { userLogin } from "../../store/user/api";
import { setUserData } from "../../store/user/actions";

import Button from "../../components/ui-components/Button/Button";
import TextInput from "../../customComponents/TextInput";

import { ReactComponent as Logo } from "../../assets/images/logo-primary.svg";

import "../../Admin/styles/login.css";

const initLogin = {
  email: {
    value: "",
    error: false,
  },
  passwort: {
    value: "",
    error: false,
  },
};

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fields, setFields] = useState(initLogin);
  const [user, setUser] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    email: false,
    passwort: false,
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { t, i18n } = useTranslation("dashboard", { keyPrefix: "login" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const onChange = (event) => {
    const { value, name } = event.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
      },
    }));
    setFieldErrors((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    if (loginError) {
      setLoginError("");
    }
  };

  const auditLogin = (e) => {
    e.preventDefault();

    if (isLoggingIn) {
      return;
    }

    if (!fields.email.value) {
      setFieldErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      return;
    }

    if (!fields.passwort.value) {
      setFieldErrors((prevState) => ({
        ...prevState,
        passwort: true,
      }));
      return;
    }

    if (!emailValidation()) {
      setLoginError("Invalid email format");
      return;
    }

    setIsLoggingIn(true);
    try {
      const data = {
        email: fields.email.value,
        password: fields.passwort.value,
      };
      dispatch(userLogin(data)).then((res) => {
        const language = res.payload.language;
        if (language) {
          const lang = languageToValue[language];
          i18n.changeLanguage(lang).then(() => {
            localStorage.setItem("i18nextLng", lang);
          });
        }
        if (res.payload.message === "Need to reset password") {
          history.push("/login/email/send");
        }
        if (res.payload.message === "Need to set up password") {
          history.push("/login/new-password?token=" + res.payload.token);
        } else {
          console.log({ payload: res.payload });
          dispatch(setUserData(res.payload));
        }
        if (res.payload.name === "AxiosError") {
          setLoginError("Invalid credentials");
        }
        if (res.payload.message === "Need to verify phone") {
          if (res.payload.phone) {
            history.push("/login/phone/sms");
          } else {
            history.push("/login/phone");
          }
        }
        if (res.payload.message !== "Need to set up password") {
          setUser(res.payload);
        }
        setIsLoggingIn(false);
      });
    } catch (error) {
      setIsLoggingIn(false);
    }
  };

  function emailValidation() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+-?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return regex.test(fields.email.value);
  }

  useEffect(() => {
    user?.token && history.push("/audits");

    const URLParams = new URLSearchParams(window.location.search);
    const language = URLParams.get("language");
    if (language) {
      const lang = languageToValue[language];
      i18n.changeLanguage(lang).then(() => {
        localStorage.setItem("i18nextLng", lang);
      });
    }
  }, [user.token]);

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <Logo width={127} height={45} />
        </Link>
        <form onSubmit={auditLogin} className={"login_form"}>
          <p className={"login_form__title"}>{t("client_portal")}</p>
          <div className={"login_form__inputs"}>
            <TextInput
              title={tGlobal("email")}
              placeholder='name@mail.com'
              value={fields.email.value}
              htmlFor={"email"}
              onChange={onChange}
              error={loginError || fieldErrors.email}
            />
            <TextInput
              title={tGlobal("password")}
              value={fields.passwort.value}
              htmlFor={"passwort"}
              type='password'
              onChange={onChange}
              showPassIcon={true}
              error={loginError || fieldErrors.passwort}
            />

            <div className='wrapper_inputActive_error'>{loginError}</div>

            <div className='login_buttonEnter'>
              <Button type='submit' primary large color={"blue"}>
                {t("action_login")}
              </Button>
            </div>
            <Link
              to={{
                pathname: "/login/email",
                state: { email: fields.email.value }, // Pass the email value as state
              }}
            >
              {t("action_forgot_password")}
            </Link>
          </div>
        </form>
        <span className='login-disclaimer'>
          <Trans
            t={t}
            i18nKey='dont_have_access'
            components={{ strong: <strong />, a: <Link to='/kontakt/besprechung' /> }}
          />
        </span>
      </div>
    </div>
  );
};

export default Login;
