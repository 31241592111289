import { useContext, useEffect, useState } from "react";
import {
  getNotifications,
  readGeneralNotifications,
  readImportantNotifications,
} from "../../api/userApi";
import { NotificationContext } from "../../context/notificationContext";
import { INotification, NotificationType } from "../../interfaces/Notifications";
import classNames from "classnames";
import styles from "../styles/notifications.module.scss";
import { useHistory } from "react-router-dom";

export const NotificationSearchType = {
  all: "all",
  important: "important",
  general: "general",
  archive: "archive",
};

export const useNotifications = () => {
  const { notification, getUserNotifications } = useContext(NotificationContext);
  const cx = classNames.bind(styles);
  const history = useHistory();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [activeTab, setActiveTab] = useState(NotificationSearchType.important);
  const [loading, setLoading] = useState(false);

  const getNotificationUrl = (item: INotification) => {
    switch (item.type) {
      case NotificationType.document_upload:
        return `/dashboard/${item.audit.id}/documents/${item.document?.id}`;
      case NotificationType.request_created:
        return `/dashboard/${item.audit.id}/requests/${item.request?.id}`;
      case NotificationType.comment_added:
        const entityName = item.request ? "requests" : item.document ? "documents" : "meetings",
          entityId = item.request
            ? item.request.id
            : item.document
            ? item.document.id
            : item.meeting?.id;
        return `/dashboard/${item.audit.id}/${entityName}/${entityId}`;
      case NotificationType.status_update:
      case NotificationType.comment_mention:
      default:
        return "";
    }
  };

  const updateUNotifications = async () => {
    await getUserNotifications();
  };

  const handleClickImportant = async (notificationId: number) => {
    if (activeTab === NotificationSearchType.important) {
      await readImportantNotifications(notificationId);
      updateUNotifications();
    }
  };

  const handleReadGeneralNotifications = async () => {
    await readGeneralNotifications();
    updateUNotifications();
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await getNotifications({ type: activeTab });
      setNotifications(response);
      setLoading(false);
    };

    getData();
    return () => {
      if (activeTab === NotificationSearchType.general) {
        handleReadGeneralNotifications();
      }
    };
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    updateUNotifications();

    if (notification && notification.id) {
      if (
        activeTab === NotificationSearchType.important &&
        notification.requiredAction === "click"
      ) {
        setNotifications((prevData) => [notification, ...prevData]);
      }
      if (activeTab === NotificationSearchType.general && notification.requiredAction === "read") {
        setNotifications((prevData) => [notification, ...prevData]);
      }
    }
    // eslint-disable-next-line
  }, [notification, activeTab]);

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  return {
    notifications,
    handleTabChange,
    activeTab,
    getNotificationUrl,
    loading,
    cx,
    styles,
    history,
    handleClickImportant,
  };
};
