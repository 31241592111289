import EditButtons from "../EditButtons/EditButtons";
import { Input } from "../../../../components/ui-components";

import styles from "./HeaderEditor.module.scss";

interface HeaderEditorProps {
  activeEdit: boolean;
  discardChanges: (name: string) => void;
  acceptChanges: (name: string) => void;
  onOpenEdit: (name: string) => void;
  name: string;
  onChangeHeader?: (name: string, value: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled: boolean;
  children: React.ReactNode | Array<React.ReactNode>;
}

const HeaderEditor: React.FC<HeaderEditorProps> = ({
  activeEdit,
  discardChanges,
  acceptChanges,
  onOpenEdit,
  name,
  onChange,
  value,
  disabled,
  children,
}) => {
  const handleOpenEdit = () => {
    if (disabled) return;
    onOpenEdit(name);
  };

  return (
    <div className={styles.headerWrapper} onClick={handleOpenEdit}>
      <div className={styles.headerTitleWrapper}>
        {activeEdit && (
          <div className={styles.editButtonsWrapper}>
            <EditButtons discardClick={discardChanges} acceptClick={acceptChanges} name={name} />
          </div>
        )}
        <Input
          className={styles.editHeader}
          readOnly={!activeEdit}
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
      {children}
    </div>
  );
};

export default HeaderEditor;
