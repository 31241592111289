import { ChangeEvent } from "react";
import { Input, Tooltip } from "antd";
import classNames from "classnames";

import styles from "./ManageTranslationsRow.module.scss";

export type isEdited = {
  en: boolean;
  de: boolean;
};

export type CompanyRowProps = {
  i18key: string;
  english: string;
  german: string;
  isEdited?: isEdited;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

const CompanyRow = ({ i18key, english, german, onChange, isEdited }: CompanyRowProps) => {
  return (
    <div className={styles.root}>
      <Tooltip placement='top' title={i18key}>
        <span className={styles.key}>{i18key}</span>
      </Tooltip>
      <span>
        <Input.TextArea
          name='en'
          value={english}
          onChange={onChange}
          className={classNames(!english && styles.highlighted, isEdited?.en && styles.edited)}
          autoSize={{ minRows: 1, maxRows: 10 }}
        />
      </span>
      <span>
        <Input.TextArea
          name='de'
          value={german}
          onChange={onChange}
          className={classNames(!german && styles.highlighted, isEdited?.de && styles.edited)}
          autoSize={{ minRows: 1, maxRows: 10 }}
        />
      </span>
      <hr />
    </div>
  );
};

export default CompanyRow;
