import * as types from "./types";
import Keys from "../../consts/helper";

const initState = {
  user_data: localStorage.getItem(Keys.USER_DATA)
    ? JSON.parse(localStorage.getItem(Keys.USER_DATA))
    : null,
  user_role: null,
  platform_role: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case types.USER_DATA:
      const user_data = { ...state.user_data, ...action.payload };
      localStorage.setItem(Keys.USER_DATA, JSON.stringify(user_data));
      if (action.payload.token) {
        localStorage.setItem(Keys.JWT_TOKEN, action.payload.token);
      }
      return {
        ...state,
        user_data: user_data,
      };
    case types.USER_TOKEN:
      const newUserData = { ...state.user_data, token: action.payload };
      if (action.payload) {
        localStorage.setItem(Keys.USER_DATA, JSON.stringify(newUserData));
        localStorage.setItem(Keys.JWT_TOKEN, action.payload);
      }
      return {
        ...state,
        user_data: newUserData,
      };
    case types.USER_ROLE:
      return {
        ...state,
        user_role: action.payload,
      };
    case types.PLATFORM_ROLE:
      return {
        ...state,
        platform_role: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
