import React from "react";
import styles from "./ToggleButton.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface ToggleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onToggle: React.MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  color: "blue" | "dark" | "green";
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  onToggle,
  children,
  color,
  active = false,
  ...restAttributes
}) => {
  const className = cx(styles.toggle_root, {
    [color]: true,
    active,
  });
  return (
    <button className={className} onClick={onToggle} {...restAttributes}>
      {children}
    </button>
  );
};

export default ToggleButton;
