import React from "react";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./i18n";
import store from "./store";
import MainRouter from "./Routers/MainRouter";

import { antdTheme } from "./styles/antd.theme";
import "./styles/global.css";
import "./styles/antd/index.scss";
import "./main.css";
import "./styles/verificationInput.scss";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <ConfigProvider theme={antdTheme}>
            <MainRouter />
          </ConfigProvider>
        </I18nextProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
