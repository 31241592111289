import { EditorState, RichUtils } from "draft-js";
import { useTranslation } from "react-i18next";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/italic.svg";

type ItalicButtonProps = {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
};

const ItalicButton = ({ editorState, onEditorStateChange }: ItalicButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const toggleStyle = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={Icon}
      isActive={editorState.getCurrentInlineStyle().has("ITALIC")}
      tooltipTitle={t("italic")}
      onClick={toggleStyle}
    />
  );
};

export default ItalicButton;
