import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATH } from "../../../../../consts/constants";

import { Loading } from "../../../../../components/ui-components";
import MeetingBlock from "./MeetingBlock/MeetingBlock";
import LeftSidebarHeader from "../../../../../Admin/pages/components/LeftSidebarHeader/LeftSidebarHeader";

import styles from "./MeetingsList.module.scss";

const MeetingsList = ({ isDiscarded, setIsDiscarded, meetings, isLoading, canCreate }) => {
  const { id: idAudit, meetingId } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "meetings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const onCreateClick = () => {
    setIsDiscarded(false);
    history.push(`/dashboard/${idAudit}/meetings/new`);
  };

  const openMeeting = (id) => {
    setIsDiscarded(false);
    history.push(`/dashboard/${idAudit}/meetings/${id}`);
  };

  return (
    <>
      <LeftSidebarHeader
        title={tGlobal("meetings")}
        onPlusClick={onCreateClick}
        canCreate={canCreate}
      />
      {!isLoading && !!meetings ? (
        <>
          <div className={styles.section}>
            <span className={styles.title}>{t("upcoming_meetings")}</span>
            {meetingId === PATH.NEW && !isDiscarded && (
              <MeetingBlock name={t("new_meetings")} readOnly active />
            )}
            {meetings["Upcoming Meetings"]?.map(
              ({
                id,
                title,
                organiser,
                platform,
                date,
                startTime,
                assignedUsers,
                assignedRoles,
              }) => (
                <MeetingBlock
                  active={parseInt(meetingId) === id}
                  platform={platform}
                  date={date}
                  startTime={startTime}
                  creator={organiser}
                  assignedUsers={assignedUsers}
                  assignedRoles={assignedRoles}
                  openMeeting={openMeeting}
                  name={title}
                  itemId={id}
                  key={id}
                />
              )
            )}
          </div>
          <div className={styles.section}>
            <span className={styles.title}>{t("past_meetings")}</span>
            {meetings["Past Meetings"]?.map(
              ({
                id,
                title,
                organiser,
                platform,
                date,
                startTime,
                assignedUsers,
                assignedRoles,
              }) => (
                <MeetingBlock
                  active={parseInt(meetingId) === +id}
                  platform={platform}
                  date={date}
                  startTime={startTime}
                  creator={organiser}
                  assignedUsers={assignedUsers}
                  assignedRoles={assignedRoles}
                  openMeeting={openMeeting}
                  name={title}
                  itemId={id}
                  key={id}
                />
              )
            )}
          </div>
        </>
      ) : (
        <div style={{ marginTop: "100px", alignSelf: "center" }}>
          <Loading withText={false} />
        </div>
      )}
    </>
  );
};

export default MeetingsList;
