import React from "react";
import styles from "./Flag.module.scss";
import Tag, { TagProps } from "../Tag/Tag";

const Flag: React.FC<TagProps> = ({ children, ...props }) => {
  return (
    <Tag className={styles.flag_root} {...props}>
      {children}
    </Tag>
  );
};

export default Flag;
