import { ContentBlock, ContentState, EditorState } from "draft-js";
import { useTranslation } from "react-i18next";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";
import { getCurrentBlock } from "../../../../../utils/textEditor";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as DecreaseIcon } from "../../../../../assets/icons/textEditor/indent-decrease.svg";
import { ReactComponent as IncreaseIcon } from "../../../../../assets/icons/textEditor/indent-increase.svg";

type IndentButtonProps = {
  type: "increase" | "decrease";
} & TextEditorButtonProps;

const IndentButton = ({ type, editorState, onEditorStateChange }: IndentButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const block = getCurrentBlock(editorState);
  const blockDepth = block.getDepth();
  const blockType = block.getType();
  const isListBlock = ["ordered-list-item", "unordered-list-item"].includes(blockType);

  const toggleStyle = () => {
    let newEditorState: EditorState | undefined;
    const adjustment = type === "increase" ? 1 : -1;
    const newDepth = blockDepth + adjustment;

    if (newDepth >= 0 && newDepth <= 2) {
      const newBlock = block.set("depth", newDepth) as ContentBlock;
      const newContentState = editorState.getCurrentContent().merge({
        blockMap: editorState.getCurrentContent().getBlockMap().set(block.getKey(), newBlock),
      }) as ContentState;

      newEditorState = EditorState.push(editorState, newContentState, "change-block-type");
    }

    if (newEditorState) {
      const editorStateWithFocus = EditorState.forceSelection(
        newEditorState,
        editorState.getSelection()
      );
      onEditorStateChange?.(editorStateWithFocus);
    }
  };

  return (
    <ToolbarButton
      disabled={
        !isListBlock ||
        (type === "increase" && blockDepth >= 2) ||
        (type === "decrease" && blockDepth === 0)
      }
      Icon={type === "decrease" ? DecreaseIcon : IncreaseIcon}
      tooltipTitle={t(`indent_${type}`)}
      onClick={toggleStyle}
    />
  );
};

export default IndentButton;
