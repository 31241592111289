import { useState, useEffect, useRef } from "react";

const useFetch = (requestData, ...params) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const lastRequestIdRef = useRef(0);
  const [fetchCount, setFetchCount] = useState(0);

  const fetchData = async () => {
    const requestId = ++lastRequestIdRef.current;
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestData(...params);
      if (requestId === lastRequestIdRef.current) {
        setData(response.data || response);
        setIsLoading(false);
      }
    } catch (error) {
      if (requestId === lastRequestIdRef.current) {
        setError(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [requestData, fetchCount, ...params]);
  const refetch = () => {
    setFetchCount((prev) => ++prev);
  };

  const stealthRefetchAsync = async (callback) => {
    const requestId = ++lastRequestIdRef.current;
    const response = await requestData(...params);
    if (requestId === lastRequestIdRef.current) {
      setData(response.data || response);
      callback?.();
    }
  };

  return {
    data,
    error,
    isLoading,
    refetch,
    stealthRefetchAsync,
    setData,
  };
};

export default useFetch;
