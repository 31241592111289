import * as types from "./types";

export const REQUEST_AND_MEETING_LOCATION = {
  DEFAULT: "",
  NEW: "new",
  EDIT: "edit",
};

const initState = {
  requestData: {
    location: REQUEST_AND_MEETING_LOCATION.DEFAULT,
    data: null,
    isEmpty: true,
  },
  meetingData: {
    location: REQUEST_AND_MEETING_LOCATION.DEFAULT,
    data: null,
    isEmpty: true,
  },
  isProfileDetailsHighlighted: false,
};

const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_NEW_REQUEST_DATA:
      return {
        ...state,
        requestData: { ...state.requestData, ...action.payload },
      };
    case types.REMOVE_NEW_REQUEST_DATA:
      return {
        ...state,
        requestData: {
          location: REQUEST_AND_MEETING_LOCATION.DEFAULT,
          data: null,
          isEmpty: true,
        },
      };
    case types.SET_NEW_MEETING_DATA:
      return {
        ...state,
        meetingData: { ...state.meetingData, ...action.payload },
      };
    case types.REMOVE_NEW_MEETING_DATA:
      return {
        ...state,
        meetingData: {
          location: REQUEST_AND_MEETING_LOCATION.DEFAULT,
          data: null,
          isEmpty: true,
        },
      };
    case types.HIGHLIGHT_PROFILE_DETAILS:
      return {
        ...state,
        isProfileDetailsHighlighted: true,
      };
    case types.UNHIGHLIGHT_PROFILE_DETAILS:
      return {
        ...state,
        isProfileDetailsHighlighted: false,
      };

    default: {
      return state;
    }
  }
};

export default globalReducer;
