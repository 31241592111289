import { EditorState } from "draft-js";
import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import { appendImage } from "../../../../../utils/textEditor";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/image.svg";

type AttachImagePopupProps = {} & TextEditorButtonProps;

export default function AttachImageButton({
  editorState,
  onEditorStateChange,
}: AttachImagePopupProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const fileRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const editorStateWithFocus = EditorState.forceSelection(
          appendImage(editorState, base64 as string),
          editorState.getSelection()
        );
        onEditorStateChange(editorStateWithFocus);
      };
      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };

  return (
    <>
      <ToolbarButton
        Icon={Icon}
        tooltipTitle={t("image")}
        onClick={() => fileRef.current?.click()}
      />
      <input
        ref={fileRef}
        type='file'
        accept='image/*'
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />
    </>
  );
}
