import React, { useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Loading, Tag } from "../../../../components/ui-components";
import { useFetch } from "../../../../hooks";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { superadminGetCompany } from "../../../../api/superadminApi";
import CompanyWrapper from "../../../pages/Company/CompanyWrapper/CompanyWrapper";
import SettingsTab from "./SettingsTab/SettingsTab";
import { COLORS } from "../../../../consts/enums";
import HeaderAdmin from "../../../components/HeaderAdmin";

const settingsTab = {
  key: "settings",
  label: "settings",
};

const TYPE_COLORS = {
  audit_firm: COLORS.DARK,
  fiduciary: COLORS.GREEN,
  client: COLORS.BLUE,
};

const ViewCompany = ({}) => {
  const { id, type, path } = useParams();
  const history = useHistory();

  const { data: companyData, setData: setCompanyData } = useFetch(superadminGetCompany, type, id);
  const [tabs] = useState([settingsTab]);
  const BASE_URL = `/admin/companies/${type}/${id}`;
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tSA } = useTranslation("admin", { keyPrefix: "companies" });

  const changeTab = (key) => {
    history.push(`${BASE_URL}/${key}`);
  };

  const syncDataOnSave = (data) => {
    setCompanyData((state) => ({ ...state, ...data }));
  };

  return (
    <>
      <HeaderAdmin backTo='/audits' />
      {!companyData ? (
        <div className='loading_wrapper'>
          <Loading />
        </div>
      ) : (
        <CompanyWrapper
          companyData={companyData}
          postfix={<Tag color={TYPE_COLORS[type]}>{tSA(`role_${type}`)}</Tag>}
        >
          <Tabs
            items={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            activeKey={path}
            defaultActiveKey={path || tabs[0].key}
            onChange={changeTab}
          />
          <Switch>
            {/*<Route*/}
            {/*  path={`${BASE_URL}/audits`}*/}
            {/*  render={(props) => <CompanyAudits {...props} companyData={companyData} />}*/}
            {/*  companyData={companyData}*/}
            {/*/>*/}
            <Route
              path={`${BASE_URL}/settings`}
              component={(props) => (
                <SettingsTab {...props} companyData={companyData} syncDataOnSave={syncDataOnSave} />
              )}
            />
          </Switch>
        </CompanyWrapper>
      )}
    </>
  );
};

export default ViewCompany;
