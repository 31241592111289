import { EditorState } from "draft-js";
import { useTranslation } from "react-i18next";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/undo.svg";

type UndoButtonProps = {} & TextEditorButtonProps;

const UndoButton = ({ editorState, onEditorStateChange }: UndoButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const handleUndo = () => {
    const newState = EditorState.undo(editorState);
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={Icon}
      disabled={editorState.getUndoStack().size <= 0}
      tooltipTitle={t("undo")}
      onClick={handleUndo}
    />
  );
};

export default UndoButton;
