import { useTranslation } from "react-i18next";

import { STATUS_COLORS, ROLES_REQUSTED_TEXT } from "../../../../../../consts/enums";

import { ToggleButton } from "../../../../../../components/ui-components";
import LeftSidebarHeader from "../../../../components/LeftSidebarHeader/LeftSidebarHeader";

import styles from "./RequestListHeader.module.scss";

const RequestListHeader = ({
  onFilterClick,
  onCreateClick,
  currentFilterNames,
  countStatuses,
  canCreate,
}) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <LeftSidebarHeader
      canCreate={canCreate}
      title={tGlobal("requests")}
      onPlusClick={onCreateClick}
    >
      <div className={styles.container}>
        {Object.entries(countStatuses).map(([statusName, count]) => (
          <div key={statusName} className={styles.buttonWrapper}>
            <ToggleButton
              active={currentFilterNames.includes(statusName)}
              color={STATUS_COLORS[statusName]}
              onToggle={(e) => onFilterClick(e, statusName)}
            >
              {`${count} ${tGlobal(ROLES_REQUSTED_TEXT[statusName] || " ").toLowerCase()}`}
            </ToggleButton>
          </div>
        ))}
      </div>
    </LeftSidebarHeader>
  );
};

export default RequestListHeader;
