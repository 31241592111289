import { getFileExtensionFromUrl } from "./general";

export const downloadFileFromURL = async (downloadURL: string, filename: string) => {
  try {
    const response = await fetch(downloadURL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const noExtension = filename.split(".")[0] || "file";
    const extension = getFileExtensionFromUrl(downloadURL);
    link.setAttribute("download", `${noExtension}.${extension}`); // Set the desired filename
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Release the object URL

    return true;
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
