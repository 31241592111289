import React from "react";
import classNames from "classnames/bind";
import { CloseRoundedIcon } from "../../../assets/icons";
import styles from "./Tag.module.scss";

const cx = classNames.bind(styles);

export interface TagProps {
  className?: string;
  nowrap?: boolean;
  color: "dark" | "orange" | "yellow" | "green" | "blue" | "gray";
  children: React.ReactNode | string | number | React.ReactElement[];
  onClick?: () => void;
  onClose?: () => void;
}

const Tag: React.FC<TagProps> = ({ children, className, color, nowrap, onClick, onClose }) => {
  const classes = cx(styles.tag_root, className, {
    [color]: true,
    nowrap: nowrap,
    clickable: !!onClick,
    closable: !!onClose,
  });

  return (
    <div onClick={onClick} className={classes}>
      {children} {!!onClose && <CloseRoundedIcon onClick={onClose} color={color} />}
    </div>
  );
};

export default Tag;
