import { Card } from "antd";
import classNames from "classnames";
import { useEffect } from "react";

import styles from "./Popup.module.scss";

const Popup = ({ children, title, titleClassName = "", isOpen, popupStyle = {}, onClose }) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === "popup-container") {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div id='popup-container' className={styles.popupContainer}>
      <div className={styles.popup} style={popupStyle}>
        {title && (
          <Card.Grid hoverable={false} className={classNames(styles.title, titleClassName)}>
            {title}
          </Card.Grid>
        )}
        {children}
      </div>
    </div>
  );
};

export default Popup;
