import React from "react";
import "./styles/partners.css";
import Button from "../customComponents/Button/Button";
import businessDeal from "../assets/images/business_deal.svg";
import interview from "../assets/images/interview.svg";
import progressiveApp from "../assets/images/progressive_app.svg";
import undrawSegment from "../assets/images/undraw_segment.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const history = useHistory();
  const { t } = useTranslation("partner");
  return (
    <div className={"partners-wrapper"}>
      <Helmet>
        <title>Partner | aivo</title>
        <meta name='theme-color' content='#f2f5f6' />
        <style>{"body { background-color: #f2f5f6; }"}</style>
      </Helmet>
      <section className={"be-our-partner"}>
        <div className={"partners-info"}>
          <span>{t("title")}</span>
          <p>{t("description")}</p>
          <a href='#section_partners'>
            <Button text={t("button")} className={"btn_orange"} />
          </a>
        </div>
        <img src={businessDeal} className={"business-deal"} alt={"business-deal"} />
      </section>
      <div id='section_partners' />
      <section className={"our-opportunities"}>
        <span className={"headline"}>{t("partnership_title")}</span>
        <div className={"opportunities-blocks"}>
          <div className={"aivo-trustees"}>
            <span className={"title"}>{t("aivo_for_fiduciaries")}</span>
            <img src={interview} className={"interview"} alt={"interview"} />
            <span className={"subparagraph"}>{t("fiduciaries_subtitle")}</span>
            <p className={"description"}>{t("fiduciaries_description_1")}</p>
            <p className={"description"}>{t("fiduciaries_description_2")}</p>
            <Button
              text={t("request_now").toUpperCase()}
              onClick={() => history.push("/kontakt")}
              className={"btn_orange"}
            />
          </div>
          <div className={"aivo-software"}>
            <span className={"title"}>{t("aivo_for_software")}</span>
            <img src={progressiveApp} className={"progressive-app"} alt={"progressive-app"} />
            <span className={"subparagraph"}>{t("software_subtitle")}</span>
            <p className={"description"}>{t("software_description_1")}</p>
            <p className={"description"}>{t("software_description_2")}</p>
            <Button
              text={t("request_now")}
              onClick={() => history.push("/kontakt")}
              className={"btn_orange"}
            />
          </div>
        </div>
      </section>
      <div className={"blue-window"}>
        <div className={"left-side-blue"}>
          <h2>{t("become_partner_title")}</h2>
          <span className={"appointment"}>{t("become_partner_description")}</span>
          <Button
            text={t("become_partner_button")}
            onClick={() => history.push("/kontakt")}
            className={"btn_orange"}
          />
        </div>
        <div className={"right-side-blue"}>
          <img src={undrawSegment} className={"undrawSegment"} alt='src' />
        </div>
      </div>
    </div>
  );
};

export default Partners;
