import classNames from "classnames";
import React, { useMemo, useState } from "react";

import { useDocuments } from "../../hooks/services/useDocuments";

import DocumentOption from "../../Admin/components/DocumentOption";
import { TextEditor } from "../ui-components";
import AttachDocuments from "../AttachDocuments";

import { TEXT_EDITOR_BUTTONS } from "./RichTextEditor.constants";
import styles from "./RichTextEditor.module.scss";

const RichTextEditor = ({
  name,
  value,
  auditId,
  editorState,
  disabled,
  bordered,
  readOnly = false,
  hiddenInput,
  allowAttachments,

  documents,
  selectedDocuments,
  isDocumentPopupActive,
  setIsDocumentPopupActive,

  onBlur,
  onFocus,
  onAttachDocuments,
  onEditorStateChange,
}) => {
  const [focused, setFocused] = useState(false);
  const { createMultipleDocuments } = useDocuments();

  const handleOpenAttachDocument = () => {
    setIsDocumentPopupActive((state) => !state);
  };

  const onAddFile = async (files) => {
    createMultipleDocuments(
      {
        documents: files,
        type: "evidence",
        auditId,
      },
      {
        onSuccess: (res) => onAttachDocuments(res.documents),
      }
    );
  };

  const handleFocus = (e) => {
    // return if clicked element has id #attach-documents or any parent has id #attach-documents
    if (e.target.id === "attach-documents" || e.target.closest("#attach-documents")) {
      return;
    }
    setFocused(true);
    onFocus?.(e, name);
  };

  const handleBlur = (e) => {
    setFocused(false);
    onBlur?.(e, name);
  };

  const handleSave = (e) => {
    onBlur(e, name);
  };

  const filteredButtons = useMemo(() => {
    const buttons = Object.entries(TEXT_EDITOR_BUTTONS);

    return allowAttachments ? buttons : buttons.filter(([key]) => key !== "attachment");
  }, [allowAttachments]);

  return (
    <>
      <TextEditor
        name={name}
        disabled={disabled}
        bordered={bordered}
        readOnly={readOnly}
        value={value}
        editorState={editorState}
        wrapperClassName={classNames(styles.wrapper, focused && styles.focused)}
        hiddenInput={hiddenInput}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [],
        }}
        toolbarCustomButtons={filteredButtons.map(([key, Element]) => (
          <Element
            key={key}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            {...(key === "document" && {
              onClick: handleOpenAttachDocument,
              popup: isDocumentPopupActive && (
                <AttachDocuments
                  placeholder='Search files'
                  options={documents}
                  values={selectedDocuments}
                  onChange={onAttachDocuments}
                  optionComponent={({ id, name, document }) => (
                    <DocumentOption document={document} name={name} id={id} key={id} />
                  )}
                  onAddFile={onAddFile}
                  setIsOpen={setIsDocumentPopupActive}
                />
              ),
            })}
          />
        ))}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onSave={onBlur && handleSave}
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
};

export default RichTextEditor;
