import { useState } from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import Popup from "../../components/ui-components/Popup/Popup";
import { Input } from "../../components/ui-components";
import CardActions from "../pages/components/CardActions";

const DeleteAuditPopup = ({ onClose, isOpen, companyName, onSubmit }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [deleteValue, setDeleteValue] = useState("");

  const closeDeleteCompanyPopup = () => {
    setDeleteValue("");
    onClose();
  };

  return (
    <Popup title={t("delete_audit")} onClose={closeDeleteCompanyPopup} isOpen={isOpen}>
      <Space.Compact direction={"vertical"} block className={"popup-body"}>
        <Row gutter={24} span={24}>
          <Col span={8}>
            <span>Confirm deletion</span>
            <span className='caption'>{t("audit_deletion_description")}</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder={t("audit_name")}
              type='name'
              value={deleteValue}
              onChange={({ target }) => {
                setDeleteValue(target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
      <CardActions
        onCancel={closeDeleteCompanyPopup}
        onSubmit={onSubmit}
        submitText={t("delete")}
        submitDisabled={deleteValue !== companyName}
        submitButtonColor={"red"}
        cancelButtonColor={"blue"}
      />
    </Popup>
  );
};

export default DeleteAuditPopup;
