import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import clsx from "clsx";
import Switch from "@mui/material/Switch";

import classes from "./Switch.module.scss";

const CustomSwitch = ({ className, checked, onChange, label, labelPlacement }) => {
  const classNames = clsx(classes.root, className, {
    [classes.checked]: checked,
  });

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <FormControlLabel
      className={classNames}
      labelPlacement={labelPlacement}
      control={<Switch disableRipple checked={checked} onChange={handleChange} />}
      label={label}
    />
  );
};

export default CustomSwitch;
