import { useTranslation } from "react-i18next";

import { CustomDropdown } from "../ui-components";
import SelectValue from "../ui-components/SelectValue/SelectValue";
import ActionDropdown from "../ui-components/ActionDropdown";

import classes from "./MemberInfo.module.scss";
import CustomAntDropdown from "../ui-components/CustomAntDropdown/CustomAntDropdown";

const signatoryOptions = [
  {
    label: "No signatory rights",
    value: "",
  },
  {
    label: "Joint signatory",
    value: "joint",
  },
  {
    label: "Sole signatory",
    value: "sole",
  },
];

const MemberActions = ({
  member,
  name,
  allowEdit,
  roles,
  role,
  tRole,
  onChangeRole,
  onChangeSignatory,
  hideSignatory = false,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  return (
    <div className={classes.actions}>
      {allowEdit ? (
        <CustomAntDropdown
          options={roles}
          value={role}
          onChange={onChangeRole}
          type='grey'
          name={name || member.id}
          style={{ width: 150 }}
          dropdownStyle={{ width: "100%" }}
          headerStyle={{ height: 32 }}
        />
      ) : (
        <SelectValue>{tRole || role}</SelectValue>
      )}
      {hideSignatory || (
        <ActionDropdown
          onChange={(_, value) => onChangeSignatory(value, member.id)}
          options={signatoryOptions}
          value={member.signatory || ""}
          t={(val) => t(`signatory_${val}`)}
        />
      )}
    </div>
  );
};

export default MemberActions;
