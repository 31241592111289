import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Dropdown from "../../pages/components/DropDown/DropDown";
import "./settingTeam.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { inviteTeamMembers } from "../../../api/teamMembersApi";
import { useTranslation } from "react-i18next";

const SettingTeamDetails = ({ save, close, teamData, newTeamData, setSave, setActive }) => {
  const { id: idAudit } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [profileData, setProfileData] = useState(
    teamData || {
      fullName: "",
      email: "",
      phone: "",
      role: "",
    }
  );
  const [checkData, setCheckData] = useState({
    fullName: false,
    email: false,
    phone: false,
    role: false,
  });

  useEffect(() => {
    if (
      save &&
      profileData.fullName &&
      profileData.email &&
      profileData.phone &&
      profileData.role
    ) {
      inviteTeamMembers(profileData, idAudit)
        .then(() => {
          close();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      if (save) {
        setCheckData({
          fullName: !profileData.fullName,
          email: !profileData.email,
          phone: !profileData.phone,
          role: !profileData.role,
        });
        setSave(false);
      }
    }
    // eslint-disable-next-line
  }, [profileData, save, idAudit]);

  function emailValidation() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+-?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(profileData.email);
  }
  useEffect(() => {
    setActive(
      emailValidation() &&
        profileData.fullName.length > 0 &&
        !!(profileData.phone?.length > 7) &&
        profileData.role > 0
    );
    if (teamData) {
      newTeamData(profileData);
    }

    // eslint-disable-next-line
  }, [profileData]);

  return (
    <div className='settingsAudit_role'>
      <div className='settings_right-audit settings_right_addTeamMember'>
        <div className='settings_right-info_inner '>
          <div className='settings_right-info-title'>{t("details")}</div>
          <hr />

          <div className='settings_audit'>
            <div className='settings_team'>
              <div className='settings_team_details'>
                <div className='settings_team_name'>
                  <span>{t("full_name")}</span>
                  <span>{tGlobal("email")}</span>
                  <span>{tGlobal("phone_number")}</span>
                  <span>{t("user_role")}</span>
                </div>
              </div>
              <div className='settings_audit-team_information'>
                <input
                  className={`${checkData.fullName ? "error-input" : ""} settings_audit-team_inner`}
                  value={profileData.fullName}
                  placeholder={"John Doe"}
                  onChange={({ target }) => {
                    setProfileData((prevState) => ({
                      ...prevState,
                      fullName: target.value,
                    }));
                    setCheckData((prevState) => ({
                      ...prevState,
                      fullName: false,
                    }));
                  }}
                />
                <input
                  className={`${checkData.email ? "error-input" : ""} settings_audit-team_inner`}
                  value={profileData.email}
                  placeholder={"doe@mail.com"}
                  onChange={({ target }) => {
                    setProfileData((prevState) => ({
                      ...prevState,
                      email: target.value,
                    }));
                    setCheckData((prevState) => ({
                      ...prevState,
                      email: false,
                    }));
                  }}
                />
                <div className='teamMember'>
                  <PhoneInput
                    className={`${checkData.phone ? "error-input" : ""} teamMember_PhoneInput`}
                    international
                    defaultCountry='CH'
                    placeholder={"079 916 46 27"}
                    value={profileData.phone}
                    limitMaxLength
                    onChange={(target) => {
                      setProfileData((prevState) => ({
                        ...prevState,
                        phone: target,
                      }));
                      setCheckData((prevState) => ({
                        ...prevState,
                        phone: false,
                      }));
                    }}
                  />
                </div>
                <Dropdown
                  check={checkData.role}
                  changeRole={false}
                  setValue={(e) => {
                    setProfileData((prevState) => ({
                      ...prevState,
                      role: e,
                    }));
                    setCheckData((prevState) => ({
                      ...prevState,
                      role: false,
                    }));
                  }}
                  valueId={profileData ? profileData.role : null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingTeamDetails;
