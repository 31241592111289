import { i18nResources } from "../../../../i18n";

export const STORAGE_KEYS = {
  translations: "AIVO_TRANSLATIONS_DATA",
};

export const MANAGE_TRANSLATIONS_TABS = Object.keys(i18nResources.en.dashboard) as Array<
  keyof typeof i18nResources.en.dashboard
>;

export const SORT_TITLES = [{ title: "Key" }, { title: "English" }, { title: "German" }];
