import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "../../../components/ui-components";

import styles from "./VerifyID.module.scss";

const VerifyID = () => {
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const onVerify = () => {
    history.push("/account/settings#identity_verification");
  };

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <span className={styles.title}>{t("id_verification_title")}</span>
        <span className={styles.description}>
          <Trans t={t} i18nKey='id_verification_description' components={{ br: <br /> }} />
        </span>
      </div>
      <Button handleClick={onVerify} color='blue' primary>
        {tGlobal("verify_now")}
      </Button>
    </div>
  );
};

export default VerifyID;
