import { Button } from "../../../../components/ui-components";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";

import styles from "./LeftSidebarHeader.module.scss";

const LeftSidebarHeader = ({ children, onPlusClick, title, canCreate }) => (
  <div className={styles.root}>
    <div className={styles.contentWrapper}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.buttonWrapper}>
        {canCreate && (
          <Button color='lightBlue' primary handleClick={onPlusClick}>
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
    {children}
  </div>
);

export default LeftSidebarHeader;
