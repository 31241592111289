import React, { useEffect, useMemo, useState } from "react";
import "./styles/assign-search.css";
import search from "../assets/admin/search.svg";
import OutsideClickHandler from "../consts/detectOutSideClick";
import { Tag } from "./ui-components";
import { createAssertion, getAssertions } from "../api/assertionApi";
import { useParams } from "react-router-dom";
import Assertion from "./ui-components/Assertion/Assertion";

const AddAssertion = ({ isOpen, values, onClose, handleSelect }) => {
  const { id } = useParams();

  const [inputSearch, setInputSearch] = useState("");
  const [assertions, setAssertions] = useState([]);

  const fetchAssertions = async () => {
    const data = await getAssertions(id);
    setAssertions(data);
  };

  useEffect(() => {
    fetchAssertions();
  }, []);

  let filteredAssertions = useMemo(() => {
    return assertions.filter(({ title }) => {
      return (
        title.toLowerCase().includes(inputSearch.toLowerCase()) &&
        !values?.some((i) => i.title === title)
      );
    });
  }, [assertions, inputSearch, values]);

  const handleAddAssertion = async (e) => {
    e?.preventDefault();
    try {
      const assertion = await createAssertion(id, inputSearch);
      setAssertions((state) => [...state, assertion]);
      handleSelect(assertion);
    } catch (e) {
      console.error(e);
    }
    setInputSearch("");
  };

  const searchPerfectMatch = useMemo(() => {
    return assertions.find(({ title }) => {
      return title.toLowerCase() === inputSearch.toLowerCase();
    });
  }, [assertions, inputSearch]);

  return (
    isOpen && (
      <OutsideClickHandler callback={onClose}>
        <div className='main-block'>
          <form onSubmit={handleAddAssertion} className='search-block'>
            <img src={search} alt='search' />
            <input
              placeholder={"Search assertions"}
              value={inputSearch}
              onChange={({ target }) => {
                setInputSearch(target.value);
              }}
            />
          </form>
          <div className='assign-scroll-wrapper'>
            <div className='table-search'>
              <span className='title'>Select assertion</span>
              {filteredAssertions
                .sort((a, b) => a.id - b.id)
                .map((item, index) => {
                  return (
                    <div
                      className='row-search'
                      key={index}
                      onClick={() => {
                        handleSelect(item);
                      }}
                    >
                      <Assertion id={item.id}>{item.title}</Assertion>
                    </div>
                  );
                })}
              {inputSearch && !searchPerfectMatch && (
                <div onClick={handleAddAssertion} className='row-search'>
                  <Tag color={"gray"}>{inputSearch}</Tag>
                  <span>Add new</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    )
  );
};

export default AddAssertion;
