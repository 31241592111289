import React, { useCallback } from "react";
import { Input } from "antd";
import "./custom_text_area.scss";
import debounce from "../utils/debounde";
import { convertToRaw } from "draft-js";
import { EditorState } from "react-draft-wysiwyg";

const { TextArea } = Input;

const CustomTextArea = ({
  label,
  htmlFor,
  value,
  placeholder,
  onChange,
  rows,
  errorMessage,
  error,
  onBlur,
  name,
  style,
  disabled,
}) => {
  const debouncedFetchData = useCallback(
    debounce(async (e) => {
      onBlur(e);
    }, 250), // Adjust the delay as needed
    []
  );

  const handleChange = (e) => {
    onChange(e);
    if (onBlur) {
      debouncedFetchData(e);
    }
  };

  return (
    <div style={style} className={`custom_text_area ${error ? "custom_text_area_error" : ""}`}>
      {label ? (
        <label htmlFor={htmlFor || null} className={""} style={{ marginBottom: 8 }}>
          {label}
        </label>
      ) : null}
      <TextArea
        rows={rows ?? 4}
        id={htmlFor ?? null}
        onBlur={onBlur}
        name={(htmlFor || name) ?? null}
        placeholder={placeholder ?? ""}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        autoSize={{ minRows: 3, maxRows: 25 }}
      />
      {error ? <span className={"error"}>{errorMessage}</span> : null}
    </div>
  );
};

export default CustomTextArea;
