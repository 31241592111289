import API from "./API.js";

export const superadminGetUsers = async (tab) => {
  try {
    const response = await API().get(`/superadmin/users`, {
      params: { tab },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminCopyInviteLink = async (id) => {
  const response = await API().get(`/superadmin/users/${id}/copy-invite`);
  return response.data;
};

export const superadminDeleteUser = async (email) => {
  try {
    const response = await API().delete(`/superadmin/users/${email}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminChangeSignatory = async (id, type, teamMemberId, body) => {
  const response = await API().post(
    `/superadmin/company/${type}/${id}/member/${teamMemberId}/signatory`,
    body
  );
  return response.data;
};

export const superadminToggleSuperadmin = async (id, body) => {
  try {
    const response = await API().post(`/superadmin/users/${id}/toggle-superadmin`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const superadminGetCompanies = async (tab) => {
  try {
    const response = await API().get(`/superadmin/companies`, {
      params: { tab },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
export const superadminGetAuditorFirms = async () => {
  try {
    const response = await API().get(`/superadmin/audit_firms`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const superadminSelectAuditorFirm = async (body) => {
  try {
    const response = await API().post(`/superadmin/select_audit_firm`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const superadminGetCompany = async (type, id) => {
  try {
    const response = await API().get(`/superadmin/company/${type}/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminCreateCompany = async (body) => {
  const FD = new FormData();
  FD.append("name", body.name);
  FD.append("UID", body.UID);
  FD.append("logo", body.logo);
  FD.append("type", body.type);
  try {
    const response = await API().post("/superadmin/company", FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminChangeMemberRole = async (id, type, teamMemberId, body) => {
  try {
    const response = await API().post(
      `/superadmin/company/${type}/${id}/member/${teamMemberId}/change-role`,
      body
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminDownloadTranslations = async (body) => {
  try {
    const response = await API().post(`/superadmin/translations/export`, body, {
      responseType: "blob",
    });
    const blob = await response.data;
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "translations.zip";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    return error;
  }
};

export const superadminRemoveCompanyMember = async (id, type, teamMemberId) => {
  try {
    const response = await API().delete(`/superadmin/company/${type}/${id}/member/${teamMemberId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const superadminInviteCompanyMember = async (id, type, body) => {
  const response = await API().post(`/superadmin/company/${type}/${id}/invite`, body);
  return response.data;
};

export const superadminDeleteCompany = async (id, type) => {
  const response = await API().delete(`/superadmin/company/${type}/${id}`);
  return response.data;
};

export const superadminUpdateCompany = async (id, type, body) => {
  const FD = new FormData();
  FD.append("name", body.name);
  FD.append("logo", body.logo);
  FD.append("UID", body.UID);
  FD.append("type", body.type);

  try {
    const response = await API().post(`/superadmin/company/${type}/${id}/update`, FD);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const superadminUpdateUser = async (id, body) => {
  const response = await API().post(`/superadmin/users/${id}/update`, body);
  return response.data;
};
