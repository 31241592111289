import { PublicIcon } from "../../../../../../assets/icons";

import styles from "./SharedWith.module.scss";

type SharedWithProps = {
  amount: number;
};

export default function SharedWith({ amount }: SharedWithProps) {
  return (
    <div className={styles.root}>
      <PublicIcon />
      <span>{amount}</span>
    </div>
  );
}
