import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";
import clsx from "clsx";

import useOutsideClick from "../../../hooks/useOutsideClick";
import { allAudits, oneAudit } from "../../../api/auditApi";
import { removeMeetingData, removeRequestData } from "../../../store/global/actions";
import { setUserPermissionsRole } from "../../../store/user/actions";
import { userPermissionsRole } from "../../../store/user/api";

import { Navigation } from "../../../Routers/Navigation";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as ArrowMenuIcon } from "../../../assets/icons/arrow-menu-open.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/icons/chevron-right.svg";

import styles from "./DashboardAside.module.scss";

function DashboardAside({ pathname }) {
  const ref = useRef(null);
  const lastRouteChange = useRef(null);
  const { id: idAudit } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [audits, setAudits] = useState([]);
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState("");

  const userRole = useSelector((state) => state.user.user_role);
  const { role, isAuditor, isCompanyAdmin } = userRole || {};
  const activePath = pathname.split("/")[3];

  const onSubmit = (id) => {
    dispatch(removeRequestData());
    dispatch(removeMeetingData());
    // currently state persists on page change, so we need to reload the page
    window.location.href = `/dashboard/${id}/${activePath}`;
    setIsSubmenuOpen(false);
  };

  useEffect(() => {
    oneAudit(idAudit)
      .then((res) => {
        setUserData({
          name: res.audit.company.name,
          revision: res.audit.name,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    allAudits()
      .then((res) => {
        setAudits(res.companies);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit]);

  const fetchPermissions = () => {
    userPermissionsRole(idAudit).then((res) => {
      dispatch(setUserPermissionsRole(res.teamMember));
    });
  };

  useEffect(() => {
    const currentTime = Date.now();
    const threeMinutes = 60 & 1000; // 1 minute in milliseconds

    if (
      (lastRouteChange.current && currentTime - lastRouteChange.current >= threeMinutes) ||
      !lastRouteChange?.current
    ) {
      fetchPermissions();
      lastRouteChange.current = currentTime;
    }

    // eslint-disable-next-line
  }, [pathname]);

  const toggleMinimised = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmenuOpen = () => setIsSubmenuOpen(true);

  useOutsideClick(ref, () => {
    setIsOpen(false);
    setIsSubmenuOpen(false);
  });

  return (
    <div className={styles.root}>
      <div ref={ref} className={styles.wrapper}>
        <div className={clsx(styles.aside, isOpen && styles.asideOpen)}>
          <div className={styles.header}>
            <Link to='/audits' className={styles.logo}>
              <Logo />
            </Link>
            <Button
              className={clsx(styles.iconButton, isOpen && styles.rotated)}
              onClick={toggleMinimised}
              icon={<ArrowMenuIcon />}
            />
          </div>
          <div className={styles.auditSubmenu} onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}>
            <div className={styles.auditSubmenuHeader}>
              <span className={styles.auditSubmenuTitle}>{userData.name}</span>
              <span className={styles.auditSubmenuDescription}>{userData.revision}</span>
            </div>
            <Button
              className={clsx(styles.iconButton, isSubmenuOpen && styles.rotated)}
              icon={<ChevronRightIcon isActive={isSubmenuOpen} />}
              onClick={handleSubmenuOpen}
            />
          </div>
          <div className={styles.asideNavigation}>
            {Navigation.adminDashboardMain
              .filter((i) => !i.hide)
              .map(
                (route, index) =>
                  index < 6 &&
                  role &&
                  (isAuditor ||
                    isCompanyAdmin ||
                    role[route.disabled] ||
                    route.param === "settings") && (
                    <Link
                      to={
                        idAudit === ":id"
                          ? "/audits"
                          : `/dashboard/${idAudit}/${route.param}${
                              ["requests", "meetings"].includes(route.param) ? "/new" : ""
                            }`
                      }
                      className={clsx(styles.asideLink, {
                        [styles.asideLinkActive]: activePath === route.param,
                      })}
                      key={route.param}
                      onClick={() => setIsOpen(false)}
                    >
                      <div className={styles.asideLinkIcon}>{route.icon}</div>
                      <div className={styles.asideLinkLabel}>{route.name}</div>
                    </Link>
                  )
              )}
          </div>
        </div>
        <div className={clsx(styles.submenu, isSubmenuOpen && styles.submenuOpen)}>
          <div className={styles.submenuInner}>
            <h4 className={styles.submenuTitle}>{t("my_audits")}</h4>
            <div className={styles.searchWrapper}>
              <Input
                placeholder={t("search_audits")}
                prefix={<SearchIcon />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {audits?.map(
              (i) =>
                (i.audits.filter((el) => el.name.toLowerCase().includes(search.toLowerCase()))
                  .length > 0 ||
                  !search) && (
                  <div className={styles.submenuListWrapper} key={i.id}>
                    <div className={styles.submenuListTitle}>{i.name}</div>
                    <div className={styles.submenuList}>
                      {i.audits.length > 0 ? (
                        i.audits
                          .filter((el) => el.name.toLowerCase().includes(search.toLowerCase()))
                          .map((item, key) => (
                            <div
                              key={key}
                              className={clsx(
                                styles.submenuListItem,
                                item.id === parseInt(idAudit) && styles.submenuListItemActive
                              )}
                              onClick={() => onSubmit(item.id)}
                            >
                              <div className={styles.navigationPoint} />
                              <div
                                className={clsx(
                                  styles.submenuListItemTitle,
                                  item.id === parseInt(idAudit) && styles.titleActive
                                )}
                              >
                                {item.name}
                              </div>
                              <div>
                                <CircularProgressbar
                                  strokeWidth={25}
                                  className='progressBar_navigation'
                                  value={item.progress}
                                />
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className={styles.submenuListItem}>
                          <span className={styles.submenuListItemTitle}>No audits</span>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      {isSubmenuOpen && <div className={styles.overlay} />}
    </div>
  );
}

export default DashboardAside;
