import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Tooltip } from "antd";

import { allAudits } from "../../../../../api/auditApi";
import { isCompanyAdmin } from "../../../../../consts/isCompanyAdmin";

import { InfoIcon, LogoPlaceholder } from "../../../../../assets/icons";

import styles from "./AccountSettingsAccessOverview.module.scss";

const AccessOverviewRow = ({ isCompany, logo, name, role, roleKey, companyRole, auditorRole }) => {
  const Logo = ({ image }) => {
    return image ? <img src={image} alt='' /> : <LogoPlaceholder />;
  };
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const companyFullPermission =
    roleKey && roleKey !== "admin" && roleKey !== "auditor" && isCompanyAdmin(companyRole);
  const Name = isCompany ? "b" : "span";
  const roleName = auditorRole ? t(`role_${auditorRole}`) : role || t(`role_${roleKey}`);

  return (
    <Card.Grid hoverable={false} style={{ paddingBlock: "16px 24px" }}>
      <Row gutter={16} align={"middle"}>
        <Col className={styles.avatar} flex='56px'>
          {isCompany && <Logo image={logo} />}
        </Col>
        <Col flex='auto'>
          <Name className={styles.name}>{name}</Name>
        </Col>
        {companyFullPermission && (
          <Col>
            <Tooltip
              placement={"bottomRight"}
              arrow={false}
              overlayStyle={{ maxWidth: "max-content" }}
              title={t("company_info")}
              className={styles.tooltip}
            >
              <InfoIcon />
            </Tooltip>
          </Col>
        )}
        <Col className={styles.role}>{roleName}</Col>
      </Row>
    </Card.Grid>
  );
};

const AccountSettingsAccessOverview = () => {
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = async () => {
    try {
      const res = await allAudits();
      setCompanies(res.companies);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return companies.map((item, index) => (
    <>
      <AccessOverviewRow
        key={index}
        isCompany
        logo={item.logo}
        name={item?.name}
        roleKey={item.myRole}
      />
      {item.audits.map((audit, index) => (
        <AccessOverviewRow
          key={index}
          name={audit.name}
          role={audit.myRole?.name}
          roleKey={audit.myRole?.key}
          auditorRole={audit.isAuditor?.role}
          companyRole={item.myRole}
        />
      ))}
    </>
  ));
};

export default AccountSettingsAccessOverview;
