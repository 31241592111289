import { Col, Row, Card, Space } from "antd";
import { useTranslation } from "react-i18next";

import { Input } from "../../../components/ui-components";
import CustomTextArea from "../../../customComponents/TextArea";

const RoleDetailsCard = ({ data = {}, syncDataOnSave }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const inputData = (key, value) => {
    const input = {
      ...data,
      [key]: value,
    };
    syncDataOnSave(input);
  };

  return (
    <Card.Grid hoverable={false}>
      <Space.Compact direction={"vertical"} block>
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("permission_role_name_input_label")}</span>
          </Col>
          <Col span={16}>
            <Input
              placeholder={t("permission_role_name_input_placeholder")}
              value={data.name}
              onChange={({ target }) => {
                inputData("name", target.value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={8}>
            <span>{t("description")}</span>
          </Col>
          <Col span={16}>
            <CustomTextArea
              value={data.description}
              onChange={({ target }) => {
                inputData("description", target.value);
              }}
            />
          </Col>
        </Row>
      </Space.Compact>
    </Card.Grid>
  );
};

export default RoleDetailsCard;
