import { Card, Col, Flex } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { countActivePermissions } from "../../../consts/auditPermissions";

import { Button } from "../../../components/ui-components";

import buttonEdit from "../../../assets/admin/buttonEdit.svg";

import "../../styles/settingsAudits.css";

const Permissions = ({ roles, hasAdminPermissions }) => {
  const { id: idAudit } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const selector = useSelector((state) => state.user.user_role?.role);

  const onSubmitAdd = () => {
    history.push(`/dashboard/${idAudit}/role/add`);
  };
  const onSubmitEdit = (idRole) => {
    history.push(`/dashboard/${idAudit}/team-role/edit/${idRole}`);
  };

  return (
    <>
      {roles
        .filter((i) => i.key !== "auditor")
        .sort((a, b) => a.id - b.id)
        .map((item, index) => {
          const [activePermissions, allPermissions] = countActivePermissions(item);
          const allActive = activePermissions === allPermissions;
          return (
            <Card.Grid hoverable={false} key={index} style={{ paddingBlock: 16 }}>
              <Flex justify='space-between' align='center'>
                <Col>
                  <span className='permission-title'>{item.name}</span>
                  <span className='permission-description'>
                    {item.description ||
                      (allActive
                        ? t("all_perm_active")
                        : `${activePermissions}/${allPermissions} ${t("perm_active")}`)}
                  </span>
                </Col>
                {(hasAdminPermissions ||
                  (selector?.editPermissionRoles && item.key !== "admin")) && (
                  <div
                    className='settings_audit-team_edit'
                    onClick={() => {
                      onSubmitEdit(item.id);
                    }}
                  >
                    <img
                      className='settings_audit-team_edit_images'
                      src={buttonEdit}
                      alt={buttonEdit}
                    />
                  </div>
                )}
              </Flex>
            </Card.Grid>
          );
        })}
      {(hasAdminPermissions || selector?.createNewPermissions) && (
        <Card.Grid hoverable={false}>
          <Button
            color={"blue"}
            style={{ width: 220, marginInline: "auto" }}
            secondary
            onClick={onSubmitAdd}
          >
            {t("add_permission")}
          </Button>
        </Card.Grid>
      )}
    </>
  );
};

export default Permissions;
