import React, { useState, useEffect, useMemo } from "react";
import btnBack from "../../../assets/admin/btnBack.svg";
import { useHistory, useParams } from "react-router-dom";
import { updateRoles, createRoles, oneRoles, deleteRole } from "../../../api/rolesApi";
import PersonalPermit from "./PersonalPermit";
import CheckData from "../../../components/CheckData";
import { setUserPermissionsRole } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SettingsSplitNavigation from "../../pages/components/SettingsSplitNavigation/SettingsSplitNavigation";
import RoleDetailsCard from "./RoleDetailsCard";
import DangerZoneCard from "./DangerZoneCard";
import { Loading } from "../../../components/ui-components";

const PersonalRoleNewEdit = () => {
  const { id: idAudit, idRole: idRoles } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const userSelector = useSelector((state) => state.user.user_role);

  const [data, setData] = useState(false);
  const [initData, setInitData] = useState(false);

  useEffect(() => {
    if (idRoles) {
      oneRoles(idAudit, idRoles)
        .then((res) => {
          setData(res.role);
          setInitData(res.role);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setData({ name: "", description: "" });
      setInitData({ name: "", description: "" });
    }
  }, [idAudit, idRoles]);

  const syncDataOnSave = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const onClose = () => {
    history.push(`/dashboard/${idAudit}/settings`);
  };

  const onDelete = async () => {
    try {
      await deleteRole(idAudit, idRoles);
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const linkSettings = [
    {
      body: <RoleDetailsCard data={data} syncDataOnSave={syncDataOnSave} />,
      title: t("details"),
      key: "details",
      href: "#details",
      bodyStyle: { padding: "0px" },
    },
    {
      body: (
        <PersonalPermit
          editRole={data}
          personalDetailsData={data}
          syncDataOnSave={syncDataOnSave}
          close={onClose}
        />
      ),
      title: t("permissions"),
      key: "permissions",
      href: "#permissions",
      bodyStyle: { padding: "0px" },
    },
    {
      body: <DangerZoneCard roleName={data.name} onDelete={onDelete} />,
      title: t("danger_zone"),
      key: "danger",
      href: "#danger",
      hide: !idRoles,
    },
  ];

  const onSubmit = () => {
    if (idRoles) {
      updateRoles(data, idAudit, idRoles)
        .then((res) => {
          if (res.id === userSelector.role.id) {
            dispatch(setUserPermissionsRole({ ...userSelector, role: res }));
          }
          onClose();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      createRoles(data, idAudit)
        .then(() => {
          onClose();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const hasChanged = useMemo(() => {
    return Object.keys(data).some((key) => data[key] !== initData[key]);
  }, [data, initData]);

  return (
    <div>
      {data ? (
        <>
          <div className='header_settings'>
            <div className='header_settings-left'>
              <img onClick={onClose} src={btnBack} alt={btnBack} />
              <span>{idRoles ? t("edit_permission_title") : t("add_permission")}</span>
            </div>
            <div className='header_settings-right'>
              <CheckData
                activeBtn={hasChanged}
                text={idRoles ? tGlobal("update") : tGlobal("add")}
                discard={onClose}
                onClick={onSubmit}
              />
            </div>
          </div>
          <SettingsSplitNavigation
            navigation={linkSettings}
            targetOffset={400}
            offsetTop={100}
            style={{ padding: "32px 24px 0" }}
          />
        </>
      ) : (
        <div className='loading_wrapper'>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default PersonalRoleNewEdit;
