import API from "./API";

export const getMeetings = async (id, params) => {
  try {
    return await API().get(`/audit/${id}/meetings`, { params });
  } catch (error) {
    return error;
  }
};

export const getAllMeetings = async (id, params) => {
  try {
    return await API().get(`/audit/${id}/meetings/all`, { params });
  } catch (error) {
    return error;
  }
};

export const getMeeting = async (id, meetingId, params) => {
  try {
    return await API().get(`/audit/${id}/meetings/${meetingId}`, params);
  } catch (error) {
    return error;
  }
};

export const createMeeting = async (id, params) => {
  try {
    const FD = new FormData();

    Object.entries(params).forEach((i) => {
      if (Array.isArray(i[1]) && i[0] === "files") {
        i[1].forEach((i, index) => {
          FD.append(`file${index}`, i);
        });
      } else if ((i[0] === "assignedUsers" || i[0] === "assignedRoles") && i[1]) {
        FD.append(i[0], i[1].join(","));
      } else if (i[0] === "privateNote" && i[1]?.note) {
        FD.append(i[0], i[1].note);
      } else if (i[0] !== "privateNote") {
        FD.append(i[0], i[1]);
      }
    });

    const response = await API().post(`/audit/${id}/meetings`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const attachExistingFile = async (id, meetingId, body, config) => {
  try {
    const response = await API().post(
      `/audit/${id}/meetings/${meetingId}/attach-document`,
      body,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const detachDocument = async (id, meetingId, documentId) => {
  return await API().post(`/audit/${id}/meetings/${meetingId}/detach-document`, {
    documentId,
  });
};

export const editMeeting = async (id, meetingId, params, config) => {
  try {
    const FD = new FormData();
    const keys = Object.keys(params);

    // for some reason update is getting called every time meeting is opened with only files param
    if (
      keys.length === 1 &&
      keys[0] === "files" &&
      !params.files?.filter((i) => !i.document).length
    ) {
      return;
    }

    Object.entries(params).forEach((i) => {
      if (Array.isArray(i[1]) && i[0] === "files") {
        i[1]
          .filter((i) => !i.document)
          .forEach((i, index) => {
            FD.append(`file${index}`, i);
          });
      } else if (i[0] === "assignedUsers" || i[0] === "assignedRoles") {
        FD.append(i[0], i[1].length ? i[1] : "[]");
      } else if (i[0] === "privateNote" && typeof i[1] === "object" && i[1]?.note) {
        FD.append(i[0], i[1].note);
      } else if (i[0] !== "privateNote" || typeof i[1] !== "object") {
        FD.append(i[0], i[1]);
      }
    });

    const response = await API().post(`/audit/${id}/meetings/${meetingId}/update`, FD, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createMeetingComment = async (id, meetingId, text) => {
  try {
    const response = await API().post(`/audit/${id}/meetings/${meetingId}/comment`, { text });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteMeeting = async (id, meetingId) => {
  try {
    const response = await API().delete(`/audit/${id}/meetings/${meetingId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
