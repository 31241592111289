import "./styles/homepage.css";
import Body from "../components/Body";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className='landing'>
      <Helmet>
        <title>Home | aivo</title>
        <style>{"body { background-color: #003e47; }"}</style>
        <meta name='theme-color' content='#003e47' />
      </Helmet>
      <div className='landing__wrapper'>
        <Body />
      </div>
    </div>
  );
}

export default App;
