import React from "react";

const PrivateNoteIcon = ({ ...restAttributes }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <g clip-path='url(#clip0_8874_13856)'>
      <path
        d='M3.99984 7.25008H4.24984V5.75008C4.24984 3.68192 5.93112 2 7.99992 2C10.0681 2 11.75 3.68192 11.75 5.75008V7.25008H12C12.5519 7.25008 13 7.69821 13 8.25008V13.2501C13 13.802 12.5519 14.2501 12 14.2501H4C3.44813 14.2501 3 13.802 3 13.2501V8.25008C3 7.69821 3.44813 7.25008 4 7.25008H3.99984ZM7.49984 10.6114V12.0001C7.49984 12.1376 7.61234 12.2501 7.74984 12.2501H8.24984C8.38797 12.2501 8.49984 12.1376 8.49984 12.0001V10.6114C8.79734 10.4376 8.99984 10.1201 8.99984 9.75011C8.99984 9.19824 8.55171 8.75011 7.99984 8.75011C7.44797 8.75011 6.99984 9.19824 6.99984 9.75011C6.99984 10.1189 7.20171 10.4382 7.49984 10.6114ZM5.74976 7.25008H10.2498V5.75008C10.2498 4.50946 9.24038 3.50016 7.99984 3.50016C6.75984 3.50016 5.74992 4.50954 5.74992 5.75008L5.74976 7.25008Z'
        fill='#003E47'
        fill-opacity='0.4'
      />
    </g>
    <defs>
      <clipPath id='clip0_8874_13856'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default PrivateNoteIcon;
