import React from "react";
import { useTranslation } from "react-i18next";

const FilePreviewError = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });

  return (
    <div
      className='listA4'
      style={{
        display: "flex",
        justifyContent: "center",
        height: "80vh",
        width: "50vw",
      }}
    >
      <span>{t("failed_load_pdf")}</span>
    </div>
  );
};

export default FilePreviewError;
