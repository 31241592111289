import { io } from "socket.io-client";
import helper from "../consts/helper";

const token = localStorage.getItem(helper.JWT_TOKEN);

export const socket = io(process.env.REACT_APP_API_URL!, {
  extraHeaders: {
    token: "Bearer " + token,
  },
});
