import { useTranslation } from "react-i18next";

import { ReactComponent as PasswordNotValid } from "../../assets/admin/passwordNotValid.svg";
import { ReactComponent as PasswordValid } from "../../assets/admin/passwordValid.svg";
import { ReactComponent as PasswordEmpty } from "../../assets/admin/passwordEmpty.svg";

const PasswordCondition = ({ validationState, profileData }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });

  return (
    <div className='condition-columns'>
      <div className='condition-item'>
        {profileData.newPassword.password === "" ? (
          <PasswordEmpty />
        ) : validationState.hasSpecialCharacter ? (
          <PasswordValid />
        ) : (
          <PasswordNotValid />
        )}
        <span
          className={
            profileData.newPassword.password === ""
              ? "condition_grey"
              : validationState.hasSpecialCharacter
              ? "condition_green"
              : "condition_red"
          }
        >
          {t("one_special_case_character")}
        </span>
      </div>
      <div className='condition-item' style={{ marginLeft: 0 }}>
        {profileData.newPassword.password === "" ? (
          <PasswordEmpty />
        ) : validationState.hasUpperCase ? (
          <PasswordValid />
        ) : (
          <PasswordNotValid />
        )}
        <span
          className={
            profileData.newPassword.password === ""
              ? "condition_grey"
              : validationState.hasUpperCase
              ? "condition_green"
              : "condition_red"
          }
        >
          {t("one_uppercase_character")}
        </span>
      </div>
      <div className='condition-item'>
        {profileData.newPassword.password === "" ? (
          <PasswordEmpty />
        ) : validationState.isMinLength ? (
          <PasswordValid />
        ) : (
          <PasswordNotValid />
        )}
        <span
          className={
            profileData.newPassword.password === ""
              ? "condition_grey"
              : validationState.isMinLength
              ? "condition_green"
              : "condition_red"
          }
        >
          {t("8_character_minimum")}
        </span>
      </div>
      <div className='condition-item' style={{ marginLeft: 0 }}>
        {profileData.newPassword.password === "" ? (
          <PasswordEmpty />
        ) : validationState.hasNumber ? (
          <PasswordValid />
        ) : (
          <PasswordNotValid />
        )}
        <span
          className={
            profileData.newPassword.password === ""
              ? "condition_grey"
              : validationState.hasNumber
              ? "condition_green"
              : "condition_red"
          }
        >
          {t("one_number")}
        </span>
      </div>
    </div>
  );
};

export default PasswordCondition;
