import { Checkbox, Tooltip } from "antd";
import i18next from "i18next";

import { Label } from "../../../../../../components/ui-components";

import styles from "./QuoteCheckboxes.module.scss";
import { InfoIcon } from "../../../../../../assets/icons";

const checkboxLabels = [
  {
    label: i18next.t("dashboard:requests:relevant_actives_label"),
    groups: [
      [
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_liquid_assets"),
          name: "hasLiquidAssets",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_requests_from_services"),
          name: "hasRequestsFromServices",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_other_short_term_receivables"),
          name: "hasOtherShortTermReceivables",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_actives_has_inventories_and_uninvoiced_services"
          ),
          name: "hasInventoriesAndUninvoicedServices",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_active_accruals"),
          name: "hasActiveAccruals",
        },
      ],
      [
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_financial_investments"),
          name: "hasFinancialInvestments",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_participations"),
          tooltip: i18next.t("dashboard:requests:relevant_actives_has_participations_hint"),
          name: "hasParticipations",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_immobile_tangible_assets"),
          name: "hasImmobileTangibleAssets",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_intangible_assets"),
          name: "hasIntangibleAssets",
        },
        {
          label: i18next.t("dashboard:requests:relevant_actives_has_unpaid_share_capital"),
          name: "hasUnpaidShareCapital",
        },
      ],
    ],
  },
  {
    label: i18next.t("dashboard:requests:relevant_passives_label"),
    groups: [
      [
        {
          label: i18next.t("dashboard:requests:relevant_passives_has_liabilities_from_services"),
          name: "hasLiabilitiesFromServices",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_passives_has_short_term_interest_bearing_liabilities"
          ),
          name: "hasShortTermInterestBearingLiabilities",
        },
        {
          label: i18next.t("dashboard:requests:relevant_passives_has_value_added_tax"),
          name: "hasValueAddedTax",
        },
        {
          label: i18next.t("dashboard:requests:relevant_passives_has_other_short_term_liabilities"),
          name: "hasOtherShortTermLiabilities",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_passives_has_passive_accruals_and_short_term_provisions"
          ),
          name: "hasPassiveAccrualsAndShortTermProvisions",
        },
      ],
      [
        {
          label: i18next.t(
            "dashboard:requests:relevant_passives_has_provisions_and_similar_legally_required_items"
          ),
          name: "hasProvisionsAndSimilarLegallyRequiredItems",
        },
        {
          label: i18next.t("dashboard:requests:relevant_passives_has_equity"),
          name: "hasEquity",
        },
      ],
    ],
  },
  {
    label: i18next.t("dashboard:requests:relevant_income_statement_label"),
    groups: [
      [
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_operational_income_from_services"
          ),
          name: "hasOperationalIncomeFromServices",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_related_party_transactions"
          ),
          tooltip: i18next.t(
            "dashboard:requests:relevant_income_statement_has_related_party_transactions_hint"
          ),
          name: "hasRelatedPartyTransactions",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_expenses_for_materials_and_services"
          ),
          name: "hasExpensesForMaterialsAndServices",
        },
        {
          label: i18next.t("dashboard:requests:relevant_income_statement_has_personnel_expenses"),
          name: "hasPersonnelExpenses",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_other_operational_expenses"
          ),
          tooltip: i18next.t(
            "dashboard:requests:relevant_income_statement_has_other_operational_expenses_hint"
          ),
          name: "hasOtherOperationalExpenses",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_financial_income_and_expenses"
          ),
          name: "hasFinancialIncomeAndExpenses",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_result_from_operating_activities"
          ),
          name: "hasResultFromOperatingActivities",
        },
        {
          label: i18next.t(
            "dashboard:requests:relevant_income_statement_has_non_operating_income_and_expenses"
          ),
          name: "hasNonOperatingIncomeAndExpenses",
        },
        {
          label: i18next.t("dashboard:requests:relevant_income_statement_has_direct_taxes"),
          name: "hasDirectTaxes",
        },
      ],
    ],
  },
  {
    label: i18next.t("dashboard:requests:short_term_financing_label"),
    groups: [
      [
        {
          label: i18next.t("dashboard:requests:short_term_financing_has_overdrafts"),
          name: "hasOverdrafts",
        },
        {
          label: i18next.t("dashboard:requests:short_term_financing_has_supplier_credits"),
          name: "hasSupplierCredits",
        },
        {
          label: i18next.t("dashboard:requests:short_term_financing_has_short_term_bank_loans"),
          name: "hasShortTermBankLoans",
        },
        {
          label: i18next.t("dashboard:requests:short_term_financing_has_factoring"),
          name: "hasFactoring",
        },
      ],
    ],
  },
];

const QuoteCheckboxes = ({ data, handleChange, disabled }) => {
  return (
    <div className={styles.root}>
      {checkboxLabels.map(({ label, groups }, index) => (
        <div key={index} className={styles.column}>
          <Label>{label}</Label>
          {groups.map((group, index) => (
            <div key={index} className={styles.group}>
              {group.map(({ label, name, tooltip }) => (
                <div key={label}>
                  <Checkbox
                    name={name}
                    disabled={disabled}
                    checked={data[name]}
                    onChange={handleChange}
                  >
                    {label}
                  </Checkbox>
                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <InfoIcon />
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default QuoteCheckboxes;
