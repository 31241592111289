import { Button as AntdButton, Col, Dropdown, MenuProps, message, Row, Space } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { auditorFirmMembers } from "../../../../../../api/auditApi";

import { Button, CustomDropdown, Tag } from "../../../../../../components/ui-components";
import InviteUserForm from "../../../../../../components/InviteUserForm/InviteUserForm";

import { ReactComponent as ArrowDownIcon } from "../../../../../../assets/icons/arrow-down.svg";
import { ReactComponent as CopyLinkIcon } from "../../../../../../assets/icons/copy-link.svg";
import { ReactComponent as LockIcon } from "../../../../../../assets/icons/lock.svg";

import styles from "./DocumentInviteOverview.module.scss";
import { useTranslation } from "react-i18next";
import { CheckmarkIcon } from "../../../../../../assets/icons";
import { IDocument, IDocumentAccess } from "../../../../../../interfaces/Document";
import {
  inviteDocumentMember,
  updateDocumentAccessSettings,
  updateDocumentMemberAccessSettings,
} from "../../../../../../api/documentApi";
import Flag from "../../../../../../components/ui-components/Flag/Flag";
import toFullName from "../../../../../../consts/toFullName";
import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";
import { listTeamMembers } from "../../../../../../api/teamMembersApi";
import { ITeamMember, IUser } from "../../../../../../interfaces/User";

type Params = {
  id: string;
  documentId: string;
};

type InviteUser = {
  email: string;
  role: string; // todo add literal type?
  teamMemberId?: number;
  userId?: number;
};

type DocumentInviteOverviewProps = {
  document: IDocument;
  setDocument: any;
  onClose: () => void;
  handleAddUser: (access: IDocumentAccess) => void;
  handleCopyLink: () => void;
};

const getUserAccessesOptions = (fullList?: boolean) => [
  { label: "Editor", value: "editor" },
  { label: "Read-only", value: "read_only" },
  { label: "Owner", value: "owner" },
  ...(fullList ? [{ label: "Remove access", value: "remove_access", danger: true }] : []),
];

const initInvite = {
  email: "",
  role: "read_only",
};

export default function DocumentInviteOverview({
  document,
  setDocument,
  onClose,
  handleAddUser,
  handleCopyLink,
}: DocumentInviteOverviewProps) {
  const { id: auditId, documentId } = useParams<Params>();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [invite, setInvite] = useState<InviteUser>(initInvite);
  const [inviteError, setInviteError] = useState("");
  const [firmMembers, setFirmMembers] = useState<IUser[]>([]);
  const { accessType, accessRole } = document;

  const accesses: MenuProps["items"] = [
    {
      key: "public",
      label: (
        <div className={styles.menuItem}>
          Anyone with the link
          {accessType === "anyone" && <CheckmarkIcon />}
        </div>
      ),
    },
    {
      key: "private",
      label: (
        <div className={styles.menuItem}>
          Restricted
          {accessType === "restricted" && <CheckmarkIcon />}
        </div>
      ),
    },
  ];

  const handleUpdateAccess: MenuProps["onClick"] = async (e) => {
    await updateDocumentSettings({ accessType: e.key });
  };

  const handleUpdateAccessRole = async (name: string, role: string) => {
    await updateDocumentSettings({ accessRole: role });
  };

  const updateDocumentSettings = async (data: any) => {
    try {
      await updateDocumentAccessSettings(data, auditId, documentId);
      setDocument((prev: IDocument) => ({ ...prev, ...data }));
    } catch (error) {
      message.error("Error updating access settings");
    }
  };

  const updateAccessObject = (userId: number, data: any, action?: string) => {
    setDocument((prev: any) => ({
      ...prev,
      access: prev.access[action || "map"]((item: IDocumentAccess) =>
        item.user.id === userId ? (action ? data : { ...item, ...data }) : item
      ),
    }));
  };

  const updateDocumentAccessRole = (userId: number) => {
    return async (name: string, role: string) => {
      try {
        const body = { role };
        await updateDocumentMemberAccessSettings(body, auditId, documentId, userId);

        if (role === "remove_access") {
          updateAccessObject(userId, false, "filter");
          return;
        }
        updateAccessObject(userId, body);
      } catch (error) {
        message.error("Error updating access settings");
      }
    };
  };

  const handleAddMember = async () => {
    try {
      const res = await inviteDocumentMember(invite, auditId, documentId);
      handleAddUser(res.access);
      setInvite(initInvite);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      if (errorMessage) {
        return setInviteError(e?.response?.data?.message);
      }
      message.error("Error inviting user");
    }
  };

  const fetchData = async () => {
    const finalArr: IUser[] = [];
    const auditors = await auditorFirmMembers(auditId);
    const members = await listTeamMembers(auditId);

    auditors.currentAuditors.forEach((auditor: any) => {
      const user = auditor?.member?.user;
      if (finalArr.some((item) => item.id === user?.id)) return;
      finalArr.push(user);
    });

    auditors.users.forEach((user: IUser) => {
      if (finalArr.some((item) => item.id === user.id)) return;
      finalArr.push(user);
    });

    members.teamMembers.forEach((i: ITeamMember) => {
      if (finalArr.some((item) => item.id === i.user.id)) return;
      finalArr.push(i.user);
    });

    setFirmMembers(finalArr);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.inner}>
        <InviteUserForm
          placeholder={t("invite_user_placeholder")}
          roles={getUserAccessesOptions()}
          invite={invite}
          setInvite={setInvite}
          users={firmMembers}
          onSubmit={handleAddMember}
          error={inviteError}
          setError={setInviteError}
        />
      </div>
      {document.access.map(({ role, user }) => (
        <div className={styles.invitedUserWrap} key={user.id}>
          <Row gutter={16} justify='space-between'>
            <Col span={16}>
              <Space>
                <CustomAvatar user={user} />
                <div>
                  <Row>
                    <h4 className={styles.inviteUserName}>{toFullName(user)}</h4>
                    {user.role === "guest" && <Flag color={"yellow"}>Guest</Flag>}
                  </Row>

                  {/* TODO: if that's client company name - how do I determine which one to use*/}
                  {/* TODO: if that's auditor or fiduciary company - we should use correct color?*/}
                  {/*<Flag color={"dark"}>aivo Revisions AG</Flag>*/}
                </div>
              </Space>
            </Col>
            <Col>
              <CustomDropdown
                value={role}
                options={getUserAccessesOptions(true)}
                onChange={updateDocumentAccessRole(user.id)}
              />
            </Col>
          </Row>
        </div>
      ))}
      <div className={styles.accessSection}>
        <div className={styles.accessTitle}>{t("invite_guests_overview_general_access")}</div>
        <Row gutter={16} justify='space-between'>
          <Col span={16}>
            <Space size={8}>
              <div className={styles.accessIcon}>
                <LockIcon />
              </div>
              <div>
                <Dropdown
                  menu={{ items: accesses, onClick: handleUpdateAccess }}
                  trigger={["click"]}
                  placement='bottomRight'
                  arrow={false}
                >
                  <AntdButton className={styles.accessButton}>
                    <div className={styles.accessButtonValue}>
                      {t(`invite_guests_overview_access_${accessType}`)}
                      <ArrowDownIcon className={styles.accessButtonArrow} />
                    </div>
                    <span>{t(`invite_guests_overview_access_${accessType}_description`)}</span>
                  </AntdButton>
                </Dropdown>
              </div>
            </Space>
          </Col>
          <Col>
            <CustomDropdown
              options={getUserAccessesOptions()}
              onChange={handleUpdateAccessRole}
              value={accessRole}
            />
          </Col>
        </Row>
      </div>
      <div className={styles.footer}>
        <Row gutter={10} justify='space-between' align='middle'>
          <Col>
            <Button tertiary color='blue' className={styles.button} onClick={handleCopyLink}>
              <CopyLinkIcon />
              {tGlobal("copy_link")}
            </Button>
          </Col>
          <Col span={5}>
            <Button fullWidth primary color='lightBlue' className={styles.button} onClick={onClose}>
              {tGlobal("close")}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
