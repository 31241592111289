import { useState } from "react";
import { Col, Row, Space, Card } from "antd";
import { useTranslation } from "react-i18next";

import { updateAudit } from "../../../api/auditApi";

import CardActions from "../../pages/components/CardActions/CardActions";
import { Input, Label } from "../../../components/ui-components";

const Details = ({ companyData, syncDataOnSave, auditId }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [profileData, setProfileData] = useState(companyData);

  const inputData = (key, value) => {
    setProfileData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onSubmit = () => {
    if (profileData.name) {
      const body = {
        name: profileData.name,
      };
      updateAudit(auditId, { name: profileData.name })
        .then(() => {
          syncDataOnSave(body);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const discard = () => {
    setProfileData(companyData);
  };

  const hasChanged = profileData.name !== companyData.name;

  return (
    <>
      <Card.Grid hoverable={false}>
        <Space.Compact direction={"vertical"} block>
          <Row gutter={24}>
            <Col span={8}>
              <Label>{t("title")}</Label>
            </Col>
            <Col span={16}>
              <Input
                value={profileData.name}
                onChange={({ target }) => {
                  inputData("name", target.value);
                }}
              />
            </Col>
          </Row>
        </Space.Compact>
      </Card.Grid>
      {hasChanged && <CardActions onSubmit={onSubmit} onCancel={discard} />}
    </>
  );
};

export default Details;
