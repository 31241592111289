import { useEffect } from "react";
import { socket } from "../../utils/socket";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const SyncAuditData = ({ children }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    console.log("invalidateQueries");
    queryClient.invalidateQueries(["documents"]);
  }, [id]);

  useEffect(() => {
    if (!queryClient) return;

    const onDocumentSync = (res) => {
      const data = queryClient.getQueryData(["documents"]);

      if (!data || !res?.context?.action) {
        return queryClient.invalidateQueries({
          queryKey: ["documents"],
          refetchType: "all",
        });
      }

      if (res.context.action === "create") {
        const existingDocument = data.find((doc) => doc.id === res.context.id);

        if (!existingDocument) {
          return queryClient.invalidateQueries({
            queryKey: ["documents"],
            refetchType: "all",
          });
        }
      }
    };

    socket.on("document", onDocumentSync);

    return () => {
      socket.off("document", onDocumentSync);
    };
  }, []);

  return children;
};

export default SyncAuditData;
