import { Popover } from "antd";
import classNames from "classnames";
import { EditorState, RichUtils } from "draft-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { getCurrentBlock } from "../../../../../utils/textEditor";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as TextSizeIcon } from "../../../../../assets/icons/textEditor/text-size.svg";

import styles from "./TextSizeButton.module.scss";

type TextSizeButtonProps = {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
};

export default function TextSizeButton({ editorState, onEditorStateChange }: TextSizeButtonProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const [isOpen, setIsOpen] = useState(false);

  const handleSetSize = (size: "header-one" | "header-two" | "header-three" | "unstyled") => () => {
    const newEditorState = RichUtils.toggleBlockType(editorState, size);
    onEditorStateChange(newEditorState);
    setIsOpen(false);
  };

  return (
    <Popover
      trigger='click'
      placement='bottomLeft'
      open={isOpen}
      onOpenChange={setIsOpen}
      rootClassName={styles.popover}
      content={
        <div className={styles.menu}>
          <div
            className={classNames(styles.menuItem, styles.h1)}
            onClick={handleSetSize("header-one")}
          >
            {t("text_heading")} 1
          </div>
          <div
            className={classNames(styles.menuItem, styles.h2)}
            onClick={handleSetSize("header-two")}
          >
            {t("text_heading")} 2
          </div>
          <div
            className={classNames(styles.menuItem, styles.h3)}
            onClick={handleSetSize("header-three")}
          >
            {t("text_heading")} 3
          </div>
          <div
            className={classNames(styles.menuItem, styles.normal)}
            onClick={handleSetSize("unstyled")}
          >
            {t("text_normal")}
          </div>
        </div>
      }
    >
      <ToolbarButton
        Icon={TextSizeIcon}
        isActive={getCurrentBlock(editorState).getType().includes("header")}
        tooltipTitle={isOpen ? "" : t("text_size")}
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
}
