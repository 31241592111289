import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { changeTeamRole, getMyTeam, inviteUser, removeTeamMember } from "../../../api/teamApi";

import SettingsSplitNavigation from "../components/SettingsSplitNavigation/SettingsSplitNavigation";
import { Loading } from "../../../components/ui-components";
import TeamProfile from "./components/TeamProfile";
import AccessControl from "./components/AccessControl";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";

import "./components/accountSettings.css";

export default function CompanySettings() {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const [team, setTeam] = useState(null);

  useEffect(() => {
    getMyTeam().then((res) => {
      if (!res.team) return;
      const members = res.team.roles.reduce((acc, role) => {
        return [...acc, ...role.members.map((i) => ({ ...i, role: role.name }))];
      }, []);
      setTeam({ ...res.team, members });
    });
  }, []);

  const handleChangeRole = async (id, data) => {
    try {
      const res = await changeTeamRole(id, { role: data });
      return { teamMember: { ...res.teamMember, role: res.teamMember?.role?.name } };
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvite = async (data) => {
    const res = await inviteUser(data);
    return { ...res, role: res?.role?.name || "" };
  };

  const handleRemove = async (id) => {
    try {
      await removeTeamMember(id);
    } catch (error) {
      console.log(error);
    }
  };

  const syncTeamDataOnSave = (data) => {
    setTeam({
      ...team,
      ...data,
    });
  };

  const navigation = useMemo(
    () => [
      {
        body: <TeamProfile userData={team} syncDataOnSave={syncTeamDataOnSave} />,
        title: t("team_profile"),
        key: "team_profile",
        href: "#team_profile",
        bodyStyle: { padding: "0px" },
      },
      {
        body: (
          <AccessControl
            customInvite={handleInvite}
            customChangeRole={handleChangeRole}
            customRemoveMember={handleRemove}
            companyData={team}
            hideMenu
          />
        ),
        title: t("access_control"),
        key: "access_control",
        href: "#access_control",
        bodyStyle: { padding: "0px" },
      },
    ],
    [team]
  );

  return (
    <div className='accountSetting'>
      <HeaderAdmin />
      <div className='accountSetting__inner'>
        <div className='accountSetting_title'>{t("company_settings")}</div>
        <div className='account-settings-wrapper'>
          {team ? (
            <SettingsSplitNavigation navigation={navigation} targetOffset={200} />
          ) : (
            <div style={{ maxHeight: "80vh" }} className='loading_wrapper'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
