import dayjs from "dayjs";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { STATUS_COLORS } from "../../../../../../consts/enums";

import { Tag } from "../../../../../../components/ui-components";
import { TagProps } from "../../../../../../components/ui-components/Tag/Tag";

import { ReactComponent as ArrowNorthEastIcon } from "../../../../../../assets/icons/arrow-north-east.svg";

import styles from "./Backlinks.module.scss";

type BacklinksItemProps = {
  icon: ReactElement;
  title: string;
  href: string;
  date?: Date;
  startTime?: Date;
  status?: string;
};

export default function BacklinksItem({
  icon,
  href,
  title,
  date,
  startTime,
  status,
}: BacklinksItemProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <Link to={href} className={styles.root}>
      {icon}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {status && (
          <Tag className={styles.tag} color={STATUS_COLORS[status] as TagProps["color"]}>
            {t(`status_${status}`)}
          </Tag>
        )}
        {date && (
          <span className={styles.date}>
            {dayjs(date).format("DD.MM.YYYY")} {dayjs(startTime).format("HH:mm")}
          </span>
        )}
      </div>
      <ArrowNorthEastIcon className={styles.endIcon} width={16} height={16} />
    </Link>
  );
}
