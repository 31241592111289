import React from "react";
import { useHistory, useParams } from "react-router-dom";
import RequestListHeader from "./RequestListHeader/RequestListHeader";
import RequestBlock from "./RequestBlock/RequestBlock";
import { Loading } from "../../../../../components/ui-components";
import { PATH } from "../../../../../consts/constants";
import { useTranslation } from "react-i18next";

const RequestsList = ({
  isDiscarded,
  setIsDiscarded,
  requests,
  isLoading,
  countRequestsStatus,
  statusFilters,
  setStatusFilters,
  canCreate,
}) => {
  const { id: idAudit, requestId } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  const history = useHistory();

  const onCreateClick = () => {
    setIsDiscarded(false);
    history.push(`/dashboard/${idAudit}/requests/new`);
  };
  const openRequest = (id, type) => {
    setIsDiscarded(false);
    const requestId = type || id;
    history.push(`/dashboard/${idAudit}/requests/${requestId}`);
  };

  const handleRequestStatusFilter = (_event, filterName) => {
    setStatusFilters((previousStatusFilters) => {
      if (!previousStatusFilters.includes(filterName)) {
        return [...previousStatusFilters, filterName];
      }
      return previousStatusFilters.filter((f) => f !== filterName);
    });
  };

  return (
    <>
      <RequestListHeader
        onFilterClick={handleRequestStatusFilter}
        onCreateClick={onCreateClick}
        currentFilterNames={statusFilters}
        countStatuses={countRequestsStatus}
        canCreate={canCreate}
      />
      {requestId === PATH.NEW && !isDiscarded && (
        <RequestBlock name={t("new_request")} readOnly active />
      )}
      {!isLoading ? (
        <>
          {requests?.map(({ status, name, comments, relatedDocs, type, id }) => (
            <RequestBlock
              active={type === requestId || (type !== "quote" && parseInt(requestId) === id)}
              openRequest={openRequest}
              status={status}
              commentsCount={comments?.length}
              relatedDocsCount={relatedDocs?.length}
              name={name}
              itemId={id}
              requestType={type}
              key={id}
            />
          ))}
        </>
      ) : (
        <div style={{ marginTop: "100px", alignSelf: "center" }}>
          <Loading withText={false} />
        </div>
      )}
    </>
  );
};

export default RequestsList;
