import { useTranslation } from "react-i18next";

import SettingsSplitNavigation from "../../components/SettingsSplitNavigation/SettingsSplitNavigation";
import ActiveAudits from "./Cards/ActiveAudits/ActiveAudits";
import FinalisedAudits from "./Cards/FinalisedAudits/FinalisedAudits";

const CompanyAudits = ({ id, companyData, refetch }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const activeAudits = companyData.audits.filter((audit) => audit.progress !== 100);
  const finishedAudits = companyData.audits.filter((audit) => audit.progress === 100);

  return (
    <div>
      <SettingsSplitNavigation
        navigation={[
          {
            key: "active_audits",
            href: "#active_audits",
            title: t("active_audits"),
            body: (
              <ActiveAudits
                id={id}
                isCompanyAdmin={companyData.isCompanyAdmin}
                isAuditor={!!companyData.auditor}
                audits={activeAudits}
                refetch={refetch}
              />
            ),
            bodyStyle: { padding: 8 },
          },
          {
            key: "finished_audits",
            href: "#finished_audits",
            title: t("archived_audits"),
            body: <FinalisedAudits audits={finishedAudits} />,
            bodyStyle: { padding: 8 },
          },
        ]}
      />
    </div>
  );
};

export default CompanyAudits;
