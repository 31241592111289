import { Menu, Button } from "../../../../components/ui-components";

import styles from "./MenuView.module.scss";

const MenuView = ({ deleteText, onDelete }) => (
  <Menu>
    <div className={styles.menuItem}>
      <Button color='red' handleClick={onDelete} modal>
        {deleteText}
      </Button>
    </div>
  </Menu>
);

export default MenuView;
