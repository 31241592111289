import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames/bind";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { PATH } from "../../../../../consts/constants";
import { REQUEST_TYPE } from "../../../../../consts/enums";
import { prepareDraft } from "../../../../../consts/prepareDraft";
import { removeRequestData } from "../../../../../store/global/actions";
import { addRequests, upDateRequests } from "../../../../../api/requestsPage";

import { ToggleComponent, Label } from "../../../../../components/ui-components";
import CustomDropdown from "../../../../../components/ui-components/CustomDropdown/CustomDropdown";
import { AssignedWrapper, HeaderSection, PageTextEditor } from "../../../components";
import DueDateTime from "../../../components/DueDateTime/DueDateTime";

import styles from "./NewRequest.module.scss";

const cx = classNames.bind(styles);

const initialRequestData = {
  name: "",
  description: "",
  assignedUsers: [],
  assignedRoles: [],
  dueDate: "",
  dueTime: dayjs().set("hours", 18).set("minutes", 0),
  request_type: "",
  security: false,
  attachments: [],
};

const NewRequest = ({
  editData,
  triggerRefetch,
  setIsDiscarded,
  handleUpdateRequestData,
  handleRedirectLastRequest,
  lastChanges,
  requestId,
}) => {
  const { id: idAudit } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [requestData, setRequestData] = useState(editData || lastChanges || initialRequestData);
  const [errors, setErrors] = useState({});

  const options = [
    {
      label: t("file_request"),
      value: REQUEST_TYPE.FILE_REQUEST,
    },
    {
      value: REQUEST_TYPE.DATA_TRANSFER_REQUEST,
      label: t("transfer_request"),
    },
  ];

  const [editorState, setEditorState] = useState(() => {
    if (editData) {
      return prepareDraft(editData?.description);
    }
    if (lastChanges) {
      return prepareDraft(lastChanges?.description);
    }
    return EditorState.createEmpty();
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (requestData !== initialRequestData) {
      handleUpdateRequestData({ data: requestData });
    }
    // eslint-disable-next-line
  }, [requestData]);

  const handleRequestData = (name, value) => {
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
    setRequestData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setRequestData((prev) => ({
      ...prev,
      description: forFormik,
    }));
    setEditorState(editorState);
  };

  // eslint-disable-next-line
  const onUpdateRequest = async () => {
    const { id, auditId, assignedRoles, assignedUsers } = requestData;
    try {
      const response = await upDateRequests(
        {
          ...requestData,
          assignedRoles: assignedRoles.map((a) => a.id),
          assignedUsers: assignedUsers.map((a) => a.id),
        },
        auditId,
        id
      );
      triggerRefetch();
      history.push(`/dashboard/${auditId}/requests/${id}`);
    } catch (err) {
      console.error(err);
      alert("ERROR");
    }
  };

  const onCreateRequest = async () => {
    if (!requestData.name || !requestData.dueDate) {
      setErrors({
        name: !requestData.name,
        dueDate: !requestData.dueDate,
      });
      return;
    }

    if (dayjs(requestData.dueDate) < dayjs().startOf("day")) {
      setErrors((prev) => ({
        ...prev,
        dueDate: true,
      }));
      return;
    }

    try {
      const response = await addRequests(
        {
          ...requestData,
          assignedRoles: requestData.assignedRoles.map((a) => a.id),
          assignedUsers: requestData.assignedUsers.map((a) => a.id),
        },
        idAudit
      );
      triggerRefetch();
      handleUpdateRequestData({ data: initialRequestData });
      history.push(`/dashboard/${idAudit}/requests/${response.request.id}`);
    } catch (err) {
      console.error(err);
      alert("ERROR");
    }
  };

  const handleAssignedUsersAndRoles = ({ assignedUsers, assignedRoles }) => {
    setRequestData((prevValue) => ({
      ...prevValue,
      assignedUsers,
      assignedRoles,
    }));
  };

  const removerAssignedUsersAndRoles = () => {
    setRequestData((prevValue) => ({
      ...prevValue,
      assignedUsers: [],
      assignedRoles: [],
    }));
  };

  const handleDiscard = () => {
    if (editData) {
      history.push(`/dashboard/${idAudit}/requests/${editData.id}`);
    } else {
      setIsDiscarded(true);
      handleRedirectLastRequest();
      dispatch(removeRequestData());
    }
  };

  const changeDateTime = (newState) => {
    if (newState.dueDate && errors.dueDate) {
      setErrors((prev) => ({
        ...prev,
        dueDate: false,
      }));
    }
    setRequestData((state) => ({
      ...state,
      ...newState,
    }));
  };

  const submitButtonText = editData ? tGlobal("update") : tGlobal("create");
  const submitButtonClick = editData ? onUpdateRequest : onCreateRequest;

  return (
    <div className={styles.wrapper}>
      <HeaderSection
        headerTitle={requestId === PATH.EDIT ? t("update_request") : t("new_request")}
        handleDiscard={handleDiscard}
        submitButtonClick={submitButtonClick}
        submitButtonText={submitButtonText}
        onHandleChange={handleRequestData}
        headerValue={requestData.name}
        headerValueName='name'
        headerPlaceholder={t("request_title")}
        titleError={errors.name}
      />
      <div className={styles.bodySection}>
        <div className={styles.column}>
          <AssignedWrapper
            creatorLabel={tGlobal("created_by")}
            assignedLabel={tGlobal("assigned_to")}
            assignButtonLabel={tGlobal("assign_user")}
            assignedRoles={requestData.assignedRoles}
            assignedUsers={requestData.assignedUsers}
            onRemove={removerAssignedUsersAndRoles}
            handleAssignUsers={handleAssignedUsersAndRoles}
          />
        </div>
        <div className={cx(styles.row, styles.dateFileWrapper)}>
          <DueDateTime
            dueDate={requestData.dueDate}
            dueTime={requestData.dueTime}
            setData={changeDateTime}
            errors={errors}
            isCreate
          />
          <div className={styles.column}>
            <Label>{t("request_type")}</Label>
            <CustomDropdown
              options={options}
              value={requestData.request_type}
              onChange={handleRequestData}
              error={errors.request_type}
              name='request_type'
              style={{ width: "200px" }}
            />
          </div>
        </div>
        <div className={styles.column}>
          <span className={styles.columnTitle}>{t("security")}</span>
          <div className={cx(styles.row, styles.toggleWrapper)}>
            <ToggleComponent
              name='security'
              value={requestData.security}
              onChange={handleRequestData}
            />

            <Label style={{ fontWeight: 600 }}>
              <strong>{t("request_sensetive")} </strong> {t("request_sensetive2")}
            </Label>
          </div>
        </div>
        <PageTextEditor
          value={requestData.description}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          name='description'
          desc
          label={t("description")}
          bordered
        />
      </div>
    </div>
  );
};

export default NewRequest;
