import React, { useState, useEffect, useMemo } from "react";
import "./styles/assign-search.css";
import search from "../assets/admin/search.svg";
import ok from "../assets/admin/ok.svg";
import OutsideClickHandler from "../consts/detectOutSideClick";
import { useParams } from "react-router-dom";
import { inviteTeamMembers, listTeamMembers } from "../api/teamMembersApi";
import { allListRoles } from "../api/rolesApi";
import { useTranslation } from "react-i18next";
import toFullName from "../consts/toFullName";
import { PlusRoundedIcon } from "../assets/icons";
import useRegex from "../hooks/useRegex";
import { EMAIL_VALIDATION_PATTERN } from "../consts/regExp";
import CustomAntDropdown from "./ui-components/CustomAntDropdown/CustomAntDropdown";
import CustomAvatar from "./CustomAvatar/CustomAvatar";

const AssignSearch = ({
  setAssignTitle,
  setAssignId,
  onClose = () => {},
  closeSvg = () => {},
  addAssign,
  type,
  isOrganiser = () => false,
  hideGroups,
  isOpen,
}) => {
  const [data, setData] = useState({ persons: [], groups: [] });
  const [showMenu, setShowMenu] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [noOutside, setNoOutside] = useState(false);
  const [answersId, setAnswers] = useState({
    persons: addAssign ? addAssign.assignedUsers.map((el) => el.id) : [],
    groups: addAssign ? addAssign.assignedRoles.map((el) => el.id) : [],
  });
  const [answersTitle, setAnswersTitle] = useState({
    assignedRoles: addAssign ? addAssign.assignedRoles : [],
    assignedUsers: addAssign ? addAssign.assignedUsers : [],
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [inviteRole, setInviteRole] = useState("");
  const { id: idAudit } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });
  const isCorrectInviteEmail = useRegex(inputSearch.toLowerCase(), EMAIL_VALIDATION_PATTERN);
  const hasExactMatch = useMemo(() => {
    return data.persons.find(
      (person) => toFullName(person.user).toLowerCase() === inputSearch.toLowerCase()
    );
  }, [data.persons, inputSearch]);

  useEffect(() => {
    listTeamMembers(idAudit)
      .then((res) => {
        setData((prevState) => ({
          ...prevState,
          persons: res.teamMembers.filter((i) => !isOrganiser(i.user.email)),
        }));
      })
      .catch((error) => {
        console.log("error", error);
      });

    allListRoles(idAudit)
      .then((res) => {
        setRoleOptions(
          res.roles.map((role) => ({
            value: role.id,
            label: role.name,
          }))
        );
        setInviteRole(res.roles?.[0]?.id);
        setData((prevState) => ({
          ...prevState,
          groups: res.roles,
        }));
      })
      .catch((error) => {
        console.log("error", error);
      });
    // eslint-disable-next-line
  }, [idAudit]);

  useEffect(() => {
    const stringify = (arr) => arr.sort((a, b) => b - a).join(",");
    const flatten = (arr) => stringify(arr.map((i) => i.id));

    if (stringify(answersId.groups) !== flatten(addAssign.assignedRoles)) {
      setAnswers((state) => ({
        ...state,
        groups: addAssign.assignedRoles.map((el) => el.id),
      }));
      setAnswersTitle((state) => ({
        ...state,
        assignedRoles: addAssign.assignedRoles,
      }));
    }
    if (stringify(answersId.persons) !== flatten(addAssign.assignedUsers)) {
      setAnswers((state) => ({
        ...state,
        persons: addAssign.assignedUsers.map((el) => el.id),
      }));
      setAnswersTitle((state) => ({
        ...state,
        assignedUsers: addAssign.assignedUsers,
      }));
    }
    // eslint-disable-next-line
  }, [addAssign.assignedRoles, addAssign.assignedUsers]);

  const newData = (item, key) => {
    let newStateTitle;
    let keyTitle = key === "persons" ? "assignedUsers" : key === "groups" ? "assignedRoles" : null;
    const newStateId = {
      ...answersId,
      [key]: answersId[key].includes(item.id)
        ? answersId[key].filter((el) => el !== item.id)
        : [...answersId[key], item.id],
    };
    if (answersTitle[keyTitle].length > 0) {
      newStateTitle = {
        ...answersTitle,
        [keyTitle]: answersTitle[keyTitle].some((el) => el.id === item.id)
          ? answersTitle[keyTitle].filter((el) => el.id !== item.id)
          : [...answersTitle[keyTitle], item],
      };
    } else {
      newStateTitle = {
        ...answersTitle,
        [keyTitle]: [...answersTitle[keyTitle], item],
      };
    }

    setAnswers(newStateId);
    setAssignId(newStateId);
    setAssignTitle(newStateTitle);
    setAnswersTitle(newStateTitle);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNoOutside(false);
    if (isCorrectInviteEmail && !hasExactMatch) {
      try {
        const member = await inviteTeamMembers({ email: inputSearch, role: inviteRole }, idAudit);
        setData({
          ...data,
          groups: data.groups.map((el) =>
            el.id === inviteRole ? { ...el, teamMembers: [...el.teamMembers, member] } : el
          ),
          persons: [...data.persons, member],
        });
        setInputSearch("");
        newData(member, "persons");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const changeInviteRole = (_, value) => {
    setInviteRole(value);
  };
  let searchGroups = data.groups.filter((el) =>
    el.name.toLowerCase().includes(inputSearch.toLowerCase())
  );
  let searchPersons = data.persons.filter((el) =>
    toFullName(el.user).toLowerCase().includes(inputSearch.toLowerCase())
  );
  return (
    isOpen && (
      <OutsideClickHandler
        callback={() => {
          if (noOutside) return;
          onClose(false);
          closeSvg(false);
          type === "inline" && setShowMenu(false);
          setTimeout(() => {
            closeSvg(true);
          }, 200);
        }}
      >
        <div className='main-block'>
          <form onSubmit={handleSubmit} className='search-block'>
            <img src={search} alt='search' />
            <input
              value={inputSearch}
              placeholder={t("request_search")}
              onChange={({ target }) => {
                setInputSearch(target.value);
              }}
              {...(type === "inline" && { onFocus: () => setShowMenu(true) })}
            />
          </form>
          <div className='assign-scroll-wrapper'>
            {((type === "inline" && showMenu) || !type) && (
              <>
                {!hideGroups && searchGroups.length > 0 && (
                  <AssignSection
                    title={t("groups")}
                    type={"groups"}
                    renderIcon={(item) => item.name}
                    renderName={(item) => `${item.teamMembers.length} ${t("people")}`}
                    list={searchGroups}
                    onSelect={newData}
                    values={answersId.groups}
                  />
                )}
                {searchPersons.length > 0 && (
                  <AssignSection
                    title={t("people")}
                    type={"persons"}
                    list={searchPersons}
                    onSelect={newData}
                    values={answersId.persons}
                    renderIcon={(item) => toFullName(item.user)[0]}
                    renderName={(item) => toFullName(item.user)}
                  />
                )}
                {inputSearch && !hasExactMatch && (
                  <div className='table-search'>
                    <span className='title'>Add new</span>
                    <div
                      className={`row-search ${!isCorrectInviteEmail ? "row-disabled" : ""}`}
                      onClick={handleSubmit}
                    >
                      <div className='block-name'>
                        <PlusRoundedIcon />
                        <span className='title-name'>{inputSearch}</span>
                      </div>
                      <CustomAntDropdown
                        options={roleOptions}
                        value={inviteRole}
                        onChange={changeInviteRole}
                        setNoOutside={setNoOutside}
                        name='inviteRole'
                        style={{ width: "120px" }}
                        dropdownStyle={{ width: "100%" }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    )
  );
};

const AssignSection = ({ title, type, list, onSelect, values, renderIcon, renderName }) => (
  <div className='table-search'>
    <span className='title'>{title}</span>
    {list.map((item, index) => (
      <div
        className='row-search'
        key={index}
        onClick={() => {
          onSelect(item, type);
        }}
      >
        <div className='block-name'>
          {type === "persons" ? (
            <CustomAvatar user={item.user} />
          ) : (
            <span className={`icon-name-${type}`}>{renderIcon(item)}</span>
          )}
          <span className='title-name'>{renderName(item)}</span>
        </div>
        <div className={values.includes(item.id) ? "checkbox-active" : "checkbox"}>
          <img src={ok} alt='ok' />
        </div>
      </div>
    ))}
  </div>
);

export default AssignSearch;
