import { Button as AntdButton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";

import {
  downloadDocument,
  oneDocument,
  signDocument,
  syncDocument,
} from "../../../api/documentApi";
import { downloadFileFromURL } from "../../../utils/downloadFileFromURL";

import arrows from "../../../assets/admin/arrows.svg";
import { ArrowLeftIcon, CalendarIcon, DownloadRoundedSmallIcon } from "../../../assets/icons";
import { Button } from "../../../components/ui-components";
import toFullName from "../../../consts/toFullName";
import StartSigningPopup from "../../../components/StartSigningPopup";
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import FileExtension from "../../../Admin/components/FileExtension";
import EditableDocumentName from "../../../components/EditableDocumentName";
import DocumentViewComments from "./components/DocumentViewComments";
import FilePreview from "../../../components/FilePreview/FilePreview";
import DocumentSharePopup from "./components/DocumentSharePopup";

import "./pdfOpen.css";
import styles from "./DocumentView.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const DocumentView = () => {
  const history = useHistory();
  const { id: idAudit, documentId: idDocument } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const selector = useSelector((state) => state.user.user_role?.role);
  const user = useSelector((state) => state.user.user_data);

  const intervalIDRef = React.useRef(null);
  const [documentData, setDocumentData] = useState(false);
  const [commentAdd, setCommentAdd] = useState(false);
  const [isSignPopupOpen, setIsSignPopupOpen] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [mySignerStatus, setMySignerStatus] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const stopSync = React.useCallback(() => {
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }, []);

  const startSync = React.useCallback(() => {
    intervalIDRef.current = setInterval(() => {
      syncDocument(idAudit, idDocument).then((res) => {
        const newSignerStatus = res.document.signers.find(
          (signer) =>
            signer.teamMember?.user.email === user.email ||
            signer.auditorMember?.member.user.email === user.email
        )?.status;

        if (newSignerStatus !== mySignerStatus) {
          setMySignerStatus(newSignerStatus);
        }

        setDocumentData((state) => {
          const isInProgress = res.document.signers.some(
            (signer) => signer.status === "in_progress"
          );
          const areSignersEqual =
            JSON.stringify(res.document.signers.map((i) => i.status)) ===
            JSON.stringify(state.signers.map((i) => i.status));

          if (!areSignersEqual && !isInProgress) {
            stopSync();
            return {
              ...state,
              signers: res.document.signers,
            };
          }

          if (!areSignersEqual) {
            return {
              ...state,
              signers: res.document.signers,
            };
          }

          return state;
        });
      });
    }, 5000);
  }, []);

  useEffect(() => {
    return () => clearInterval(intervalIDRef.current); // to clean up on unmount
  }, []);

  const unfinishedSigners = documentData?.signers?.filter((signer) => signer?.status !== "signed");
  const inProgressSigner = unfinishedSigners?.find((signer) => signer?.status === "in_progress");
  const hasInProgressSigners = !!inProgressSigner;
  const signerUser = (inProgressSigner?.teamMember || inProgressSigner?.auditorMember?.member)
    ?.user;
  const mySigner = unfinishedSigners?.find(
    (signer) =>
      signer.teamMember?.user.email === user.email ||
      signer.auditorMember?.member.user.email === user.email
  );
  const isUnsigned = mySigner?.status === "unsigned" || mySigner?.status === "failed";

  useEffect(() => {
    oneDocument(idAudit, idDocument)
      .then((res) => {
        setDocumentData(res.document);
        const signerInProgress = res.document.signers.some(
          (signer) => signer.status === "in_progress"
        );
        if (signerInProgress) {
          startSync();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit, idDocument, commentAdd]);

  // add a scroll event listener to the window
  // useLayoutEffect(() => {
  // if (documentData) {
  //   const sidebar = document.getElementById("pdfSidebar");
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     const scrollDifference = 104 - scrollY;
  //     sidebar.style.height = `calc(100vh - ${scrollDifference > 0 ? scrollDifference : 0}px)`;
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }
  // }, [documentData]);

  const sendSignRequest = async (body) => {
    try {
      const sign = await signDocument(idAudit, idDocument, body);
      console.log({ sign });
      startSync();
    } catch (error) {
      stopSync();
      startSync();
      console.log("error", error);
    }
  };

  const handleOpenSignPopup = () => {
    setIsSignPopupOpen(true);
  };

  const handleOpenSharePopup = () => {
    setIsSharePopupOpen(true);
  };

  const handleUpdateName = (name) => {
    setDocumentData((p) => ({
      ...p,
      name,
    }));
  };

  const handleDownload = async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    try {
      const res = await downloadDocument(idAudit, idDocument);
      const result = await downloadFileFromURL(res, documentData.name || documentData.document);

      if (!result) window.open(res, "_blank");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    documentData && (
      <div className='main-wrapper-pdf'>
        <div className='first-block'>
          <div className='buttonExit-block'>
            <AntdButton icon={<ArrowLeftIcon />} onClick={history.goBack} />
            <span>{t("document_preview")}</span>
          </div>
          <div className='document-header-actions'>
            {hasInProgressSigners && (
              <div className='being-signed'>
                <Trans
                  t={t}
                  i18nKey='currently_signed'
                  values={{
                    name: toFullName(signerUser),
                  }}
                />
              </div>
            )}
            <Button
              primary
              color='lightBlue'
              style={{ width: "110px" }}
              onClick={handleOpenSharePopup}
            >
              {tGlobal("share")}
            </Button>
            <Button
              primary
              disabled={!isUnsigned || hasInProgressSigners || !mySigner}
              color='green'
              style={{ width: "118px" }}
              onClick={handleOpenSignPopup}
            >
              {t("sign")}
            </Button>
          </div>
        </div>
        <div className='content-block'>
          <StartSigningPopup
            mySignerStatus={mySignerStatus}
            sendSignRequest={sendSignRequest}
            ids={{ idAudit, idDocument }}
            isOpen={isSignPopupOpen}
            close={() => setIsSignPopupOpen(false)}
          />
          <div
            style={{
              minHeight: documentData.status ? "" : "0px",
              height: documentData.status ? "" : "max-content",
            }}
            id={"pdfSidebar"}
            className='info-block'
          >
            <div className='info-block-row'>
              <div className={styles.fileNameWrapper}>
                <FileExtension document={documentData.document} />
                <EditableDocumentName
                  id={documentData.id}
                  className={styles.fileName}
                  name={documentData.name}
                  onSave={handleUpdateName}
                />
              </div>
              <Button
                onClick={handleDownload}
                secondary
                color='blue'
                className={styles.downloadButton}
              >
                <DownloadRoundedSmallIcon />
                <span>{t("download_file")}</span>
              </Button>
            </div>

            {documentData.signers?.length > 0 && (
              <div className='info-block-row pd-by'>
                <span className='title-table'>{t("require_signatures")}</span>
                {documentData.signers.map((signer) => {
                  const currentUser = signer.teamMember?.user || signer.auditorMember?.member.user;
                  const isMe = currentUser.email === user.email;
                  return (
                    <div className='block-by block-bordered'>
                      <div className='block-by-content'>
                        <CustomAvatar user={currentUser} width={24} height={24} />
                        <span className={`content-table signer-name ${isMe ? "my-name" : ""}`}>
                          {toFullName(currentUser)}
                        </span>
                        {isMe && <span className='content-table signer-me'>(me)</span>}
                      </div>
                      <span className={`signature-status ${signer.status}`}>
                        {signer.status.split("_").join(" ")}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}

            <div className='info-block-row pd-by'>
              <span className='title-table'>{t("uploaded_by")}</span>
              <div className='block-by block-bordered'>
                <div className='block-by-content'>
                  <CustomAvatar user={documentData.addedBy} width={24} height={24} />
                  <span style={{ marginTop: 0 }} className='content-table'>
                    {toFullName(documentData.addedBy)}
                  </span>
                </div>
              </div>
            </div>
            <div className='info-block-row'>
              <span className='title-table'>{t("uploaded_on")}</span>
              <span className='content-table'>
                <CalendarIcon />
                {dayjs(documentData.addedAt).format("D MMM YYYY, HH.mm")}
              </span>
            </div>
            {documentData.uploaded === true && (
              <div className='button-arrows_uploadPage'>
                <div
                  className='button-arrows'
                  onClick={() => {
                    if (selector?.requestsAccess) {
                      history.push(`/dashboard/${idAudit}/requests/${documentData.request.id}`);
                    }
                  }}
                >
                  <img src={arrows} alt='Download' />
                  <span className='button-arrows_inner'>{documentData.request.name}</span>
                </div>
              </div>
            )}
            <DocumentViewComments
              data={documentData.comments}
              onAfterSubmit={() => setCommentAdd(!commentAdd)}
            />
          </div>
          <div className='pdf-block'>
            <div className='pdf-view'>
              <span>{t("document_preview")}</span>
              <FilePreview url={documentData.document} />
            </div>
          </div>
        </div>
        <DocumentSharePopup
          document={documentData}
          setDocument={setDocumentData}
          open={isSharePopupOpen}
          onCancel={() => setIsSharePopupOpen(false)}
        />
      </div>
    )
  );
};

export default DocumentView;
