import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomAvatar from "../../../../../../components/CustomAvatar/CustomAvatar";
import { RootState } from "../../../../../../store";
import { User } from "../../../../../../types/user";
import { getFullNameFromUserData } from "../../../../../../utils/general";

import styles from "./CommentBlock.module.scss";

export type CommentBlockProps = {
  tKeyPrefix: "requests" | "meetings";
  text?: string;
  user?: User;
  createdAt: string;
  empty?: boolean;
};

export default function CommentBlock({
  tKeyPrefix,
  text,
  user,
  createdAt,
  empty,
}: CommentBlockProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: tKeyPrefix });
  const { user_data } = useSelector((state: RootState) => state.user);

  const dateTitle = dayjs(createdAt).format("DD MMM YYYY");

  return (
    <div className={styles.commentWrapper}>
      {empty ? (
        <>
          <span className={styles.dot} />
          <p className={styles.title}>
            {t("request_generated", { userName: getFullNameFromUserData(user_data) })}
          </p>
          <p className={styles.dateTitle}>{dateTitle}</p>
        </>
      ) : (
        <>
          {user && <CustomAvatar user={user} />}
          <div className={styles.textWrapper}>
            <div className={styles.titlesWrapper}>
              <p className={styles.title}>{getFullNameFromUserData(user)}</p>
              <p className={styles.dateTitle}>{dateTitle}</p>
            </div>
            <p className={styles.comment}>{text}</p>
          </div>
        </>
      )}
    </div>
  );
}
