import helper from "../consts/helper";

export const saveLastVisitedPage = () => {
  if (localStorage.getItem(helper.PREVIOUS_VISITED_PAGE) === window.location.pathname) {
    localStorage.removeItem(helper.PREVIOUS_VISITED_PAGE);
    localStorage.removeItem(helper.LAST_VISITED_PAGE);
  } else {
    localStorage.setItem(helper.LAST_VISITED_PAGE, window.location.pathname);
  }
};
