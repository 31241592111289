import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import { User } from "../types/user";
import { getFullNameFromUserData } from "./general";

export const appendUser = (editorState: EditorState, user: User) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
  const blockText = currentBlock.getText();

  const atIndex = blockText.lastIndexOf("@", selectionState.getStartOffset());

  if (atIndex !== -1) {
    const updatedSelection = selectionState.merge({
      anchorOffset: atIndex,
      focusOffset: selectionState.getStartOffset(),
    });

    const contentStateWithEntity = contentState.createEntity("MENTION", "IMMUTABLE", {
      user,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithMention = Modifier.replaceText(
      contentStateWithEntity,
      updatedSelection,
      `${getFullNameFromUserData(user) || user.email}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    return EditorState.push(editorState, contentStateWithMention, "insert-characters");
  }

  return editorState;
};

export const appendImage = (editorState: EditorState, url: string) => {
  const contentState = editorState.getCurrentContent();

  const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getCurrentContent().getSelectionAfter()
  );
};

export const getCurrentBlock = (state: EditorState) => {
  const selection = state.getSelection();
  const content = state.getCurrentContent();
  return content.getBlockForKey(selection.getStartKey());
};

export const getCurrentColor = (editorState: EditorState) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const colorStyle = currentStyle.find((style) => (style ? style.startsWith("color-") : false));
  return colorStyle ? colorStyle.replace("color-", "") : null;
};
