import API from "../../api/API";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userLogin = createAsyncThunk("/user/login", async (body) => {
  try {
    const response = await API().post("/user/login", body);
    return response.data;
  } catch (error) {
    return error;
  }
});
export const userPermissionsRole = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/team-members/me`);
    return response.data;
  } catch (error) {
    return error;
  }
};
