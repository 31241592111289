import React, { useEffect, useState } from "react";
import "../ui-components/CustomAntDropdown/CustomAntDropdown.scss";
import { Dropdown } from "antd";
import { ThreeDotsDarkIcon } from "../../assets/icons";
import Button from "./Button/Button";
import DropdownMenuOverlay from "./DropdownMenuOverlay";
import OutsideClickHandler from "../../consts/detectOutSideClick";

interface ActionDropdownProps {
  options: { label: string; value: string }[];
  value?: string;
  overlayStyle?: { width: number };
  placement?: any;
  onChange: (name: string, value: string) => void;
  setNoOutside?: (isOpen: boolean) => void;
  type?: string;
  headerStyle?: React.CSSProperties;
  name?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  error?: boolean;
  t?: (key: string) => string;
}

const ActionDropdown = ({
  options,
  value = "",
  overlayStyle = { width: 200 },
  placement = "bottomRight",
  onChange,
  setNoOutside,
  type,
  headerStyle,
  name = "",
  style,
  disabled,
  error,
  t,
}: ActionDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (e: any) => {
    // e.domEvent.preventDefault();
    if (onChange) {
      onChange(name, e.key);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setNoOutside?.(isOpen);
  }, [isOpen]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  const menu = (
    <OutsideClickHandler callback={handleOutsideClick}>
      <DropdownMenuOverlay
        handleOptionClick={handleOptionClick}
        options={options}
        value={value}
        t={t}
      />
    </OutsideClickHandler>
  );

  return (
    <div
      className={`${type} ${disabled ? "disabled" : ""} ${isOpen ? "open" : ""} ${
        error ? "error" : ""
      }`}
      style={style}
      onClick={handleOpen}
    >
      <Dropdown
        overlayStyle={overlayStyle}
        placement={placement}
        trigger={["click"]}
        overlay={menu}
        visible={isOpen}
      >
        <Button
          handleClick={() => {}}
          color={"gray"}
          tertiary
          icon={<ThreeDotsDarkIcon />}
          style={headerStyle}
        />
      </Dropdown>
    </div>
  );
};

export default ActionDropdown;
