export const assertionColors = [
  {
    background: "#49BACA33",
    color: "#0F8594",
  },
  {
    background: "#6DB33333",
    color: "#569720",
  },
  {
    background: "#F17F1633",
    color: "#F17F16",
  },
  {
    background: "#D9474733",
    color: "#D94747",
  },
  {
    background: "#D6B02933",
    color: "#C19B14",
  },
  {
    background: "#304BDB33",
    color: "#595FF5",
  },
  {
    background: "#DB4FF233",
    color: "#D52FFF",
  },
  {
    background: "#003E471A",
    color: "#003E47CC",
  },
];
