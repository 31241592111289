import { message, Tabs } from "antd";
import type { TabsProps } from "antd";
import { useState } from "react";

import StyledModal from "../../../../../components/ui-components/StyledModal";
import DocumentInviteGuestsForm from "./DocumentInviteGuestsForm";
import DocumentInviteOverview from "./DocumentInviteOverview";

import styles from "./DocumentSharePopup.module.scss";
import { IDocument, IDocumentAccess } from "../../../../../interfaces/Document";

interface DocumentSharePopupProps {
  onCancel: () => void;
  document: IDocument;
  setDocument: (document: IDocument) => void;
}

export default function DocumentSharePopup({
  onCancel,
  document,
  setDocument,
  ...props
}: DocumentSharePopupProps) {
  const [tab, setTab] = useState("overview");

  const handleSetTab = (key: string) => {
    setTab(key);
  };

  const handleAddUser = (access: IDocumentAccess) => {
    setDocument({
      ...document,
      access: [...document.access, access],
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/public/document/${document.id}/${document.accessCode}/access`
      )
      .then(() => message.success("Link copied to clipboard"));
  };

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <DocumentInviteOverview
          document={document}
          setDocument={setDocument}
          onClose={onCancel}
          handleAddUser={handleAddUser}
          handleCopyLink={handleCopyLink}
        />
      ),
    },
    {
      key: "invite_guests",
      label: "Invite Guests",
      children: (
        <DocumentInviteGuestsForm
          handleSetTab={handleSetTab}
          handleAddUser={handleAddUser}
          handleCopyLink={handleCopyLink}
        />
      ),
    },
  ];

  return (
    <StyledModal
      width={720}
      title='Share'
      className={styles.root}
      classNames={{
        body: styles.body,
      }}
      footer={null}
      onCancel={onCancel}
      {...props}
    >
      <Tabs className={styles.tabs} activeKey={tab} items={items} onChange={handleSetTab} />
    </StyledModal>
  );
}
