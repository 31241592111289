import React, { useEffect, useState } from "react";
import { Button } from "./ui-components";
import Popup from "./ui-components/Popup/Popup";
import { GreenTickmark, RedCross } from "../assets/icons";
import { verifySwisscom } from "../api/documentApi";
import { LeapFrog } from "@uiball/loaders";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";

const StartSigningPopup = ({ close, isOpen, ids, sendSignRequest, mySignerStatus }) => {
  const history = useHistory();
  const [count, setCount] = useState(3);
  const [step, setStep] = useState("start");

  const handleClose = () => {
    close();
    setStep("start");
  };

  useEffect(() => {
    if (count === 0) {
      close();
    }
  }, [count]);

  useEffect(() => {
    let timer;
    if (mySignerStatus === "signed") {
      setStep("signed");
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (mySignerStatus === "failed") {
      setStep("error");
    }

    return () => clearInterval(timer);
  }, [mySignerStatus]);

  const startSigning = async () => {
    try {
      const res = await verifySwisscom(ids.idAudit, ids.idDocument);
      if (res.serialNumber) {
        try {
          await sendSignRequest({ serialNumber: res.serialNumber });
        } catch (e) {
          console.log(e);
        }
      } else if (res.message === "Swisscom account not found") {
        setStep("signing-app");
      } else if (res.message === "Incorrect personal data") {
        setStep("personal-data");
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    if (isOpen) {
      startSigning();
    }
  }, [isOpen]);

  const handleRetry = async () => {
    setStep("start");
    await startSigning();
  };

  const handleOpenProfile = () => {
    history.push("/account/settings");
    handleClose();
  };

  const handleSetupSwisscom = () => {
    window.open("https://srsident.trustservices.swisscom.com/en/");
    handleClose();
  };

  const PopupContent = () => {
    switch (step) {
      case "start":
        return (
          <>
            <div className='sign-progress'>
              <LeapFrog color='#0F8594' />
              <span className='status'>In progress</span>
            </div>
            <span className={"sign-bold-text"}>
              Please check your device for further instructions
            </span>
            <span className={"sign-opacue-text"}>Provide by Swisscom</span>
            <span onClick={handleClose} className={"sign-red-text"}>
              Cancel
            </span>
          </>
        );
      case "signed":
        return (
          <>
            <div className='sign-progress signed'>
              <GreenTickmark />
              <span className='status'>Signed</span>
            </div>
            <span className={"sign-bold-text"}>You have successfully signed the document</span>
            <span className={"sign-opacue-text"}>Provide by Swisscom</span>
            <Typography.Text className='countText'>Will close in {count}</Typography.Text>
          </>
        );
      case "error":
        return (
          <>
            <div className='sign-progress error'>
              <RedCross />
              <span className='status'>TIMED OUT</span>
            </div>
            <span className={"sign-bold-text"}>Sorry, the signing was not sucessful</span>
            <span className={"sign-opacue-text"}>Provide by Swisscom</span>
            <div className='sign-action-row'>
              <Button handleClick={handleClose} color={"red"} secondary>
                Cancel
              </Button>
              <Button handleClick={handleRetry} color={"blue"} primary>
                Try again
              </Button>
            </div>
          </>
        );
      case "signing-app":
        return (
          <>
            <span className={"sign-bold-text"}>
              It seems like you have not setup the signing app yet
            </span>
            <span className={"sign-opacue-text"}>
              To be able to sign documents we need to make sure your identity is verified. Please
              setup the signing app, before continuing with the signing process
            </span>
            <Button handleClick={handleSetupSwisscom} color={"blue"} large primary>
              Setup
            </Button>
            <span onClick={handleClose} className={"sign-red-text"}>
              Cancel
            </span>
          </>
        );
      case "personal-data":
        return (
          <>
            <span className={"sign-bold-text"}>
              It seems like your profile data doesn't match the data in Swisscom
            </span>
            <span className={"sign-opacue-text"}>
              Please check your profile and make sure the data entered is correct.
            </span>
            <Button handleClick={handleOpenProfile} color={"blue"} large primary>
              Go to my account
            </Button>
            <span onClick={handleClose} className={"sign-red-text"}>
              Cancel
            </span>
          </>
        );
    }
  };
  return (
    <Popup isOpen={isOpen} title='Signing in process' onClose={handleClose}>
      <div className={`popup-body sign-popup ${step}`}>
        <PopupContent />
      </div>
    </Popup>
  );
};

export default StartSigningPopup;
