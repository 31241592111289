import { Tag } from "antd";
import { useTranslation } from "react-i18next";

import { STATUS_COLORS } from "../../consts/enums";
import { User } from "../../types/user";
import { getFullNameFromUserData } from "../../utils/general";

import { Tag as CustomTag } from "../ui-components";
import { TagProps } from "../ui-components/Tag/Tag";
import CustomAvatar from "../CustomAvatar";

import styles from "./UserBadge.module.scss";

type UserBadgeProps = {
  signStatus?: string;
  company?: string;
  user: User;
};

export default function UserBadge({ company, signStatus, user }: UserBadgeProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const tagColor = signStatus ? (STATUS_COLORS[signStatus] as TagProps["color"]) : "gray";

  return (
    <div className={styles.wrapper}>
      <CustomAvatar user={user} />
      <div className={styles.content}>
        <div className={styles.nameWrapper}>
          <div className={styles.nameStack}>
            <div className={styles.name}>{getFullNameFromUserData(user) || user.email}</div>
            {user.role === "guest" && <Tag color='yellow'>Guest</Tag>}
          </div>
          {signStatus && (
            <CustomTag nowrap color={tagColor}>
              {t(signStatus)}
            </CustomTag>
          )}
        </div>
        {company && <Tag>{company}</Tag>}
      </div>
    </div>
  );
}
