import i18next from "i18next";

import Homepage from "../pages/Homepage";
import Revision from "../pages/Revision";
import Imprint from "../pages/Contact/Imprint";
import DataProtection from "../pages/Contact/DataProtection";
import Team from "../pages/Team";
import Partners from "../pages/Partners";
import Contact from "../pages/Contact";
import Faq from "../pages/Faq";
import Overview from "../Admin/pages/Overview/Overview";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import Documents from "../pages/dashboard/Documents";
import Login from "../pages/Login";
import ThankYou from "../pages/ThankYou";
import LoginPassword from "../pages/LoginPassword/LoginPassword.js";
import LoginPhone from "../pages/LoginPhone";
import LoginPhoneSms from "../pages/LoginPhoneSms";
import LoginPhoneDoubleSms from "../pages/LoginPhoneDoubleSms/LoginPhoneDoubleSms";
import Audits from "../pages/dashboard/Audits/Audits";
import AccountSettings from "../pages/account/AccountSettings";
import Settings from "../pages/dashboard/Settings";
import SettingsTeamEdit from "../Admin/SettingsAudits/settingsTeamEdit";
import PersonalRoleNewEdit from "../Admin/SettingsAudits/settingsPersonalRole/PersonalRoleNewEdit";
import AddNewTeamMember from "../Admin/SettingsAudits/settingTeam/AddNewTeamMember.js";
import ForgotPassword from "../Admin/pages/ForgotPassword/ForgotPassword";
import DocumentView from "../pages/dashboard/DocumentView/DocumentView";
import LoginEmailSend from "../pages/LoginEmailSend/LoginEmailSend";
import RequestPageWrapper from "../Admin/pages/Requests/Request";
import Meetings from "../pages/dashboard/Meetings";
import StatusPage from "../pages/dashboard/Status";
import CompanyPage from "../Admin/pages/Company/CompanyPage";
import Notifications from "../pages/Notifications";
import CompanySettings from "../Admin/pages/CompanySettings";
import PlatformAdmin from "../Admin/Superadmin/PlatformAdmin";
import ViewCompany from "../Admin/Superadmin/PlatformAdmin/ViewCompany/ViewCompany";

import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";

export const Navigation = {
  mainNavigation: [
    {
      exact: true,
      path: "/",
      theme: "green",
      name: "",
      main: () => <Homepage />,
    },
    {
      exact: true,
      path: "/login/phone",
      theme: "green",
      name: "",
      main: () => <LoginPhone />,
    },
    {
      exact: true,
      path: "/login/phone/sms",
      theme: "green",
      name: "",
      main: () => <LoginPhoneSms />,
    },
    {
      exact: true,
      path: "/login/phone/double_sms",
      theme: "green",
      name: "",
      main: () => <LoginPhoneDoubleSms />,
    },
    {
      exact: true,
      path: "/audits",
      theme: "green",
      name: "",
      main: () => <Audits />,
    },

    {
      exact: true,
      path: "/revision",
      name: "revision",
      theme: "gray",
      main: () => <Revision />,
    },
    {
      exact: true,
      path: "/team",
      name: "team",
      theme: "gray",
      main: () => <Team />,
    },
    {
      exact: true,
      path: "/partner",
      name: "partner",
      theme: "gray",
      main: () => <Partners />,
    },
    {
      exact: true,
      path: "/kontakt/:slug?",
      name: "kontakt",
      theme: "gray",
      main: () => <Contact />,
    },
    {
      exact: true,
      name: "faq",
      path: "/faq",
      theme: "gray",
      main: () => <Faq />,
    },
    {
      exact: true,
      name: "imprint",
      path: "/imprint",
      theme: "gray",
      main: () => <Imprint />,
    },
    {
      exact: true,
      name: "data_protection",
      path: "/data_protection",
      theme: "gray",
      main: () => <DataProtection />,
    },
    {
      exact: true,
      path: "/dashboard/:slug?",
      main: () => <DashboardLayout />,
    },
    {
      exact: true,
      path: "/login",
      main: () => <Login />,
    },
    {
      exact: true,
      name: "thank-you",
      theme: "gray",
      path: "/thank-you",
      main: () => <ThankYou />,
    },
    {
      exact: true,
      path: "/login/new-password",
      main: () => <LoginPassword />,
    },
    {
      exact: true,
      path: "/login/email",
      main: () => <ForgotPassword />,
    },
    {
      exact: true,
      path: "/login/email/send",
      main: () => <LoginEmailSend />,
    },
    {
      exact: true,
      path: "/account/settings",
      main: () => <AccountSettings />,
    },

    // <Route exact path='/thank-you' component={ThankYou}/>
  ],

  adminPlatform: [
    {
      exact: true,
      path: "/audits",
      main: Audits,
    },
    {
      exact: true,
      path: "/company/:id/:path",
      main: CompanyPage,
    },
    {
      exact: true,
      path: "/notifications",
      main: Notifications,
    },
    {
      exact: true,
      path: "/account/settings",
      main: AccountSettings,
    },
    {
      exact: true,
      path: "/company/settings",
      main: CompanySettings,
    },
    {
      exact: true,
      path: "/admin/:path",
      main: PlatformAdmin,
    },
    {
      exact: true,
      path: "/admin/companies/:type/:id/:path",
      main: ViewCompany,
    },
  ],

  adminDashboardMain: [
    {
      exact: true,
      path: "/dashboard/:id/overview",
      name: i18next.t("dashboard:global:dashboard"),
      icon: <DashboardIcon />,
      param: "overview",
      disabled: "overviewAccess",
      main: () => <Overview />,
    },

    {
      exact: true,
      name: i18next.t("dashboard:global:requests"),
      path: "/dashboard/:id/requests/:requestId",
      param: "requests",
      disabled: "requestsAccess",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13.8999 13.2796H7.69995V10.4202C7.69995 9.9999 7.23979 9.7999 6.96011 10.0804L2.57971 14.46C2.40002 14.6397 2.40002 14.96 2.57971 15.1397L6.95931 19.5193C7.23899 19.7989 7.69915 19.5794 7.69915 19.1794L7.69993 16.3396H13.8999C14.0999 16.3396 14.2398 16.1795 14.2398 15.9998V13.6396C14.2398 13.4396 14.0796 13.2794 13.8999 13.2794L13.8999 13.2796Z'
            fill='white'
            fillOpacity='0.8'
          />
          <path
            d='M21.4203 8.86021L17.0399 4.46021C16.7603 4.18053 16.3001 4.40006 16.3001 4.80005V7.67965H10.1001C9.90009 7.67965 9.76025 7.83981 9.76025 8.01949V10.3797C9.76025 10.5797 9.92041 10.7195 10.1001 10.7195H16.3001V13.5797C16.3001 14.0001 16.7603 14.2001 17.0399 13.9196L21.4195 9.53997C21.6 9.36028 21.6 9.03997 21.4203 8.86029L21.4203 8.86021Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      ),
      main: () => <RequestPageWrapper />,
    },
    {
      exact: true,
      path: "/dashboard/:id/status",
      name: i18next.t("dashboard:global:status"),
      disabled: "statusAccess",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.7398 4.56021H5.2602C3.73988 4.56021 2.5 5.80005 2.5 7.32041V16.6602C2.5 18.1805 3.73984 19.4204 5.2602 19.4204H18.7398C20.2601 19.4204 21.5 18.1806 21.5 16.6602V7.32041C21.5 5.80009 20.2602 4.56021 18.7398 4.56021ZM17.6 13H16.0796L14.6195 15.6C14.4398 15.9203 14.1195 16.1 13.7593 16.1C13.3992 16.1 13.0593 15.9 12.8992 15.6L10.2602 10.9204L9.38048 12.4806C9.20001 12.8001 8.8797 13.0001 8.52032 13.0001H6.39992C5.86008 13.0001 5.39992 12.5603 5.39992 12.0001C5.39992 11.44 5.83976 11.0001 6.39992 11.0001H7.92024L9.3804 8.40011C9.74056 7.77979 10.7601 7.77979 11.1202 8.40011L13.7406 13.0797L14.6203 11.5196C14.8 11.2 15.1203 11 15.4797 11H17.6001C18.1399 11 18.6001 11.4399 18.6001 12C18.6001 12.5602 18.1399 13 17.6001 13H17.6Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      ),
      param: "status",
      main: () => <StatusPage />,
    },
    {
      exact: true,
      path: "/dashboard/:id/documents",
      name: i18next.t("dashboard:global:documents"),
      disabled: "documentAccess",
      icon: (
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M16.6 6.0914L12.575 2.0664C12.1062 1.59764 11.4711 1.33358 10.8078 1.3328H6.5C5.11876 1.3328 4 2.45234 4 3.8328V15.5C4 16.8805 5.11876 18 6.5 18H14.8328C16.214 18 17.3328 16.8805 17.3328 15.5V7.8578C17.3328 7.1953 17.0687 6.56014 16.6 6.0914Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      ),
      param: "documents",
      main: () => <Documents />,
    },
    {
      exact: true,
      path: "/dashboard/:id/meetings/:meetingId",
      name: i18next.t("dashboard:global:meetings"),
      disabled: "meetingAccess",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.3183 5.66417H17.2383V4.49417C17.2383 4.09831 16.9142 3.77417 16.5183 3.77417H14.9525C14.5566 3.77417 14.2325 4.09831 14.2325 4.49417V5.64588H9.75012V4.49417C9.75012 4.09831 9.42598 3.77417 9.03012 3.77417H7.46427C7.06841 3.77417 6.74427 4.09831 6.74427 4.49417V5.64588H5.79012C4.49427 5.64588 3.43176 6.70759 3.43176 8.00424V17.8326C3.43176 19.1285 4.49347 20.191 5.79012 20.191H18.1743C19.4702 20.191 20.5327 19.1292 20.5327 17.8326L20.532 7.87842C20.5502 6.65428 19.5602 5.66424 18.3185 5.66424L18.3183 5.66417ZM18.7683 17.85C18.7683 18.1741 18.5166 18.4258 18.1925 18.4258H5.8083C5.48416 18.4258 5.23245 18.1741 5.23245 17.85V10.47H18.7683L18.7683 17.85Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      ),
      param: "meetings",
      main: () => <Meetings />,
    },
    {
      exact: true,
      path: "/dashboard/:id/settings",
      name: i18next.t("dashboard:global:settings"),
      icon: (
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.4996 1.61571C9.57149 1.07992 8.42817 1.07992 7.50009 1.61571L3.35523 4.00917C2.42711 4.54496 1.85547 5.53496 1.85547 6.60729V11.3928C1.85547 12.465 2.42711 13.455 3.35523 13.9909L7.50009 16.3843C8.4282 16.9201 9.57153 16.9201 10.4996 16.3843L14.6445 13.9909C15.5726 13.4551 16.1442 12.4651 16.1442 11.3928V6.60729C16.1442 5.53503 15.5726 4.54503 14.6445 4.00917L10.4996 1.61571ZM8.99985 11.2498C10.2423 11.2498 11.2498 10.2423 11.2498 8.99985C11.2498 7.75743 10.2423 6.74985 8.99985 6.74985C7.75744 6.74985 6.74985 7.75743 6.74985 8.99985C6.74985 10.2423 7.75744 11.2498 8.99985 11.2498Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      ),
      param: "settings",
      main: () => <Settings />,
    },
    {
      exact: true,
      path: "/dashboard/:id/team-member/edit/:idTeam",
      main: () => <SettingsTeamEdit />,
    },
    {
      exact: true,
      path: "/dashboard/:id/team-member/add",
      main: () => <AddNewTeamMember />,
    },
    {
      exact: true,
      path: "/dashboard/:id/team-role/edit/:idRole",
      main: () => <PersonalRoleNewEdit />,
    },
    {
      exact: true,
      path: "/dashboard/:id/role/add",
      main: () => <PersonalRoleNewEdit />,
    },
    {
      exact: true,
      path: "/dashboard/:id/documents/:documentId",
      name: "documentView",
      main: () => <DocumentView />,
    },
  ],
};

export const privatePaths = [
  ...Navigation.adminPlatform.map((route) => route.path),
  ...Navigation.adminDashboardMain.map((route) => route.path),
];
