import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import file from "../assets/admin/file.svg";
import "./styles/newDocument.css";
import CheckData from "./CheckData";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AssignSearch from "./AssignSearch";
import { AssignButton } from "../Admin/pages/components";
import AvatarBlock from "../Admin/pages/Requests/components/NewRequest/AvatarBlock/AvatarBlock";
import { useTranslation } from "react-i18next";
import CustomSwitch from "./ui-components/Switch/Switch";
import toFullName from "../consts/toFullName";
import { useDocuments } from "../hooks/services/useDocuments";

const EditDocument = ({ docName, docFile, closeDrop, id, requiresSignature, assigners }) => {
  const [activeData, setActiveData] = useState(false);
  const user = useSelector((state) => state.user.user_data);
  const userRole = useSelector((state) => state.user.user_role);
  const isOrganiser = (email) => email === user.email;
  const { id: idAudit } = useParams();
  const [assignOpen, setAssignOpen] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState(assigners);
  const [signMyself, setSignMyself] = useState(
    !!assigners.find(
      (u) => u.teamMember?.userId === user.id || u.auditorMember?.member.userId === user.id
    )
  );
  const [data, setData] = useState({
    name: docName,
    file: docFile,
    requiresSignature: requiresSignature,
  });
  const { updateDocument } = useDocuments();

  useEffect(() => {
    setActiveData(data.name !== docName && data.name.length > 0);
  }, [data, docName]);

  useEffect(() => {
    const assignedUserIds = assignedUsers.map(
      (u) => u.teamMember?.userId || u.auditorMember?.member.userId
    );
    const assignerIds = assigners.map(
      (el) => el.teamMember?.userId || el.auditorMember?.member.userId
    );
    setActiveData(JSON.stringify(assignerIds) !== JSON.stringify(assignedUserIds));
  }, [assignedUsers, assigners]);

  const removeAssignee = (userId) => {
    setAssignedUsers((state) =>
      state.filter(
        (item) =>
          (!item.teamMember || item.teamMember?.userId !== userId) &&
          (!item.auditorMember || item.auditorMember?.member.userId !== id)
      )
    );
  };

  const handleAssignOpen = () => {
    setAssignOpen(true);
  };

  const handleAssignedUsers = ({ assignedUsers: newAssignedUsers }) => {
    const populateUsers = newAssignedUsers.map((u) => {
      let assigner = assigners.find(
        (a) => a.teamMember?.userId === u.userId || a.auditorMember?.member.userId === u.userId
      );
      if (!assigner) {
        assigner = {
          teamMember: u,
        };
      }
      return assigner;
    });
    setAssignedUsers(populateUsers);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const signers = assignedUsers.map(({ teamMember, auditorMember }) => ({
      userId: (teamMember || auditorMember?.member)?.userId,
      memberId: teamMember?.id,
      auditorId: auditorMember?.id,
    }));

    const memberId = userRole.member?.id || (!userRole.auditor ? userRole.id : null);
    const auditorId = userRole.auditor?.id;
    const includesMyself = signers.some((u) => u.userId === user.id);

    if (signMyself && !includesMyself) {
      signers.push({
        memberId,
        auditorId,
      });
    }

    if (!signMyself && includesMyself) {
      const index = signers.findIndex((u) => u.userId === user.id);
      if (index > -1) signers.splice(index, 1);
    }

    if (activeData) {
      closeDrop(null);
      const requiresSignature = signers.length > 0;
      updateDocument({
        body: {
          name: data.name,
          requiresSignature,
          signers: JSON.stringify(signers),
        },
        documentId: id,
        auditId: idAudit,
      });
    }
  };

  const editData = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeSwitch = () => {
    setSignMyself((prev) => !prev);
    setActiveData(true);
  };

  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className='sub_menu_document'>
      <div className='settings_right-audit'>
        <span className='popup-title'>{t("edit_document")}</span>
        <hr className='documents_line-create' />
        <div className='block-input_edit'>
          <div className='block-row_edit'>
            <span className='title-input'>{t("upload_file")}</span>
            <div className='file-down-block'>
              <div className='file-down-row-block'>
                <div className='file-down-name-blocks'>
                  <img className='file-down-name-blockFile' src={file} alt='File' />
                  <span className='title-file-name'> {docFile}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='block-row_edit'>
            <span className='title-input'>{t("filename")}</span>
            <input
              className={"inputNewDocuments"}
              placeholder='e.g. Angebot'
              value={data.name}
              onChange={({ target }) => {
                editData("name", target.value);
              }}
            />
          </div>
          <div className='block-row_edit'>
            <span className='title-input'>{t("signature")}</span>
            <FormGroup>
              <div className={"require-signature-block"}>
                <div className={"require-signature-row"}>
                  {assignedUsers
                    .filter(
                      (u) =>
                        (!u.teamMember || u.teamMember?.userId !== user.id) &&
                        (!u.auditorMember || u.auditorMember?.member.userId !== user.id)
                    )
                    .map((assignedUser) => (
                      <AvatarBlock
                        key={assignedUser.id}
                        removable
                        onRemove={() =>
                          removeAssignee(
                            assignedUser.teamMember?.userId ||
                              assignedUser.auditorMember?.member.userId
                          )
                        }
                        name={toFullName(
                          assignedUser.teamMember?.user || assignedUser.auditorMember?.member.user
                        )}
                        user={
                          assignedUser.teamMember?.user || assignedUser.auditorMember?.member.user
                        }
                      />
                    ))}

                  <AssignButton handleAssignOpen={handleAssignOpen} />
                </div>
                <div className={"upload-document-signer"}>
                  <AssignSearch
                    isOpen={assignOpen}
                    onClose={() => {
                      setAssignOpen(false);
                    }}
                    setAssignId={() => {}}
                    isOrganiser={isOrganiser}
                    setAssignTitle={handleAssignedUsers}
                    hideGroups
                    addAssign={{
                      assignedRoles: [],
                      assignedUsers: assignedUsers.map(
                        (u) => u.teamMember || u.auditorMember?.member
                      ),
                    }}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
          <div className='block-row_edit' style={{ alignItems: "center" }}>
            <span className='title-input'>{t("my_signature")}</span>
            <FormGroup style={{ marginLeft: "6px" }}>
              <div className={"require-signature-block"}>
                <CustomSwitch
                  checked={signMyself}
                  onChange={handleChangeSwitch}
                  labelPlacement='end'
                  label={t("sign_by_my_self")}
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <hr className='documents_line-create' />
        <div className='addAuditBtn'>
          <CheckData
            discard={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              closeDrop(null);
            }}
            onClick={onSubmit}
            text={tGlobal("save")}
            activeBtn={activeData}
          />
        </div>
      </div>
    </div>
  );
};

export default EditDocument;
