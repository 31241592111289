import React from "react";
import classes from "./Warning.module.scss";

const Warning = ({ children, color = "orange", ...props }) => {
  const rootClasses = [classes.root, classes[color]].join(" ");
  return (
    <div className={rootClasses} {...props}>
      {children}
    </div>
  );
};

export default Warning;
