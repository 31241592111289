import { ReactNode } from "react";

import { User } from "../../../../../types/user";

import UserBadge from "../../../../../components/UserBadge";
import StyledTooltip from "../../../../../components/ui-components/StyledTooltip";

import { Label } from "../../../../../components/ui-components";

import styles from "./DocumentRowUsersTooltip.module.scss";

type DocumentRowUsersTooltipProps = {
  children: ReactNode;
  signStatus?: string;
  title?: string;
  users: Array<User>;
};

export default function DocumentRowUsersTooltip({
  children,
  title,
  users,
  signStatus,
}: DocumentRowUsersTooltipProps) {
  return (
    <StyledTooltip
      placement='bottomLeft'
      title={
        users?.length > 0 && (
          <>
            <Label className={styles.title}>{title}</Label>
            {users.map((item) => (
              <UserBadge key={item?.id} signStatus={signStatus} user={item} />
            ))}
          </>
        )
      }
      theme='light'
    >
      {children}
    </StyledTooltip>
  );
}
