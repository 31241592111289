import React, { useEffect, useState } from "react";
import styles from "../../../../Admin/Superadmin/PlatformAdmin/Users/Users.module.scss";
import { Button, Input, Label } from "../../index";
import { Modal } from "antd";

const RenamePresetPopup = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState(isOpen);

  const handleChange = (_, value) => {
    setName(value);
  };

  useEffect(() => {
    setName(isOpen);
  }, [isOpen]);

  return (
    <Modal
      className={styles.modal}
      title='Rename the template'
      onCancel={onClose}
      open={!!isOpen}
      centered
      footer={[
        <Button key='cancel' primary color='red' handleClick={onClose}>
          Cancel
        </Button>,
        <Button
          disabled={!name}
          key='submit'
          secondary
          color='green'
          handleClick={() => onSubmit(name)}
        >
          Rename
        </Button>,
      ]}
    >
      <div className={styles.column}>
        <Label>Template name</Label>
        <Input
          value={name}
          placeholder='Enter the template name'
          name='template'
          onHandleChange={handleChange}
        />
      </div>
    </Modal>
  );
};

export default RenamePresetPopup;
