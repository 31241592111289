import { Card, Col, message, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getDeletedDocuments, getDocumentBacklinks } from "../../../api/documentApi";
import useQueryParams from "../../../hooks/useQueryParams";

import NewDocument from "../../../components/NewDocument";
import TableHeader from "../../../Admin/components/TableHeader";
import { Button, Loading } from "../../../components/ui-components";
import DocumentRow from "./components/DocumentRow";
import EditDocument from "../../../components/EditDocument";
import Tabs from "../../../components/ui-components/Tabs/Tabs";
import SearchInput from "../../../components/ui-components/SearchInput/SearchInput";
import DocumentExistModal from "./components/DocumentExistModal";
import EmptyState from "../../../components/EmptyState";
import DocumentUploadFileModal from "./components/DocumentUploadFileModal";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

import { SORT_TITLES } from "./Documents.constant";
import { getFilteredDocuments } from "./Documents.helpers";
import styles from "./Documents.module.scss";
import { TrashGrayIcon } from "../../../assets/icons";
import { useDropzone } from "react-dropzone";
import { useDocuments } from "../../../hooks/services/useDocuments";
import { FolderIcon } from "../../../AdminComponents/icons";

const tabs = ["all", "legal", "shared", "evidence", "unlinked"];
const filteredQueryKey = ["documents", "filtered"];
const filteredDeletedQueryKey = ["documents", "filtered", "deleted"];

const Documents = () => {
  const { id: idAudit } = useParams();
  const { query, setQueryParams } = useQueryParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { createMultipleDocuments } = useDocuments();

  const userRole = useSelector((state) => state.user.user_role);

  const [editDoc, setEditDoc] = useState(null);
  const [filters, setFilters] = useState();
  const [activeTab, setActiveTab] = useState("all");
  const [addNewDocument, setAddNewDocument] = useState(false);
  const [search, setSearch] = useState("");
  const [showDeleted, setShowDeleted] = useState(query.deleted);
  const [uploadLoading, setUploadLoading] = useState(false);

  const hasFilter = !!search;
  const queryClient = useQueryClient();
  const {
    data: allDocument = [],
    isLoading: isDocumentLoading,
    refetch,
  } = useQuery({
    // removed staleTime because backlink amount may change frequently
    // and we cannot track it because it's related to status task description
    // once we update the logic so the documents could be attached to task
    // without needed to be mentioned in text - we could bring back staleTime: Infinity or 5min
    // staleTime: 5 * 60 * 1000,
    queryKey: hasFilter ? filteredQueryKey : ["documents"],
    queryFn: () => getDocumentBacklinks(idAudit, { search }),
  });
  const {
    data: deletedDocuments = [],
    isLoading: isDeletedDocumentLoading,
    refetch: refetchDeleted,
  } = useQuery({
    queryKey: hasFilter ? filteredDeletedQueryKey : ["documents", "deleted"],
    queryFn: () => getDeletedDocuments(idAudit, { search }),
  });

  const onDrop = async (files) => {
    console.log(files);
    setUploadLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    createMultipleDocuments(
      {
        documents: files,
        type: "evidence",
        auditId: idAudit,
      },
      {
        onSuccess: () => {
          message.success("Files uploaded successfully");
          setUploadLoading(false);
        },
        onError: () => {
          message.error("Error uploading files");
          setUploadLoading(false);
        },
      }
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    onDropAccepted: onDrop,
  });

  const queryTab = query.type;
  const { hasAdminPermissions } = userRole || {};
  const filteredDocuments = useMemo(() => {
    const currentArray = showDeleted ? deletedDocuments : allDocument;
    return getFilteredDocuments(currentArray, activeTab, filters);
  }, [allDocument, activeTab, filters, deletedDocuments, showDeleted]);

  useEffect(() => {
    setActiveTab(queryTab ?? "all");
  }, [queryTab]);

  useEffect(() => {
    setFilters(query);
  }, []);

  const handleSetTab = (tab) => {
    setActiveTab(tab);
    setQueryParams({ tab: tab === "all" ? undefined : tab });
  };

  useEffect(() => {
    if (!search) return;

    console.log(12344);
    if (showDeleted) {
      refetchDeleted();
    } else {
      refetch();
    }

    return () => {
      const currentQueryKey = showDeleted ? filteredDeletedQueryKey : filteredQueryKey;
      queryClient.removeQueries({ queryKey: currentQueryKey, exact: true });
    };
  }, [search, idAudit]);

  const userTabs = useMemo(
    () =>
      tabs.filter((tab) => {
        if (!hasAdminPermissions) {
          return tab !== "evidence";
        }
        return true;
      }),
    [hasAdminPermissions]
  );

  const handleAddDocument = () => setAddNewDocument(true);

  const handleSort = (filters) => {
    setQueryParams(filters);
    setFilters(filters);
  };

  const toggleShowDeleted = () => {
    setShowDeleted((state) => {
      const newShowDeleted = !state;
      if (newShowDeleted) {
        refetchDeleted();
        setQueryParams({ deleted: true });
      } else {
        refetch();
        setQueryParams({ deleted: undefined });
      }
      return newShowDeleted;
    });
  };

  return (
    <div className={styles.wrapper}>
      {addNewDocument && <NewDocument activeTab={activeTab} close={setAddNewDocument} />}
      {editDoc && (
        <EditDocument
          id={editDoc.id}
          docName={editDoc.name}
          docFile={editDoc.document}
          requiresSignature={editDoc.requiresSignature}
          closeDrop={setEditDoc}
          assigners={editDoc.signers}
        />
      )}
      <Row justify='space-between' align='middle'>
        <Col>
          <h3 className={styles.title}>
            {showDeleted && `${t("deleted")} `}
            {tGlobal("documents")}
          </h3>
        </Col>
        <Row style={{ gap: 16 }}>
          <Button
            onClick={toggleShowDeleted}
            color='gray'
            secondary
            {...(!showDeleted && { icon: <TrashGrayIcon /> })}
          >
            {showDeleted ? "All Files" : "Deleted Files"}
          </Button>
          {!showDeleted && hasAdminPermissions && (
            <Button
              primary
              color='lightBlue'
              icon={<PlusIcon width={24} height={24} />}
              onClick={() => setAddNewDocument(true)}
            />
          )}
        </Row>
      </Row>
      <Card className={styles.headerPanel}>
        <div className={styles.headerPanelInner}>
          <Tabs t={t} options={userTabs} value={activeTab} onClick={handleSetTab} />
          <SearchInput value={search} placeholder={t("search_documents")} onChange={setSearch} />
        </div>
      </Card>
      <div className={styles.documentList}>
        <TableHeader
          titles={SORT_TITLES}
          onChange={handleSort}
          wrapperStyles={{ gridTemplateColumns: "minmax(10rem, 1fr) 60px 120px 130px 140px 100px" }}
          filters={filters}
        />
        <div className={styles.documentColumns} {...getRootProps()}>
          <input
            type='file'
            name='attachments'
            hidden
            // onChange={onAddFile}
            {...getInputProps()}
          />
          <div className={`${styles.attachmentDropzone} ${isDragActive ? styles.active : ""}`}>
            <div className={styles.visualAssist}>
              <FolderIcon />
              <p>Drop files here to upload them to the audit.</p>
            </div>
          </div>
          {!uploadLoading && !isDocumentLoading ? (
            filteredDocuments.length > 0 ? (
              filteredDocuments.map((item, index) => (
                <DocumentRow
                  key={item.id}
                  document={item}
                  style={{ zIndex: filteredDocuments.length - index }}
                  onEdit={() => setEditDoc(item)}
                  showDeleted={showDeleted}
                />
              ))
            ) : (
              <EmptyState
                title={t("no_shared")}
                description={t("no_shared_description")}
                buttonText={t("upload_document")}
                isShowButton={hasAdminPermissions}
                onButtonClick={handleAddDocument}
              />
            )
          ) : (
            <Loading position='absoluteCenter' />
          )}
        </div>
      </div>
      <DocumentExistModal fileName={editDoc?.name} />
      <DocumentUploadFileModal />
    </div>
  );
};

export default Documents;
