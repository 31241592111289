import { Button } from "../ui-components";

import styles from "./EmptyState.module.scss";

type DocumentsEmptyStateProps = {
  title: string;
  description?: string;
  isShowButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
};

export default function EmptyState({
  title,
  description,
  isShowButton = true,
  buttonText,
  onButtonClick,
}: DocumentsEmptyStateProps) {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{title}</h3>
      <h4 className={styles.subtitle}>{description}</h4>
      {isShowButton && (
        <Button large primary color='blue' className={styles.button} onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
}
