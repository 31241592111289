import { Alert } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { useTranslation } from "react-i18next";

import {
  approveSubmitRequest,
  detachQuoteDocument,
  getRequestByType,
  rejectRequest,
  sendQuoteRequest,
  updateQuoteRequest,
} from "../../../../../api/requestsPage";
import { useFetch } from "../../../../../hooks";
import { prepareDraft } from "../../../../../consts/prepareDraft";
import { REQUEST_STATUSES, ROLES_REQUSTED_TEXT, STATUS_COLORS } from "../../../../../consts/enums";
import { industryOptions, industrySegments, ownersOptions, requiredFields } from "./constants";

import { Button, Label, Loading, Tag } from "../../../../../components/ui-components";
import { AssignedWrapper, HeaderEditor, PageTextEditor } from "../../../components";
import DueDateTime from "../../../components/DueDateTime/DueDateTime";
import FormPicker from "../../../components/FormPicker/FormPicker";
import TextArea from "../../../../../customComponents/TextArea";
import QuoteFields from "./QuoteFields/QuoteFields";
import CustomDropdown from "../../../../../components/ui-components/CustomDropdown/CustomDropdown";
import AccountingSystemAutocomplete from "./AccountingSystemAutocomplete";

import styles from "./QuoteRequestView.module.scss";

const initEditMode = { name: false, description: false };

const ignoredFields = ["employees", "companyRevenue", "companyTotalAssets"];
const errorsOrder = [
  "industry",
  "industrySegment",
  "businessDescription",
  "numberOfOwners",
  "accountingSystem",
  "provideInformation",
  "employees",
  "companyRevenue",
  "companyTotalAssets",
  "reportDate",
];

const QuoteRequestView = ({ changeCurrentRequest, hasAdminPermissions }) => {
  const { requestId, id: auditId } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [requestData, setRequestData] = useState(null);
  const [editRequestData, setEditRequestData] = useState({
    name: "",
    description: "",
  });
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editMode, setEditMode] = useState(initEditMode);

  const { isLoading, error, data } = useFetch(getRequestByType, auditId, requestId);

  const isOrganiser = () => false;

  const handleChangePicker = async (name, value) => {
    if (name === "accountingSystem") {
      if (value === "bexio") {
        setRequestData((state) => ({
          ...state,
          provideInformation: "auto",
        }));
        await updateRequest({ provideInformation: "auto" });
      } else {
        setRequestData((state) => ({
          ...state,
          provideInformation: null,
        }));
        await updateRequest({ provideInformation: null });
      }
    } else if (name === "industry") {
      setRequestData((state) => ({
        ...state,
        industrySegment: null,
      }));
      await updateRequest({ industrySegment: null });
    }

    setRequestData((state) => ({
      ...state,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }

    await updateRequest({ [name]: value });
  };

  useEffect(() => {
    if (data && !error && !isLoading) {
      setRequestData(data.request);
      const { name, description } = data.request;
      setEditRequestData({ name, description });
      setEditorState(prepareDraft(description || ""));
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    setEditMode(initEditMode);
  }, [requestId]);

  const updateRequest = async (body, config) => {
    // TODO: send request only if the status is "requested"? OR THIS CANNOT BE EDITED AFTER SENDING?
    if (requestData.status === REQUEST_STATUSES.SENT && !hasAdminPermissions) {
      console.log("cannot edit");
      return;
    }

    if (requestData.status === REQUEST_STATUSES.APPROVED) {
      console.log("cannot edit");
      return;
    }
    try {
      setErrors((state) => ({
        ...state,
        reportDate: false,
      }));
      return await updateQuoteRequest(auditId, body, config);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleEditRequestData = (e) => {
    const { name, value } = e.target;
    setEditRequestData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = async (e, isBlur) => {
    const { name, value } = e.target;
    setRequestData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }

    if (isBlur) {
      await updateRequest({ [name]: value });
    }
  };

  const handleCheckboxChange = async (e) => {
    const { name, checked } = e.target;
    setRequestData((prev) => ({
      ...prev,
      [name]: checked,
    }));

    await updateRequest({ [name]: checked });
  };

  const handleAssignedUsersAndRoles = (assignedUsers = []) => {
    setRequestData((prevValue) => ({
      ...prevValue,
      preparer: assignedUsers || null,
    }));
  };

  const openEditMode = (name) => {
    setEditMode((prev) => ({ ...prev, [name]: true }));
  };
  const closeEditMode = (event, name) => {
    event.stopPropagation();
    setEditMode((prev) => ({ ...prev, [name]: false }));
  };

  const onEditorStateChange = (editorState) => {
    if (editMode.description) {
      const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setEditRequestData((prev) => ({
        ...prev,
        description: forFormik,
      }));
      setEditorState(editorState);
    }
  };

  const acceptChanges = async (event, name) => {
    closeEditMode(event, name);
    setRequestData((prev) => ({
      ...prev,
      [name]: editRequestData[name],
    }));
    await updateRequest({ [name]: editRequestData[name] });
  };

  const discardChanges = async (event, name) => {
    setEditRequestData((prev) => ({
      ...prev,
      [name]: requestData[name],
    }));
    closeEditMode(event, name);
  };

  const handleAssignedUsers = async ({ assignedUsers }) => {
    const newUser = assignedUsers.pop();
    handleAssignedUsersAndRoles(newUser);

    // TODO: send request only if the status is not "requested"? OR THIS CANNOT BE EDITED AFTER SENDING?
    await updateRequest({
      preparer: newUser?.id,
    });
  };

  const removeAssignee = async () => {
    setRequestData((prevValue) => ({
      ...prevValue,
      preparer: null,
    }));

    await updateRequest({
      preparer: null,
    });
  };

  const addAttachmentFile = async (files) => {
    setRequestData((prev) => ({
      ...prev,
      attachments: [...(prev.attachments || []), files[0]],
    }));
    const res = await updateRequest(
      { attachments: [files[0]] },
      {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      }
    );
    // logically it shouldn't be only res.request, but backend returns request object both
    // for request and meeting, should be fixed
    console.log(res);
    setRequestData(res?.request);
    setProgress(0);
  };

  const handleDeleteDocument = async (id) => {
    try {
      await detachQuoteDocument(auditId, id);
      setRequestData((prev) => ({
        ...prev,
        relatedDocs: prev.relatedDocs.filter((el) => el.id !== id),
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const errorFields = useMemo(() => {
    if (!requestData) return {};

    const requiredFieldsErrors = requiredFields.reduce((acc, field) => {
      if (
        (requestData?.provideInformation === "upload" ||
          requestData?.provideInformation === "auto") &&
        ignoredFields.includes(field)
      ) {
        return acc;
      }
      if (field === "reportDate" && dayjs().add(2, "week") > dayjs(requestData.reportDate)) {
        acc.reportDate = true;
      } else if (!requestData[field] && requestData[field] !== false) {
        acc[field] = true;
      }
      return acc;
    }, {});
    if (Object.keys(requiredFieldsErrors).length > 0) {
      return requiredFieldsErrors;
    }
    return {};
  }, [requestData]);

  const onSendRequest = async () => {
    if (Object.keys(errorFields).length > 0) {
      setErrors(errorFields);
      const firstError = errorsOrder.filter((err) => Object.keys(errorFields).includes(err))[0];
      if (firstError) {
        const element = document.getElementById(firstError);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      return;
    }

    try {
      await sendQuoteRequest(auditId, requestData);
      changeCurrentRequest(requestId, { status: "sent" }, requestData.status);
      setRequestData((prev) => ({
        ...prev,
        status: "sent",
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onRejectRequest = async () => {
    if (requestData.status !== REQUEST_STATUSES.SENT) {
      console.log("cannot edit");
      return;
    }
    try {
      await rejectRequest(auditId, requestData, requestId);
      changeCurrentRequest(requestId, { status: "requested" }, requestData.status);
      setRequestData((prev) => ({
        ...prev,
        status: "requested",
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onApproveRequest = async () => {
    if (requestData.status !== REQUEST_STATUSES.SENT || !hasAdminPermissions) {
      console.log("cannot edit");
      return;
    }
    try {
      await approveSubmitRequest(auditId, requestData, requestId);
      changeCurrentRequest(requestId, { status: "approved" }, requestData.status);
      setRequestData((prev) => ({
        ...prev,
        status: "approved",
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const changeDateTime = (newState) => {
    setRequestData((state) => ({
      ...state,
      ...newState,
    }));
  };

  const provideInformationOptions = useMemo(
    () => [
      {
        name: "Manual",
        value: "manual",
      },
      {
        name: "Upload",
        value: "upload",
      },
      {
        name: "Auto",
        value: "auto",
        disabled: requestData?.accountingSystem !== "bexio",
      },
    ],
    [requestData?.accountingSystem]
  );

  const industrySegmentOptions = useMemo(
    () =>
      (industrySegments[requestData?.industry || ""] || []).map((i) => ({ label: i, value: i })),
    [requestData?.industry]
  );

  if (error) {
    return <div> Something went wrong, please try again later</div>;
  }

  const isDisabled =
    requestData?.status === REQUEST_STATUSES.APPROVED ||
    (requestData?.status === REQUEST_STATUSES.SENT && !hasAdminPermissions);
  const errorAmount = Object.values(errorFields).filter((i) => i).length;

  return !isLoading && requestData && editRequestData ? (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <div className={styles.headerSection}>
          <HeaderEditor
            activeEdit={editMode.name}
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            onOpenEdit={openEditMode}
            name='name'
            onChange={handleEditRequestData}
            value={editRequestData.name}
            disabled={isDisabled}
          >
            <Tag color={STATUS_COLORS[requestData.status]}>
              {tGlobal(ROLES_REQUSTED_TEXT[requestData.status] || " ")}
            </Tag>
          </HeaderEditor>

          <div className={styles.headerActions}>
            {(requestData.status === REQUEST_STATUSES.REQUESTED ||
              requestData.status === REQUEST_STATUSES.REJECTED) && (
              <Button handleClick={onSendRequest} color={"green"} primary style={{ width: 133 }}>
                {t("send")}
              </Button>
            )}

            {hasAdminPermissions && requestData.status === REQUEST_STATUSES.SENT && (
              <>
                <Button secondary color='red' style={{ width: 133 }} handleClick={onRejectRequest}>
                  {t("quote_reject_button_text")}
                </Button>
                <Button
                  primary
                  disabled={errorAmount !== 0 || (errorAmount === 1 && errorFields.reportDate)}
                  color='green'
                  style={{ width: 133 }}
                  handleClick={onApproveRequest}
                >
                  {t("quote_approve_button_text")}
                </Button>
              </>
            )}
          </div>
          {/*{requestData.status === REQUEST_STATUSES.SENT && (*/}
          {/*  <Button handleClick={onApproveRequest} color={"green"} primary style={{ width: 133 }}>*/}
          {/*    Approve*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*<MenuView*/}
          {/*  onOpenEdit={openFullEdit}*/}
          {/*  onDelete={handleDelete}*/}
          {/*  editText={t("edit_request")}*/}
          {/*  deleteText={t("delete_request")}*/}
          {/*/>*/}
        </div>
        <div className={styles.bodySection}>
          {Object.values(errors).filter((i) => i).length > 0 && (
            <Alert className={styles.alert} type='error' message={t("form_has_errors")} />
          )}
          <PageTextEditor
            label={t("description")}
            name='description'
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            openEditMode={openEditMode}
            value={editRequestData.description}
            isEditMode={editMode.description}
            editorState={editorState}
            readOnly={!editMode.description}
            onEditorStateChange={onEditorStateChange}
            textValue={requestData.description}
            hiddenInput
            disabled={isDisabled}
          />
          <div className={styles.splitRow}>
            <div className={styles.column}>
              <Label id='industry'>{t("industry")}</Label>
              <CustomDropdown
                error={errors.industry}
                options={industryOptions}
                value={requestData.industry}
                onChange={handleChangePicker}
                name='industry'
                disabled={isDisabled}
                type={"big"}
              />
            </div>
            <div className={styles.column}>
              <Label id='industrySegment'>{t("industry_segment")}</Label>
              <CustomDropdown
                placeholder={
                  requestData.segment ? tGlobal("select_an_option") : t("select_industry_segment")
                }
                error={errors.industrySegment}
                options={industrySegmentOptions}
                value={requestData.industrySegment}
                onChange={handleChangePicker}
                name='industrySegment'
                disabled={isDisabled || !requestData.industry}
                type={"big"}
              />
            </div>
          </div>
          <div className={styles.column}>
            <Label id='businessDescription'>{t("business_description")}</Label>
            <TextArea
              value={requestData.businessDescription}
              placeholder={t("business_description_placeholder")}
              name='businessDescription'
              onChange={handleInputChange}
              onBlur={(e) => handleInputChange(e, true)}
              error={errors.businessDescription}
              disabled={isDisabled}
            />
          </div>
          <FormPicker
            options={ownersOptions}
            selected={requestData.numberOfOwners}
            error={errors.numberOfOwners}
            name='numberOfOwners'
            onSelect={handleChangePicker}
            label={t("number_of_owners")}
            labelId='numberOfOwners'
            disabled={isDisabled}
          />
          <div className={styles.containerAssigned}>
            {/* TODO: ALLOW ONLY TO PICK 1 USER */}
            <AssignedWrapper
              creatorLabel={tGlobal("created_by")}
              assignedLabel={t("preparer")}
              assignButtonLabel={tGlobal("assign_user")}
              creator={requestData?.addedBy}
              assignedRoles={[]}
              assignedUsers={requestData.preparer ? [requestData.preparer] : []}
              onRemove={removeAssignee}
              isOrganiser={isOrganiser}
              handleAssignUsers={handleAssignedUsers}
              hideGroups
              disabled={isDisabled}
            />
          </div>
          <AccountingSystemAutocomplete
            error={errors.accountingSystem}
            value={requestData.accountingSystem}
            onChange={(v) => handleChangePicker("accountingSystem", v)}
          />
          <FormPicker
            options={provideInformationOptions}
            error={errors.provideInformation}
            selected={requestData.provideInformation}
            name='provideInformation'
            onSelect={handleChangePicker}
            label={t("provide_information")}
            labelId='provideInformation'
            disabled={isDisabled}
          />
          <QuoteFields
            errors={errors}
            requestData={requestData}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            progress={progress}
            lastFetchDate={requestData.last_fetched}
            handleDeleteDocument={handleDeleteDocument}
            addAttachmentFile={addAttachmentFile}
            handleChangePicker={handleChangePicker}
            disabled={isDisabled}
          />
          <div>
            <div className={classNames(styles.row, styles.dateFileWrapper)}>
              <DueDateTime
                label={t("report_date")}
                labelId='reportDate'
                name={"reportDate"}
                dueDate={dayjs(requestData.reportDate)}
                errors={errors}
                setData={changeDateTime}
                onChange={updateRequest}
                disabledDate={(current) => current && current < dayjs().add(2, "week")}
                hideTime
                disabled={isDisabled}
              />
            </div>
            {errors.reportDate && (
              <div className={styles.errorText}>Date has to be at least 2 weeks from now</div>
            )}
          </div>
          <div className={styles.column}>
            <Label>{t("additional_notes")}</Label>
            <TextArea
              value={requestData.additionalNotes}
              placeholder={t("additional_notes_description")}
              name='additionalNotes'
              onChange={handleInputChange}
              onBlur={(e) => handleInputChange(e, true)}
              disabled={isDisabled}
            />
          </div>
          {/*<div className={styles.container}>*/}
          {/*  <Label>{tGlobal("log_and_comment")}</Label>*/}
          {/*  <CommentsViewer*/}
          {/*    comment={comment}*/}
          {/*    handleComment={handleComment}*/}
          {/*    addComment={addComment}*/}
          {/*    comments={requestData.comments}*/}
          {/*    dateCreated={requestData.createdAt}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  );
};

export default QuoteRequestView;
