import { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import useOutsideClick from "../../hooks/useOutsideClick";

import { Button, Input } from "../ui-components";

import { SearchIcon } from "../../assets/icons";

import styles from "./AttachDocuments.module.scss";

const AttachDocuments = (props) => {
  const {
    placeholder,
    values,
    optionComponent,
    options,
    restrictMode = {},
    isLoading,

    setIsOpen,
    onAddFile,
    onChange,
  } = props;
  const ref = useRef(null);
  const searchRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const { getInputProps, open } = useDropzone({
    noClick: true,
    multiple: true,
    accept: restrictMode,
    disabled: isLoading,
    onDropAccepted: !isLoading && onAddFile,
  });

  useEffect(() => {
    searchRef.current && searchRef.current.focus();
  }, []);

  const handleClose = () => setIsOpen(false);

  const handleOptionClick = (option) => {
    if (onChange) {
      onChange(option);
      setIsOpen(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          [option.document, option.name, option.title].some((i) =>
            i?.toLowerCase().includes(searchValue.toLowerCase())
          ) && !values.some((i) => i.id === option.id)
      ),
    [options, searchValue, values]
  );

  useOutsideClick(ref, handleClose);

  return (
    <div ref={ref} id='attach-documents' className={styles.dropdownList}>
      <div className={styles.pointer} />
      <div className={styles.dropdownHeader}>
        <SearchIcon className={styles.searchIcon} />
        <Input
          ref={searchRef}
          value={searchValue}
          onChange={handleSearchChange}
          placeholder={placeholder}
        />
      </div>
      <ul className={styles.options}>
        {filteredOptions.length ? (
          filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)} style={option.style}>
              {optionComponent(option)}
            </li>
          ))
        ) : (
          <li className={styles.noResults}>{searchValue ? "No results" : "No documents"}</li>
        )}
      </ul>
      <div className={styles.footerActions}>
        <input type='file' name='attachments' hidden onChange={onAddFile} {...getInputProps()} />
        <Button handleClick={open} color={"blue"} secondary>
          Upload new
        </Button>
      </div>
    </div>
  );
};

export default AttachDocuments;
