import { Button, Col, Divider, Input, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { allListRoles } from "../../../../../../api/rolesApi";

import { ReactComponent as ArrowLeftIcon } from "../../../../../../assets/icons/arrow-left.svg";
import { auditServices } from "../../../../../../services/auditService";
import { PlatformRole } from "../../../../../../types/team";
import { Label } from "../../../../../ui-components";
import CustomAntDropdown from "../../../../../ui-components/CustomAntDropdown/CustomAntDropdown";
import InputHelperText from "../../../../../ui-components/InputHelperText";

import styles from "./MentionInvitePerson.module.scss";

type MentionInvitePersonProps = {
  screen?: "invite" | "add_contact"; // part of key from translation file
  onBack: () => void;
  onAfterSubmit: () => void;
};

type Params = {
  id: string;
};

interface FormValues {
  email: string;
  company: string;
  role: string;
  firstName: string;
  lastName: string;
}

export default function MentionInvitePerson({
  screen = "invite",
  onAfterSubmit,
  onBack,
}: MentionInvitePersonProps) {
  const { id: auditId } = useParams<Params>();
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [roles, setRoles] = useState([]);
  const [view, setView] = useState<MentionInvitePersonProps["screen"]>(screen);

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    watch,
    setValue,
    formState: { errors, isLoading },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (view === "invite") {
      if (!data.role) {
        setError("role", { message: t("field_required") });
        return;
      }
      auditServices
        .inviteTeamMember(auditId, {
          email: data.email,
          role: data.role,
          company: data.company,
        })
        .then(onAfterSubmit)
        .catch((error) => {
          setError("email", { message: error.response?.data?.message });
        });
    }
  };

  const handleChangeRole = (name: keyof FormValues, value: string) => {
    setValue(name, value);
    clearErrors();
  };

  const fetchRoles = async () => {
    const rolesResp = await allListRoles(auditId);
    setRoles(
      rolesResp.roles.map((role: PlatformRole) => ({
        ...role,
        label: role.name,
        value: role.id,
      }))
    );
  };

  useEffect(() => {
    fetchRoles();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.header} onClick={onBack}>
        <ArrowLeftIcon />
        {t("invite_popup_header")}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.body}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                color='gray'
                className={classNames(styles.button, view === "invite" && styles.buttonActive)}
                onClick={() => setView("invite")}
              >
                {t("invite_popup_invite_button")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                color='gray'
                className={classNames(styles.button, view === "add_contact" && styles.buttonActive)}
                onClick={() => setView("add_contact")}
              >
                {t("invite_popup_add_contact_button")}
              </Button>
            </Col>
          </Row>
          <div className={styles.alert}>{t(`invite_popup_${view}_description`)}</div>
          <Divider className={styles.divider} />
          <Row gutter={[8, 16]}>
            {view === "add_contact" ? (
              <>
                <Col span={12}>
                  <Label>{tGlobal("first_name")}</Label>
                  <Input
                    status={errors.firstName && "error"}
                    {...register("firstName", { required: tGlobal("field_required") })}
                  />
                  {errors.firstName && (
                    <InputHelperText error>{errors.firstName.message}</InputHelperText>
                  )}
                </Col>
                <Col span={12}>
                  <Label>{tGlobal("last_name")}</Label>
                  <Input
                    status={errors.lastName && "error"}
                    {...register("lastName", { required: tGlobal("field_required") })}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={15}>
                  <Label>{tGlobal("email")}</Label>
                  <Controller
                    control={control}
                    {...register("email", {
                      required: tGlobal("email_required"),
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: tGlobal("email_invalid"),
                      },
                    })}
                    render={({ field }) => <Input {...field} />}
                  />
                  {errors.email && <InputHelperText error>{errors.email.message}</InputHelperText>}
                </Col>
                <Col span={9}>
                  <Label>{tGlobal("role")}</Label>
                  <CustomAntDropdown
                    name='role'
                    placeholder='Select'
                    options={roles}
                    value={watch("role")}
                    onChange={handleChangeRole}
                    style={{ width: "100%" }}
                  />
                  {errors.role && <InputHelperText error>{errors.role.message}</InputHelperText>}
                </Col>
              </>
            )}
            <Col span={24}>
              <Label>{tGlobal("company")}</Label>
              <Controller
                control={control}
                {...register("company")}
                render={({ field }) => <Input {...field} />}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.footer}>
          <Button htmlType='submit' disabled={isLoading} className={styles.footerButton}>
            Invite
          </Button>
        </div>
      </form>
    </>
  );
}
