import { Input } from "antd";

import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import styles from "./SearchInput.module.scss";

const SearchInput = ({ value, onChange, placeholder = "Search" }) => {
  return (
    <div className={styles.root}>
      <Input
        size='small'
        prefix={<SearchIcon className={styles.icon} />}
        classNames={{
          input: styles.input,
        }}
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => {
          onChange(target.value);
        }}
      />
    </div>
  );
};

export default SearchInput;
