import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

import { verifySmS, sendSmS } from "../../api/phoneApi";
import Keys from "../../consts/helper";
import { setUserData, setUserToken } from "../../store/user/actions";

import Button from "../../components/ui-components/Button/Button";

import logo from "../../assets/images/logo-primary.svg";

import "../../Admin/styles/loginPhoneDoubleSms.css";

const LoginPhoneDoubleSms = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const userData = JSON.parse(localStorage.getItem(Keys.USER_DATA));

  const onSubmit = () => {
    if (value.length === 6) {
      verifySmS(
        {
          code: value,
        },
        userData.token
      )
        .then((res) => {
          if (res.success) {
            dispatch(setUserData(res));
            dispatch(setUserToken(res.token));
            history.push("/audits");
          } else if (res.response.data?.message === "Codes do not match") {
            setError(res.response.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const onSubmitResend = (e) => {
    e.preventDefault();
    sendSmS(
      {
        phone: userData.phone,
      },
      userData.token
    )
      .then((res) => {
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (value.length === 6) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>

        <form onSubmit={onSubmitResend} className={"login_form"}>
          <p className={"login_form__title"}>{t("almost_there")}</p>
          <p className={"login_form__subtitle"}>
            {t("number_confirm_first")} <br /> {t("number_confirm_second")}
            <span>{userData.phone}</span>
            {t("number_confirm_third")}
          </p>
          <div className={"resend_block"}>
            <span className={"resend_span"}>{t("code_resended")}</span>
          </div>
          <div className={"login_sms_form"}>
            <div className='login_sms-list'>
              <VerificationInput
                validChars='A-Za-z0-9'
                placeholder=''
                onChange={(target) => {
                  setValue(target);
                }}
              />
            </div>
            {error && <div className='error-codes-sms'>{error}</div>}

            <Button onClick={onSubmit} primary large disabled={value.length !== 6} color={"blue"}>
              {value.length > 5 ? "Send" : `${6 - value.length} ${t("digits_left")}`}
            </Button>
            <div className='btn_resend'>
              <Button secondary large color={"blue"} style={{ marginTop: "8px" }}>
                {t("resend_code")}
              </Button>
            </div>
            <Link to='/login/phone' className='login_form-link'>
              {t("phone_mistake")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPhoneDoubleSms;
