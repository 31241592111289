import { IAudit } from "./Audit";
import { IDocument } from "./Document";
import { IMeeting } from "./Meeting";
import { IRequest } from "./Request";
import { IUser } from "./User";

export interface INotification {
  id: number;
  read: boolean;
  text?: string;
  createdAt: any;
  requiredAction: NotificationAction;
  type: NotificationType;
  creator: IUser;
  creatorId: number;
  request?: IRequest;
  requiestId?: number;
  document?: IDocument;
  documentId?: number;
  meeting?: IMeeting;
  meetingId?: number;
  audit: IAudit;
  auditId?: number;
  user: IUser;
  userId: number;
}

export enum NotificationType {
  status_update = "status_update",
  document_upload = "document_upload",
  comment_added = "comment_added",
  comment_mention = "comment_mention",
  request_created = "request_created",
}

export enum NotificationAction {
  read = "read",
  click = "click",
}
