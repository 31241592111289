import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./user/reducer";
import globalReducer from "./global/reducer";

const store = configureStore({
  reducer: {
    user: authReducer,
    global: globalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
