import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InviteForm from "../InviteForm";

const InviteUserForm = ({
  placeholder,
  roles,
  invite,
  setInvite,
  onSubmit,
  users,
  error,
  setError,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const [shownMenu, setShowMenu] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const activeRole = roles.find((role) => role.value === invite.role);

  const onChangeInvite = ({ target }) => {
    const inputEmail = target.value;
    setInvite((prevState) => ({
      ...prevState,
      email: inputEmail,
      teamMemberId: null,
    }));
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.firstName.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.lastName.toLowerCase().includes(inputEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    setShowMenu(true);
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const inputData = (key, value) => {
    setInvite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClickUser = (_, item) => {
    setInvite((prevState) => ({
      ...prevState,
      email: item.email,
      teamMemberId: item.teamMemberId,
      userId: item.id,
    }));
    setShowMenu(false);
    setFilteredUsers(users);
  };

  return (
    <InviteForm
      placeholder={placeholder || t("invite_auditor")}
      allowMenu={shownMenu}
      handleClickUser={handleClickUser}
      filteredUsers={filteredUsers}
      invite={invite}
      inviteRoles={roles}
      inputData={inputData}
      inviteMember={onSubmit}
      onChangeInvite={onChangeInvite}
      activeRole={activeRole}
      error={error}
      setError={setError}
    />
  );
};

export default InviteUserForm;
