import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { sendData } from "../../../../../api/userApi";
import { languageToValue } from "../../../../../consts/languageToValue";

import LanguageSelect from "../../../../../Admin/components/LanguageSelect/LanguageSelect";
import { setUserData } from "../../../../../store/user/actions";
import { Label } from "../../../../../components/ui-components";

const AccountSettingsSettings = ({ userData, syncDataOnSave }) => {
  const { dispatch } = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const onChange = async (response) => {
    const data = { language: response.label };
    try {
      const res = await sendData(data);
      dispatch(setUserData({ ...userData, ...data, logo: res.logo }));
      syncDataOnSave(data);
    } catch (error) {
      console.log(error);
    }
  };
  const language = userData.language
    ? languageToValue[userData.language]
    : localStorage.getItem("i18nextLng");

  return (
    <Card.Grid hoverable={false}>
      <Row gutter={24}>
        <Col span={8}>
          <Label>{t("language")}</Label>
        </Col>
        <Col span={16}>
          <LanguageSelect value={language} onChange={onChange} />
        </Col>
      </Row>
    </Card.Grid>
  );
};

export default AccountSettingsSettings;
