import { useLayoutEffect } from "react";

import styles from "./DualScrollWrapper.module.scss";

const DualScrollWrapper = ({ leftSidebar, children }) => {
  useLayoutEffect(() => {
    document.body.classList.add(styles.offScroll);
    return () => document.body.classList.remove(styles.offScroll);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftBlock}>{leftSidebar}</div>
        <div className={styles.rightBlock}>
          <div className={styles.childrenWrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DualScrollWrapper;
