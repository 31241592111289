import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import updateLocale from "dayjs/plugin/updateLocale";
import { useTranslation } from "react-i18next";

import { CalendarIcon, ChevronBottom } from "../../../assets/icons";

import classes from "./CustomDatePicker.module.css";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const CustomDatePicker = ({ value, error, customOverlay, onPopupOpen, disabled, ...props }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const [open, setOpen] = useState(false);
  const [currentlyClosing, setCurrentlyClosing] = useState(false);

  const priorOpenChange = (val) => {
    setCurrentlyClosing(true);
    setOpen(val);
    setTimeout(() => {
      setCurrentlyClosing(false);
    }, 200);
  };

  const openCalendar = () => {
    if (currentlyClosing) return;
    setOpen(true);
    onPopupOpen?.();
  };

  const valueFormatted = value ? dayjs(value).format("DD MMM YYYY") : t("select_date");

  return (
    <div
      className={`${classes.root} ${open ? classes.active : ""} ${
        disabled ? classes.disabled : ""
      } ${error ? classes.error : ""}`}
    >
      {customOverlay ? (
        customOverlay(valueFormatted, open, openCalendar)
      ) : (
        <div className={classes.overlay} onClick={openCalendar}>
          <CalendarIcon className={classes.icon} />
          <span>{valueFormatted}</span>
          <ChevronBottom className={classes.chevron} />
        </div>
      )}
      <DatePicker
        open={open}
        onOpenChange={priorOpenChange}
        value={value}
        presets={[]}
        {...props}
      />
    </div>
  );
};

export default CustomDatePicker;
