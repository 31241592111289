import { Col, ModalProps, Row, Space } from "antd";
import StyledModal from "../../../../../components/ui-components/StyledModal";
import { Button } from "../../../../../components/ui-components";

type DocumentExistModalProps = {
  fileName: string;
} & ModalProps;

export default function DocumentExistModal({ fileName, ...props }: DocumentExistModalProps) {
  return (
    <StyledModal
      title='File already exist'
      {...props}
      footer={
        <Row justify='space-between'>
          <Col>
            <div>
              <Button tertiary color='red'>
                Cancel
              </Button>
            </div>
          </Col>
          <Col>
            <Space>
              <Button primary color='lightBlue'>
                Keep both
              </Button>
              <Button primary color='blue'>
                Replace
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      {fileName} already exists. Please choose how to proceed.
    </StyledModal>
  );
}
