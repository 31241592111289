import clsx from "clsx";
import i18next from "i18next";
import React, { useRef, useEffect, useState } from "react";

import { ChevronBottom, TickmarkIcon } from "../../../assets/icons";

import "./CustomDropdown.scss";

const CustomDropdown = (props) => {
  const {
    error = null,
    options = [],
    readOnly,
    value = "",
    type = "",
    name = "",
    style = {},
    headerStyle = {},
    dropdownStyle = {},
    disabled = false,
    placeholder = i18next.t("dashboard:global:select_an_option"),
    onChange,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleContainer = useRef(null);

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  const onClickOutside = (event) => {
    if (toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) {
      onChange(name, option.value);
      setIsOpen(false);
    }
  };

  const selectedOption = options.find((opt) => opt.value === value);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (readOnly) return;
    setIsOpen(!isOpen);
  };

  return (
    <div
      ref={toggleContainer}
      className={clsx("dropdown-container", type, {
        disabled: disabled,
        open: isOpen,
        error: error,
        readOnly: readOnly,
      })}
      style={style}
    >
      <div className='dropdown-header' onClick={handleOpen} style={headerStyle}>
        {selectedOption ? selectedOption.label : placeholder}
        <ChevronBottom className='dropdown-arrow' />
      </div>
      {isOpen && (
        <ul style={dropdownStyle} className='dropdown-list'>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={(e) => handleOptionClick(e, option)}
              className={clsx("dropdown-list-item", {
                "dropdown-active-item": value === option.value,
                "dropdown-danger-item": option.danger,
              })}
              style={option.style}
            >
              {option.label}
              {value === option.value && <TickmarkIcon />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
