import clsx from "clsx";
import i18next from "i18next";
import TableHeader from "../../../../../../../../Admin/components/TableHeader";
import { CheckmarkGreenIcon } from "../../../../../../../../assets/icons";
import Assertion from "../../../../../../../../components/ui-components/Assertion/Assertion";
import { StatusStep } from "../../../../../../../../types/status";
import styles from "./SubtasksTable.module.scss";

export const SORT_TITLES = [
  { title: i18next.t("dashboard:status:risk_table_header_level"), key: "level" },
  { title: i18next.t("dashboard:status:risk_table_header_description"), key: "description" },
  { title: i18next.t("dashboard:status:risk_table_header_assertion"), key: "assertion" },
];

type Props = {
  list?: Array<StatusStep>;
};

export default function SubtasksTable({ list = [] }: Props) {
  return (
    <>
      <TableHeader
        titles={SORT_TITLES}
        wrapperStyles={{ gridTemplateColumns: "120px 1fr 100px" }}
      />
      <div className={styles.list}>
        {list
          ?.sort((a, b) => (a.order !== b.order ? a.order - b.order : a.id - b.id))
          .map((i) => (
            <div key={i.name} className={clsx(styles.listItem, styles[i.status])}>
              <div className={styles.subtaskInfo}>
                {i.status === "done" ? <CheckmarkGreenIcon /> : <div className={styles.status} />}
                <span className={styles.type}>{i.type.split("_").join(" ")}</span>
              </div>
              <div className={styles.name}>{i.name}</div>
              <div className={styles.subtaskActions}>
                <div className={styles.assertions}>
                  {i.assertions
                    ?.sort((a, b) => a.id - b.id)
                    .map((i) => (
                      <Assertion id={i.id}>{i.title}</Assertion>
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
