import { Menu } from "antd";

import { Tag } from "../../../../../../components/ui-components";

const FilterTasksOverlay = ({ handleOptionClick, options, style, t }) => {
  return (
    <Menu style={style} onClick={handleOptionClick}>
      <span className='dropdown-list-title'>Filter out tasks</span>
      {options.map((option) => {
        return (
          <Menu.Item className={`dropdown-list-item`} key={option.value}>
            <Tag color={option.color}>{t ? t(option.value) : option.label}</Tag>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default FilterTasksOverlay;
