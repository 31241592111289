import { RichUtils } from "draft-js";
import { useTranslation } from "react-i18next";

import { TextEditorButtonProps } from "../../../RichTextEditor.constants";

import ToolbarButton from "../../ToolbarButton";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/bold.svg";

type BoldButtonProps = {} & TextEditorButtonProps;

const BoldButton = ({ editorState, onEditorStateChange }: BoldButtonProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const toggleStyle = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, "BOLD");
    onEditorStateChange(newState);
  };

  return (
    <ToolbarButton
      Icon={Icon}
      isActive={editorState.getCurrentInlineStyle().has("BOLD")}
      tooltipTitle={t("bold")}
      onClick={toggleStyle}
    />
  );
};

export default BoldButton;
