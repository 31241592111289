import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Label } from "../../../../components/ui-components";
import CustomDatePicker from "../../../../components/ui-components/CustomDatePicker/CustomDatePicker";
import TimeRangePicker from "../../../../components/ui-components/TimeRangePicker/TimeRangePicker";

import "../../../../styles/timepicker.scss";
import styles from "./DueDateTime.module.scss";
import clsx from "clsx";

const DueDateTime = (props) => {
  const {
    dueTime,
    dueDate,
    setData,
    onChange,
    errors,
    label,
    labelId,
    hideTime,
    hideLabel = false,
    fullWidth,
    disabledDate,
    disabled,
    name,
  } = props;
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  const editDueDateTime = async (name, value) => {
    const newDate = new Date(value).toISOString();
    setData({ [name]: newDate });
    onChange?.({ [name]: newDate });
  };

  const editTimeRange = (value) => {
    setData({ dueTime: value });

    onChange?.({ dueTime: value });
  };

  return (
    <div className={clsx(styles.column, fullWidth && styles.fullWidth)}>
      {!hideLabel && <Label id={labelId}>{label || t("due_date")}</Label>}
      <div className={styles.row} style={{ gap: "8px" }}>
        <CustomDatePicker
          onChange={(e) => editDueDateTime(name || "dueDate", e)}
          value={dueDate ? dayjs(dueDate) : null}
          allowClear={false}
          disabledDate={disabledDate}
          disabled={disabled}
          error={errors?.date || errors?.dueDate || errors?.reportDate}
        />
        {!hideTime && (
          <TimeRangePicker
            mode={"single"}
            value={dayjs(dueTime || undefined)}
            disabled={disabled}
            onChange={editTimeRange}
          />
        )}
      </div>
    </div>
  );
};

export default DueDateTime;
