import { Card } from "antd";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { COMPANY_ROLES } from "../../../../../../consts/company";
import {
  changeMemberRole,
  changeMemberSignatory,
  inviteCompanyMember,
  removeCompanyMember,
} from "../../../../../../api/companyApi";
import { getAllUsers } from "../../../../../../api/userApi";

import MemberInfo from "../../../../../../components/MemberRow/MemberInfo";
import InviteForm from "../../../../../../components/InviteForm";
import MemberActions from "../../../../../../components/MemberRow/MemberActions";

import classes from "./Team.module.scss";

const initInvite = {
  email: "",
  role: "member",
};

const teamTypeToColor = {
  audit_firm: { color: "#003E4766", backgroundColor: "#003E4712" },
  fiduciary: { color: "#569720", backgroundColor: "#6DB33333" },
};

const Team = ({ companyData }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [members, setMembers] = useState(companyData.members || []);
  const [invite, setInvite] = useState(initInvite);
  const [_, setShowMenu] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [myMember, setMyMember] = useState(null);

  const user = useSelector((state) => state.user.user_data);

  const memberRoles = [
    {
      label: t("member"),
      value: "member",
    },
    {
      label: t("admin"),
      value: "admin",
    },
    {
      label: t("remove_user"),
      value: "remove",
      caution: true,
    },
  ];

  const inviteRoles = [
    {
      label: t("member"),
      value: "member",
      disclaimer: "access_description",
    },
    {
      label: t("admin"),
      value: "admin",
      disclaimer: "admin_description",
      disclaimerColor: "#F17F16",
    },
  ];

  const handleGetUsers = async () => {
    const users = await getAllUsers();
    const membersEmails = members.map((member) => {
      if (member.user.email === user.email) setMyMember(member);
      return member.user.email;
    });
    setUsers(users.filter((user) => !membersEmails.includes(user.email)));
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
  }, [users]);

  const inputData = (key, value) => {
    setInvite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const inviteMember = async () => {
    try {
      const member = await inviteCompanyMember(companyData.id, invite);
      setMembers((state) => [...state, member]);
      setUsers((state) => state.filter((user) => user.email !== invite.email));
      setInvite(initInvite);
    } catch (error) {
      setError(error?.response?.data);
      console.log(error);
    }
  };

  const changeRole = async (id, role) => {
    try {
      if (role === "remove") {
        await removeCompanyMember(companyData.id, id);
        setMembers((state) => state.filter((item) => item.id !== id));
        return;
      }

      const member = await changeMemberRole(companyData.id, id, { role });
      setMembers((state) => state.map((item) => (item.id === id ? member.teamMember : item)));
    } catch (error) {
      console.log(error);
    }
  };

  const changeSignatory = async (value, memberId) => {
    try {
      const signatory = value || null;
      await changeMemberSignatory(companyData.id, memberId, { signatory });
      setMembers((state) =>
        state.map((item) => (item.id === memberId ? { ...item, signatory } : item))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeInvite = ({ target }) => {
    const inputEmail = target.value;
    inputData("email", inputEmail);
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.firstName.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.lastName.toLowerCase().includes(inputEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    setShowMenu(true);
  };

  const roles = useMemo(() => {
    return memberRoles;
  }, []);
  const activeRole = inviteRoles.find((role) => role.value === invite.role);

  const handleClickUser = (email) => {
    inputData("email", email);
    setShowMenu(false);
    setFilteredUsers(users);
  };

  const externalMembers = companyData.audits.reduce((acc, audit) => {
    audit.auditors.forEach((auditor) => {
      const email = auditor.member.user.email;
      const auditFirmName = audit.auditorFirm.name;
      // if (!acc.find((item) => item.email === auditor.email)) return;
      if (!acc[email]) {
        acc[email] = [auditFirmName];
      } else {
        if (acc[email].some((i) => i === auditFirmName)) return;
        acc[email] = [...acc[email], auditFirmName];
      }
    });
    return acc;
  }, {});
  const hasAdminRights = myMember?.role === "admin" || user?.role === "super-admin";
  const adminAmount = members.filter((member) => member.role === "admin").length;

  return (
    <>
      {hasAdminRights && (
        <InviteForm
          placeholder={t("invite_company")}
          allowMenu
          handleClickUser={handleClickUser}
          filteredUsers={filteredUsers}
          invite={invite}
          inviteRoles={inviteRoles}
          inputData={inputData}
          inviteMember={inviteMember}
          onChangeInvite={onChangeInvite}
          activeRole={activeRole}
          error={error}
          setError={setError}
        />
      )}
      {members
        .sort((a, b) => b.id - a.id)
        .map((member) => {
          const { id, firstName, lastName, email, lastActive, signatoryStatus } = member.user;
          const role = member.role;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid, {
            [classes.highlighted]: id === error?.userId,
          });
          const hasFlag = role === COMPANY_ROLES.MAIN_ADMIN || role === COMPANY_ROLES.AUDITOR;
          const flags = hasFlag ? [{ label: role.split("_").join(" ") }] : [];
          const isAdmin = role === COMPANY_ROLES.ADMIN;
          if (externalMembers[email]) {
            externalMembers[email].forEach((i) => {
              flags.push({
                label: i,
                style: { ...teamTypeToColor.audit_firm, textTransform: "none" },
              });
            });
          }

          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <MemberInfo
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  addedAt={member.addedAt}
                  flags={flags}
                  lastActive={lastActive}
                  signatory={member.signatory}
                  signatoryStatus={signatoryStatus}
                />

                <MemberActions
                  allowEdit={hasAdminRights && (adminAmount > 1 || !isAdmin)}
                  role={role}
                  tRole={t(`role_${role}`)}
                  roles={roles}
                  member={member}
                  onChangeRole={changeRole}
                  onChangeSignatory={changeSignatory}
                />
              </div>
            </Card.Grid>
          );
        })}
      {/*{externalMembers.map(({ member }) => {*/}
      {/*  const { firstName, lastName, email } = member.user;*/}
      {/*  const { team } = member.role;*/}
      {/*  const memberGridClasses = clsx(classes.grid, classes.memberGrid);*/}
      {/*  const flags = [{ label: team.name, style: teamTypeToColor[team.type] }];*/}

      {/*  return (*/}
      {/*    <Card.Grid hoverable={false} className={memberGridClasses}>*/}
      {/*      <div className={classes.memberRow}>*/}
      {/*        <MemberRow*/}
      {/*          firstName={firstName}*/}
      {/*          lastName={lastName}*/}
      {/*          email={email}*/}
      {/*          addedAt={member.addedAt}*/}
      {/*          flags={flags}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Card.Grid>*/}
      {/*  );*/}
      {/*})}*/}
    </>
  );
};

export default Team;
