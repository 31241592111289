import React from "react";
import { Menu } from "antd";
import { TickmarkIcon } from "../../assets/icons";

const DropdownMenuOverlay = ({ handleOptionClick, options, value, t }) => {
  return (
    <Menu onClick={handleOptionClick}>
      {options.map((option) => {
        const isSelected = value?.toString() === option.value.toString();
        const isCaution = option.caution;
        const isDisabled = option.disabled;
        return (
          <Menu.Item
            className={`dropdown-list-item ${isSelected ? "dropdown-active-item" : ""} ${
              isCaution ? "dropdown-caution-item" : ""
            } ${isDisabled ? "dropdown-disabled-item" : ""}`}
            key={isDisabled ? "disabled" : option.value}
          >
            {t ? t(option.value) : option.label}
            {isSelected && <TickmarkIcon />}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default DropdownMenuOverlay;
