import React from "react";
import styles from "./BoxioAttachment.module.css";
import moment from "moment";
import { DownloadRoundedIcon } from "../../../../../../assets/icons";

const BoxioAttachment = ({ lastFetchDate, download }: { lastFetchDate: string; download: any }) => (
  <div onClick={download} className={styles.root}>
    <span className={styles.filetype}>ZIP</span>
    <div className={styles.info}>
      <div className={styles.infoTop}>
        <span className={styles.name}>Fetched-files.zip</span>
      </div>
      <div className={styles.infoBot}>
        <span className={styles.date}>{moment(lastFetchDate).format("D MMM YYYY HH:mm")}</span>
      </div>
    </div>

    <div className={styles.actions}>
      <span>
        <DownloadRoundedIcon color={"red"} />
      </span>
    </div>
  </div>
);

export default BoxioAttachment;
