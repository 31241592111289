import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CustomAvatar from "../CustomAvatar/CustomAvatar";

import { VerificationPendingIcon, VerifiedIcon } from "../../assets/icons";

import classes from "./MemberInfo.module.scss";

const MemberInfo = ({
  firstName,
  lastName,
  email,
  lastActive,
  addedAt,
  flags,
  signatory,
  signatoryStatus,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { t: tSettings } = useTranslation("dashboard", { keyPrefix: "settings" });

  const date = dayjs(addedAt).format("DD.MM.YYYY");
  const hasName = firstName || lastName;

  const user = {
    firstName,
    lastName,
    email,
    lastActive,
  };

  const allFlags = useMemo(() => {
    const allFlags = [];

    if (signatory) {
      allFlags.push({ label: tSettings(`signatory_${signatory}`) });
    }
    if (flags) {
      allFlags.push(...flags);
    }
    return allFlags;
  }, [flags, signatory, signatoryStatus]);

  return (
    <div className={classes.memberInfo}>
      <CustomAvatar user={user} />
      <div className={classes.memberName}>
        <div className={classes.memberTopRow}>
          <span className={classes.name}>
            {hasName ? `${firstName || ""} ${lastName || ""}` : email}
          </span>
          {signatory &&
            (signatoryStatus === "verified" ? <VerifiedIcon /> : <VerificationPendingIcon />)}
          {allFlags?.map((i) => (
            <span style={i.style} className={classes.flag}>
              {i.label}
            </span>
          ))}
        </div>
        <span className={classes.date}>
          {t("added_on")} {date}
        </span>
      </div>
    </div>
  );
};

export default MemberInfo;
