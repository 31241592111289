import { Space } from "antd";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { verifyIdentity } from "../../../../../api/documentApi";
import { Button, Loading } from "../../../../../components/ui-components";
import { setProfileDetailsHighlighted } from "../../../../../store/global/actions";

import {
  CopyIcon,
  IdentityIcon,
  IdentityRejectedIcon,
  IdentityVerificationNeededIcon,
  IdentityVerifiedIcon,
} from "../../../../../assets/icons";

import styles from "./AccountSettingsIdentityVerification.module.scss";

interface IdentityVerificationProps {
  code?: string;
  status: "verified" | "pending" | "rejected" | "verification" | null;
  setUserInfo: any;
}

const AccountSettingsIdentityVerification = ({
  code,
  status,
  setUserInfo,
}: IdentityVerificationProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = async () => {
    const initStatus = status;
    setIsLoading(true);
    try {
      const res = await verifyIdentity();
      const signatoryStatus = res.signatoryStatus;
      setUserInfo((state: any) => ({
        ...state,
        signatoryStatus: signatoryStatus || initStatus,
        voucher: res.voucher || state.voucher,
      }));
    } catch (err: any) {
      setUserInfo((state: any) => ({
        ...state,
        signatoryStatus: "rejected",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (status === "pending") {
      handleVerify();
    }
  }, [status]);

  const handleCheckVerificationDetails = () => {
    window.scrollTo(0, 0);
    dispatch(setProfileDetailsHighlighted());
  };

  const handleCopy = (text?: string) => () => {
    if (!text) return;
    navigator.clipboard.writeText(text);
  };

  const swisscomDirect = () => {
    window.open(
      "https://srsident.trustservices.swisscom.com/en/voucher-checkout/?add-to-cart=62567",
      "_blank"
    );
  };

  const Content = () => {
    if (isLoading) return <Loading customDelay={0} />;
    switch (status) {
      case "verified":
        return (
          <>
            <IdentityVerifiedIcon />
            <span className={styles.title}>{t("id_verified_title")}</span>
            <span className={styles.description}>{t("id_verified_description")}</span>
          </>
        );
      case "rejected":
        return (
          <>
            <IdentityRejectedIcon />
            <span className={styles.title}>{t("id_rejected_title")}</span>
            <span className={styles.description}>
              <Trans t={t} i18nKey='id_rejected_description' components={{ b: <b /> }} />
            </span>
            <Space size={16} className={styles.gutterTop}>
              <Button handleClick={handleCheckVerificationDetails} color='lightBlue' primary>
                {t("check_details")}
              </Button>
              <Button handleClick={handleVerify} color={"blue"} primary>
                {t("try_again")}
              </Button>
            </Space>
          </>
        );
      case "pending":
        return (
          <>
            <IdentityVerificationNeededIcon />
            <span className={styles.title}>{t("id_verification_needed_title")}</span>
            <span className={styles.description}>
              <Trans t={t} i18nKey='id_verification_needed_description' components={{ b: <b /> }} />
            </span>
            <Space className={styles.code} size='middle' align='center'>
              <span className={styles.title}>{code}</span>
              <Button onClick={handleCopy(code)} color='lightBlue' primary icon={<CopyIcon />} />
            </Space>
            <Space>
              <Button
                primary
                color='blue'
                className={styles.gutterTop}
                handleClick={swisscomDirect}
                style={{ width: "201.5px" }}
              >
                {t("verify")}
              </Button>
            </Space>
            <Space>
              <Button handleClick={handleVerify} color={"blue"} primary>
                {t("try_again")}
              </Button>
            </Space>
          </>
        );
      case null:
      default:
        return (
          <>
            <IdentityIcon />
            <span className={styles.title}>{t("id_verification_title")}</span>
            <span className={styles.description}>
              <Trans t={t} i18nKey='id_verification_description' components={{ br: <br /> }} />
            </span>
            <Button className={styles.gutterTop} handleClick={handleVerify} color={"blue"} primary>
              {t("verify")}
            </Button>
          </>
        );
    }
  };

  return (
    <div className={styles.root}>
      <Content />
    </div>
  );
};

export default AccountSettingsIdentityVerification;
