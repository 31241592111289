import { useTranslation } from "react-i18next";
import Select from "react-select";

const LanguageData = [
  { value: "en", label: "English" },
  { value: "de", label: "German" },
];

const colorStyles = {
  control: (base) => ({
    ...base,
    background: "#003E471A",
    width: "140px",
    height: "45px",
    border: "none",
  }),

  singleValue: (base) => ({
    ...base,
    color: "#003E47",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "700",
  }),
  option: (base, state) => ({
    ...base,
    color: "#003E47",
    width: "140px",
    height: "45px",
    backgroundColor: state.isSelected ? "#003E471A" : "#fff",
  }),

  placeholder: (base) => ({
    ...base,
    color: "#003E47",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: "1px",
  }),

  menu: (base) => ({
    ...base,
    width: "140px",
  }),
};

const LanguageSelect = ({ value, onChange }) => {
  const { i18n } = useTranslation();

  const handleChange = (response) => {
    onChange(response);
    i18n.changeLanguage(response.value).then(() => {
      localStorage.setItem("i18nextLng", response.value);
    });
  };

  const currentLanguage = i18n.language === "de" ? "German" : "English";

  return (
    <Select
      onChange={handleChange}
      value={value}
      label='Single select'
      placeholder={currentLanguage}
      options={LanguageData}
      styles={colorStyles}
    />
  );
};

export default LanguageSelect;
