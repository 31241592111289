import API from "./API.js";

export const inviteTeamMembers = async (body, id) => {
  const response = await API().post(`/audit/${id}/team-members/invite`, body);
  return response.data;
};

export const changeRoleTeamMembers = async (body, id, id_team) => {
  try {
    const response = await API().post(`/audit/${id}/team-members/${id_team}/change-role`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateTeamMembers = async (body, id, id_team) => {
  try {
    const response = await API().post(`/audit/${id}/team-members/${id_team}/update`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const listTeamMembers = async (id) => {
  try {
    const response = await API().get(`/audit/${id}/team-members/`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const oneTeamMembers = async (id, id_team) => {
  try {
    const response = await API().get(`/audit/${id}/team-members/${id_team}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteMember = async (id, id_team) => {
  try {
    const response = await API().delete(`/audit/${id}/team-members/${id_team}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
