import React from "react";
import classNames from "classnames/bind";
import styles from "./CardBlock.module.scss";

const cx = classNames.bind(styles);

const CardBlock = ({ onClick, active, children, bordered }) => {
  const blockClassName = cx(styles.root, {
    active,
    bordered,
  });
  return (
    <div onClick={onClick} className={blockClassName}>
      {children}
    </div>
  );
};

export default CardBlock;
