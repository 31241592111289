import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createVoucher,
  deleteVoucher,
  detachVoucher,
  toggleVoucherUsage,
} from "../../services/voucherService";
import { Voucher } from "../../types/vouchers";

export const useVouchers = () => {
  const queryClient = useQueryClient();

  const createVoucherMutation = useMutation({
    mutationFn: createVoucher,
    onSuccess: (result, variables, context) => {
      console.log({ result, variables, context });
      queryClient.setQueryData(["vouchers"], (old: Voucher[]) => [result, ...old]);
    },
  });

  const deleteVoucherMutation = useMutation({
    mutationFn: deleteVoucher,
    onMutate: (variables) => {
      queryClient.setQueryData(["vouchers"], (old: Voucher[]) =>
        old.filter((voucher) => voucher.id !== Number(variables))
      );
    },
  });

  const detachVoucherMutation = useMutation({
    mutationFn: detachVoucher,
    onMutate: (variables) => {
      queryClient.setQueryData(["vouchers"], (old: Voucher[]) =>
        old.map((voucher) =>
          voucher.id === Number(variables) ? { ...voucher, user: null, attachedAt: null } : voucher
        )
      );
    },
  });

  const toggleVoucherUsageMutation = useMutation({
    mutationFn: toggleVoucherUsage,
    onMutate: (variables) => {
      queryClient.setQueryData(["vouchers"], (old: Voucher[]) =>
        old.map((voucher) =>
          voucher.id === variables.id ? { ...voucher, used: variables.used } : voucher
        )
      );
    },
  });

  return {
    createVoucher: createVoucherMutation.mutate,
    deleteVoucher: deleteVoucherMutation.mutate,
    detachVoucher: detachVoucherMutation.mutate,
    toggleVoucherUsage: toggleVoucherUsageMutation.mutate,
  };
};
