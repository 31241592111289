import { CollapseProps, Space } from "antd";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TableHeader from "../../../../../../../Admin/components/TableHeader";

import { ReactComponent as ChartLineIcon } from "../../../../../../../assets/icons/chart-line.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../../../../../assets/icons/search.svg";
import { ReactComponent as StarIcon } from "../../../../../../../assets/icons/star.svg";
import { Button } from "../../../../../../../components/ui-components";
import StatusTableActions from "../StatusTableActions";
import StyledCollapse from "../StyledCollapse";
import CreateFinding from "./CreateFinding";

import styles from "./Findings.module.scss";
import FindingsItem from "./FindingsItem";

const SORT_TITLES = [
  {
    title: `${i18next.t("dashboard:status:risk_table_header_type")} & ${i18next.t(
      "dashboard:status:risk_table_header_description"
    )}`,
    key: "name",
  },
  { title: i18next.t("dashboard:status:risk_table_header_status"), key: "status" },
];

export default function Findings() {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [type, setType] = useState<"all" | "quantitative" | "qualitative">("all");
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <FindingsItem status='high' type='quantitative' title='Group Debtor Balance' />,
    },
    {
      key: "2",
      label: (
        <FindingsItem
          type='improvement'
          title='None and no indication for the necessity to build some.'
          status='medium'
        />
      ),
    },
    {
      key: "3",
      label: <FindingsItem type='qualitative' title='Completeness of other operating expenses' />,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t("findings")}</h3>
        <div className={styles.headerActions}>
          <Button color='gray' icon={<SearchIcon />} />
          <Button small primary color='blue' onClick={() => setIsOpenCreate(true)}>
            <PlusIcon />
            {tGlobal("add_new")}
          </Button>
        </div>
      </div>
      <div className={styles.subheader}>
        <Space>
          <Button
            small
            primary
            color={type === "all" ? "dark" : "gray"}
            onClick={() => setType("all")}
          >
            {tGlobal("all")}
          </Button>
          <Button
            small
            primary
            color={type === "quantitative" ? "dark" : "gray"}
            onClick={() => setType("quantitative")}
          >
            <ChartLineIcon />
            {t("quantitative")}
          </Button>
          <Button
            primary
            color={type === "qualitative" ? "dark" : "gray"}
            small
            onClick={() => setType("qualitative")}
          >
            <StarIcon />
            {t("qualitative")}
          </Button>
        </Space>
        <StatusTableActions />
      </div>
      <TableHeader titles={SORT_TITLES} wrapperStyles={{ gridTemplateColumns: "1fr 100px" }} />
      <div className={styles.list}>
        <StyledCollapse items={items} />
      </div>
      <CreateFinding open={isOpenCreate} onClose={() => setIsOpenCreate(false)} />
    </div>
  );
}
