import { useTranslation } from "react-i18next";

import { Button } from "./ui-components";

import "./styles/checkData.css";

const CheckData = ({ onClick, discard, activeBtn, text }) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className='checkData'>
      <Button style={{ minWidth: "128px" }} color={"red"} onClick={discard} secondary>
        {tGlobal("cancel")}
      </Button>
      <Button
        style={{ minWidth: "128px" }}
        onClick={onClick}
        disabled={!activeBtn}
        primary
        color={"green"}
      >
        {text}
      </Button>
    </div>
  );
};

export default CheckData;
