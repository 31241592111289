import React from "react";

import classes from "./CustomAvatar.module.scss";

const CustomAvatar = ({ user, height = 32, width = 32 }) => {
  const char = (user.firstName || user.email || "")[0];
  const hasLogo = user.logo?.includes("http");

  return (
    <div
      style={{ height, width }}
      className={`${classes.root} ${!user.lastActive ? classes.inactive : ""}`}
    >
      {hasLogo ? <img src={user.logo} alt='' /> : char}
    </div>
  );
};

export default CustomAvatar;

// export default memo(CustomAvatar, (prev, next) => {
//   const prevURL = new URL(prev.user.logo);
//   const nextURL = new URL(next.user.logo);
//   return prevURL.pathname === nextURL.pathname;
// });
