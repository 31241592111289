import React from "react";
import { Redirect, Route } from "react-router";
import helper from "../consts/helper";
import { saveLastVisitedPage } from "../utils/saveLastVisitedPage";

const PrivateRoute = ({ children, ...props }) => {
  const isAuthenticated = localStorage.getItem(helper.JWT_TOKEN);
  return (
    <Route
      {...props}
      render={(_) => {
        if (!isAuthenticated) {
          saveLastVisitedPage();
          return <Redirect to='/login' />;
        }
        return children;
      }}
    />
  );
};
export default PrivateRoute;
