import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { exportDocument } from "../../../../api/documentApi";
import API from "../../../../api/API";
import Keys from "../../../../consts/helper";

import BexioIcon from "../../../../AdminComponents/icons/BexioIcon";
import { FolderIcon } from "../../../../AdminComponents/icons";
import { Button, Label } from "../../../../components/ui-components";
import AttachmentRow from "./components/AttachmentRow/AttachmentRow";
import BoxioAttachment from "./components/BoxioAttachment/BoxioAttachment";
import DocumentOption from "../../../components/DocumentOption";
import SelectEntity from "../SelectEntity/SelectEntity";
import AttachmentsAuditorInfo from "./components/AttachmentsAuditorInfo";

import styles from "./Attachments.module.scss";

const Attachments = (props) => {
  const {
    relatedDocs = [],
    isBoxioRequest,
    attachments = [],
    progress,
    onAddFile,
    newDocument,
    onRemoveFile,
    noLabel = false,
    multiple = false,
    bexio_refresh = false,
    customDropzoneText,
    customDropzoneIcon,
    restrictMode = {},
    hasAdminPermissions,
    lastFetchDate: initialLastFetchDate,
    hasSearch,
    searchOptions = [],
    onAttach,
    label,
    disabled,
  } = props;
  const { id: idAudit, requestId } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [lastFetchDate, setLastFetchDate] = useState(initialLastFetchDate);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    multiple: multiple,
    accept: restrictMode,
    disabled: isBoxioRequest || progress > 0,
    onDropAccepted: !isBoxioRequest && !progress && onAddFile,
  });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const handleClick = async () => {
    if (isBoxioRequest && bexio_refresh) {
      await refetchBexio();
      return;
    }

    if (isBoxioRequest) {
      await connectBexio();
      return;
    }

    open();
  };

  const connectBexio = async () => {
    localStorage.setItem(Keys.BEXIO_REDIRECT_URL, window.location.pathname);
    localStorage.setItem(
      Keys.BEXIO_REQUEST_DATA,
      JSON.stringify({
        requestId: requestId || "quote",
        auditId: idAudit,
        origin: window.location.origin,
      })
    );
    API()
      .get("/bexio/login")
      .then((res) => {
        window.open(res.data, "_self");
      });
  };

  const exportBexioData = async () => {
    await exportDocument(idAudit, requestId);
  };

  const refetchBexio = () => {
    setIsFetching(true);
    API()
      .post(`/bexio/${idAudit}/refetch/${requestId}`)
      .then((res) => {
        setLastFetchDate(res.data.lastFetched);
      })
      .catch(() => {
        // setActiveData((state) => ({
        //   ...state,
        //   bexio_refresh: "error",
        // }));
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const dropzoneText = useMemo(() => {
    if (customDropzoneText) {
      return customDropzoneText;
    }

    if (isBoxioRequest && bexio_refresh) {
      return tGlobal("bexio_connected");
    }
    if (newDocument) {
      return tGlobal("select_file");
    }

    if (isBoxioRequest) {
      return tGlobal("connect_bexio");
    }

    return tGlobal("drag_drop");
  }, [bexio_refresh, customDropzoneText, isBoxioRequest]);

  // const boxioError = bexio_refresh === "error";
  // const boxioSuccess = bexio_refresh && !boxioError;
  const dropzoneIcon = customDropzoneIcon || (isBoxioRequest ? <BexioIcon /> : <FolderIcon />);
  const dropzoneButtonText = isBoxioRequest
    ? bexio_refresh
      ? tGlobal("refetch")
      : tGlobal("connect")
    : tGlobal("browse");
  const showRelatedDocs = relatedDocs?.length > 0;
  const showAttachments = attachments?.length > 0;
  const showAttachmentList = bexio_refresh || showRelatedDocs || showAttachments;
  const dropzoneClasses = clsx(styles.attachment_wrapper, {
    [styles.boxioConnected]: isBoxioRequest && bexio_refresh,
    [styles.dragActive]: isDragActive,
  });

  const allAttachments = useMemo(
    () => [...relatedDocs, ...attachments],
    [relatedDocs, attachments]
  );

  return (
    <div className={styles.container}>
      {noLabel || <Label>{label || tGlobal("attachments")}</Label>}
      <div className={`${styles.container}  ${disabled ? styles.disabled : ""}`}>
        <div className={dropzoneClasses} {...(isBoxioRequest ? {} : getRootProps())}>
          <div className={styles.attachment_dropzone}>
            {isBoxioRequest || (
              <input
                type='file'
                name='attachments'
                hidden
                onChange={onAddFile}
                {...getInputProps()}
              />
            )}
            <div className={styles.text_img_wrapper}>
              <div className={styles.img_wrapper}>{dropzoneIcon}</div>
              <p className={styles.text}>{dropzoneText}</p>
            </div>
            <Button
              secondary
              disabled={isFetching || progress > 0}
              color={isBoxioRequest ? "green" : "blue"}
              onClick={handleClick}
            >
              {dropzoneButtonText}
            </Button>
          </div>
          {!isBoxioRequest && hasSearch && (
            <div className={styles.attachment_search}>
              <SelectEntity
                name={"attachedDocuments"}
                values={allAttachments}
                onChange={onAttach}
                dropdownLabel={"The latest documents"}
                placeholder={"Search existing files"}
                url={`/dashboard/${idAudit}/documents/`}
                options={searchOptions}
                hideValues
                optionComponent={({ document, name, id }) => (
                  <DocumentOption document={document} name={name} key={id} />
                )}
              />
            </div>
          )}
        </div>
      </div>
      {showAttachmentList && (
        <div className={styles.attachmentList}>
          {isBoxioRequest &&
            bexio_refresh &&
            (hasAdminPermissions ? (
              <BoxioAttachment lastFetchDate={lastFetchDate} download={exportBexioData} />
            ) : (
              <AttachmentsAuditorInfo />
            ))}
          {!isBoxioRequest &&
            showRelatedDocs &&
            relatedDocs.map((el) => (
              <AttachmentRow key={el.id} file={el} deleteDocument={onRemoveFile} />
            ))}
          {!isBoxioRequest &&
            showAttachments &&
            attachments?.map((el) => (
              <AttachmentRow
                key={el.id}
                file={el}
                progress={progress}
                deleteDocument={onRemoveFile}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Attachments;
