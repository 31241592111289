import React from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui-components";

const DangerZoneCard = ({ onDelete, roleName }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  return (
    <Space.Compact direction={"vertical"} block>
      <Row align={"top"}>
        <Col span={8}>
          <span>{t("delete_role")}</span>
        </Col>
        <Col span={16}>
          <Button handleClick={onDelete} secondary color={"red"} style={{ width: "max-content" }}>
            {t("delete_permission_role")} {roleName}
          </Button>
          <span className='danger_zone_warning'>{t("delete_permission_role_desc")}</span>
        </Col>
      </Row>
    </Space.Compact>
  );
};

export default DangerZoneCard;
