import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./InputHelperText.module.scss";

type InputHelperTextProps = {
  children?: ReactNode;
  error?: boolean;
  className?: string;
};

export default function InputHelperText({ children, className, error }: InputHelperTextProps) {
  return (
    <div className={classNames(styles.root, className, error && styles.error)}>{children}</div>
  );
}
