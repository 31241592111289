import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

import { Navigation } from "../../Routers/Navigation";
import DashboardAside from "./DashboardAside";
import SyncAuditData from "../../Admin/components/SyncAuditData";
import HeaderAdmin from "../../Admin/components/HeaderAdmin";
import Chat from "../Chat";

import "./dashboard_main.css";
import styles from "./DashboardLayout.module.scss";

const DashboardLayout = (props) => {
  const { pathname } = useLocation();

  const hideAdminHeader = /\/dashboard\/\d+\/documents\/\d+/.test(pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SyncAuditData>
      <div className={styles.wrapper}>
        <DashboardAside pathname={pathname} />
        <div className={styles.content} id='dashboardMain'>
          {hideAdminHeader || <HeaderAdmin dashboard backTo='/audits' />}
          {Navigation.adminDashboardMain.map((route) => (
            <Route
              path={route.path}
              exact={route.exact ?? true}
              {...props}
              component={route.main}
              key={route.path}
            />
          ))}
        </div>
        <Chat />
      </div>
    </SyncAuditData>
  );
};

export default DashboardLayout;
