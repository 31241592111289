import React, { useState } from "react";
import OutsideClickHandler from "../../../consts/detectOutSideClick";
import styles from "./Menu.module.scss";
interface MenuProps {
  handleChangeToggle: (value: boolean) => void;
  children: React.ReactNode | string | number | React.ReactElement[];
}

const Menu: React.FC<MenuProps> = ({ handleChangeToggle, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (handleChangeToggle) {
      handleChangeToggle(!isOpen);
    }
  };
  return (
    <OutsideClickHandler
      callback={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.menu}>
        <div className={styles.menu_button} onClick={handleToggle}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
        {isOpen && <div className={styles.dropdown}>{children}</div>}
      </div>
    </OutsideClickHandler>
  );
};
export default Menu;
