import { useMemo } from "react";
import classNames from "classnames";
import { Dropdown, Button, MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import { ThinChevronBottom } from "../../../../assets/icons";

import styles from "./StatusDropdown.module.scss";

interface Status<T extends string> {
  label?: string;
  key: T;
  color: string;
  onClick?: () => void;
}

type StatusDropdownProps<T extends string> = {
  value: T;
  onChange: (key: T) => void;
  statuses: Array<Status<T>>;
};

export default function StatusDropdown<T extends string>({
  statuses,
  value,
  onChange,
}: StatusDropdownProps<T>) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const selectedStatus = useMemo(
    () => statuses.find((status) => status.key === value) || statuses[0],
    [value, statuses]
  );

  const items: MenuProps["items"] = statuses.map((status) => ({
    key: status.key,
    label: t(status.key),
    icon: (
      <span className={classNames(styles.statusDot)} style={{ backgroundColor: status.color }} />
    ),
  }));

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const status = statuses.find((s) => s.key === e.key);
    if (status) {
      onChange(status.key);
      status.onClick?.();
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      trigger={["click"]}
      overlayClassName={styles.root}
      overlayStyle={{ minWidth: 200, zIndex: 30002 }}
    >
      <div className={styles.wrapper}>
        <Button
          block
          type='primary'
          className={styles.button}
          style={{
            color: selectedStatus.color,
          }}
        >
          <div className={styles.buttonBg} style={{ backgroundColor: selectedStatus.color }} />
          {t(selectedStatus.key)}
          <div className={styles.indicator}>
            <ThinChevronBottom />
          </div>
        </Button>
      </div>
    </Dropdown>
  );
}
