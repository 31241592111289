import React from "react";
import "./styles/dataProtection.css";
import { useTranslation } from "react-i18next";

const DataProtection = () => {
  const { t } = useTranslation("dataPrivacy");
  return (
    <div className={"data-protection-wrapper"}>
      <div className={"data-protection-block"}>
        <span className={"data-protection-title"}>{t("privacy_policy")}</span>
        <p className={"data-protection-description"}>{t("privacy_policy_description_1")}</p>
        <p className={"data-protection-description"}>{t("privacy_policy_description_2")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("personal_data")}</span>
        <p className={"data-protection-description"}>{t("personal_data_description_1")}</p>
        <span className={"data-protection-description"}>{t("personal_data_description_2")}</span>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("personal_data_usage")}</span>
        <p className={"data-protection-description"}>{t("personal_data_usage_description_1")}</p>
        <p className={"data-protection-description"}>{t("personal_data_usage_description_2")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("storage_period")}</span>
        <p className={"data-protection-description"}>{t("storage_period_description")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("contact")}</span>
        <p className={"data-protection-description"}>{t("contact_description")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("aivo_use")}</span>
        <p className={"data-protection-description"}>{t("aivo_use_description")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("secure_personal_data")}</span>
        <p className={"data-protection-description"}>{t("secure_personal_data_description")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("disclaimer")}</span>
        <p className={"data-protection-description"}>{t("disclaimer_description")}</p>
      </div>

      <div className={"data-protection-block"}>
        <span className={"data-protection-headline"}>{t("google_analytics")}</span>
        <p className={"data-protection-description"}>{t("google_analytics_description_1")}</p>
        <p className={"data-protection-description"}>{t("google_analytics_description_2")}</p>
        <p className={"data-protection-description"}>{t("google_analytics_description_3")}</p>
        <p className={"data-protection-description"}>{t("google_analytics_description_4")}</p>
        <p className={"data-protection-description"}>{t("google_analytics_description_5")}</p>
      </div>
    </div>
  );
};

export default DataProtection;
