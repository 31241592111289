import React from "react";

const MeetingIcon = ({ ...restAttributes }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <path
      d='M13 7.05741C13 10.6871 9.29142 13.2747 8.26214 13.923C8.09919 14.0257 7.90081 14.0257 7.73786 13.923C6.70858 13.2747 3 10.6871 3 7.05741C3 4.26429 5.23858 2 8 2C10.7614 2 13 4.26429 13 7.05741Z'
      stroke='#003E47'
      stroke-opacity='0.6'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M10 7C10 8.10458 9.10458 9 8 9C6.89542 9 6 8.10458 6 7C6 5.89542 6.89542 5 8 5C9.10458 5 10 5.89542 10 7Z'
      stroke='#003E47'
      stroke-opacity='0.6'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default MeetingIcon;
